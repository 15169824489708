import { useRequest } from 'ahooks';
import { Button } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import pnApi from '../../../api/pn-api';
import { useUserInfo } from '../../../context';
import hashPassword from '../../../utils/hashPassword';
import ParticleKeywords from '../ParticleKeywords/index';

import SVGIcon from '../../../components/icon/svg-icon';
import tokenProvider from '../../../provider';
import { isSignQuery } from '../../../utils/common-utils';
import { isEMVMethod } from '../../../utils/transaction-utils';
import { PageType } from '../accountVerify/config';
import './index.less';

const SetPaymentPassword = () => {
    const router = useNavigate();
    const { t } = useTranslation();
    const { state } = useLocation();
    // 1. loading  3. password  4,success 5. check 6. code 7  confirm password
    type StepType = 'loading' | 'password' | 'success' | 'check' | 'code' | 'confirm';
    const [step, setStep] = useState<StepType>('loading');
    const [codeVal, setCodeVal] = useState('');
    const [password, setPassword] = useState('');
    const [passwordMistake, setPasswordMistake] = useState<boolean>();
    const [passwordMatched, setPasswordMatched] = useState<boolean>(false);
    const { setUserInfo } = useUserInfo();
    const { loading: submitLoading, run: runSubmit } = useRequest(pnApi.securityPayments, {
        manual: true,
        onSuccess: (result, params) => {
            setUserInfo({
                security_account: result,
            });
            if (isSignQuery(tokenProvider.params)) {
                if (isEMVMethod(tokenProvider.params.method)) {
                    router('/evm-chain/sign', { replace: true });
                } else {
                    router('/solana/sign', { replace: true });
                }
            } else {
                router('/account/security', { replace: true });
            }
        },
        onError: (error) => {
            console.log('set password error', error);
        },
    });

    const { loading: updateLoading, run: runUpdate } = useRequest(pnApi.securityPaymentsUpdate, {
        manual: true,
        onSuccess: (result, params) => {
            setUserInfo({
                security_account: result,
            });
            router('/account/security', { replace: true });
        },
        onError: (error: any) => {
            console.log('upate password error', error);
            if (error?.error_code === 50104) {
                setCodeVal('');
                setPassword('');
                setPasswordMistake(false);
                setStep('password');
            }
        },
    });

    useEffect(() => {
        setStep('password');
    }, []);

    const checkPassword = (value: string) => {
        if (value.length === 6) {
            if (value === password) {
                setCodeVal(value);
                setPasswordMatched(true);
            } else {
                setCodeVal('');
                setPassword('');
                setPasswordMistake(true);
                setStep('password');
                setPasswordMatched(false);
            }
        } else {
            setCodeVal(value);
            setPasswordMatched(false);
        }
    };

    const submitPassword = () => {
        if ((state as any)?.account) {
            //reset password
            router('/account/verify', {
                state: {
                    account: (state as any)?.account,
                    password: codeVal,
                    pageType: PageType.ResetPaymentPassword,
                },
            });
        } else if ((state as any)?.old_password) {
            //change password
            const oldPassword = hashPassword((state as any)?.old_password);
            const password = hashPassword(codeVal);
            runUpdate({
                password: password,
                old_password: oldPassword,
            });
        } else {
            const str = hashPassword(codeVal);
            runSubmit({
                password: str,
            });
        }
    };

    if (step === 'confirm') {
        return (
            <div className="set-password-container">
                <SVGIcon className="icon-navigation-back" name="circle_back" onClick={() => router(-1)} />
                <h2 className="payment-title">{t('account.set_payment_password')}</h2>
                <p className="payment-desc-1">{t('account.re_enter_confirm')}</p>
                <div className="keyboard-container">
                    <ParticleKeywords onChange={checkPassword} value={codeVal}></ParticleKeywords>
                </div>
                <div className="payment-buttons2">
                    <Button
                        className="payment-main-button2"
                        disabled={!passwordMatched}
                        loading={(state as any)?.old_password ? updateLoading : submitLoading}
                        onClick={submitPassword}
                    >
                        {t('account.done')}
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <div className="set-password-container">
            <SVGIcon className="icon-navigation-back" name="circle_back" onClick={() => router(-1)} />
            <h2 className="payment-title">{t('account.set_payment_password')}</h2>
            <p className="payment-desc-1">{t('account.set_payment_password_tip')}</p>
            <div className="keyboard-container">
                <ParticleKeywords
                    onChange={(e) => {
                        setPassword(e);
                        if (e.length > 5) {
                            setPasswordMistake(false);
                            setPasswordMatched(false);
                            setStep('confirm');
                        }
                    }}
                    value={password}
                ></ParticleKeywords>
            </div>

            {passwordMistake && <div className="password-mistake">{t('account.password_do_not_match')}</div>}
        </div>
    );
};

export default SetPaymentPassword;
