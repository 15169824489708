export interface ThirdpartyUserInfo {
    provider: string;
    user_info: {
        id: string;
        email: string;
        name: string;
        picture: string;
    };
}

export interface SecurityAccount {
    email?: string;
    phone?: string;
    has_set_payment_password: boolean;
    has_set_master_password: boolean;
    payment_password_updated_at?: string;
}

export interface UserInfo {
    id: number;
    uuid: string;
    phone?: string;
    email?: string;
    created_at: string;
    updated_at: string;
    mac_key: string;
    token: string;
    wallets: WalletInfo[];
    cognito_result: CognitoResult;
    thirdparty_user_info?: ThirdpartyUserInfo;
    security_account?: SecurityAccount;
    google_id?: string;
    google_email?: string;
    facebook_id?: string;
    facebook_email?: string;
    twitter_id?: string;
    twitter_email?: string;
    passkeys_id?: string;
}

export type UserPreview = Omit<UserInfo, 'wallets' | 'mac_key' | 'cognito_result' | 'id'>;

export type UserInfoSync = Omit<UserInfo, 'token' | 'mac_key' | 'cognito_result' | 'id' | 'thirdparty_user_info'>;

export enum WalletEncryptedType {
    KMS_AES = 0,
    KMS_AES_WITH_DATA_KEY_AND_DEFAULT_MASTER_PASSWORD = 1,
    KMS_AES_WITH_DATA_KEY_AND_MASTER_PASSWORD = 2,
}

export type WalletInfo = {
    uuid: string;
    user_uuid: string;
    chain_name: string;
    public_address?: string;
    encrypted_data: string;
    encrypted_type?: WalletEncryptedType;
    encrypted_kms_data_key?: string;
};

export type Wallet = Pick<WalletInfo, 'uuid' | 'chain_name' | 'public_address'>;

export type CognitoResult = {
    region: string;
    identity_id: string;
    id_token: string;
    kms_key_id: string;
};

export interface UserWalletRequestParam {
    encrypted_type: number;
    encrypted_kms_data_key: string;
    wallets: {
        wallet_uuid: string;
        encrypted_data: string;
        public_address?: string;
    }[];
}

export interface LoginAccount {
    id_combined: string;
    user_uuid: string;
    provider: string;
    created_at: string;
}

export interface SmartAccount {
    smartAccountAddress: string;
}
