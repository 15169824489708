import { LoadingOutlined } from '@ant-design/icons';
import { Turnstile } from '@marsidev/react-turnstile';
import { Modal, Spin } from 'antd';
import tokenProvider from '../../provider';
import './index.less';

interface TurnstileModalProp {
    onSuccess: (token: string) => void;
    onError: () => void;
    visible: boolean;
    setVisible: (visible: boolean) => void;
}

const TurnstileModal = (prop: TurnstileModalProp) => {
    const { onSuccess, onError, visible, setVisible } = prop;

    const getLanguage = () => {
        const lng = tokenProvider.launage?.replace('_', '-');
        if (lng) {
            if (lng === 'zh-HK') {
                return 'zh-TW';
            } else {
                return lng.split('-')[0];
            }
        }
        return 'en';
    };

    return (
        <Modal className="turnstile-modal" visible={visible} centered={true} closable={false} destroyOnClose={true}>
            <div className="turnstile-loading">
                <Spin indicator={<LoadingOutlined className="spin" spin />}></Spin>
            </div>
            <Turnstile
                className="turnstile-box"
                siteKey="0x4AAAAAAAEh4p1bZ_p-6CWN"
                onSuccess={(token) => {
                    setVisible(false);
                    onSuccess(token);
                }}
                onError={() => {
                    setVisible(false);
                    onError();
                }}
                options={{ theme: (tokenProvider.themeType as any) || 'light', language: getLanguage() as any }}
            />
        </Modal>
    );
};

export default TurnstileModal;
