function SplashLoading(props: any) {
    const { visible } = props;
    return visible ? (
        <div className="start-loading">
            <div className="lds-default">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className="power-text">Powered by</div>
            <div className="logo-text">Particle Network</div>
        </div>
    ) : null;
}

export default SplashLoading;
