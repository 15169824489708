import { decrypt, decryptDataKey, generateDataKey } from '../aws/kms';
import tokenProvider from '../provider';
import { PromptMasterPasswordSettingWhenLoginType } from '../provider/bundle';
import { PreferenceKey, load, once, removeItem, save } from '../repository';
import { isNullish } from '../utils/common-utils';
import required from '../utils/validate';
import { WalletEncryptedType, WalletInfo } from './model/user-info';
import pnApi from './pn-api';
import walletUtils from './wallet';

/**
 * evm and solan wallet will be created when set master password
 * @param password
 * @param changePassword
 * @returns
 */
export const setMasterPassword = async (password: string, changePassword = false): Promise<boolean> => {
    const userInfo = tokenProvider.userInfo;
    const outputDK = await generateDataKey();
    const localKey = await walletUtils.generateKey(password, userInfo.uuid);
    const localKMSDataKey = await walletUtils.generateKey(
        Buffer.from(outputDK.Plaintext as any).toString('base64'),
        userInfo.uuid
    );
    const requests = userInfo.wallets.map((wallet) => {
        if (wallet.encrypted_type === WalletEncryptedType.KMS_AES) {
            if (isNullish(wallet.public_address)) {
                return createType2Wallet(wallet, localKey, localKMSDataKey);
            } else {
                return encryptType0Wallet(wallet, localKey, localKMSDataKey);
            }
        } else if (wallet.encrypted_type === WalletEncryptedType.KMS_AES_WITH_DATA_KEY_AND_DEFAULT_MASTER_PASSWORD) {
            return encryptTypeWallet(wallet, localKey, localKMSDataKey);
        } else {
            return changePassword
                ? encryptTypeWallet(wallet, localKey, localKMSDataKey)
                : Promise.reject('The master password has been set');
        }
    });

    const encryptResults = await Promise.all(requests);

    // check whether can decrypt
    for (let result of encryptResults) {
        const decryptResult = await decryptP2Key(result.p2KeyEncrypted2, localKey, localKMSDataKey);
        required(decryptResult === result.p2Key, 'check decrypt p2Key error');
    }

    const wallets = encryptResults.map((item) => {
        const { uuid, public_address } = item.wallet;
        return {
            wallet_uuid: uuid,
            public_address: (item as any).created ? public_address : undefined,
            encrypted_data: item.p2KeyEncrypted2,
        };
    });
    const encryptedKMSDataKey = Buffer.from(outputDK.CiphertextBlob as any).toString('base64');
    const updateResult = await pnApi.updateWallet(userInfo.uuid, {
        encrypted_type: WalletEncryptedType.KMS_AES_WITH_DATA_KEY_AND_MASTER_PASSWORD,
        encrypted_kms_data_key: encryptedKMSDataKey,
        wallets,
    });

    // save local-key and local-kms-data-key
    saveLocalKey(localKey);
    saveLocalKMSDataKey(encryptedKMSDataKey, localKMSDataKey);
    userInfo.wallets.forEach((wallet) => {
        const newWallet = wallets.find((item) => item.wallet_uuid === wallet.uuid);
        if (newWallet?.encrypted_data) {
            wallet.encrypted_data = newWallet.encrypted_data;
            wallet.encrypted_kms_data_key = encryptedKMSDataKey;
            wallet.encrypted_type = WalletEncryptedType.KMS_AES_WITH_DATA_KEY_AND_MASTER_PASSWORD;
            if (newWallet.public_address) {
                wallet.public_address = newWallet.public_address;
            }
        }
    });
    if (userInfo.security_account) {
        userInfo.security_account.has_set_master_password = true;
    }
    tokenProvider.userInfo = userInfo;
    if (!changePassword) {
        sessionStorage.setItem(PreferenceKey.PN_TEMP_SECURITY_ACCOUNT_CHANGED, '1');
    }

    return updateResult;
};

export const createType2Wallet = async (wallet: WalletInfo, localKey: string, localKMSDataKey: string) => {
    const { p2Key, address } = await walletUtils.create(wallet.chain_name);
    const p2KeyEncrypted2 = await encryptP2Key(p2Key, localKey, localKMSDataKey);
    wallet.public_address = address;
    return {
        p2Key,
        p2KeyEncrypted2,
        wallet,
        created: true,
    };
};

export const createEncryptedWallet = async (
    wallet: WalletInfo,
    p2Key: string,
    address: string,
    encryptedKMSDataKey: string,
    localKMSDataKey?: string
): Promise<boolean> => {
    const userInfo = tokenProvider.userInfo;
    const encryptedType = walletUtils.hasMasterPassword()
        ? WalletEncryptedType.KMS_AES_WITH_DATA_KEY_AND_MASTER_PASSWORD
        : WalletEncryptedType.KMS_AES_WITH_DATA_KEY_AND_DEFAULT_MASTER_PASSWORD;

    if (!localKMSDataKey) {
        localKMSDataKey = await loadLocalKMSDataKey(encryptedKMSDataKey);
    }

    let localKey;
    if (encryptedType === WalletEncryptedType.KMS_AES_WITH_DATA_KEY_AND_MASTER_PASSWORD) {
        localKey = await loadLocalKey();
    } else {
        localKey = await walletUtils.generateKey('', userInfo.uuid);
    }

    const p2KeyEncrypted2 = await encryptP2Key(p2Key, localKey, localKMSDataKey);

    // check whether can decrypt
    const decryptResult = await decryptP2Key(p2KeyEncrypted2, localKey, localKMSDataKey);
    required(decryptResult === p2Key, 'check decrypt p2Key error');

    const updateResult = await pnApi.updateWallet(userInfo.uuid, {
        encrypted_type: encryptedType,
        encrypted_kms_data_key: encryptedKMSDataKey,
        wallets: [
            {
                wallet_uuid: wallet.uuid,
                encrypted_data: p2KeyEncrypted2,
                public_address: address,
            },
        ],
    });

    // save local-key and local-kms-data-key
    saveLocalKey(localKey);
    saveLocalKMSDataKey(encryptedKMSDataKey, localKMSDataKey);

    const newWallet = userInfo.wallets.find((item) => item.uuid === wallet.uuid);
    if (newWallet) {
        newWallet.encrypted_data = p2KeyEncrypted2;
        newWallet.encrypted_kms_data_key = encryptedKMSDataKey;
        newWallet.encrypted_type = encryptedType;
        newWallet.public_address = address;
    }
    tokenProvider.userInfo = userInfo;

    return updateResult;
};

export const decryptWallet = async (wallet: WalletInfo): Promise<string> => {
    let p2Key = '';
    if (wallet.encrypted_type === WalletEncryptedType.KMS_AES) {
        p2Key = await decrypt(wallet.encrypted_data, wallet?.chain_name === 'evm_chain');
    } else {
        const localKey = await loadLocalKey();
        const localKMSDataKey = await loadLocalKMSDataKey(wallet.encrypted_kms_data_key || '');
        p2Key = await decryptP2Key(wallet.encrypted_data, localKey, localKMSDataKey);
    }

    return p2Key;
};

const encryptType0Wallet = async (wallet: WalletInfo, localKey: string, localKMSDataKey: string) => {
    let p2Key = await decrypt(wallet.encrypted_data, wallet?.chain_name === 'evm_chain');
    if (wallet?.chain_name === 'evm_chain') {
        p2Key = await walletUtils.refreshEcdsa(p2Key);
    }

    const p2KeyEncrypted2 = await encryptP2Key(p2Key, localKey, localKMSDataKey);
    return {
        p2Key,
        p2KeyEncrypted2,
        wallet,
    };
};

const encryptTypeWallet = async (wallet: WalletInfo, localKey: string, localKMSDataKey: string) => {
    const oldLocalKMSDataKey = await loadLocalKMSDataKey(wallet.encrypted_kms_data_key || '');
    const oldLocalKey = await loadLocalKey();

    let p2Key = await decryptP2Key(wallet.encrypted_data, oldLocalKey, oldLocalKMSDataKey);

    if (wallet?.chain_name === 'evm_chain') {
        p2Key = await walletUtils.refreshEcdsa(p2Key);
    }
    const p2KeyEncrypted2 = await encryptP2Key(p2Key, localKey, localKMSDataKey);
    return {
        p2Key,
        p2KeyEncrypted2,
        wallet,
    };
};

const decryptP2Key = async (p2KeyEncrypted: string, localKey: string, localKMSDataKey: string) => {
    const p2KeyEncrypted1 = await walletUtils.decryptData(p2KeyEncrypted, localKMSDataKey);
    const p2Key = await walletUtils.decryptData(p2KeyEncrypted1, localKey);
    return p2Key;
};

const encryptP2Key = async (p2Key: string, localKey: string, localKMSDataKey: string) => {
    const p2KeyEncrypted1 = await walletUtils.encryptData(p2Key, localKey);
    const p2KeyEncrypted2 = await walletUtils.encryptData(p2KeyEncrypted1, localKMSDataKey);
    return p2KeyEncrypted2;
};

const loadLocalKMSDataKey = async (encryptedKMSDataKey: string) => {
    if (!encryptedKMSDataKey) {
        throw new Error('encryptedKMSDataKey not found');
    }
    let localKMSDataKey = load(encryptedKMSDataKey);
    if (localKMSDataKey) {
        return localKMSDataKey;
    }
    const kmsDataKey = await decryptDataKey(encryptedKMSDataKey);
    localKMSDataKey = await walletUtils.generateKey(kmsDataKey, tokenProvider.userInfo.uuid);
    saveLocalKMSDataKey(encryptedKMSDataKey, localKMSDataKey);
    return localKMSDataKey;
};

const loadLocalKey = async (): Promise<string> => {
    const localKey = load(PreferenceKey.PN_LOCAL_KEY + tokenProvider.userInfo.uuid);
    if (!localKey) {
        if (walletUtils.hasMasterPassword()) {
            throw new Error('Local Key not found');
        } else {
            const localKey = await walletUtils.generateKey('', tokenProvider.userInfo.uuid);
            saveLocalKey(localKey);
            return localKey;
        }
    }
    return localKey;
};

export const hasLocalKey = () => {
    const localKey = load(PreferenceKey.PN_LOCAL_KEY + tokenProvider.userInfo.uuid);
    return !isNullish(localKey);
};

export const checkMasterPassword = async (password: string): Promise<boolean> => {
    const localKey = await walletUtils.generateKey(password, tokenProvider.userInfo.uuid);
    const wallet = tokenProvider.userInfo.wallets.find(
        (item) =>
            !isNullish(item.public_address) &&
            item.encrypted_type === WalletEncryptedType.KMS_AES_WITH_DATA_KEY_AND_MASTER_PASSWORD
    );
    if (!wallet) {
        throw new Error('master password not set');
    }
    const localKMSDataKey = await loadLocalKMSDataKey(wallet.encrypted_kms_data_key || '');
    const p2KeyEncrypted1 = await walletUtils.decryptData(wallet.encrypted_data, localKMSDataKey);
    await walletUtils.decryptData(p2KeyEncrypted1, localKey);
    saveLocalKey(localKey);
    return true;
};

export const clearLocalKeyAndLocalKMSDataKey = () => {
    removeItem(PreferenceKey.PN_LOCAL_KEY + tokenProvider.userInfo.uuid);
    // local kms data key use encryptedKMSDataKey as key, so don't need to remove
};

export const saveLocalKey = (localKey: string) => {
    save(PreferenceKey.PN_LOCAL_KEY + tokenProvider.userInfo.uuid, localKey);
};

export const saveLocalKMSDataKey = (encryptedKMSDataKey: string, localKMSDataKey: string) => {
    save(encryptedKMSDataKey, localKMSDataKey);
};

export const isPromptSetMasterPassword = (promptMasterPasswordSettingWhenLogin?: number | boolean) => {
    if (
        promptMasterPasswordSettingWhenLogin === PromptMasterPasswordSettingWhenLoginType.every ||
        promptMasterPasswordSettingWhenLogin === PromptMasterPasswordSettingWhenLoginType.everyAndNotSkip
    ) {
        return true;
    } else if (
        promptMasterPasswordSettingWhenLogin ||
        promptMasterPasswordSettingWhenLogin === PromptMasterPasswordSettingWhenLoginType.first
    ) {
        return !once(PreferenceKey.PN_OPEN_SET_MASTER_PASSWORD + tokenProvider.userInfo.uuid);
    } else {
        return false;
    }
};

export const restoreType1Wallet = async () => {
    const wallet = tokenProvider.userInfo.wallets.find(
        (item) => item.encrypted_type === WalletEncryptedType.KMS_AES_WITH_DATA_KEY_AND_DEFAULT_MASTER_PASSWORD
    );
    if (wallet) {
        const localKey = await walletUtils.generateKey('', tokenProvider.userInfo.uuid);
        saveLocalKey(localKey);
        await loadLocalKMSDataKey(wallet.encrypted_kms_data_key || '');
    }
};
