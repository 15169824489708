import Icon from '@ant-design/icons';

const SVGIcon = ({ className = '', name = '', ...others }) => {
    const svg = require(`../../common/icon/${name}.svg`).default;
    return (
        <Icon
            className={className ? `theme-font-icon ${className}` : 'theme-font-icon'}
            //@ts-ignore
            component={svg}
            {...others}
        />
    );
};

export default SVGIcon;
