import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PowerFooter from '../../../components/power-footer';
import { useParticleAuth } from '../../../context';
import useSignData from '../../../context/hooks/useSignData';
import { PromptMasterPasswordSettingWhenLoginType } from '../../../provider/bundle';
import authorizeUtils from '../../../utils/authorizeUtils';
import ParticleDrawer from '../../sign/components/particle-drawer';
import './index.less';

const MasterPasswordModal = (props: any) => {
    const { t } = useTranslation();
    const { visible, setVisible } = props;
    const navigate = useNavigate();
    const { signWithConfirm } = useSignData();
    const { loginSuccessRedirectToApp, securityAccountConfig } = useParticleAuth();
    const navigateToSetMasterPassword = () => {
        setVisible(false);
        navigate('/account/master-password', {
            state: {
                loginSetMasterPassword: true,
            },
        });
    };

    const laterToSet = async () => {
        setVisible(false);

        // 登录免确认签名：老用户登录成功不设置主密码跳转签名页面 start
        if (authorizeUtils.isNeedAuthorize()) {
            if (!(await signWithConfirm())) {
                return;
            }
        }
        // 登录免确认签名：老用户登录成功不设置主密码跳转签名页面 end

        loginSuccessRedirectToApp();
    };

    return (
        <ParticleDrawer
            title={t('account.set_master_password')}
            placement="bottom"
            className="master-password-drawer"
            closable={false}
            visible={visible}
            maskClosable={false}
            onClose={() => laterToSet()}
            height={
                // 强制设置主密码少个按钮，高度减少100px
                securityAccountConfig.promptMasterPasswordSettingWhenLogin ===
                PromptMasterPasswordSettingWhenLoginType.everyAndNotSkip
                    ? 531
                    : 594
            }
            zIndex={10000}
        >
            <div className="mp-modal-container">
                <div className="mp-set-desc">{t('account.set_master_password_tip')}</div>
                {/* <SVGIcon className="mp-set-img" name="master_password" /> */}
                <div className="mp-set-img"></div>
                <Button className="mp-set-now mp-antd-bt" onClick={navigateToSetMasterPassword}>
                    {t('account.set_master_password_now')}
                </Button>

                {
                    // 强制设置主密码，隐藏 Later 按钮
                    securityAccountConfig.promptMasterPasswordSettingWhenLogin !==
                        PromptMasterPasswordSettingWhenLoginType.everyAndNotSkip && (
                        <Button className="mp-set-later mp-antd-bt" onClick={laterToSet}>
                            {t('account.later')}
                        </Button>
                    )
                }
                <PowerFooter />
            </div>
        </ParticleDrawer>
    );
};

export default MasterPasswordModal;
