import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CircleClose from '../../../components/icon/circle-close';
import { useParticleAuth } from '../../../context';
import { PromptSettingWhenSignType } from '../../../provider/bundle';
import './index.less';
export interface AccountTipModalProps {
    visible: boolean;
    confirm?: () => void;
}

const AccountTipModal = ({ modal }: { modal: AccountTipModalProps }) => {
    const { visible, confirm } = modal;
    const { t } = useTranslation();
    const { setPaymentPassword, showAccountTipModal, securityAccountConfig } = useParticleAuth();

    const setPassword = () => {
        showAccountTipModal({
            visible: false,
        });
        setPaymentPassword();
    };

    const continueConfirm = () => {
        confirm?.();
        showAccountTipModal({
            visible: false,
        });
    };

    return (
        <Modal
            visible={visible}
            closable={false}
            centered={true}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={'90%'}
        >
            <div className="wrong-password-content">
                <CircleClose
                    className="close-icon"
                    onClick={() =>
                        showAccountTipModal({
                            visible: false,
                        })
                    }
                />
                <h3 className="modal-title">{t('account.account_modal1_title')}</h3>
                <div className="tip">{t('account.account_modal1_tip')}</div>
                <Button className="btn" onClick={setPassword}>
                    {t('account.account_modal1_btn1')}
                </Button>
                {
                    // 强制设置支付密码，隐藏跳过按钮
                    securityAccountConfig.promptSettingWhenSign !== PromptSettingWhenSignType.everyAndNotSkip && (
                        <Button className="btn cancel" onClick={continueConfirm}>
                            {t('account.account_modal1_btn2')}
                        </Button>
                    )
                }
            </div>
        </Modal>
    );
};

export default AccountTipModal;
