import { useRequest } from 'ahooks';
import evmApi from '../../api/evm-api';

const usePendingTransactionsRequest = () => {
    const {
        data: pendingTransactions = [],
        run: getPendingTransactionsByAddressRequest,
        runAsync: getPendingTransactionsByAddressAsyncRequest,
    } = useRequest(
        (account) => {
            return evmApi.getPendingTransactionsByAddress(account).then((res) => {
                // TODO: mock data
                // res = [
                //     {
                //         chainId: '0x5',
                //         nonce: '0x8b',
                //         maxPriorityFeePerGas: '0x540ae480',
                //         maxFeePerGas: '0x325286abcb',
                //         gasLimit: '0x5208',
                //         to: '0x257C04a36b8E2380deEac8D2A7acdE85d26EFfC2',
                //         value: '0x5af3107a4000',
                //         data: '0x',
                //         accessList: [],
                //         v: '0x0',
                //         r: '0x9d776522dc5518e89874e7aad75e62f5943a74b6a0486e1ed4b29089ad759473',
                //         s: '0x811aae714ea9819f0f4d166c6ba7359b0b7e2c972e93b5c48964aa696aa8720',
                //         hash: '0x6468e5eeb83e856c551eb2ec390ae567e9ecca1c8702f8c5fbee1283c0a94da0',
                //         from: '0xb4025B0319E56F8226D9849589e212D842Eeb942',
                //         type: 2,
                //         status: 1,
                //         timestamp: 1671186066,
                //     },
                // ];
                return res;
            });
        },
        {
            manual: true,
        }
    );
    return {
        pendingTransactions,
        getPendingTransactionsByAddressRequest,
        getPendingTransactionsByAddressAsyncRequest,
    };
};

export default usePendingTransactionsRequest;
