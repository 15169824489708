import './loading.less';

function Loading(props: any) {
    const { visible } = props;

    return visible ? (
        <div className="loading-container">
            <div className="start-loading">
                <div className="lds-default">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    ) : null;
}

export default Loading;
