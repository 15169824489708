async function retry<T>(action: () => Promise<T>, count = 3): Promise<T> {
    while (count > 0) {
        try {
            return await action();
        } catch (error) {
            count--;
            if (count == 0) {
                return Promise.reject(error);
            } else {
                console.log('catch error and retry', error);
            }
        }
    }
    throw new Error('retry error');
}

export default retry;
