import { RecordType } from '@particle-network/analytics';
import { useRequest } from 'ahooks';
import { Button, Form, Space, Typography, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkMasterPassword } from '../../../api/master-password';
import InputPassword from '../../../components/input-password';
import PowerFooter from '../../../components/power-footer';
import { useParticleAuth } from '../../../context';
import useSignData from '../../../context/hooks/useSignData';
import authorizeUtils from '../../../utils/authorizeUtils';
import { biRecords } from '../../../utils/bi';
import './index.less';

const MasterPasswordVerify = () => {
    const { Text, Link } = Typography;
    const navigate = useNavigate();
    const [showPasswordError, setShowPasswordError] = useState(false);
    const { t } = useTranslation();

    const { state } = useLocation();

    const [nextDisabled, setNextDisabled] = useState(true);
    const { signWithConfirm } = useSignData();
    const { loginSuccessRedirectToApp } = useParticleAuth();

    const { run: runCheckMasterPassword, loading } = useRequest(checkMasterPassword, {
        manual: true,
        onBefore: () => {
            biRecords({
                record_type: RecordType.PAGE_MASTER_PASSWORD_VERIFY, // 主密码验证
            });
        },
        onSuccess: async () => {
            if ((state as any)?.loginVerifyMasterPassword) {
                biRecords({
                    record_type: RecordType.PAGE_MASTER_PASSWORD_VERIFY_SUCCESS, // 主密码验证成功
                });

                // 登录免确认签名：老用户设置完主密码,跳转签名页面 start
                if (authorizeUtils.isNeedAuthorize()) {
                    if (!(await signWithConfirm())) {
                        return;
                    }
                }
                // 登录免确认签名：老用户设置完主密码,跳转签名页面 end

                loginSuccessRedirectToApp();
            } else {
                navigate(-1);
            }
        },
        onError: (error) => {
            console.log('checkMasterPassword error', error);
            if (error?.message === 'Master password decryption error') {
                setShowPasswordError(true);
            } else {
                message.error(error?.message || 'check master password error');
            }
        },
    });

    const toDecription = () => {
        navigate('/account/master-password/description');
    };

    const verifyMasterPassword = (values: any) => {
        const { password } = values;
        if (password?.length >= 6 && password?.length <= 20) {
            setShowPasswordError(false);
            runCheckMasterPassword(password);
        } else {
            setShowPasswordError(true);
        }
    };

    const onValuesChange = (values: any) => {
        const { password } = values;
        setShowPasswordError(false);
        setNextDisabled(!password);
    };

    return (
        <div className="mp-verify-container">
            <div className="mp-verify-title">{t('account.restore_wallet')}</div>
            <Form layout="vertical" onFinish={verifyMasterPassword} onValuesChange={onValuesChange}>
                <div className="mp-input-name">{t('account.master_password')}</div>
                <Form.Item name="password">
                    <InputPassword className="mp-input" />
                </Form.Item>
                {showPasswordError && <div className="mp-input-error">{t('account.password_error')}</div>}
                <Space direction="vertical" className="mp-tip-space">
                    <Text>{t('account.mpc_tss_intro')}</Text>
                    <Text>
                        {t('account.input_decrypt_hint')}{' '}
                        <Link onClick={toDecription} className="more-text-btn">
                            {t('account.learn_more_period')}
                        </Link>
                    </Text>
                </Space>
                <Form.Item>
                    <div className="bottom-container">
                        <Button
                            className="primary-antd-btn mp-next"
                            htmlType="submit"
                            loading={loading}
                            disabled={nextDisabled}
                        >
                            {t('common.confirm')}
                        </Button>
                        <PowerFooter />
                    </div>
                </Form.Item>
            </Form>
        </div>
    );
};

export default MasterPasswordVerify;
