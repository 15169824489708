import { Button, Modal, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SVGIcon from '../../components/icon/svg-icon';
import { passkeysRegistration } from '../../utils/passkeys';
import './index.less';
import AlertModal from '../../components/alertModal';

const PageCreatePassKeys = () => {
    const searchParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search]);

    const { t } = useTranslation();

    const [modalVisible, setModalVisible] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        generatePassKeys();
    }, []);

    const generatePassKeys = async () => {
        const uuid = searchParams.get('uuid') || '';
        const account = searchParams.get('account') || '';
        const projectUuid = searchParams.get('projectUuid') || '';
        if (!uuid || !account || !projectUuid) {
            message.error('Params Error');
            return;
        }
        try {
            const data = await passkeysRegistration({ uuid, account, projectUuid });
            window.opener.postMessage(
                {
                    name: 'particle-network-create-passkeys',
                    data,
                },
                location.origin
            );
            setTimeout(() => {
                window.close();
            }, 10);
        } catch (error: any) {
            setErrorMessage(error.message?.split(' See: https')?.[0] || error);
            setModalVisible(true);
        }
    };

    const handleCancel = () => {
        window.opener.postMessage(
            {
                name: 'particle-network-create-passkeys',
                error: errorMessage,
            },
            location.origin
        );
        setTimeout(() => {
            window.close();
        }, 10);
    };

    return (
        <div className="create-passkeys-content">
            <SVGIcon name={'passkeys_icon'} className="passkeys-icon" />
            <div className="create-hint">{t('account.create_passkeys')}</div>
            <AlertModal
                okText={t('common.retry')}
                cancelText={t('common.cancel')}
                closable={false}
                onOk={() => {
                    setModalVisible(false);
                    generatePassKeys();
                }}
                onCancel={() => {
                    setModalVisible(false);
                    handleCancel();
                }}
                visible={modalVisible}
                contentMessage={errorMessage || ''}
            ></AlertModal>
        </div>
    );
};

export default PageCreatePassKeys;
