import { DownOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { CountryCode, isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultCountriesData } from '../../../api/model/all-countries';
import { AuthType } from '../../../api/model/login-type';
import TurnstileModal from '../../../components/turnstileModal';
import { useParticleAuth } from '../../../context';
import { isPhoneValid } from '../../../utils';
import { isInIframe } from '../../../utils/common-utils';
import device from '../../../utils/detect-device';
import popupLogin from '../../../utils/popup-login';
import './login.less';

function PhoneLogin(props: any) {
    const { t } = useTranslation();

    const { sendCaptcha, setShowInputCaptcha, account, supportAuthTypes, loginWithAccount } = props;

    const [selectVisible, setSelectVisible] = useState(false);

    const [countryData, setCountryData] = useState(['United States', 'us', '1']);

    const [form] = Form.useForm();

    const clickRef = useRef<any>();

    const [errorTip, setErrorTip] = useState(false);
    const [noInputTip, setNoInputTip] = useState(false);

    const [loading, setLoading] = useState(false);

    const { appInfo, docHeight } = useParticleAuth();

    const phoneInput = useRef<any>(null);

    const [turnstileVisible, setTurnstileVisible] = useState(false);

    const [phoneValue, setPhoneValue] = useState<string>();

    useEffect(() => {
        if (selectVisible) {
            document.addEventListener('click', clickCallback, false);
            return () => {
                document.removeEventListener('click', clickCallback, false);
            };
        }
    }, [selectVisible]);

    useEffect(() => {
        let currentCountry;
        if (account && !account.includes('@')) {
            if (isValidPhoneNumber(account)) {
                const phoneNumber = parsePhoneNumber(account);
                const countryCode = phoneNumber.countryCallingCode.toString();
                const regionCode = phoneNumber.country;
                if (countryCode && regionCode) {
                    const items = defaultCountriesData.filter(
                        (item) =>
                            item[2].toString() === countryCode && item[1].toLowerCase() === regionCode.toLowerCase()
                    );
                    if (items && items.length > 0) {
                        currentCountry = items[items.length - 1];
                    }
                }
            }
        }
        if (!currentCountry) {
            const language =
                navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
            const locales = language.split('-');
            const region = locales.length > 1 ? locales[1] : locales[0];
            if (region && region.length > 0) {
                currentCountry = defaultCountriesData.find((item) => item[1].toUpperCase() === region);
            }
        }
        if (currentCountry) {
            setCountryData(currentCountry);
        }
        if (loginWithAccount && account && sendCaptcha) {
            getCaptcha(account, currentCountry ? currentCountry[1].toUpperCase() : undefined);
        }
    }, []);

    const clickCallback = (event: any) => {
        if (clickRef.current?.contains(event.target)) {
            return;
        }
        setSelectVisible(false);
    };

    // 验证 form
    const validateForm = (phoneValue: string, regionCode: string, isValidateEmpty = true) => {
        if (!phoneValue && isValidateEmpty) {
            setNoInputTip(true);
            return;
        }

        if (phoneValue?.length === 1) {
            setErrorTip(true);
            return;
        }

        if (!isPhoneValid(phoneValue, regionCode)) {
            setErrorTip(true);
            return false;
        } else {
            setErrorTip(false);
            return true;
        }
    };

    const getCaptcha = (phone: string, regionCode?: string, formSubmit = false) => {
        phoneInput.current.focus();
        if (!phone) {
            setNoInputTip(true);
            return;
        }
        if (phone.length === 1) {
            setErrorTip(true);
            return;
        }

        if (!regionCode) {
            regionCode = countryData[1].toUpperCase();
        }
        if (isPhoneValid(phone, regionCode)) {
            const countryCode = regionCode.toUpperCase() as CountryCode;
            const phoneE164 = parsePhoneNumber(phone, countryCode).format('E.164');
            console.log('login phone: ' + phoneE164);
            if (formSubmit && isInIframe() && device.isBlockingThirdpartyCookiesBrowser(true)) {
                //safari popup login
                popupLogin(AuthType.phone, phoneE164);
            } else {
                setPhoneValue(phoneE164);
                setTurnstileVisible(true);
            }
        } else {
            setErrorTip(true);
        }
    };

    const sendCaptchaImpl = (token: string) => {
        setLoading(true);
        sendCaptcha({
            phone: phoneValue,
            cf_turnstile_response: token,
        })
            .then((result: boolean) => {
                setShowInputCaptcha(result);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const initialAccountValue = () => {
        if (account?.includes('@')) {
            return '';
        } else if (account?.length > 0) {
            const phoneNumber = parsePhoneNumber(account);
            return phoneNumber.nationalNumber;
        }
        return '';
    };

    return (
        <div className="login-style">
            {selectVisible && (
                <div className="select-country" ref={clickRef}>
                    {defaultCountriesData.map((item, index) => {
                        return (
                            <div
                                key={index}
                                className="country-item"
                                onClick={() => {
                                    setCountryData(item);
                                    setSelectVisible(false);

                                    setTimeout(() => {
                                        const phoneValue = form.getFieldsValue().phone;
                                        if (phoneValue) {
                                            validateForm(phoneValue, item[1].toUpperCase(), false);
                                        }
                                    });
                                }}
                            >
                                <div className="country-box">
                                    <div className="country-flag">{getUnicodeFlagIcon(`${item[1]}`)}</div>
                                    <span className="country-name">{item[0]}</span>
                                </div>
                                <div className="country-code">{'+' + item[2]}</div>
                            </div>
                        );
                    })}
                </div>
            )}
            <Form
                name="login-form"
                onFinish={(values) => getCaptcha(values.phone, undefined, true)}
                className="form-box"
                requiredMark={false}
                form={form}
                labelCol={{
                    style: { textAlign: 'left' },
                }}
            >
                <div className="phone-box">
                    <div
                        className="phone-select"
                        defaultValue={`+${countryData[2]}`}
                        onClick={() => {
                            if (!loading) {
                                setSelectVisible(true);
                            }
                        }}
                    >
                        {getUnicodeFlagIcon(`${countryData[1]}`)}
                        <DownOutlined className="down-more" />
                    </div>
                    <div className="country-code">+{countryData[2]}</div>
                    <Form.Item
                        name="phone"
                        // label={t("login.enter_phone")}
                        label=""
                        className="form-item"
                        getValueFromEvent={(event) => {
                            return event.target.value.replace(/\D+/g, '');
                        }}
                        initialValue={initialAccountValue()}
                    >
                        <Input
                            type="text"
                            className="phone-input"
                            placeholder={t('login.mobile_number')}
                            ref={phoneInput}
                            onChange={() => {
                                setErrorTip(false);
                                setNoInputTip(false);
                            }}
                            readOnly={loginWithAccount}
                            onFocus={() => {
                                document.querySelector('.phone-box')?.setAttribute('data-focus', 'true');
                            }}
                            onBlur={() => {
                                document.querySelector('.phone-box')?.removeAttribute('data-focus');
                            }}
                        />
                    </Form.Item>
                </div>

                <div className="error-tip">{errorTip && t('login.phone_format_error')}</div>
                <div className="error-tip">{noInputTip && t('login.input_phone_holder')}</div>

                <Form.Item className="form-item">
                    <Button type="primary" htmlType="submit" className="primary-antd-btn submit-btn" loading={loading}>
                        {appInfo?.theme?.web_auth?.send_button_text || t('login.get_captcha')}
                    </Button>
                </Form.Item>
            </Form>

            {!loginWithAccount && supportAuthTypes.length > 0 && (
                <div className="login-or" data-size={docHeight < 640 ? 'mini' : ''}>
                    <div className="or-line"></div>
                    {t('login.or')}
                    <div className="or-line"></div>
                </div>
            )}

            <TurnstileModal
                onError={() => {
                    message.error(t('error.server_20112'));
                }}
                onSuccess={sendCaptchaImpl}
                visible={turnstileVisible}
                setVisible={setTurnstileVisible}
            ></TurnstileModal>
        </div>
    );
}

export default PhoneLogin;
