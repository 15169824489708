import { message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthType } from '../../../api/model/login-type';
import pnApi from '../../../api/pn-api';
import wallet from '../../../api/wallet';
import Header from '../../../components/header';
import SVGIcon from '../../../components/icon/svg-icon';
import PowerFooter from '../../../components/power-footer';
import { useParticleAuth } from '../../../context';
import tokenProvider from '../../../provider';
import { load } from '../../../repository';
import { displayEmail, displayPhone, isSignQuery, shortString } from '../../../utils/common-utils';
import device from '../../../utils/detect-device';
import redirectToApp, { AuthError } from '../../../utils/redirect-utils';
import { isEMVMethod } from '../../../utils/transaction-utils';
import { PageType } from '../accountVerify/config';
import BindSecurityAccountPrompt from '../bindSecurityAccountPrompt';
import './index.less';

const AccountAndSecurity = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [promptSecurityAccount, setPromptSecurityAccount] = useState(false);

    const { showSelectSecurityAccount, setPaymentPassword, userInfo, setUserInfo, setPaymentVerify } =
        useParticleAuth();

    useEffect(() => {
        if (!tokenProvider.isLogin() || !wallet.exist()) {
            redirectToApp({
                error: AuthError.notLogin(),
            });
        }
    }, [tokenProvider.isLogin()]);

    const hasMasterPassword = useMemo(() => userInfo.wallets && wallet.hasMasterPassword(), [userInfo]);

    const formatId = (id?: string) => {
        const provider = userInfo?.thirdparty_user_info?.provider;
        if (provider === AuthType.jwt) {
            return id?.replace(`${tokenProvider.params.project_uuid}:`, '');
        }
        return id;
    };

    // 当前登录账号类型
    const currentAuthType = useMemo(() => {
        // key : `login_auth_type_${userInfo.uuid}`;
        const key = `login_auth_type_${userInfo.uuid}`;
        let authType = load(key);
        if (!authType) {
            authType = userInfo?.thirdparty_user_info?.provider || 'email';
        }
        if (authType === 'twitterv1') {
            authType = 'twitter';
        }
        return authType as AuthType;
    }, [userInfo]);

    // 当前登录账号
    const currentAccount = useMemo(() => {
        let result = '';
        if (currentAuthType && userInfo) {
            result =
                // @ts-ignore
                userInfo[`${currentAuthType}`] ||
                // @ts-ignore
                userInfo[`${currentAuthType}_email`] ||
                // @ts-ignore
                shortString(formatId(userInfo[`${currentAuthType}_id`]));
        }
        return (
            result ||
            userInfo.email ||
            userInfo.phone ||
            userInfo?.thirdparty_user_info?.user_info?.email ||
            shortString(formatId(userInfo?.thirdparty_user_info?.user_info?.id || ''))
        );
    }, [currentAuthType, userInfo]);

    const setSecurityAccount = (type: 'phone' | 'email') => {
        if (
            (userInfo.security_account?.email && !userInfo.security_account?.phone) ||
            (!userInfo.security_account?.email && userInfo.security_account?.phone)
        ) {
            // 设置安全账号,需先验证另一个安全账号
            navigate('/account/verify', {
                state: {
                    account: userInfo.security_account?.email || userInfo.security_account?.phone,
                    authType: type,
                    redirectUrl: '/account/security',
                    pageType: PageType.VerifySecurityAccount_SetSecurityAccount,
                },
            });
        } else {
            navigate('/account/bind', {
                state: {
                    accountType: type,
                    redirectUrl: '/account/security',
                },
            });
        }
    };

    const chooseVerifyType = () => {
        if (userInfo?.security_account?.email && userInfo?.security_account?.phone) {
            showSelectSecurityAccount(true);
        } else {
            navigate('/account/set-password', {
                state: {
                    account: userInfo?.security_account?.email || userInfo?.security_account?.phone,
                },
            });
        }
    };

    /**
     * 关闭支付密码
     */
    const closePaymentPassword = () => {
        setPaymentVerify({
            visible: true,
            type: 'close',
            onVerifyCompleted: (password) => {
                pnApi
                    .closePaymentPassword({ password })
                    .then((result) => {
                        message.success(t('account.close_success'));
                        setUserInfo({
                            security_account: result,
                        });
                    })
                    .catch((error) => {
                        error.message && message.error(error.message);
                        console.log('closePaymentPassword Error', error);
                    });
            },
        });
    };

    const isCanBack = useMemo(() => {
        return isSignQuery(tokenProvider.params);
    }, [tokenProvider.params]);

    const goBack = () => {
        if (isSignQuery(tokenProvider.params)) {
            if (isEMVMethod(tokenProvider.params.method)) {
                navigate('/evm-chain/sign', { replace: true });
            } else {
                navigate('/solana/sign', { replace: true });
            }
        }
    };

    const changeMasterPassword = () => {
        navigate('/account/master-password/change');
    };

    const setMasterPassword = () => {
        navigate('/account/master-password');
    };

    const aboutMasterPassword = () => {
        navigate('/account/master-password/description');
    };

    const hasSecurityAccount = useMemo(
        () => userInfo.security_account?.email || userInfo.security_account?.phone,
        [userInfo.security_account?.email, userInfo.security_account?.phone]
    );

    return (
        <div className="account-and-security">
            {isCanBack && <Header onBack={goBack}>{t('account.account_and_security')}</Header>}
            {!isCanBack && <Header>{t('account.account_and_security')}</Header>}
            <div className="scroll-content">
                <div className="category-title">{t('account.account')}</div>
                <div className="account-box">
                    <img className="account-avatar" src={require(`@/common/images/${currentAuthType}_icon.png`)} />
                    <div className="account">{currentAccount}</div>
                    <div
                        className="more-account"
                        onClick={() => {
                            if (hasSecurityAccount) {
                                navigate('/login-account');
                            } else {
                                setPromptSecurityAccount(true);
                            }
                        }}
                    >
                        {t('account.more_login_account')}
                    </div>
                </div>

                <div className="payment-security authorization-item">
                    <div
                        className="label-item hover"
                        onClick={() => {
                            navigate('/manageDevices/deviceList');
                        }}
                    >
                        <div className="label-item-name">{t('new.authorization')}</div>
                        <SVGIcon className="arrow-right-icon" name="arrow_right_icon" />
                    </div>
                </div>

                <div className="category-title">{t('account.security')}</div>

                {/* master password */}
                {!device.authEmbed() && (
                    <div className="master-password-item">
                        <div className="mp-header">
                            <div className="mp-title">{t('account.master_password')}</div>
                            <div className="mp-learn-more" onClick={aboutMasterPassword}>
                                {t('account.learn_more')}
                            </div>
                        </div>
                        <div className="mp-content">
                            <div className="mp-desc">{t('account.master_password_tip')}</div>
                            {!hasMasterPassword && (
                                <div className="mp-set" onClick={setMasterPassword}>
                                    {t('account.set')}
                                </div>
                            )}
                        </div>
                        {hasMasterPassword && (
                            <div className="label-item hover" onClick={changeMasterPassword}>
                                <div className="label-item-name">{t('account.change_master_password')}</div>
                                <SVGIcon className="arrow-right-icon" name="arrow_right_icon" />
                            </div>
                        )}
                    </div>
                )}

                <div className="verification-security">
                    <h3>{t('account.verification_security')}</h3>
                    <p>{t('account.verification_security_tip')}</p>

                    <div className="label-item">
                        <div className="label-item-name">{t('account.email')}</div>

                        {displayEmail(userInfo?.security_account?.email || '') || (
                            <div className="label-item-btn" onClick={() => setSecurityAccount('email')}>
                                {t('account.set')}
                            </div>
                        )}
                    </div>
                    <div className="label-item">
                        <div className="label-item-name">{t('account.mobile')}</div>
                        {displayPhone(userInfo?.security_account?.phone || '') || (
                            <div className="label-item-btn" onClick={() => setSecurityAccount('phone')}>
                                {t('account.set')}
                            </div>
                        )}
                    </div>
                </div>

                <div className="payment-security">
                    <h3>{t('account.payment_security')}</h3>
                    <p>{t('account.payment_password_hint')}</p>

                    {userInfo?.security_account?.has_set_payment_password ? (
                        <>
                            <div className="label-item hover" onClick={() => navigate('/account/change-password')}>
                                <div className="label-item-name">{t('account.change_payment_password')}</div>
                                <SVGIcon className="arrow-right-icon" name="arrow_right_icon" />
                            </div>
                            <div className="label-item hover" onClick={chooseVerifyType}>
                                <div className="label-item-name">{t('account.forgot_payment_password')}</div>
                                <SVGIcon className="arrow-right-icon" name="arrow_right_icon" />
                            </div>
                            <div className="label-item hover" onClick={closePaymentPassword}>
                                <div className="label-item-name">{t('account.close_payment_password')}</div>
                                <SVGIcon className="arrow-right-icon" name="arrow_right_icon" />
                            </div>
                        </>
                    ) : (
                        <div className="label-item">
                            <div className="label-item-name">{t('account.payment_password')}</div>
                            <div
                                className="label-item-btn"
                                onClick={() => {
                                    if (hasSecurityAccount) {
                                        setPaymentPassword();
                                    } else {
                                        setPromptSecurityAccount(true);
                                    }
                                }}
                            >
                                {t('account.set')}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <PowerFooter />
            <BindSecurityAccountPrompt
                visible={promptSecurityAccount}
                setVisible={setPromptSecurityAccount}
                onBind={() => {
                    navigate('/account/bind', {
                        state: {
                            accountType: userInfo?.email ? 'phone' : 'email',
                            showSwitch: true,
                            redirectUrl: '/account/security',
                        },
                    });
                }}
            ></BindSecurityAccountPrompt>
        </div>
    );
};

export default AccountAndSecurity;
