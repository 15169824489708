import { useState } from 'react';
import { AuthType } from '../../../api/model/login-type';
import SVGIcon from '../../../components/icon/svg-icon';

const SocialLogin = (props: any) => {
    const { supportAuthTypes, preferredAuthType, onPreferredAuthTypeChange, loginWithSocial } = props;

    const [collapse, setCollapse] = useState(true);

    const authLogin = (authType: AuthType) => {
        loginWithSocial?.(authType);
    };

    const toggleEmailOrPhone = (authType: AuthType) => {
        onPreferredAuthTypeChange?.(authType);
    };

    const oauthItems = (isCollapse: boolean) => {
        const authTypes = supportAuthTypes as AuthType[];
        const hasEmailAndPhone = authTypes.includes(AuthType.email) && authTypes.includes(AuthType.phone);
        const items = [];
        if (hasEmailAndPhone) {
            if (preferredAuthType === AuthType.phone) {
                items.push(
                    <img
                        key="email-login"
                        src={require('@/common/images/email_icon.png')}
                        alt=""
                        onClick={() => toggleEmailOrPhone(AuthType.email)}
                    />
                );
            } else {
                items.push(
                    <img
                        key="phone-login"
                        src={require('@/common/images/phone_icon.png')}
                        alt=""
                        onClick={() => toggleEmailOrPhone(AuthType.phone)}
                    />
                );
            }
        }
        const displayAuthTypes = authTypes.filter(
            (authType) => authType !== AuthType.email && authType !== AuthType.phone
        );

        items.push(
            ...displayAuthTypes.map((authType) => (
                <img
                    key={`${authType}-login`}
                    src={require(`@/common/images/${authType}_icon.png`)}
                    alt=""
                    onClick={() => authLogin(authType as AuthType)}
                />
            ))
        );

        const collapseIcon = (
            <div
                key="collapse-login"
                className="collapse-login-icon"
                onClick={() => setCollapse(!collapse)}
                data-is-collapse={isCollapse}
            >
                <SVGIcon className="collapse-icon" name="collapse_icon" />
            </div>
        );

        if (items.length > 5) {
            items.splice(4, 0, collapseIcon);
        }

        if (isCollapse) {
            items.splice(5, items.length - 5);
        }

        return items;
    };

    return (
        <div className="change-login-mode">
            <div className="change-social">{oauthItems(collapse)}</div>
        </div>
    );
};

export default SocialLogin;
