import { stripHexPrefix } from '@ethereumjs/util';
import { urlCrypto } from '@particle-network/crypto';
import { AuthType } from '../api/model/login-type';
import pnApi from '../api/pn-api';
import i18n from '../i18n';
import { PreferenceKey } from '../repository';
import device from '../utils/detect-device';
import { loadNativeUserInfo } from '../utils/nativeBridge';
import url from '../utils/url-utils';
import { CurrencyUnit, QueryParamKey, QueryParams, SignQuery, currencyUnitValues } from './bundle';
import tokenProvider from './index';

export default async function queryParse() {
    const path = window.location.hash;
    const search = window.location.search;
    setTheme();
    setLanguage();
    if (
        path === '' ||
        path === '#/welcome' ||
        path === '#/device' ||
        path.startsWith('#/error') ||
        path.startsWith('#/create-passkeys')
    ) {
        return;
    }

    if (search && search.length > 0) {
        //jsonString(AES) + secretKey(32)
        let params = url.getQueryVariable(QueryParamKey.PARAMS);
        if (params) {
            if (params.startsWith('session_key_')) {
                const data = await pnApi.getAuthSession(params.replace('session_key_', ''));
                params = decodeURIComponent(data);
            }

            const secretKey = params.slice(-32);
            const plaintext = urlCrypto.decryptUrlParam(
                params,
                (url.getQueryVariable('encoding') as 'base64' | 'hex') ?? 'hex'
            );

            const queryParams = {} as QueryParams;
            queryParams.secretKey = secretKey;
            queryParams.params = JSON.parse(plaintext);

            const themeType = url.getQueryVariable(QueryParamKey.THEME_TYPE);
            queryParams.themeType = themeType;

            let redirectType = '';
            if (path.indexOf('login') !== -1) {
                redirectType = 'login';
            } else if (path.indexOf('logout') !== -1) {
                redirectType = 'logout';
            } else if (path.indexOf('wallet') !== -1) {
                redirectType = 'wallet';
            } else if (path.indexOf('sign') !== -1) {
                redirectType = (queryParams.params as SignQuery).method;
            } else if (path.indexOf('account/security') !== -1) {
                redirectType = 'account_security';
            }
            queryParams.redirectType = redirectType;

            const state = url.getQueryVariable(QueryParamKey.STATE);
            queryParams.state = state;

            const fiatCoin = url.getQueryVariable(QueryParamKey.FIAT_COIN);
            if (fiatCoin && currencyUnitValues.includes(fiatCoin as CurrencyUnit)) {
                queryParams.fiatCoin = fiatCoin;
            }

            const erc4337 = url.getQueryVariable(QueryParamKey.ERC4337);
            queryParams.erc4337 = JSON.parse(erc4337 ?? 'false');

            const lng = url.getQueryVariable(QueryParamKey.LANGUAGE);
            queryParams.language = lng ? lng.replace('_', '-') : undefined;

            tokenProvider.queryParams = queryParams;
            console.log('query param parse completed', queryParams);

            if (device.isSafariOrIOS()) {
                document.body.classList.add('hide-hover');
            }

            setTheme();
            setLanguage();

            try {
                await loadNativeUserInfo();
            } catch (error) {
                //ignore
            }

            return;
        }
    }

    if (process.env.NODE_ENV !== 'production') {
        const testParams = {
            device_id: 'dc560023-5598-40e5-b9c1-ea38b5e56b3c',
            project_client_key: 'cKtHdeHis0ghNom64w7mdNkGYX5rmRr0jLlIKatY',
            project_uuid: 'fa0fe0e8-f1bb-47ff-88bb-4fa31711e7b3',
            project_app_uuid: '859fada8-48ad-441b-b8e6-cde15ae1b48f',
            chain_name: 'Ethereum',
            sdk_version: 'android_1.0.0',
            theme_type: 'light',
            support_auth_types: Object.values(AuthType).join(','),
        };
        sessionStorage.setItem(PreferenceKey.PN_QUERY_PARAMS, JSON.stringify({ params: testParams }));
    }
}

function setLanguage() {
    const lng = url.getQueryVariable(QueryParamKey.LANGUAGE) || tokenProvider.launage;
    i18n.changeLanguage(lng ? lng.replace('_', '-') : undefined);
}

function setTheme() {
    const themeType = url.getQueryVariable(QueryParamKey.THEME_TYPE) || tokenProvider.themeType;
    if (themeType && themeType === 'dark') {
        document.documentElement.style.setProperty('--theme-background-color', '#1C1D22');
        document.documentElement.style.setProperty('--theme-color', '#fff');
        document.documentElement.style.setProperty('--theme-loading-color', '#fff');
    }

    if (themeType && themeType === 'dark') {
        document.body.classList.add('dark');
    } else {
        document.body.classList.remove('dark');
    }
}

/**
 * Parse the parameters in the url
 * @returns {}
 */
export const parseParams = () => {
    const params = url.getQueryVariable('params');
    if (params) {
        //@ts-ignore
        const plaintext = urlCrypto.decryptUrlParam(params, url.getQueryVariable('encoding') ?? 'hex');
        return JSON.parse(plaintext || '{}');
    } else {
        return {};
    }
};

const decodeMessage = (message: string): string => {
    const msg = Buffer.from(stripHexPrefix(message), 'hex').toString('utf-8');
    return msg;
};

export const parseMessage = () => {
    const params = sessionStorage.getItem(PreferenceKey.PN_QUERY_PARAMS) ?? '{}';
    const queryParam = JSON.parse(params);
    return JSON.parse(decodeMessage(queryParam.params.message));
};
