import React, { useEffect, useState, useRef, useCallback } from 'react';
import './index.less';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import PowerFooter from '../../../components/power-footer';
import SVGIcon from '../../../components/icon/svg-icon';

const ParticleKeywords = ({
    onChange,
    value,
    keyboardInvisible,
}: {
    onChange?: (val: string) => void;
    value?: string;
    keyboardInvisible?: boolean;
}) => {
    const { t } = useTranslation();
    const [input, setInput] = useState(['', '', '', '', '', '']);
    const [inputVal, setInputVal] = useState(value ?? '');
    const [keywords, setKeywords] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, '', 0]);

    const inputChange = (val: string) => {
        if (inputVal.length < 6) {
            setInputVal(inputVal + '' + val);
        }
    };

    const delVal = () => {
        if (inputVal.length > 0) {
            const newVal = inputVal.slice(0, inputVal.length - 1);
            setInputVal(newVal);
        }
    };
    useEffect(() => {
        onChange?.(inputVal);
    }, [inputVal]);

    useEffect(() => {
        setInputVal(value ?? '');
    }, [value]);

    if (!keyboardInvisible) {
        return (
            <>
                <div className="particle-keywords-box">
                    {input.map((item, idx) => (
                        <span key={item + idx} className={`input-item ${!item || 'active'}`}>
                            {inputVal[idx] ? '●' : ''}
                        </span>
                    ))}
                </div>
                <div className="particle-keywords-map">
                    {keywords.map((item) => (
                        <span
                            className="span"
                            key={item}
                            data-value={item}
                            onClick={() => {
                                if (item === '') {
                                    return;
                                }
                                inputChange(item + '');
                            }}
                        >
                            {item}
                        </span>
                    ))}
                    <span className="span del" onClick={delVal}>
                        <SVGIcon className="img" name="backspace" />
                    </span>
                    <PowerFooter />
                </div>
            </>
        );
    }
    return (
        <div className="particle-keywords-map loading">
            <img src={require('../../../common/images/account/icon_logo_gray.png')} alt="" />
            <p>{t('account.payment_checking')}</p>
            <LoadingOutlined className="loading-icon" />
        </div>
    );
};

export default ParticleKeywords;
