import { useRequest } from 'ahooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ApiError } from '../../../api/api-error';
import pnApi from '../../../api/pn-api';
import { useParticleAuth } from '../../../context';
import hashPassword from '../../../utils/hashPassword';
import ParticleKeywords from '../ParticleKeywords/index';

import Header from '../../../components/header';
import './index.less';
const ChangePaymentPassword = (prop: any) => {
    const router = useNavigate();
    const { t } = useTranslation();
    const [pwd, setPwd] = useState('');

    const { setWrongPassword } = useParticleAuth();

    const { loading: verifyLoading, run: runVerify } = useRequest(pnApi.securityPaymentsVerify, {
        manual: true,
        onSuccess: (result, params) => {
            router('/account/set-password', {
                state: {
                    old_password: pwd,
                },
            });
        },
        onError: (error: any) => {
            console.log('verify password error', error);
            setPwd('');
            if (error?.error_code === ApiError.WrongPaymentPassword) {
                setWrongPassword({ visible: true });
            } else if (error?.error_code === ApiError.SecurityAccountFrozen) {
                const seconds = error.extra.seconds || 0;
                setWrongPassword({
                    visible: true,
                    accountFrozen: {
                        seconds: seconds,
                    },
                });
            }
        },
    });

    const checkInputPwd = (value: string) => {
        setPwd(value);
        if (value.length === 6) {
            runVerify({
                password: hashPassword(value),
            });
        }
    };

    return (
        <div className="payment-password-container">
            <Header displayBackBtn={true}></Header>
            <div className="wrapper">
                <div className="page-title">{t('account.change_payment_password')}</div>
                <p className="payment-desc-1">{t('account.change_enter_payment')}</p>
                <ParticleKeywords onChange={checkInputPwd} value={pwd} keyboardInvisible={verifyLoading} />
            </div>
        </div>
    );
};

export default ChangePaymentPassword;
