import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import CircleClose from '../../../components/icon/circle-close';
import './index.less';

interface Prop {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    onBind: () => void;
}

const BindSecurityAccountPrompt = (prop: Prop) => {
    const { visible, setVisible, onBind } = prop;
    const { t } = useTranslation();

    const onClose = () => {
        setVisible(false);
    };

    return (
        <Modal
            className="bind-security-account-prompt"
            visible={visible}
            maskClosable={false}
            onCancel={onClose}
            closeIcon={<CircleClose />}
            centered
        >
            <div className="bind-hint-content">{t('account.security_account_bind_hint')}</div>
            <div className="prompt-bottom-btn-box">
                <Button className="primary-antd-btn secondary particle-cancel-button" onClick={onClose}>
                    {t('common.cancel')}
                </Button>
                <Button
                    className="primary-antd-btn"
                    onClick={() => {
                        setVisible(false);
                        onBind();
                    }}
                >
                    {t('common.bind')}
                </Button>
            </div>
        </Modal>
    );
};

export default BindSecurityAccountPrompt;
