import { intToHex } from '@ethereumjs/util';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import evmApi from '../../api/evm-api';
import { GasFeeResult, TokenPrice, TransactionData } from '../../api/model/rpc-data';
import tokenProvider from '../../provider';
import { RootState } from '../../redux/store';
import { EVMTransaction } from '../../types/tx';
import { bnToHex } from '../../utils/common-utils';
import { toWei } from '../../utils/number-utils';
import { AuthError } from '../../utils/redirect-utils';
import { isEIP1559Type } from '../../utils/transaction-utils';
import type { GasFee, TotalAmount } from './../../api/evm-api';

export interface TransactionValue {
    gasFee: GasFeeResult;
    prices: TokenPrice[];
    gasLimit: string;
    data: EVMTransaction;
    gasFeeDisplay: GasFee;
    totalAmountDisplay: TotalAmount;
    gasFeeMode: GasFeeMode;
    error: AuthError;
}

export enum GasFeeMode {
    custom = 'custom',
    low = 'low',
    medium = 'medium',
    high = 'high',
}

export interface GasState {
    value: Partial<TransactionValue>;
    status: 'idle' | 'loading' | 'failed';
}

const initialState: GasState = {
    value: {},
    status: 'idle',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const fetchGasAsync = createAsyncThunk(
    'transaction/fetchGas',
    async ({
        addresses,
        from,
        to,
        value,
        data,
    }: {
        addresses: string[];
        from: string;
        to: string;
        value?: string;
        data?: string;
    }) => {
        try {
            const result = await Promise.all([
                fetchSuggestedGasFee(),
                fetchPrice(addresses),
                fetchEstimateGas({
                    from,
                    to,
                    value,
                    data,
                }),
            ]);
            // The value we return becomes the `fulfilled` action payload
            return {
                gasFee: result[0],
                prices: result[1],
                gasLimit: result[2],
            };
        } catch (error: any) {
            if (error.code && error.message) {
                return Promise.reject({
                    message: 'code:' + error.code + ', ' + error.message.toString(),
                });
            } else {
                return Promise.reject(error);
            }
        }
    }
);

function fetchSuggestedGasFee(): Promise<GasFeeResult> {
    return evmApi.suggestedGasFees();
}

function fetchPrice(addresses: string[]): Promise<TokenPrice[]> {
    return evmApi.getPrice(addresses, [tokenProvider.fiatCoin.toLowerCase()]);
}

async function fetchEstimateGas({
    from,
    to,
    value,
    data,
}: {
    from: string;
    to: string;
    value?: string;
    data?: string;
}): Promise<string | Error> {
    try {
        const result = await evmApi.estimateGas({
            from,
            to,
            value,
            data,
        });
        return result;
    } catch (error: any) {
        return error;
    }
}

export const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setTransaction: (state, action: PayloadAction<EVMTransaction>) => {
            state.value.data = action.payload;
            if (action.payload.gasLevel) {
                state.value.gasFeeMode = action.payload.gasLevel as GasFeeMode;
                console.log('update gas fee mode', action.payload.gasLevel);
            } else {
                state.value.gasFeeMode = GasFeeMode.custom;
                console.log('update gas fee mode (default)', GasFeeMode.custom);
            }
        },
        updateTransaction: (state, action: PayloadAction<Partial<TransactionData>>) => {
            if (state.value.data) {
                if (action.payload.maxFeePerGas) {
                    state.value.data.maxFeePerGas = action.payload.maxFeePerGas;
                }

                if (action.payload.maxPriorityFeePerGas) {
                    state.value.data.maxPriorityFeePerGas = action.payload.maxPriorityFeePerGas;
                }

                if (action.payload.gasLimit) {
                    state.value.data.gasLimit = action.payload.gasLimit;
                    console.log('update gasLimit:', action.payload.gasLimit);
                }

                if (action.payload.gasPrice) {
                    state.value.data.gasPrice = action.payload.gasPrice;
                }

                if (action.payload.data) {
                    state.value.data.data = action.payload.data;
                }

                if (
                    action.payload.maxFeePerGas ||
                    action.payload.maxPriorityFeePerGas ||
                    action.payload.gasLimit ||
                    action.payload.gasPrice
                ) {
                    updateGasAndTotalDisplay(state.value);
                }
            }
        },
        setGasFeeMode: (state, action: PayloadAction<GasFeeMode>) => {
            state.value.gasFeeMode = action.payload;
            console.log('update gas fee mode (setGasFeeMode)', action.payload);
            if (state.value.data && state.value.gasFee && state.value.gasFeeMode !== GasFeeMode.custom) {
                if (isEIP1559Type(state.value.data.type)) {
                    state.value.data.maxFeePerGas = bnToHex(
                        toWei(state.value.gasFee[state.value.gasFeeMode].maxFeePerGas, 'gwei')
                    );
                    state.value.data.maxPriorityFeePerGas = bnToHex(
                        toWei(state.value.gasFee[state.value.gasFeeMode].maxPriorityFeePerGas, 'gwei')
                    );
                } else {
                    state.value.data.gasPrice = bnToHex(
                        toWei(state.value.gasFee[state.value.gasFeeMode].maxFeePerGas, 'gwei')
                    );
                }

                updateGasAndTotalDisplay(state.value);
            }
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(fetchGasAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchGasAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                state.value.gasFee = action.payload.gasFee;
                state.value.prices = action.payload.prices;
                if (typeof action.payload.gasLimit === 'string') {
                    state.value.gasLimit = action.payload.gasLimit;
                    state.value.error = undefined;
                } else {
                    if (!state.value.gasLimit) {
                        state.value.gasLimit = intToHex(50000);
                        state.value.error = {
                            message: action.payload.gasLimit.message,
                            code: -32000,
                        };
                    }
                }

                if (state.value.data) {
                    if (isEIP1559Type(state.value.data.type)) {
                        //eip 1559
                        if (state.value.gasFeeMode && state.value.gasFeeMode !== GasFeeMode.custom) {
                            state.value.data.maxFeePerGas = bnToHex(
                                toWei(state.value.gasFee[state.value.gasFeeMode].maxFeePerGas, 'gwei')
                            );
                            state.value.data.maxPriorityFeePerGas = bnToHex(
                                toWei(state.value.gasFee[state.value.gasFeeMode].maxPriorityFeePerGas, 'gwei')
                            );
                        } else if (!state.value.data.maxFeePerGas || !state.value.data.maxPriorityFeePerGas) {
                            state.value.data.maxFeePerGas = bnToHex(
                                toWei(state.value.gasFee.medium.maxFeePerGas, 'gwei')
                            );
                            state.value.data.maxPriorityFeePerGas = bnToHex(
                                toWei(state.value.gasFee.medium.maxPriorityFeePerGas, 'gwei')
                            );
                            if (!state.value.data.gasLimit) {
                                state.value.gasFeeMode = GasFeeMode.medium;
                                console.log('update gas fee mode (fulfilled)', GasFeeMode.medium);
                            }
                        }
                    } else {
                        if (state.value.gasFeeMode && state.value.gasFeeMode !== GasFeeMode.custom) {
                            state.value.data.gasPrice = bnToHex(
                                toWei(state.value.gasFee[state.value.gasFeeMode].maxFeePerGas, 'gwei')
                            );
                        } else if (!state.value.data.gasPrice) {
                            state.value.data.gasPrice = bnToHex(toWei(state.value.gasFee.medium.maxFeePerGas, 'gwei'));
                            if (!state.value.data.gasLimit) {
                                state.value.gasFeeMode = GasFeeMode.medium;
                                console.log('update gas fee mode (fulfilled)', GasFeeMode.medium);
                            }
                        }
                    }

                    if (
                        !state.value.data.gasLimit ||
                        state.value.gasFeeMode !== GasFeeMode.custom ||
                        Number(state.value.data.gasLimit) < Number(state.value.gasLimit)
                    ) {
                        state.value.data.gasLimit = state.value.gasLimit;
                        console.log('update gasLimit(state.value):', state.value.gasLimit);
                    }

                    updateGasAndTotalDisplay(state.value);
                }
            })
            .addCase(fetchGasAsync.rejected, (state, action) => {
                state.status = 'failed';
                if (action.error?.message) {
                    message.error(action.error?.message.replace('code:-32000,', ''));
                }
            });
    },
});

function updateGasAndTotalDisplay(value: Partial<TransactionValue>) {
    if (value.data && value.gasFee) {
        const gasFeeDisplay = evmApi.gasFee({
            gasLimit: value.data.gasLimit!,
            baseFee: bnToHex(toWei(value.gasFee.baseFee, 'gwei')),
            maxFeePerGas: value.data.maxFeePerGas,
            maxPriorityFeePerGas: value.data.maxPriorityFeePerGas,
            gasPrice: value.data.gasPrice,
        });
        value.gasFeeDisplay = gasFeeDisplay;

        const totalAmountDisplay = evmApi.totalAmount({
            value: value.data.value,
            gasLimit: value.data.gasLimit!,
            baseFee: bnToHex(toWei(value.gasFee.baseFee, 'gwei')),
            maxFeePerGas: value.data.maxFeePerGas,
            maxPriorityFeePerGas: value.data.maxPriorityFeePerGas,
            gasPrice: value.data.gasPrice,
        });
        value.totalAmountDisplay = totalAmountDisplay;
    }
}

export const { setTransaction, updateTransaction, setGasFeeMode } = transactionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectTransaction = (state: RootState) => state.transaction.value;

export const selectTransactionData = (state: RootState) => state.transaction.value.data;

export const selectGasError = (state: RootState) => state.transaction.value.error;

export const selectTotalAmount = (state: RootState) => state.transaction.value.totalAmountDisplay;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectGas(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default transactionSlice.reducer;
