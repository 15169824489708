export enum AuthType {
    email = 'email',
    phone = 'phone',
    facebook = 'facebook',
    google = 'google',
    apple = 'apple',
    twitter = 'twitter',
    discord = 'discord',
    github = 'github',
    twitch = 'twitch',
    microsoft = 'microsoft',
    linkedin = 'linkedin',
    jwt = 'jwt',
    passkeys = 'passkeys',
}

export type CaptchaRequest = {
    email?: string;
    phone?: string;
    cf_turnstile_response?: string;
};

export type LoginRequest = { code: string } & CaptchaRequest;

export type LoginBindRequest = {
    email?: string;
    phone?: string;
    code: string;
    security_account_verify_token: string;
};

export type LoginBindingsThirdpartyRequest = {
    provider: AuthType;
    thirdparty_code: string;
    security_account_verify_token: string;
};

export type LoginBindingsCheckResponse = {
    exists: boolean;
};
