// wallet : displaywallet  每个页面都有
// account : 每个页面都有,红点 点击消失 存储到localstorage
// transaction : evm 链 eth_sendTransaction 时有,红点点击消失 存储到 sessionStorage
// 小于3个的时候，不显示menu，直接平铺

import { Badge, Popover } from 'antd';
import md5 from 'crypto-js/md5';
import cloneDeep from 'lodash/cloneDeep';
import qs from 'qs';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SVGIcon from '../../../components/icon/svg-icon';
import MenuIcon from '../../../components/menuIcon';
import usePendingTransactionsRequest from '../../../context/hooks/usePendingTransactionsRequest';
import { EvmSignMethod } from '../../../provider/bundle';
import { popupwindowNoLimit } from '../../../utils/common-utils';
import url from '../../../utils/url-utils';

export const webWalletTransactionUrl = (signQuery = {}) => {
    let walletUrl = signQuery ? url.buildWalletUrl(signQuery as any, true) : '';
    if (walletUrl) {
        let { origin, pathname } = new URL(walletUrl);
        const query = qs.parse(walletUrl.split('?')[1]);
        pathname = '/tokenDetail.html';
        walletUrl =
            origin +
            pathname +
            '?' +
            qs.stringify({
                ...query,
                pageMode: 'Simple',
                // tokenAddress: transactionInfo?.data?.to || 'native',
                tokenAddress: 'native',
            });
    }
    return walletUrl;
};

const Menu = (props: any) => {
    const { wallet, userInfo, signQuery = {} } = props;

    const router = useNavigate();
    const { t } = useTranslation();

    const appId = useMemo(() => {
        return signQuery?.project_app_uuid;
    }, [signQuery?.project_app_uuid]);

    const accountSecurityKey = useMemo(() => {
        if (!appId || !userInfo?.uuid) return '';
        let key = `${appId}_${userInfo?.uuid}`;
        return `account_security_${md5(key)}`;
    }, [appId, userInfo?.uuid]);

    const transactionActivityKey = useMemo(() => {
        if (!appId || !userInfo?.uuid) return '';
        let key = `${appId}_${userInfo?.uuid}`;
        return `transaction_activity_${md5(key)}`;
    }, [appId, userInfo?.uuid]);

    const [meunInfo, setMeunInfo] = useState<any>({
        wallet: {
            name: 'Wallet',
            display: false,
            badge: false,
        },
        account: {
            name: 'Account & Security',
            display: true,
            badge: false,
        },
        transaction: {
            name: 'Transaction Activity',
            display: false,
            badge: false,
        },
    });

    const webWalletUrl = useMemo(() => {
        return signQuery ? url.buildWalletUrl(signQuery) : '';
    }, [signQuery]);

    const { getPendingTransactionsByAddressAsyncRequest } = usePendingTransactionsRequest();

    useEffect(() => {
        meunInfo.wallet.display = !!webWalletUrl;
        setMeunInfo(cloneDeep(meunInfo));
    }, [webWalletUrl]);

    // transaction : evm 链 eth_sendTransaction 显示
    useEffect(() => {
        meunInfo.transaction.display = false;
        if (signQuery.method === EvmSignMethod.eth_sendTransaction) {
            meunInfo.transaction.display = true;
        }
        setMeunInfo(cloneDeep(meunInfo));
    }, [signQuery?.method]);

    /**
     * 有Paymentpassword或Masterpassword其中一个没有就设置 security 为true，显示红点
     */
    useEffect(() => {
        if (userInfo?.security_account && !!accountSecurityKey && !localStorage.getItem(accountSecurityKey)) {
            const { has_set_master_password, has_set_payment_password } = userInfo?.security_account || {};
            if (!has_set_master_password || !has_set_payment_password) {
                meunInfo.account.badge = true;
            } else {
                meunInfo.account.badge = false;
            }
        } else {
            meunInfo.account.badge = false;
        }
        setMeunInfo(cloneDeep(meunInfo));
    }, [userInfo?.security_account, accountSecurityKey]);

    // useEffect(() => {
    //     if (!sessionStorage.getItem(transactionActivityKey)) {
    //         getPendingTransactionsByAddressAsyncRequest(wallet.publicAddress()).then((res) => {
    //             const pendingTransactions = res || [];
    //             const now = new Date().getTime();
    //             if (
    //                 pendingTransactions?.length >= 3 ||
    //                 !!pendingTransactions.find((item: any) => {
    //                     const timestamp = new Date(item.timestamp * 1000).getTime();
    //                     return now - timestamp > 30 * 60 * 1000;
    //                 })
    //             ) {
    //                 // 当该地址有超过3笔交易正在Pending或一笔交易timestamp超过30分钟未完成时，用户再次确认交易时将弹出此弹窗
    //                 meunInfo.transaction.badge = true;
    //                 setMeunInfo(cloneDeep(meunInfo));
    //             }
    //         });
    //     }
    // }, [transactionActivityKey]);

    const displayMenuList = useMemo(() => {
        return Object.entries(meunInfo).filter((item: any) => item[1].display);
    }, [meunInfo]);

    const walletButton = (
        <div
            className="item"
            onClick={() => {
                popupwindowNoLimit(webWalletUrl, '_blank', 1000, 800);
            }}
        >
            <Badge dot={false}>
                <>
                    <SVGIcon className="wallet-icon" name="wallet_icon" />
                    {displayMenuList.length > 2 && t('sign.wallet')}
                </>
            </Badge>
        </div>
    );
    const accountButton = (
        <div
            className="item"
            onClick={() => {
                meunInfo.account.badge = false;
                setMeunInfo(cloneDeep(meunInfo));
                router('/account/security');
                localStorage.setItem(accountSecurityKey, 'true');
            }}
        >
            <Badge dot={!!meunInfo.account.badge}>
                <>
                    <SVGIcon className="wallet-icon" name="security_icon" />
                    {displayMenuList.length > 2 && t('new.account_security')}
                </>
            </Badge>
        </div>
    );

    const transactionButton = (
        <>
            {/* transaction_activity */}
            <div
                className="item"
                onClick={() => {
                    meunInfo.transaction.badge = false;
                    setMeunInfo(cloneDeep(meunInfo));

                    sessionStorage.setItem(transactionActivityKey, 'true');

                    let width, height;
                    if (window.screen.availWidth <= 600) {
                        width = window.screen.availWidth;
                        height = window.screen.availHeight;
                    } else {
                        width = 480;
                        height = width * 1.68;
                    }

                    // 取消签名，跳转到wallet
                    popupwindowNoLimit(webWalletTransactionUrl(signQuery), '_blank', width, height);
                    // cancelSign();
                }}
            >
                <Badge dot={!!meunInfo.transaction.badge}>
                    <>
                        <SVGIcon className="wallet-icon" name="transaction_icon" />
                        {displayMenuList.length > 2 && t('new.transaction_activity')}
                    </>
                </Badge>
            </div>
        </>
    );

    if (displayMenuList.length <= 2) {
        return (
            <div className="top-menu-list">
                {!!meunInfo?.wallet?.display && walletButton}

                {!!meunInfo?.account?.display && accountButton}

                {!!meunInfo?.transaction?.display && transactionButton}
            </div>
        );
    }

    return (
        <div className="menu-entry">
            <Popover
                placement="bottomLeft"
                trigger="click"
                title={''}
                className="menu-popover"
                overlayClassName="menu-popover-overlay"
                getPopupContainer={(): HTMLElement => {
                    return document.querySelector('.menu-entry') as HTMLElement;
                }}
                content={
                    <div className="menu-list">
                        {!!meunInfo?.wallet?.display && walletButton}

                        {!!meunInfo?.account?.display && accountButton}

                        {!!meunInfo?.transaction?.display && transactionButton}
                    </div>
                }
            >
                <Badge dot={meunInfo.account.badge || meunInfo.transaction.badge}>
                    <MenuIcon />
                </Badge>
            </Popover>
        </div>
    );
};

export default Menu;
