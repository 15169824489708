import { DownOutlined } from '@ant-design/icons';
import { useClickAway } from 'ahooks';
import { Form, FormInstance, Input, InputNumber } from 'antd';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { defaultCountriesData, getCurrentCountry } from '../../api/model/all-countries';
import './index.less';
interface Iprops {
    form: FormInstance;
    name: string;
    disabled?: boolean;
    previewMode?: string;
    [key: string]: any;
}

const phoneInputItem = (props: Iprops) => {
    const form = props.form;
    const phoneValue = Form.useWatch(props.name, form);
    const [countryData, setCountryData] = useState(['United States', 'us', '1']);
    const { t } = useTranslation();
    const [visbSelectCountry, setVisbSelectCountry] = useState(false);
    const clickRef = useRef<any>();
    const forItemRef = useRef<any>();
    const [searchValue, setSearchValue] = useState('');
    const searchInputRef = useRef<any>();
    const countriesList = useMemo(() => {
        return defaultCountriesData.filter((item) => {
            return item.join('+').toLowerCase().includes(searchValue.toLowerCase());
        });
    }, [searchValue]);

    useClickAway(() => {
        setVisbSelectCountry(false);
    }, clickRef);

    useEffect(() => {
        if (visbSelectCountry) {
            setSearchValue('');
        }
    }, [visbSelectCountry]);

    useEffect(() => {
        if (phoneValue?.includes('*')) {
            return;
        }
        const countryCode = `+${countryData[2]}`;
        const value = (phoneValue || '').replace(/^\+\d+/, '').trim();
        form.setFieldsValue({
            [props.name]: `${countryCode} ${value}`,
        });
        if (value) {
            form.validateFields(['_phone']);
        }
    }, [countryData, phoneValue]);

    useEffect(() => {
        try {
            if (phoneValue?.includes?.('*')) {
                form.setFieldsValue({
                    _phone: phoneValue,
                });
            } else if (phoneValue) {
                if (isValidPhoneNumber(phoneValue)) {
                    const phoneNumber = parsePhoneNumber(phoneValue);
                    const value = phoneNumber.nationalNumber;

                    form.setFieldsValue({
                        _phone: value,
                    });

                    const countryCode = phoneNumber.countryCallingCode.toString();
                    const regionCode = phoneNumber.country;

                    if (countryCode && regionCode) {
                        const items = defaultCountriesData.filter(
                            (item) =>
                                item[2].toString() === countryCode && item[1].toLowerCase() === regionCode.toLowerCase()
                        );
                        if (items && items.length > 0) {
                            setCountryData(items[items.length - 1]);
                        }
                    }
                }
            } else {
                const currentCountry = getCurrentCountry();
                if (currentCountry) {
                    setCountryData(currentCountry);
                }
            }
        } catch (error) {
            // pass
        }
    }, [phoneValue]);

    return (
        <div className="phone-input-item-container">
            <Form.Item
                className={!props.disabled || (props.disabled && props.previewMode !== 'input') ? 'hidden' : ''}
                extra={props.extra}
                name={props.name}
            >
                <Input disabled={props.disabled}></Input>
            </Form.Item>
            <Form.Item
                name="_phone"
                className={props.disabled && props.previewMode == 'input' ? 'hidden' : ''}
                extra={props.extra}
                // getValueFromEvent={(event) => {
                //     return event.target.value.replace(/\D+/g, '');
                // }}
                rules={[
                    {
                        required: true,
                        validator: (rule, value) => {
                            try {
                                if (!value) {
                                    return Promise.reject(t('login.input_phone_holder'));
                                } else {
                                    try {
                                        value = `+${countryData[2]} ${value}`;
                                        if (!isValidPhoneNumber(value)) {
                                            return Promise.reject(t('login.phone_format_error'));
                                        }
                                    } catch (error) {
                                        return Promise.reject(t('login.phone_format_error'));
                                    }
                                }
                            } catch (error) {
                                return Promise.reject(t('login.phone_format_error'));
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <InputNumber
                    placeholder={t('account.mobile')}
                    disabled={props.disabled}
                    controls={false}
                    onChange={(value) => {
                        form.setFieldsValue({
                            [props.name]: `+${countryData[2]} ${value}`,
                        });
                    }}
                    onFocus={() => {
                        document.querySelector('.ant-input-number-group-wrapper')?.setAttribute('data-focus', 'true');
                    }}
                    onBlur={() => {
                        document.querySelector('.ant-input-number-group-wrapper')?.setAttribute('data-focus', 'false');
                    }}
                    addonBefore={
                        <div className="country-box phone" ref={forItemRef}>
                            <div
                                className="prefix-wrap"
                                onClick={() => {
                                    if (!props.disabled) {
                                        setVisbSelectCountry(!visbSelectCountry);
                                        setTimeout(() => {
                                            searchInputRef.current?.focus();
                                        });
                                    }
                                }}
                                ref={clickRef}
                            >
                                <div className="account-select-opt">
                                    <div className="account-select-icon">{getUnicodeFlagIcon(`${countryData[1]}`)}</div>
                                    <DownOutlined className="down-more" />
                                </div>
                                <span>+{countryData[2]}</span>
                            </div>

                            {visbSelectCountry && (
                                <div className="account-select-country-list ">
                                    <div
                                        className="account-select-country-item search-input-wrap"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <Input
                                            ref={searchInputRef}
                                            className="search-input"
                                            type="text"
                                            placeholder={t('login.search_country_holder')}
                                            allowClear
                                            onChange={(e) => {
                                                const value = e?.target?.value || '';
                                                setSearchValue(value);
                                            }}
                                        />
                                    </div>
                                    {countriesList && !!countriesList.length ? (
                                        <div className="p-country-list">
                                            {countriesList.map((item, index) => (
                                                <div
                                                    key={`${item[0]}-${item[1]}-${item[2]}`}
                                                    className="account-select-country-item"
                                                    onClick={() => {
                                                        setCountryData(item);
                                                        setVisbSelectCountry(false);
                                                    }}
                                                >
                                                    <div className="country-box">
                                                        <div className="country-flag">
                                                            {getUnicodeFlagIcon(`${item[1]}`)}
                                                        </div>
                                                        <span className="country-name">{item[0]}</span>
                                                    </div>
                                                    <div className="country-code">{'+' + item[2]}</div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="no-data">No data</div>
                                    )}
                                </div>
                            )}
                        </div>
                    }
                />
            </Form.Item>
        </div>
    );
};

export default phoneInputItem;
