import en from './en.json';
import zh from './zh.json';
import tc from './tc.json';
import jp from './jp.json';
import kr from './kr.json';

const resources = {
    en: {
        translation: en,
    },
    'zh-CN': {
        translation: zh,
    },
    'zh-TW': {
        translation: tc,
    },
    'zh-HK': {
        translation: tc,
    },
    ja: {
        translation: jp,
    },
    ko: {
        translation: kr,
    },
};
export default resources;
