import { addHexPrefix, stripHexPrefix } from '@ethereumjs/util';
import { Button, Image, Modal, Tabs, message } from 'antd';
import { BigNumber } from 'bignumber.js';
import { Buffer } from 'buffer';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
    EVMDeserializeTransactionResult,
    EVMFunction,
    EVMNFTChange,
    EvmEnhancedMethod,
    TransactionSmartType,
} from '../../../api/model/rpc-data';
import pnApi from '../../../api/pn-api';
import wallet from '../../../api/wallet';
import tokenProvider from '../../../provider';
import { EvmSignMethod, PromptSettingWhenSignType, SignQuery } from '../../../provider/bundle';
import {
    defaultTokenIcon,
    formatAddress,
    getChainDisplayName,
    getChainIcon,
    getNativeSymbol,
} from '../../../utils/chain-utils';
import { isNullish, popupwindowNoLimit, shortString } from '../../../utils/common-utils';
import { formatTokenAmount2, fromSunFormat, fromWeiFormat } from '../../../utils/number-utils';
import redirectToApp, { AuthError } from '../../../utils/redirect-utils';
import './info-sign.less';

import { RecordType } from '@particle-network/analytics';
import BN from 'bn.js';
// @ts-ignore
import jt from 'json-toy';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import errorHandle from '../../../api/error-handle';
import IconCopy from '../../../components/icon/icon-copy';
import PowerFooter from '../../../components/power-footer';
import SplashLoading from '../../../components/splash-loading';
import { useParticleAuth } from '../../../context';
import usePendingTransactionsRequest from '../../../context/hooks/usePendingTransactionsRequest';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { PreferenceKey, load, save } from '../../../repository';
import { EVMTransaction } from '../../../types/tx';
import authorizeUtils from '../../../utils/authorizeUtils';
import { biRecords } from '../../../utils/bi';
import { isEIP1559Type, isEVMAddress, isTron, parseTransaction } from '../../../utils/transaction-utils';
import RiskModal from '../riskModal';
import RiskReminder from '../riskReminder';
import { selectGasError, selectTransactionData, setTransaction, updateTransaction } from '../transaction-slice';
import NewErcTransfers from './NewErcTransfers';
import EvmGas from './evm-gas';
import GasDisplay from './gas-display';
import Menu, { webWalletTransactionUrl } from './menu';
import NoGas from './no-gas';

enum RenderPageType {
    SIGN_MESSAGE,
    SEND_TX,
    SIGN_TYPE_DATA,
}

export const getAAIcon = (aaType: string, theme: string) => {
    aaType = (aaType ?? 'biconomy')?.toLowerCase();
    const aaIcons: Record<string, any> = {
        biconomyfff: require('@/common/images/smartAccount/biconomy-fff-icon.png'),
        biconomy000: require('@/common/images/smartAccount/biconomy-000-icon.png'),
        cyberconnectfff: require('@/common/images/smartAccount/cyberconnect-fff-icon.png'),
        cyberconnect000: require('@/common/images/smartAccount/cyberconnect-000-icon.png'),
        simplefff: require('@/common/images/smartAccount/simple-fff-icon.png'),
        simple000: require('@/common/images/smartAccount/simple-000-icon.png'),
        lightfff: require('@/common/images/smartAccount/light-fff-icon.png'),
        light000: require('@/common/images/smartAccount/light-000-icon.png'),
        btcfff: require('@/common/images/smartAccount/btc-icon.png'),
        btc000: require('@/common/images/smartAccount/btc-icon.png'),
        universalfff: require('@/common/images/smartAccount/universal-fff-icon.png'),
        universal000: require('@/common/images/smartAccount/universal-000-icon.png'),
        xteriofff: require('@/common/images/smartAccount/xterio-icon.png'),
        xterio000: require('@/common/images/smartAccount/xterio-icon.png'),
    };
    const key = `${aaType}${theme === 'light' ? 'fff' : '000'}`;
    return aaIcons[key] ?? aaIcons['biconomyfff'];
};

function EvmSign() {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    const [transactionInfo, setTransactionInfo] = useState<EVMDeserializeTransactionResult>();

    const [headerTitle, setHeaderTitle] = useState<string>('');
    const [headerDes, setHeaderDes] = useState<string>('');

    const [gasVis, setGasVis] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const router = useNavigate();

    const [renderPageType, setRenderPageType] = useState<RenderPageType | ''>('');

    const [changeApproveAmount, setChangeApproveAmount] = useState<string>('');

    const transactionData = useAppSelector(selectTransactionData);
    const gasError = useAppSelector(selectGasError);

    const { getPendingTransactionsByAddressAsyncRequest } = usePendingTransactionsRequest();

    const [riskPrompt, setRiskPrompt] = useState(false);

    const signQuery = useMemo(() => tokenProvider.params as SignQuery, []);

    const [addressDisplayed, setAddressDisplayed] = useState<string>();

    const {
        setPaymentVerify,
        setPaymentPassword,
        userInfo,
        showAccountTipModal,
        securityAccountConfig,
        loadSecurityAccountsAsync,
        loginSuccessRedirectToApp,
    } = useParticleAuth();

    const loadsecurityAccounts = () => {
        loadSecurityAccountsAsync()
            .then(() => {
                approveSign();
            })
            .catch((error) => {
                setLoading(false);
                message.error(error.message ?? 'Sign Error');
            });
    };

    const showSetPaymentPasswordOrConfirm = (confirm: () => void) => {
        const has = userInfo?.security_account?.has_set_payment_password;
        if (has) {
            confirm();
        } else {
            if (
                securityAccountConfig.promptSettingWhenSign === PromptSettingWhenSignType.every ||
                securityAccountConfig.promptSettingWhenSign === PromptSettingWhenSignType.everyAndNotSkip
            ) {
                showAccountTipModal({
                    visible: true,
                    confirm,
                });
            } else if (
                (securityAccountConfig.promptSettingWhenSign ||
                    isNullish(securityAccountConfig.promptSettingWhenSign)) &&
                !load(PreferenceKey.PN_OPEN_SET_PAYMENT_PASSWORD + userInfo.uuid)
            ) {
                save(PreferenceKey.PN_OPEN_SET_PAYMENT_PASSWORD + userInfo.uuid, '1');
                showAccountTipModal({
                    visible: true,
                    confirm,
                });
            } else {
                confirm();
            }
        }
    };

    const hasSetPaymentPassword = useMemo(
        () => userInfo?.security_account?.has_set_payment_password,
        [userInfo?.security_account?.has_set_payment_password]
    );

    const { TabPane } = Tabs;

    useEffect(() => {
        if (gasVis) {
            // @ts-ignore
            document.querySelector('body').style.overflow = 'hidden';
            // @ts-ignore
            document.querySelector('body').style.position = 'fixed';
        } else {
            // @ts-ignore
            document.querySelector('body').style.overflow = '';
            // @ts-ignore
            document.querySelector('body').style.position = '';
        }
    }, [gasVis]);

    const isPersonalSign = useMemo(
        () => signQuery.method === EvmSignMethod.personal_sign || signQuery.method === EvmSignMethod.personal_sign_uniq,
        [signQuery]
    );

    useEffect(() => {
        wallet
            .publicAddressDisplayed()
            .then((address) => {
                setAddressDisplayed(address);
            })
            .catch((error) => {
                console.error('publicAddressDisplayed', error);
                setAddressDisplayed(wallet.publicAddress());
            });
    }, []);

    useEffect(() => {
        let renderPageType: RenderPageType | '' = '';
        console.log('signQuery.method', signQuery.method);
        if (isPersonalSign) {
            //sign message
            setHeaderTitle(t('sign.signature_message'));
            setHeaderDes(t('sign.signature_title'));

            renderPageType = RenderPageType.SIGN_MESSAGE;
        } else if (signQuery.method === EvmSignMethod.eth_sendTransaction) {
            //send tx
            setHeaderTitle(t('sign.send_transaction'));
            setHeaderDes(t('sign.approve_and').format(getChainDisplayName(tokenProvider.chain)));
            const txData = setTransactionData(decodeMessage(signQuery.message));
            deserializeTransaction(txData);
            renderPageType = RenderPageType.SEND_TX;
        } else if (
            signQuery.method === EvmSignMethod.eth_signTypedData4 ||
            signQuery.method === EvmSignMethod.eth_signTypedData_v4_uniq
        ) {
            deserializeTypedData(decodeMessage(signQuery.message)).then((res) => {
                if (res?.type === TransactionSmartType.SEAPORT_NFT_LISTING) {
                    setHeaderTitle(t('sign.send_transaction'));
                    setHeaderDes(t('sign.approve_and').format(getChainDisplayName(tokenProvider.chain)));
                    renderPageType = RenderPageType.SEND_TX;
                } else {
                    renderPageType = RenderPageType.SIGN_TYPE_DATA;
                    setHeaderTitle(t('sign.sign_typed_data'));
                    setHeaderDes(t('sign.signature_title'));
                }
                setRenderPageType(renderPageType);
            });
        } else if (signQuery.method.includes(EvmSignMethod.eth_signTypedData)) {
            //sign typed data
            setHeaderTitle(t('sign.sign_typed_data'));
            setHeaderDes(t('sign.signature_title'));
            renderPageType = RenderPageType.SIGN_TYPE_DATA;
        }

        setRenderPageType(renderPageType);

        if (!wallet.exist()) {
            redirectToApp({
                error: AuthError.walletNotCreated(),
            });
            return;
        }
    }, []);

    const setTransactionData = (rawTransaction: string): EVMTransaction => {
        const txData = parseTransaction(rawTransaction);
        console.log('setTransactionData', txData);
        dispatch(setTransaction(txData));
        return txData;
    };

    const deserializeTypedData = async (jsonStr: string) => {
        return pnApi
            .evmRpc<EVMDeserializeTransactionResult>({
                id: uuidv4(),
                jsonrpc: '2.0',
                method: EvmEnhancedMethod.particle_deserializeTypedData,
                params: [jsonStr],
            })
            .then((output) => {
                setTransactionInfo(output.result);
                return output.result;
            })
            .catch((error) => {
                console.log('deserializeTypedData error', error);
                message.error(error.message ?? 'deserializeTypedData Error');
            });
    };

    const deserializeTransaction = async (txData: EVMTransaction) => {
        if (!checkTxData(txData)) {
            return;
        }

        const { TransactionFactory } = await import('@ethereumjs/tx');
        const tx = TransactionFactory.fromTxData(txData);
        pnApi
            .evmRpc<EVMDeserializeTransactionResult>({
                id: uuidv4(),
                jsonrpc: '2.0',
                method: EvmEnhancedMethod.particle_deserializeTransaction,
                params: [wallet.publicAddress(), addHexPrefix(tx.serialize().toString('hex'))],
            })
            .then((output) => {
                // TODO
                // output.result.type = TransactionSmartType.ERC1155_TRANFER;
                // output.result.type = TransactionSmartType.ERC721_TRANFER;
                // output.result.type = TransactionSmartType.ERC20_APPROVE;
                // output.result.type = TransactionSmartType.ERC20_TRANSFER;

                setTransactionInfo(output.result);
            })
            .catch((error) => {
                console.log('deserializeTransaction error', error);
                Modal.error({
                    title: error.message ?? 'Deserialize Transaction Error',
                    okCancel: true,
                    cancelText: t('common.cancel'),
                    okText: t('common.retry'),
                    onOk: () => {
                        deserializeTransaction(txData);
                    },
                });
            });
    };

    const hasSecurityRisk = useMemo(() => {
        return transactionInfo?.securityDetection && transactionInfo?.securityDetection.length > 0;
    }, [transactionInfo?.securityDetection]);

    const checkTxData = (txData: EVMTransaction): boolean => {
        if (isTron(tokenProvider.chain)) {
            if (!txData.from || !txData.to || !txData.value) {
                Modal.error({
                    title: 'Transaction error, see doc for more info',
                    onOk: () => {
                        redirectToApp({
                            error: AuthError.paramsError(),
                        });
                    },
                });
                return false;
            }

            return true;
        }
        if (Number(txData.type) !== 0 && Number(txData.type) !== 1 && Number(txData.type) !== 2) {
            Modal.error({
                title: 'Transaction type error, see doc for more info',
                onOk: () => {
                    redirectToApp({
                        error: AuthError.paramsError(),
                    });
                },
            });
            return false;
        } else if (isEIP1559Type(txData.type) && txData.maxFeePerGas && txData.maxPriorityFeePerGas) {
            const maxFeePerGasBN = new BN(stripHexPrefix(txData.maxFeePerGas), 16);
            const maxPriorityFeePerGasBN = new BN(stripHexPrefix(txData.maxPriorityFeePerGas), 16);
            if (maxFeePerGasBN.lte(maxPriorityFeePerGasBN)) {
                // maxFeePerGas cannot be less than maxPriorityFeePerGas
                Modal.error({
                    title: 'maxFeePerGas cannot be less than maxPriorityFeePerGas.',
                    onOk: () => {
                        redirectToApp({
                            error: AuthError.feeError(),
                        });
                    },
                });
                return false;
            }
        }
        return true;
    };

    const decodeMessage = (message: string): string => {
        const msg = Buffer.from(stripHexPrefix(message), 'hex').toString('utf-8');
        if (isPersonalSign) {
            if (/�/.test(msg)) {
                return message;
            }
        }
        return msg;
    };

    const formatFunction = (evmFunction: EVMFunction): string => {
        if (evmFunction.params.length > 0) {
            const p = evmFunction.params.map((item) => item.type).join(', ');
            return `${evmFunction.name}(${p})`;
        }
        return `${evmFunction.name}()`;
    };

    const approveSign = async (pendingConfirm = false) => {
        if (!signQuery) return;

        if (!tokenProvider.isLogin()) {
            redirectToApp({
                error: AuthError.notLogin(),
            });
            return;
        }

        biRecords({
            record_type: RecordType.PAGE_SIGN_CONFIRM_BUTTON_CLICK, // confirm按钮点击
        });

        if (signQuery.method === EvmSignMethod.eth_sendTransaction) {
            let pendingTransactions = [];
            if (
                transactionData &&
                transactionData.action !== 'cancel' &&
                transactionData.action !== 'speedup' &&
                !pendingConfirm
            ) {
                pendingTransactions = await getPendingTransactionsByAddressAsyncRequest(wallet.publicAddress());
            }
            if (
                transactionData &&
                transactionData.action !== 'cancel' &&
                transactionData.action !== 'speedup' &&
                !pendingConfirm &&
                (pendingTransactions?.length >= 3 ||
                    !!pendingTransactions.find((item: any) => {
                        const now = new Date().getTime();
                        const timestamp = new Date(item.timestamp * 1000).getTime();
                        return now - timestamp > 30 * 60 * 1000;
                    }))
            ) {
                // 当该地址有超过3笔交易正在Pending或一笔交易timestamp超过30分钟未完成时，用户再次确认交易时将弹出此弹窗
                console.log('pendingTransactions', pendingTransactions);
                // @ts-ignore
                window.pendingModal = Modal.warning({
                    className: 'pending-warning-modal',
                    content: (
                        <div className="content-wrap">
                            <div className="content">
                                {/* {t('new.transaction_pending').format(pendingTransactions?.length)} */}
                                {t('new.transaction_pending_v2')}
                            </div>
                            <div className="footer-btns">
                                <Button
                                    className="cancel-btn continue-btn"
                                    disabled={pendingTransactions?.length >= 10}
                                    onClick={() => {
                                        approveSign(true);
                                        // @ts-ignore
                                        window.pendingModal.destroy();
                                    }}
                                >
                                    {t('new.continue')}
                                </Button>
                                <Button
                                    className="process-now-btn"
                                    onClick={() => {
                                        let width, height;
                                        if (window.screen.availWidth <= 600) {
                                            width = window.screen.availWidth;
                                            height = window.screen.availHeight;
                                        } else {
                                            width = 480;
                                            height = width * 1.68;
                                        }
                                        // 取消签名，跳转到wallet
                                        popupwindowNoLimit(webWalletTransactionUrl(signQuery), '_blank', width, height);
                                        // cancelSign();
                                        setTimeout(() => {
                                            // @ts-ignore
                                            window.pendingModal.destroy();
                                        });
                                    }}
                                >
                                    {t('new.process_now')}
                                </Button>
                            </div>
                        </div>
                    ),
                    closable: true,
                    maskClosable: false,
                    getContainer: () => {
                        return document.querySelector('.info-sign') as HTMLElement;
                    },
                });
            } else if (userInfo?.security_account?.has_set_payment_password) {
                setPaymentVerify({
                    visible: true,
                    onVerifyCompleted: signTx,
                });
            } else {
                showSetPaymentPasswordOrConfirm(signTx);
            }
        } else if (signQuery.method.includes(EvmSignMethod.eth_signTypedData) || isPersonalSign) {
            if (userInfo?.security_account?.has_set_payment_password) {
                setPaymentVerify({
                    visible: true,
                    onVerifyCompleted: signData,
                });
            } else {
                if (
                    signQuery.method.includes(EvmSignMethod.eth_signTypedData) ||
                    securityAccountConfig.promptSettingWhenSign === PromptSettingWhenSignType.everyAndNotSkip
                ) {
                    // method是eth_signTypedData 或者 强制设置支付密码
                    showSetPaymentPasswordOrConfirm(signData);
                } else {
                    signData();
                }
            }
        } else {
            Modal.error({
                title: `method ${signQuery.method} not support`,
            });
        }
    };

    const signTx = async () => {
        if (!signQuery || !transactionData) return;

        setLoading(true);

        const unsigned = JSON.stringify(transactionData);
        console.log('unsigned tx:', unsigned);

        let signed;
        try {
            signed = await wallet.evmSign(unsigned, signQuery.method);
            biRecords({
                record_type: RecordType.PAGE_SIGN_CONFIRM_BUTTON_CLICK_SUCCESS, // confirm成功
            });
        } catch (error: any) {
            console.error(signQuery.method, error);
            biRecords({
                record_type: RecordType.PAGE_SIGN_CONFIRM_BUTTON_CLICK_FAILURE, // confirm失败
            });
            if (error?.error_code === 50103 && !userInfo?.security_account?.has_set_payment_password) {
                loadsecurityAccounts();
            } else if (
                error?.message === 'Local Key not found' ||
                error?.message === 'Master password decryption error'
            ) {
                router('/account/master-password/verify');
            } else {
                errorHandle(error);
                setLoading(false);
            }
            return;
        }

        let output;
        try {
            output = await pnApi.evmRpc<string>({
                id: uuidv4(),
                jsonrpc: '2.0',
                method: 'eth_sendRawTransaction',
                params: [signed],
            });
        } catch (error: any) {
            console.log('eth_sendRawTransaction error', error);
            setLoading(false);
            Modal.error({
                title: error.message ?? 'Send Transaction Error',
                okText: t('common.confirm'),
                onOk: () => {
                    redirectToApp({
                        error: error,
                    });
                },
            });
            return;
        }

        redirectToApp({
            signature: output.result,
        });
    };

    const signData = async () => {
        if (!signQuery) return;

        setLoading(true);
        const unsigned = decodeMessage(signQuery.message);

        let signed;
        try {
            signed = await wallet.evmSign(unsigned, signQuery.method);

            biRecords({
                record_type: RecordType.PAGE_SIGN_CONFIRM_BUTTON_CLICK_SUCCESS, // confirm成功
            });
        } catch (error: any) {
            console.error(signQuery.method, error);
            biRecords({
                record_type: RecordType.PAGE_SIGN_CONFIRM_BUTTON_CLICK_FAILURE, // confirm失败
            });
            if (error?.error_code === 50103 && !userInfo?.security_account?.has_set_payment_password) {
                loadsecurityAccounts();
            } else if (
                error?.message === 'Local Key not found' ||
                error?.message === 'Master password decryption error'
            ) {
                router('/account/master-password/verify');
            } else {
                errorHandle(error);
                setLoading(false);
            }
            return;
        }

        if (authorizeUtils.isNeedAuthorize()) {
            sessionStorage.setItem(
                PreferenceKey.PN_TEMP_AUTHORIZE_RESULT,
                JSON.stringify({
                    signature: signed,
                    message: signQuery.message,
                })
            );
            loginSuccessRedirectToApp();
        } else {
            redirectToApp({
                signature: signed,
            });
        }
    };

    const cancelSign = async () => {
        if (loading) {
            return;
        }
        //返回app
        redirectToApp({
            error: AuthError.userRejectedRequest(),
        });
    };

    const formatValue = (data: any) => {
        if (isEVMAddress(data.value)) {
            return shortString(formatAddress(data.value));
        }
        return data.value;
    };

    const signMessageTitle = useMemo(() => {
        let title = '';
        if (signQuery?.message && !isPersonalSign) {
            try {
                const signQueryMessage = eval(`(${decodeMessage(signQuery?.message)})`);
                const { primaryType } = signQueryMessage;
                title = primaryType;
            } catch (error) {
                // pase error
            }
        }
        return title || 'Message';
    }, [signQuery?.message]);

    const sMessage = useMemo(() => {
        if (signQuery?.message && !isPersonalSign) {
            try {
                const signQueryMessage = eval(`(${decodeMessage(signQuery?.message)})`);
                let { message } = signQueryMessage;

                if (!signQueryMessage.message) {
                    message = signQueryMessage;
                }

                return message;
            } catch (error) {
                // pase error
            }
        }
        return {};
    }, [signQuery?.message]);

    const getRow = (key: string, index: number) => {
        key = key.replace('ROOT.', '');
        let value = jt.getValByKeyPath(sMessage, key);
        let isTitle = false;
        if (typeof value !== 'string' && typeof value !== 'number') {
            value = '';
            isTitle = true;
        }
        const indent = key.split('.').length;

        return (
            <div key={index} className="s-row">
                <div
                    className="label"
                    style={{
                        paddingLeft: 20 * indent,
                    }}
                    data-type={isTitle ? 'title' : ''}
                >
                    {key.split('.').pop()}：
                </div>

                {isEVMAddress(value) ? (
                    <CopyToClipboard text={value} onCopy={() => message.success(t('new.copied_to'))}>
                        <div className="value copy-text">{formatValue({ value })}</div>
                    </CopyToClipboard>
                ) : (
                    <div
                        className="value"
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {value}
                    </div>
                )}
            </div>
        );
    };
    // sign message
    const signMessage = () => {
        return (
            <div className="sign-message">
                <div
                    className={'message' + (hasSetPaymentPassword ? '' : ' no-password-tip')}
                    data-transaction-type={transactionInfo?.type}
                >
                    {!signQuery?.method.includes(EvmSignMethod.eth_signTypedData) && (
                        <div className="pre-wrap personal-message">{decodeMessage(signQuery!.message)}</div>
                    )}

                    {signQuery?.method.includes(EvmSignMethod.eth_signTypedData) && (
                        <>
                            <div className="s-row">
                                <div className="label" data-type="title">
                                    {signMessageTitle}
                                </div>
                            </div>
                            {jt.travelJson(sMessage).map((key: string, index: number) => {
                                return getRow(key, index);
                            })}
                        </>
                    )}
                </div>
            </div>
        );
    };

    const getNFTName = (info: EVMNFTChange): string => {
        if (info.name && info.name.length > 0) {
            return info.name;
        }

        return `NFT#${info.tokenId}`;
    };

    const approveDisabled = (data?: EVMTransaction): boolean => {
        if (!signQuery) {
            return true;
        }
        if (signQuery.method === EvmSignMethod.eth_sendTransaction) {
            if (!data) {
                return true;
            }

            if (isTron(tokenProvider.chain)) {
                return false;
            }

            if (isEIP1559Type(data.type)) {
                return !data.gasLimit || !data.maxPriorityFeePerGas || !data.maxFeePerGas;
            } else {
                return !data.gasLimit || !data.gasPrice;
            }
        }
        return false;
    };
    const [displayDetail, setDisplayDetail] = useState<boolean>(false);

    const isErc4361 = useMemo(() => {
        // https://eips.ethereum.org/EIPS/eip-4361#example-message
        let result = false;
        if (isPersonalSign && signQuery.message) {
            const signMessage = decodeMessage(signQuery.message);
            const domain = signMessage.match(/^(.+)?\swants you/)?.[1];
            const address = signMessage.match(/wants you to sign in with your Ethereum account:\n(.*)/)?.[1];
            const uri = signMessage.match(/URI:(.*)/)?.[1];
            const version = signMessage.match(/Version:(.*)/)?.[1];
            const chainId = signMessage.match(/Chain ID:(.*)/)?.[1];
            const nonce = signMessage.match(/Nonce:(.*)/)?.[1];
            if (domain && address && uri && version && chainId && nonce) {
                result = true;
            }
        }
        return result;
    }, [transactionData, isPersonalSign, signQuery.message]);

    useEffect(() => {
        if (isErc4361) {
            setHeaderTitle(t('new.sign_in_request'));
            setHeaderDes(t('new.requesting_sign_4361'));
        }
    }, [isErc4361]);

    // sign/send transaction
    const signTransaction = () => {
        return (
            <div>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t('sign.details')} key="1">
                        <div className="balance-change">
                            <div className="title">{t('sign.estimated_balance_change')}</div>
                            <div className="change-body">
                                {transactionInfo?.estimatedChanges?.natives
                                    ?.filter((info) => info.address === wallet.publicAddress())
                                    ?.map((info, index) => {
                                        return (
                                            <div className="change-title" key={`native-change-${index}`}>
                                                {getNativeSymbol(tokenProvider.chain)}
                                                <div
                                                    className="change-val"
                                                    style={info.nativeChange.includes('-') ? { color: '#ea4335' } : {}}
                                                >
                                                    {info.nativeChange.includes('-') ? '' : '+'}
                                                    {isTron(tokenProvider.chain)
                                                        ? fromSunFormat(info.nativeChange)
                                                        : fromWeiFormat(info.nativeChange, 'ether', 18)}
                                                </div>
                                            </div>
                                        );
                                    })}

                                {transactionInfo?.estimatedChanges?.nfts?.map((info, index) => {
                                    return (
                                        <div className="change-title" key={`nft-change-${index}`}>
                                            {getNFTName(info)}
                                            <div
                                                className="change-val"
                                                style={info.amountChange < 0 ? { color: '#ea4335' } : {}}
                                            >
                                                {info.amountChange < 0 ? '' : '+'}
                                                {info.amountChange}
                                            </div>
                                        </div>
                                    );
                                })}

                                {transactionInfo?.estimatedChanges?.tokens?.map((info, index) => {
                                    return (
                                        <div className="change-title" key={`token-change-${index}`}>
                                            {info.name ? info.name : 'Unknown Token'}
                                            <div
                                                className="change-val"
                                                style={info.amountChange < 0 ? { color: '#ea4335' } : {}}
                                            >
                                                {info.amountChange < 0 ? '' : '+'}
                                                {formatTokenAmount2(info.amountChange, info.decimals)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        {transactionInfo && (
                            <div className="from-to">
                                <div className="address-item">
                                    <div>{t('sign.from')}</div>
                                    <div>{shortString(formatAddress(transactionInfo.data.from))}</div>
                                </div>
                                <div className="address-item mt10">
                                    <div>{t('sign.to')}</div>
                                    <div>{shortString(formatAddress(transactionInfo.data.to))}</div>
                                </div>
                                {!isTron(tokenProvider.chain) && (
                                    <div className="address-item mt10">
                                        <div>{t('sign.nonce')}</div>
                                        <div>#{parseInt(transactionInfo.data.nonce)}</div>
                                    </div>
                                )}
                            </div>
                        )}

                        {!gasError && transactionData && signQuery && !isTron(tokenProvider.chain) && (
                            <GasDisplay
                                transactionData={transactionData}
                                openGasDrawer={() => setGasVis(true)}
                                signLoading={loading}
                            />
                        )}

                        {gasError && !isTron(tokenProvider.chain) && <NoGas />}
                    </TabPane>
                    <TabPane tab={t('sign.data')} key="2">
                        <div>
                            {transactionInfo && transactionInfo.data.function && (
                                <div className="inner-instruction" key={'instruction-function'}>
                                    <div className="inner-content">
                                        <div className="content-item">
                                            <div className="item">
                                                <div className="item-0">
                                                    {t('sign.function_type')}{' '}
                                                    {formatFunction(transactionInfo.data.function)}
                                                </div>

                                                {transactionInfo.data.function.params.map((item) => (
                                                    <div
                                                        className="item-1 mt10"
                                                        key={`instruction-function${item.name}`}
                                                    >
                                                        {shortString(item.name)}
                                                        <span>{shortString(item.value)}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* hex data */}

                            {transactionInfo && (
                                <div className="inner-instruction" key={'instruction-hex-data'}>
                                    <div className="inner-content">
                                        <div className="content-item">
                                            <div className="item">
                                                <div className="item-0">{t('sign.hex_data')}</div>
                                                <div className="item-1 mt10">
                                                    <div className="data">{transactionInfo.data.data}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        );
    };

    const editApproveAmount = async (amount: string) => {
        setChangeApproveAmount(amount);

        if (amount && amount !== '' && transactionInfo) {
            const { decimals = 18 } = transactionInfo?.estimatedChanges?.tokens?.[0] || {};

            BigNumber.config({ EXPONENTIAL_AT: [-256, 256] });
            const bn = new BigNumber(amount).multipliedBy(new BigNumber(10).pow(decimals));
            const approveAmount = bn.toString();
            BigNumber.config({ EXPONENTIAL_AT: [-7, 21] });

            const spender = transactionInfo.data?.function?.params?.[0]?.value || '';
            const encodeData = await pnApi.evmRpc<string>({
                id: uuidv4(),
                jsonrpc: '2.0',
                method: 'particle_abi_encodeFunctionCall',
                params: [transactionInfo.data.to, 'erc20_approve', [spender, approveAmount]],
            });

            dispatch(
                updateTransaction({
                    data: encodeData.result,
                })
            );
        }
    };

    // @ts-ignore
    window.tokenProvider = tokenProvider;

    return (
        <>
            {(transactionInfo?.type ||
                renderPageType === RenderPageType.SIGN_TYPE_DATA ||
                renderPageType === RenderPageType.SIGN_MESSAGE) && (
                <div className={`info-sign info-sign-${transactionInfo?.type}`} data-type={transactionInfo?.type}>
                    {!hasSetPaymentPassword && (
                        <div className="has-payment-password">
                            <div className="has-payment-password-icon"></div>
                            <div className="has-payment-password-tip">{t('account.waring_tip1')}</div>
                            <div className="has-payment-password-set" onClick={setPaymentPassword}>
                                {t('account.set')}
                            </div>
                        </div>
                    )}
                    <div className={'scroll-part' + (hasSetPaymentPassword ? '' : ' no-password-tip')}>
                        <Menu
                            wallet={wallet}
                            userInfo={userInfo}
                            transactionInfo={transactionInfo}
                            signQuery={signQuery}
                        />
                        <div className="info-request">
                            {tokenProvider.erc4337 && wallet.isEVMChain() && (
                                // <div className="aa-tag">
                                //     AA
                                // </div>
                                <div className="aa-icon">
                                    <Image
                                        src={getAAIcon(
                                            ((tokenProvider.erc4337 as any)?.name ?? 'BICONOMY').toLowerCase(),
                                            tokenProvider.themeType || 'light'
                                        )}
                                        fallback={defaultTokenIcon}
                                        preview={false}
                                    />
                                </div>
                            )}
                            <span>{headerTitle}</span>
                        </div>
                        <div className="info-title">
                            <Image
                                src={getChainIcon(tokenProvider.chain)}
                                fallback={defaultTokenIcon}
                                preview={false}
                            />
                            {getChainDisplayName(tokenProvider.chain)}
                        </div>
                        <CopyToClipboard
                            text={addressDisplayed || ''}
                            onCopy={() => message.success(t('new.copied_to'))}
                        >
                            <div className="info-address">
                                {shortString(addressDisplayed)}
                                <div className="copy-icon">
                                    <IconCopy />
                                </div>
                            </div>
                        </CopyToClipboard>

                        <div className="info-des">{headerDes}</div>
                        <div className="apart-line"></div>

                        {transactionInfo?.type === TransactionSmartType.NativeTransfer ||
                        transactionInfo?.type === TransactionSmartType.ERC20_TRANSFER ||
                        transactionInfo?.type === TransactionSmartType.ERC20_APPROVE ||
                        transactionInfo?.type === TransactionSmartType.ERC721_TRANFER ||
                        transactionInfo?.type === TransactionSmartType.ERC1155_TRANFER ||
                        transactionInfo?.type === TransactionSmartType.SEAPORT_CANCEL_ORDER ||
                        transactionInfo?.type === TransactionSmartType.SEAPORT_FULFILL_ORDER ||
                        transactionInfo?.type === TransactionSmartType.SEAPORT_NFT_LISTING ? (
                            <NewErcTransfers
                                setDisplayDetail={setDisplayDetail}
                                displayDetail={displayDetail}
                                gasError={gasError}
                                signQuery={signQuery}
                                setGasVis={setGasVis}
                                formatFunction={formatFunction}
                                transactionInfo={transactionInfo}
                                changeApproveAmount={changeApproveAmount}
                                setChangeApproveAmount={editApproveAmount}
                                signLoading={loading}
                                signMessage={signMessage}
                            />
                        ) : signQuery?.method && signQuery?.method === EvmSignMethod.eth_sendTransaction ? (
                            signTransaction()
                        ) : signQuery?.method && signQuery?.method !== EvmSignMethod.eth_sendTransaction ? (
                            signMessage()
                        ) : (
                            ''
                        )}
                    </div>

                    <div className="btn-box">
                        <div>
                            <Button className="btn-cancel" onClick={cancelSign}>
                                {t('common.cancel')}
                            </Button>
                            <Button
                                className={`btn-approve ${gasError || hasSecurityRisk ? 'still-confirm' : ''}`}
                                onClick={() => {
                                    if (hasSecurityRisk) {
                                        setRiskPrompt(true);
                                    } else {
                                        approveSign();
                                    }
                                }}
                                loading={loading}
                                disabled={approveDisabled(transactionData)}
                            >
                                {isErc4361
                                    ? t('new.sign_in')
                                    : gasError || hasSecurityRisk
                                    ? t('common.still_confirm')
                                    : t('common.confirm')}
                            </Button>
                        </div>

                        <PowerFooter></PowerFooter>
                    </div>

                    {hasSecurityRisk && transactionInfo?.securityDetection && (
                        <>
                            <RiskReminder securityDetection={transactionInfo?.securityDetection}></RiskReminder>
                            <RiskModal
                                visible={riskPrompt}
                                setVisible={setRiskPrompt}
                                securityDetection={transactionInfo?.securityDetection}
                                onConfirm={() => {
                                    approveSign();
                                }}
                            ></RiskModal>
                        </>
                    )}

                    <EvmGas openGasDrawer={() => setGasVis(false)} visible={gasVis} />
                </div>
            )}
            {!transactionInfo && (
                <SplashLoading
                    visible={
                        renderPageType !== RenderPageType.SIGN_TYPE_DATA &&
                        renderPageType !== RenderPageType.SIGN_MESSAGE
                    }
                />
            )}
        </>
    );
}

export default EvmSign;
