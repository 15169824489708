import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthType } from '../../../api/model/login-type';
import { useParticleAuth } from '../../../context';
import { displayEmail, displayPhone } from '../../../utils/common-utils';
import ParticleDrawer from '../../sign/components/particle-drawer';
import './index.less';

const SelectSecurityAccount = (props: any) => {
    const { t } = useTranslation();
    const { visible, state } = props;
    const { showSelectSecurityAccount, userInfo } = useParticleAuth();
    const navigate = useNavigate();

    const navigateToVerify = (account?: string, authType?: AuthType) => {
        if (state) {
            navigate('/account/verify', {
                state: {
                    account,
                    ...state,
                },
            });
        } else {
            navigate('/account/set-password', {
                state: {
                    account,
                },
            });
        }
        showSelectSecurityAccount(false);
    };

    return (
        <ParticleDrawer
            title={t('account.modal_select_verification')}
            placement="bottom"
            closable={false}
            visible={visible}
            maskClosable={true}
            height={274}
            onClose={() => showSelectSecurityAccount(false)}
        >
            <div className="select-account">
                <Button
                    className="account-btn"
                    onClick={() => navigateToVerify(userInfo?.security_account?.email, AuthType.email)}
                >
                    {`${t('account.email')} - ${displayEmail(userInfo?.security_account?.email || '')}`}
                </Button>
                <Button
                    className="account-btn"
                    onClick={() => navigateToVerify(userInfo?.security_account?.phone, AuthType.phone)}
                >
                    {`${t('account.mobile')} - ${displayPhone(userInfo?.security_account?.phone || '')}`}
                </Button>
            </div>
        </ParticleDrawer>
    );
};

export default SelectSecurityAccount;
