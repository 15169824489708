import { Button, Modal, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { DeviceInfo } from '../../../api/model/rpc-data';
import pnApi from '../../../api/pn-api';
import Header from '../../../components/header';
import SVGIcon from '../../../components/icon/svg-icon';
import PowerFooter from '../../../components/power-footer';
import './index.less';

const DeviceList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [visibleModal, setVisibleModal] = useState(false);
    const loginDeviceInfo: DeviceInfo = (state as any)?.loginDeviceInfo || {};
    const [deleteLoading, setDeleteLoading] = useState(false);
    const openDeleteModal = () => {
        setVisibleModal(true);
    };
    const closeDeleteModal = () => {
        setVisibleModal(false);
    };

    return (
        <div className="device-details-wrapper">
            <div className="device-details-container">
                <Header displayBackBtn={true}>{t('new.details')}</Header>
                <div className="details-list">
                    <div className="item">
                        <div className="left">{t('new.app')}</div>
                        <div className="right">{loginDeviceInfo.project_app_name || '-'}</div>
                    </div>
                    <div className="item">
                        <div className="left">{t('new.device')}</div>
                        <div className="right">{loginDeviceInfo.device || '-'}</div>
                    </div>
                    <div className="item">
                        <div className="left">{t('new.authorized_time')}</div>
                        <div className="right">{loginDeviceInfo.updated_at || '-'}</div>
                    </div>
                    <div className="item">
                        <div className="left">{t('new.login_method')}</div>
                        <div className="right">
                            {(loginDeviceInfo.login_channel || '-').replace('Twitterv1', 'Twitter')}
                        </div>
                    </div>
                    <div className="item">
                        <div className="left">{t('new.ip')}</div>
                        <div className="right">{loginDeviceInfo?.location?.ip || '-'}</div>
                    </div>
                    <div className="item">
                        <div className="left">{t('new.country')}</div>
                        <div className="right">
                            {loginDeviceInfo?.location?.country_name || loginDeviceInfo?.location?.country_code || '-'}
                        </div>
                    </div>
                    <div className="item">
                        <div className="left">{t('new.city')}</div>
                        <div className="right">{loginDeviceInfo?.location?.city || '-'}</div>
                    </div>
                </div>
                <Modal className="delete-confirm-modal" visible={visibleModal} closable={false}>
                    <div className="content-wrap">
                        <div
                            className="close-btn"
                            onClick={() => {
                                closeDeleteModal();
                            }}
                        >
                            <SVGIcon className="circle_close" name="circle_close" />
                        </div>
                        <div className="content-text">
                            {t('new.delete_access_to_n').format(
                                loginDeviceInfo?.project_app_name,
                                loginDeviceInfo?.device
                            )}
                        </div>
                        <div className="btns">
                            <Button
                                className="cancel-btn"
                                onClick={() => {
                                    closeDeleteModal();
                                }}
                            >
                                {t('common.cancel')}
                            </Button>
                            <Button
                                className="still-confirm delete-btn"
                                loading={deleteLoading}
                                onClick={() => {
                                    setDeleteLoading(true);
                                    setTimeout(() => {
                                        pnApi
                                            .deleteAuthorization(loginDeviceInfo?.project_app_uuid)
                                            .finally(() => {
                                                setDeleteLoading(false);
                                            })
                                            .then((res: boolean) => {
                                                if (res) {
                                                    closeDeleteModal();
                                                    setTimeout(() => {
                                                        navigate(-1);
                                                    }, 500);
                                                }
                                            })
                                            .catch((error: any) => {
                                                message.error(error.message);
                                                console.log('deleteAuthorization Error', error);
                                            });
                                    }, 500);
                                }}
                            >
                                {t('new.delete')}
                            </Button>
                        </div>
                    </div>
                </Modal>
                <div className="btns">
                    <Button
                        className="still-confirm confirm-delete-btn"
                        disabled={loginDeviceInfo.isCurrent}
                        onClick={() => {
                            openDeleteModal();
                        }}
                    >
                        {t('new.delete_access')}
                    </Button>
                </div>
                <PowerFooter />
            </div>
        </div>
    );
};

export default DeviceList;
