import type { AccessList, AccessListBuffer } from '@ethereumjs/tx';
import type { PrefixedHexString } from '@ethereumjs/util';

export type RpcInput = {
    jsonrpc: string;
    id: string;
    method: string;
    params?: unknown[];
};

export type RpcOutput<T> = {
    chainId: number;
    jsonrpc: string;
    id: string;
    result: T;
};

export type SolanaDeserializeTransactionResult = {
    instructions: SolanaDeserializeInstruction[];
    estimatedChanges: EstimatedChanges;
    estimatedLamportsFee: number;
};

export type SolanaDeserializeInstruction = {
    type: string;
    programId: string;
    data: string;
};

export type EstimatedChanges = {
    sols: SolChangeInfo[];
    nfts: TokenChangeInfo[];
    tokens: SplTokenChangeInfo[];
};

export type SolChangeInfo = {
    address: string;
    lamportsChange: number;
};

export type TokenChangeInfo = {
    name: string;
    symbol: string;
    image: string;
    mint: string;
    associatedTokenAddress: string;
    amountChange: number;
};

export type SplTokenChangeInfo = {
    decimals: number;
} & TokenChangeInfo;

export enum SolanaEnhancedMethod {
    enhancedDeserializeTransaction = 'enhancedDeserializeTransaction',
}

export enum EvmEnhancedMethod {
    particle_deserializeTransaction = 'particle_deserializeTransaction',
    particle_deserializeTypedData = 'particle_deserializeTypedData',
    particle_suggestedGasFees = 'particle_suggestedGasFees',
    particle_getPrice = 'particle_getPrice',
    particle_getPendingTransactionsByAddress = 'particle_getPendingTransactionsByAddress',
    eth_estimateGas = 'eth_estimateGas',
}

export enum TransactionSmartType {
    NativeTransfer = 'native_transfer',
    ERC20_TRANSFER = 'erc20_transfer',
    ERC20_APPROVE = 'erc20_approve',
    ERC721_TRANFER = 'erc721_transfer',
    ERC1155_TRANFER = 'erc1155_transfer',
    SEAPORT_FULFILL_ORDER = 'seaport_fulfill_order',
    SEAPORT_CANCEL_ORDER = 'seaport_cancel_order',
    SEAPORT_NFT_LISTING = 'seaport_nft_listing',
    OTHER = 'other',
}

export type NftPrice = {
    address: string;
    amount: string;
    decimals: number;
    symbol: string;
};

export type SecurityDetection = {
    type: string;
    risks?: string[];
    warnings?: string[];
};

export type EVMDeserializeTransactionResult = {
    type?: TransactionSmartType;
    estimatedChanges: EVMEstimatedChange;
    data: EVMData;
    toTag?: string;
    price?: NftPrice;
    securityDetection?: SecurityDetection[];
};

export type EVMEstimatedChange = {
    natives: EVMNativeChange[];
    nfts: EVMNFTChange[];
    tokens: EVMTokenChange[];
};

//十进制数字字符串
export type DecimalString = string;

export type EVMNativeChange = {
    address: PrefixedHexString;
    nativeChange: DecimalString;
};

export type EVMNFTChange = {
    name: string;
    symbol: string;
    image: string;
    address: string;
    fromAddress: string;
    amountChange: number;
    tokenId: string;
    amount?: string;
    isSemiFungible?: boolean;
    description?: string;
};

export type EVMTokenChange = {
    decimals: number;
    name: string;
    symbol: string;
    image: string;
    address: string;
    fromAddress: string;
    amountChange: number;
};

export interface Consideration {
    address: string;
    amount: string;
    decimals: number;
    recipient: string;
    symbol: string;
}
export interface EVMData {
    from: PrefixedHexString;
    chainId: PrefixedHexString;
    nonce: PrefixedHexString;
    maxPriorityFeePerGas: PrefixedHexString;
    maxFeePerGas: PrefixedHexString;
    gasPrice: PrefixedHexString;
    gasLimit: PrefixedHexString;
    to: PrefixedHexString;
    value: PrefixedHexString;
    data: PrefixedHexString;
    accessList: AccessListBuffer | AccessList;
    v?: PrefixedHexString;
    r?: PrefixedHexString;
    s?: PrefixedHexString;
    function?: EVMFunction;
    offer?: EVMNFTChange[];
    offerer?: string;
    consideration?: Consideration[];
    startTime?: string;
    endTime?: string;
}

export interface TransactionData extends EVMData {
    type: PrefixedHexString;
    gas: PrefixedHexString; //gasLimit
    action: string; // use for particle cancel or speed up transaction
    function?: never;
}

export interface EVMFunction {
    name: string;
    params: EVMParam[];
}

export interface EVMParam {
    name: string;
    value: unknown;
    type: string;
}

export interface GasFeeResult {
    low: GasFeeLevel;
    medium: GasFeeLevel;
    high: GasFeeLevel;
    baseFee: string;
}

export interface GasFeeLevel {
    maxPriorityFeePerGas: string;
    maxFeePerGas: string;
    minWaitTime: number;
    maxWaitTime: number;
}

export interface TokenPrice {
    address: string;
    currencies: Currency[];
}

export interface Currency {
    type: string;
    price: number;
    marketCap: number;
    '24hChange': number;
    '24hVol': number;
    lastUpdatedAt: number;
}

export interface RecentBlockhash {
    value: {
        blockhash: string;
    };
}

export interface DeviceInfo {
    user_agent: string;
    device: string;
    createdAt: string;
    updated_at: string;
    user_uuid: string;
    project_uuid: string;
    project_app_uuid: string;
    login_channel: string;
    login_name: string;
    isCurrent?: boolean;
    location: {
        ip: string;
        country_code: string;
        country_name?: string;
        city: string;
        continent: string;
        longitude: string;
        latitude: string;
    };
    project_app_name: string;
}
