export default function required(condition: unknown, errorPrompt?: string | Error | { code: number; message: string }) {
    let result = condition;
    if (typeof condition === 'function') {
        result = condition();
    }
    if (!result) {
        if (errorPrompt) {
            throw errorPrompt;
        } else {
            throw new Error('Required conditions not met');
        }
    }
}
