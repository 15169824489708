import * as Sentry from '@sentry/react';
import { detect } from 'detect-browser';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { errorToRedirect } from '../../api/error-handle';
import { isPromptSetMasterPassword } from '../../api/master-password';
import { AuthType } from '../../api/model/login-type';
import pnApi from '../../api/pn-api';
import wallet from '../../api/wallet';
import SplashLoading from '../../components/splash-loading';
import { useParticleAuth } from '../../context';
import useSignData from '../../context/hooks/useSignData';
import tokenProvider from '../../provider';
import { PreferenceKey } from '../../repository';
import authorizeUtils from '../../utils/authorizeUtils';
import { authPostMessage } from '../../utils/common-utils';
import redirectToApp, { AuthError } from '../../utils/redirect-utils';
import url from '../../utils/url-utils';
import MasterPasswordModal from '../account/masterPasswordModal';

function OAuthCallback() {
    const [promptSetMasterPassword, setPromptSetMasterPassword] = useState(false);

    const { loginSuccessRedirectToApp, securityAccountConfig, setUserInfo } = useParticleAuth();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { signWithConfirm } = useSignData();
    useEffect(() => {
        handleAuthResult();
    }, []);

    const handleAuthResult = () => {
        const localState = sessionStorage.getItem(PreferenceKey.PN_TEMP_SOCIAL_STATE_KEY);
        console.log('Social Login localState', localState);
        if (localState) {
            const authType = localState.split('@')[0] as AuthType;
            const verifyToken = localState.split('@')[2] || '';

            if (authType === 'twitter') {
                const localToken = localState.split('@')[1];
                const oauth_token = url.getQueryVariable('oauth_token');
                const oauth_verifier = url.getQueryVariable('oauth_verifier');
                console.log('twitter login', oauth_token, oauth_verifier);
                if (oauth_token && oauth_verifier && oauth_token === localToken && verifyToken) {
                    // bind account
                    accountBindSuccess(`${oauth_token},${oauth_verifier}`, authType, verifyToken);
                } else if (oauth_token && oauth_verifier && oauth_token === localToken) {
                    // login
                    authSuccess(`${oauth_token},${oauth_verifier}`, authType);
                } else {
                    Sentry.captureException(
                        {
                            message: 'Twitter login failed',
                        },
                        {
                            extra: {
                                local_state: localState,
                                local_token: localToken,
                                oauth_token,
                                oauth_verifier,
                                device: detect(),
                            },
                        }
                    );

                    redirectToApp({
                        error: AuthError.userCancelOperation(),
                    });
                }
            } else {
                const code = url.getQueryVariable('code');
                const state = url.getQueryVariable('state');
                if (code && state && localState && verifyToken) {
                    // bind account
                    accountBindSuccess(code, authType, verifyToken);
                } else if (code && state && localState === state) {
                    // login
                    authSuccess(code, authType);
                } else {
                    redirectToApp({
                        error: AuthError.userCancelOperation(),
                    });
                }
            }
        } else {
            Sentry.captureException(
                {
                    message: 'Social Login localState not found',
                },
                {
                    extra: {
                        queryParams: tokenProvider.params,
                        device: detect(),
                    },
                }
            );
            redirectToApp({
                error: AuthError.userCancelOperation(),
            });
        }
    };

    const accountBindSuccess = (code: string, authType: AuthType, verifyToken: string) => {
        const params = {
            provider: authType,
            thirdparty_code: code,
            security_account_verify_token: verifyToken,
        };

        console.log('loginBindingsThirdparty Params', params);

        const messageKey = 'particle-network-bind-account';

        pnApi
            .loginBindingsThirdparty(params)
            .then((userInfo) => {
                console.log('loginBindingsThirdpartyResponse', userInfo);
                const params = {
                    name: messageKey,
                    data: userInfo,
                    state: 'success',
                    _: Date.now(),
                };
                authPostMessage(messageKey, params);
                setTimeout(() => {
                    window.close();
                }, 10);
            })
            .catch((error: any) => {
                const params = {
                    name: messageKey,
                    data: {
                        message: error?.message || 'Bind account failed 100043',
                        errorCode: error.error_code,
                    },
                    _: Date.now(),
                    state: 'error',
                };
                authPostMessage(messageKey, params);
                setTimeout(() => {
                    window.close();
                }, 10);
            });
    };

    const authSuccess = (code: string, authType: AuthType) => {
        pnApi
            .loginWithThirdparty(authType, code)
            .then(async (userInfo) => {
                setUserInfo({ ...userInfo });
                if (wallet.hasMasterPassword()) {
                    navigate('/account/master-password/verify', {
                        replace: true,
                        state: {
                            loginVerifyMasterPassword: true,
                        },
                    });
                } else {
                    if (isPromptSetMasterPassword(securityAccountConfig.promptMasterPasswordSettingWhenLogin)) {
                        setPromptSetMasterPassword(true);
                    } else {
                        // 登录免确认签名：老用户登录跳过设置主密码，跳转签名页面 start
                        if (authorizeUtils.isNeedAuthorize()) {
                            if (!(await signWithConfirm())) {
                                return;
                            }
                        }

                        // 登录免确认签名：老用户登录跳过设置主密码，跳转签名页面 end
                        loginSuccessRedirectToApp();
                    }
                }
            })
            .catch((err) => {
                errorToRedirect(err);
            });
    };
    return (
        <>
            <SplashLoading visible={true} />
            <MasterPasswordModal visible={promptSetMasterPassword} setVisible={setPromptSetMasterPassword} />
        </>
    );
}

export default OAuthCallback;
