import { useInterval } from 'ahooks';
import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CircleClose from '../../../components/icon/circle-close';
import { useParticleAuth } from '../../../context';
import './index.less';

export interface WrongPasswordInfo {
    visible: boolean;
    accountFrozen?: { seconds: number };
}

const WrongPasswordModal = ({ info }: { info: WrongPasswordInfo }) => {
    const { t } = useTranslation();
    const { setWrongPassword, setPaymentVerify, userInfo, showSelectSecurityAccount } = useParticleAuth();

    const navigate = useNavigate();

    const forgotPassword = () => {
        setWrongPassword({ visible: false });
        setPaymentVerify({ visible: false });
        chooseVerifyType();
    };

    //reset password
    const chooseVerifyType = () => {
        if (userInfo?.security_account?.email && userInfo?.security_account?.phone) {
            showSelectSecurityAccount(true);
        } else {
            navigate('/account/set-password', {
                state: {
                    account: userInfo?.security_account?.email || userInfo?.security_account?.phone,
                },
            });
        }
    };

    const retry = () => {
        setWrongPassword({ visible: false });
    };

    const [interval, setInterval] = useState<number | undefined>();

    const [countdown, setCountdown] = useState(info.accountFrozen?.seconds || 0);

    useInterval(() => {
        const result = countdown - 1;
        setCountdown(result);
        if (result <= 0) {
            setInterval(undefined);
            retry();
        }
    }, interval);

    useEffect(() => {
        if (info.accountFrozen) {
            setCountdown(info.accountFrozen.seconds);
            setInterval(1000);
        }
        return () => {
            setInterval(undefined);
        };
    }, [info.accountFrozen]);

    const formatTime = (seconds: number): string => {
        let h: string | number = Math.floor(seconds / 60 / 60);
        h = h < 10 ? '0' + h : h;
        let m: string | number = Math.floor((seconds / 60) % 60);
        m = m < 10 ? '0' + m : m;
        let s: string | number = Math.floor(seconds % 60);
        s = s < 10 ? '0' + s : s;
        return h + ':' + m + ':' + s;
    };

    return (
        <Modal
            visible={info.visible}
            closable={false}
            centered={true}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            <div className="wrong-password-content">
                <CircleClose className="close-icon" onClick={retry} />

                {info.accountFrozen ? (
                    <>
                        <div className="modal-title">{t('account.incorrect_payment_password')}</div>
                        <div className="tip">{t('account.incorrect_tip2')}</div>
                    </>
                ) : (
                    <div className="tip">{t('account.incorrect_tip1')}</div>
                )}

                {info.accountFrozen && <div className="frozen-countdown">{formatTime(countdown)}</div>}

                <Button className="btn" onClick={forgotPassword}>
                    {t('account.forgot_password')}
                </Button>

                {!info.accountFrozen && (
                    <Button className="btn" onClick={retry}>
                        {t('account.retry')}
                    </Button>
                )}
            </div>
        </Modal>
    );
};

export default WrongPasswordModal;
