import { stripHexPrefix } from '@ethereumjs/util';
import { useNavigate } from 'react-router-dom';
import { useParticleAuth } from '..';
import wallet from '../../api/wallet';
import tokenProvider from '../../provider';
import { EvmSignMethod, SignQuery, SolanaSignMethod } from '../../provider/bundle';
import { PreferenceKey } from '../../repository';
import authorizeUtils from '../../utils/authorizeUtils';
import { toHexPrefixString } from '../../utils/common-utils';
import { AuthError } from '../../utils/redirect-utils';
import { isEMVMethod } from '../../utils/transaction-utils';

interface SignOptions {
    message: string;
    method: SolanaSignMethod | EvmSignMethod;
}

interface SignData {
    signature?: string;
    message?: string;
    error?: AuthError;
}

const isPersonalSign = (signQuery: SignQuery) => {
    return signQuery.method === EvmSignMethod.personal_sign || signQuery.method === EvmSignMethod.personal_sign_uniq;
};

const decodeMessage = (signQuery: SignQuery): string => {
    const msg = Buffer.from(stripHexPrefix(signQuery.message), 'hex').toString('utf-8');
    if (isPersonalSign(signQuery)) {
        if (/�/.test(msg)) {
            return signQuery.message;
        }
    }
    return msg;
};

const useSignData = () => {
    const { userInfo, appInfo } = useParticleAuth();
    const navigate = useNavigate();
    const sign = async (options: SignOptions): Promise<string> => {
        const { message, method } = options;

        try {
            console.log('sign message', message, method);
            if (isEMVMethod((tokenProvider.params as SignQuery).method)) {
                return await wallet.evmSign(message, method);
            } else {
                return await wallet.solanaSign(message, method);
            }
        } catch (error: any) {
            throw new Error(error.message || error);
        }
    };

    /**
     * 没有支付秘密和主密码时，不需要用户确认，直接签名返回
     * @returns
     */
    const signWithoutConfirm = async () => {
        if (authorizeUtils.isNeedAuthorize() && !userInfo?.security_account?.has_set_payment_password) {
            const signResult: SignData = {};
            try {
                setSignParams();
                const signQuery = tokenProvider.params as SignQuery;
                const signature = await sign({
                    message: isEMVMethod(signQuery.method) ? decodeMessage(signQuery) : signQuery.message,
                    method: signQuery.method,
                });

                signResult.signature = signature;
                signResult.message = signQuery.message;
                console.log('signWithoutConfirm success', signResult);
            } catch (error: any) {
                if (error.code && error.message) {
                    signResult.error = error;
                } else {
                    signResult.error = AuthError.authorizeError();
                }
                console.log('signWithoutConfirm error', signResult);
            }
            sessionStorage.setItem(PreferenceKey.PN_TEMP_AUTHORIZE_RESULT, JSON.stringify(signResult));
        }
    };

    /**
     * 有支付密码时，跳转到签名页面
     * @returns
     */
    const signWithConfirm = async () => {
        let result = true;
        if (authorizeUtils.isNeedAuthorize()) {
            if (authorizeUtils.isCanSignWithoutConfirm()) {
                await signWithoutConfirm();
            } else if (tokenProvider.chain.name.toLowerCase() !== 'solana') {
                setSignParams();
                console.log('navigate /evm-chain/sign');
                navigate('/evm-chain/sign', { replace: true });
                result = false;
            } else {
                setSignParams();
                console.log('navigate /solana/sign');
                navigate('/solana/sign', { replace: true });
                result = false;
            }
        }
        return result; // 返回false 中断后面的逻辑
    };

    const setSignParams = () => {
        const authorization = authorizeUtils.get();
        const isEvm = tokenProvider.chain.name.toLowerCase() !== 'solana';

        const queryParams = tokenProvider.queryParams;
        if (authorization) {
            queryParams.params.message =
                authorization?.message ||
                toHexPrefixString(
                    authorizeUtils.generateMessage(appInfo.platform === 'web' ? appInfo.package_id : undefined)
                );
            queryParams.params.method = isEvm
                ? authorization?.uniq
                    ? EvmSignMethod.personal_sign_uniq
                    : EvmSignMethod.personal_sign
                : SolanaSignMethod.signMessage;
            tokenProvider.queryParams = queryParams;
        }
    };

    return {
        signWithConfirm,
    };
};

export default useSignData;
