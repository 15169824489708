import InfoSign from './components/info-sign';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import './index.less';
import tokenProvider from '../../provider';
import EvmSign from './components/evm-sign';
import Loading from '../../components/loading';
import wallet from '../../api/wallet';
import { store } from '../../redux/store';
import { Provider } from 'react-redux';

function Sign(props: any) {
    const navigate = useNavigate();

    const { hash } = window.location;

    useEffect(() => {
        console.log('Sign, isLogin: ', isUserReady(), hash);
        if (!isUserReady()) {
            navigate('/error/login');
        }
    }, []);

    const isUserReady = () => {
        return tokenProvider.isLogin() && wallet.exist();
    };

    return isUserReady() ? (
        <div>
            {hash.includes('solana') ? (
                <InfoSign />
            ) : (
                <Provider store={store}>
                    <EvmSign />
                </Provider>
            )}
        </div>
    ) : (
        <Loading visible={true} />
    );
}

export default Sign;
