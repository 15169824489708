import { Button, Modal } from 'antd';
import './index.less';

type AlertModalProps = {
    visible: boolean;
    closable?: boolean;
    maskClosable?: boolean;
    contentMessage: string;
    cancelText?: string;
    okText: string;
    onCancel?: () => void;
    onOk: () => void;
};

const AlertModal = (props: AlertModalProps) => {
    const { visible, closable, maskClosable, contentMessage, cancelText, okText, onCancel, onOk } = props;
    return (
        <Modal
            className="alert-modal"
            visible={visible}
            centered={true}
            closable={closable ?? false}
            maskClosable={maskClosable ?? false}
            onCancel={onCancel}
        >
            <div className="alert-content-message">{contentMessage}</div>
            <div className="alert-modal-btns">
                {cancelText && (
                    <Button className="primary-antd-btn secondary" onClick={onCancel}>
                        {cancelText}
                    </Button>
                )}

                <Button className="primary-antd-btn" onClick={onOk}>
                    {okText}
                </Button>
            </div>
        </Modal>
    );
};

export default AlertModal;
