import './index.less';
import Icon from '@ant-design/icons';
import svg from '../../common/icon/circle_close.svg';

const CircleClose = ({ className = '', ...others }) => {
    return (
        <Icon
            className={className ? `circle-close-icon ${className}` : 'circle-close-icon'}
            //@ts-ignore
            component={svg}
            {...others}
        />
    );
};

export default CircleClose;
