import { isHexString } from '@ethereumjs/util';
import type { Chain } from '@particle-network/chains';
import { toHex } from 'tron-format-address';
import tokenProvider from '../provider';
import { EVMTransaction } from '../types/tx';
import { toHexPrefixString } from './common-utils';

export function isEIP1559Type(type: string | number | undefined): boolean {
    if (typeof type == 'number') {
        return type === 2;
    } else if (typeof type == 'string' && isHexString(type)) {
        return Number(type) === 2;
    }
    return false;
}

export function parseTransaction(rawTransaction: string): EVMTransaction {
    console.log('parseTransaction:', rawTransaction);
    const txData = JSON.parse(rawTransaction);
    if (txData.gas && !txData.gasLimit) {
        txData.gasLimit = txData.gas;
        txData.gas = undefined;
    }

    const tron = isTron(tokenProvider.chain);
    if (txData.from) {
        if (tron && !isHexString(txData.from)) {
            txData.from = toHex(txData.from);
        }
    }
    if (txData.to) {
        if (tron && !isHexString(txData.to)) {
            txData.to = toHex(txData.to);
        }
    }

    if (txData.value) {
        txData.value = toHexPrefixString(txData.value);
    }
    if (txData.data) {
        txData.data = toHexPrefixString(txData.data);
    }
    if (txData.chainId) {
        txData.chainId = toHexPrefixString(txData.chainId);
    } else {
        txData.chainId = toHexPrefixString(tokenProvider.chain.id);
    }

    if (txData.nonce) {
        txData.nonce = toHexPrefixString(txData.nonce);
    }
    if (txData.gasLimit) {
        txData.gasLimit = toHexPrefixString(txData.gasLimit);
    }
    if (txData.maxPriorityFeePerGas) {
        txData.maxPriorityFeePerGas = toHexPrefixString(txData.maxPriorityFeePerGas);
    }
    if (txData.maxFeePerGas) {
        txData.maxFeePerGas = toHexPrefixString(txData.maxFeePerGas);
    }
    if (txData.gasPrice) {
        txData.gasPrice = toHexPrefixString(txData.gasPrice);
    }
    if (txData.type || txData.type === 0) {
        txData.type = toHexPrefixString(txData.type);
    } else {
        txData.type = '0x0';
    }

    return txData;
}

export function isEMVMethod(method: string): boolean {
    return typeof method === 'string' && (method.startsWith('eth_') || method.startsWith('personal_'));
}

/**
 * isEVMAddress
 * @param address
 * @returns
 */
export function isEVMAddress(address: string): boolean {
    // @ts-ignore
    return address && address.length === 42 && address.startsWith('0x');
}

export function isTron(chain: Chain): boolean {
    return chain.name.toLowerCase() === 'tron';
}
