import './index.less';
import Icon from '@ant-design/icons';
import svg from '../../common/icon/menu.svg';

const MenuIcon = ({ className = '', ...others }) => {
    return (
        <Icon
            className={className ? `menu-icon ${className}` : 'menu-icon'}
            //@ts-ignore
            component={svg}
            {...others}
        />
    );
};

export default MenuIcon;
