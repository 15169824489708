import { Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SVGIcon from '../../../components/icon/svg-icon';
import device from '../../../utils/detect-device';
import './index.less';

const MasterPasswordDescription = () => {
    const { Title, Paragraph } = Typography;
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="about-mp-container">
            {!device.authEmbed() && (
                <SVGIcon className="icon-navigation-back" name="circle_back" onClick={() => navigate(-1)} />
            )}
            <Space direction="vertical" className="space-container">
                <Title level={3} className="master-title">
                    {t('account.about_master_password')}
                </Title>
                <Title level={5} className="title">
                    {t('account.about_master_password_t1')}
                </Title>
                <Paragraph className="content">{t('account.about_master_password_p1')}</Paragraph>
                <Title level={5} className="title">
                    {t('account.about_master_password_t2')}
                </Title>
                <Paragraph className="content">{t('account.about_master_password_p2')}</Paragraph>
                <Title level={5} className="title">
                    {t('account.about_master_password_t3')}
                </Title>
                <Paragraph className="content">{t('account.about_master_password_p3')}</Paragraph>
            </Space>
        </div>
    );
};

export default MasterPasswordDescription;
