import type { Chain } from '@particle-network/chains';
import { UserInfo } from '../api/model/user-info';
import { load, PreferenceKey, removeItem, save } from '../repository';
import { clearLocalKeyAndLocalKMSDataKey } from './../api/master-password';
import { UserPreview } from './../api/model/user-info';
import { BasicQuery, CurrencyUnit, ERC4337Options, LoginQuery, QueryParams, SignQuery } from './bundle';

class TokenProvider {
    private _paymentToken: string | undefined;

    fiatCoin: CurrencyUnit = 'USD';

    get erc4337(): boolean | ERC4337Options {
        return this.queryParams.erc4337;
    }

    get secretKey(): string {
        return this.queryParams.secretKey;
    }

    get token(): string {
        return this.userInfo.token;
    }

    get themeType(): string | null {
        return this.queryParams.themeType;
    }

    get launage(): string | undefined {
        return this.queryParams.language;
    }

    get params(): LoginQuery | SignQuery | BasicQuery {
        return this.queryParams.params;
    }

    get queryParams(): QueryParams {
        const params = sessionStorage.getItem(PreferenceKey.PN_QUERY_PARAMS) || '{}';
        return JSON.parse(params);
    }

    set queryParams(params: QueryParams) {
        if (params) {
            sessionStorage.setItem(PreferenceKey.PN_QUERY_PARAMS, JSON.stringify(params));
        } else {
            sessionStorage.removeItem(PreferenceKey.PN_QUERY_PARAMS);
        }
    }

    get chain(): Chain {
        const params = this.params;
        let name = params?.chain_name?.toLowerCase();
        if (name) {
            // TODO: remove this when chain name is fixed
            if (name === 'mapo') {
                name = 'mapprotocol';
            } else if (name === 'conflux') {
                name = 'confluxespace';
            } else if (name === 'polygon zkevm') {
                name = 'polygonzkevm';
            }
            // TODO: remove this when chain name is fixed

            if ('chain_id' in params) {
                const id = params.chain_id;
                return {
                    name,
                    id,
                };
            } else {
                return {
                    name: name,
                    id: 0, // not care chain id
                };
            }
        } else {
            return {
                name: 'Ethereum',
                id: 1,
            };
        }
    }

    get userPreview(): UserPreview {
        const { mac_key, wallets, cognito_result, id, ...preview } = this.userInfo;
        return preview;
    }

    get userInfo(): UserInfo {
        const info = load(PreferenceKey.PN_USER_INFO);
        return JSON.parse(info ?? '{}');
    }

    set userInfo(userInfo: UserInfo) {
        save(PreferenceKey.PN_USER_INFO, JSON.stringify(userInfo));
        const params = this.params;
        if (params.token !== userInfo.token) {
            params.token = userInfo.token;
            const queryParams: QueryParams = JSON.parse(sessionStorage.getItem(PreferenceKey.PN_QUERY_PARAMS) || '{}');
            sessionStorage.setItem(PreferenceKey.PN_QUERY_PARAMS, JSON.stringify({ ...queryParams, params }));
        }
    }

    get paymentToken(): string | undefined {
        return this._paymentToken;
    }

    set paymentToken(token: string | undefined) {
        this._paymentToken = token;
    }

    isLogin(): boolean {
        const info = load(PreferenceKey.PN_USER_INFO);
        if (info) {
            const userInfo = JSON.parse(info);
            return userInfo.token && userInfo.token === this.params.token;
        }
        return false;
    }

    logout() {
        try {
            clearLocalKeyAndLocalKMSDataKey();
            removeItem(PreferenceKey.PN_USER_INFO);
        } catch (error) {
            //ignore
        }
    }

    hasPaymentPassword(): boolean {
        return this.userInfo?.security_account?.has_set_payment_password ?? false;
    }

    hasMasterPassword(): boolean {
        return this.userInfo?.security_account?.has_set_master_password ?? false;
    }
}

const tokenProvider: TokenProvider = new TokenProvider();
export default tokenProvider;
