import { LoadingOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Image, message } from 'antd';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js/max';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { errorToRedirect } from '../../api/error-handle';
import { clearLocalKeyAndLocalKMSDataKey } from '../../api/master-password';
import { AuthType, CaptchaRequest } from '../../api/model/login-type';
import pnApi from '../../api/pn-api';
import PowerFooter from '../../components/power-footer';
import SplashLoading from '../../components/splash-loading';
import { useParticleAuth } from '../../context';
import { authLogin } from '../../oauth';
import tokenProvider from '../../provider';
import { LoginQuery } from '../../provider/bundle';
import { isSocialLogin } from '../../utils/common-utils';
import device from '../../utils/detect-device';
import { isSDKSupportPassKeys, passkeysAuthentication } from '../../utils/passkeys';
import MasterPasswordModal from '../account/masterPasswordModal';
import CaptchaInput from './components/captcha-input';
import EmailLogin from './components/email-login';
import PhoneLogin from './components/phone-login';
import SocialLogin from './components/social-login';
import './index.less';

function Login(props: any) {
    const { t } = useTranslation();

    const [loginType, setLoginType] = useState<AuthType>();

    const [supportAuthTypes, setSupportAuthTypes] = useState<AuthType[]>([]);

    const [account, setAccount] = useState<string | undefined>('');

    const [showInputCaptcha, setShowInputCaptcha] = useState(false);

    const [loginWithAccount, setLoginWithAccount] = useState(false);

    const [promptSetMasterPassword, setPromptSetMasterPassword] = useState(false);

    const [loginFormStyle, setLoginFormStyle] = useState({});

    const { appInfo, loginSuccessRedirectToApp, setUserInfo } = useParticleAuth();

    const { run: loginWithSocial } = useRequest(authLogin, {
        manual: true,
        retryCount: 3,
        onError: (error) => {
            console.log('async social login error', error);
        },
    });

    const { run: passkeysLogin } = useRequest(passkeysAuthentication, {
        manual: true,
        onSuccess: (result) => {
            loginWithPasskeys(result);
        },
        onError: (error: any) => {
            message.error(error.message?.split(' See: https')?.[0] || error);
        },
    });

    const loginWithPasskeys = (data: any) => {
        pnApi
            .loginWithThirdparty(AuthType.passkeys, JSON.stringify(data))
            .then((userInfo) => {
                setUserInfo({ ...userInfo });
                loginSuccessRedirectToApp();
            })
            .catch((err) => {
                console.log('login with passkeys error', err);
            });
    };

    const displayInputCaptcha = (visible: boolean) => {
        setShowInputCaptcha(visible);
    };

    useEffect(() => {
        document.documentElement.setAttribute('data-login-type', loginType || '');
    }, [loginType]);

    useEffect(() => {
        // remove local key when login
        clearLocalKeyAndLocalKMSDataKey();
        setLoginFormStyle({});
        handleLogin();
    }, []);

    const patt = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

    //发送验证码
    const sendCaptcha = (data: CaptchaRequest): Promise<boolean> => {
        setAccount(data.email ?? data.phone!);
        return pnApi.sendCaptcha(data).then((result) => {
            console.log(`request captcha success: ${result.success}`);
            return result.success;
        });
    };

    // login directly
    const loginDirectly = (authType: AuthType, account: string) => {
        if (authType === AuthType.email) {
            if (patt.test(account)) {
                setLoginWithAccount(true);
                setAccount(account);
            }
        } else if (authType === AuthType.phone) {
            const regularExpression = /^\+?\d{8,14}$/;
            if (regularExpression.test(account)) {
                try {
                    if (isValidPhoneNumber(account)) {
                        const phone = parsePhoneNumber(account).format('E.164');
                        setLoginWithAccount(true);
                        setAccount(phone);
                    }
                } catch (e) {
                    // ignore
                }
            }
        } else if (authType === AuthType.jwt) {
            pnApi
                .loginWithThirdparty(authType, account)
                .then((userInfo) => {
                    setUserInfo({ ...userInfo });
                    loginSuccessRedirectToApp();
                })
                .catch((err) => {
                    errorToRedirect(err);
                });
        }
    };

    const loginContent = () => {
        if (showInputCaptcha) {
            return (
                <CaptchaInput
                    sendCaptcha={sendCaptcha}
                    setShowInputCaptcha={displayInputCaptcha}
                    account={account}
                    loginWithAccount={loginWithAccount}
                    setPromptSetMasterPassword={setPromptSetMasterPassword}
                />
            );
        } else {
            if (loginType === AuthType.email) {
                return (
                    <EmailLogin
                        sendCaptcha={sendCaptcha}
                        setShowInputCaptcha={displayInputCaptcha}
                        setLoginType={changeLoginType}
                        account={account}
                        supportAuthTypes={supportAuthTypes}
                        loginWithAccount={loginWithAccount}
                    />
                );
            } else if (loginType === AuthType.phone) {
                return (
                    <PhoneLogin
                        sendCaptcha={sendCaptcha}
                        setShowInputCaptcha={displayInputCaptcha}
                        setLoginType={changeLoginType}
                        account={account}
                        supportAuthTypes={supportAuthTypes}
                        loginWithAccount={loginWithAccount}
                    />
                );
            } else {
                return <div />;
            }
        }
    };

    const changeLoginType = (type: AuthType) => {
        setLoginType(type);
    };

    const handleLogin = () => {
        const query = tokenProvider.params as LoginQuery;
        const type = query.login_type ?? AuthType.email;
        console.log(`login type - >> ${type}`, query.account);
        if ((type === AuthType.email || type === AuthType.phone || type === AuthType.jwt) && query.account) {
            loginDirectly(type, query.account);
        }
        setLoginType(type as AuthType);
        if (!device.authEmbed() && !loginWithAccount) {
            if (query.support_auth_types) {
                const allTypes = Object.values(AuthType);
                let types;
                if (query.support_auth_types === 'all') {
                    types = allTypes.filter((authType) => authType !== AuthType.jwt);
                    if (!isSDKSupportPassKeys()) {
                        types.splice(types.indexOf(AuthType.passkeys), 1);
                    }
                    setSupportAuthTypes(types as AuthType[]);
                } else {
                    const allTypeValues = allTypes.map((authType) => authType as string);
                    types = query.support_auth_types
                        .split(',')
                        .filter((authType) => authType !== AuthType.jwt && allTypeValues.includes(authType));

                    if (types.includes(AuthType.passkeys) && !isSDKSupportPassKeys()) {
                        types.splice(types.indexOf(AuthType.passkeys), 1);
                    }
                    if (!types.includes(type)) {
                        types.push(type);
                    }
                    if (!types.includes(AuthType.email) && !types.includes(AuthType.phone) && types.length > 0) {
                        types.unshift(AuthType.email);
                    }
                    if (types.length === 1 && (types[0] === AuthType.email || types[0] === AuthType.phone)) {
                        //clear all
                        types.length = 0;
                    }

                    setSupportAuthTypes(types as AuthType[]);
                }
            }

            if (isSocialLogin(type)) {
                loginWithSocial(type as AuthType);
            }
        } else {
            setSupportAuthTypes([AuthType.email, AuthType.apple]);
        }
    };

    const authTypeDisplayed = (type: AuthType): string => {
        if (type === AuthType.jwt) {
            return 'JWT';
        } else {
            return type.titleCase();
        }
    };

    const loggingHint = (loginType: AuthType): string => {
        if (loginType === AuthType.jwt) {
            return t('login.create_wallet');
        } else {
            return t('login.social_login').format(authTypeDisplayed(loginType));
        }
    };

    const loginDes = (getCode: boolean) => {
        if (getCode) {
            return <div className="login-des">{account}</div>;
        } else {
            return (
                loginType && (
                    <div className="login-des">
                        {loginType === AuthType.email ||
                        loginType === AuthType.phone ||
                        loginType === AuthType.passkeys ? (
                            t('login.to_continue').format(appInfo.name)
                        ) : (
                            <div className="third-party-des">{loggingHint(loginType)}</div>
                        )}
                    </div>
                )
            );
        }
    };

    const projectLogo = useMemo(() => {
        let img =
            tokenProvider.themeType !== 'dark'
                ? appInfo.theme?.web_auth?.light_mode?.project_logo
                : appInfo.theme?.web_auth?.dark_mode?.project_logo;
        if (!img) {
            img = appInfo.theme?.web_auth?.light_mode?.project_logo || appInfo.theme?.web_auth?.dark_mode?.project_logo;
        }
        if (!img) {
            img = require('@/common/images/logo.png');
        }
        return img;
    }, [appInfo.theme]);

    return loginType ? (
        <div className="login-container-box">
            <div className="wrap" style={loginFormStyle}>
                <div className="login-logo">
                    <Image
                        className="product-logo"
                        preview={false}
                        height={64}
                        src={projectLogo}
                        key={projectLogo}
                        placeholder={
                            <div className="placeholder-wrap">
                                <LoadingOutlined style={{ fontSize: 24 }} spin />
                            </div>
                        }
                        fallback={require('@/common/images/logo.png')}
                        onError={(e) => {
                            e.currentTarget.src = require('@/common/images/logo.png');
                        }}
                    />
                </div>
                <div className="login-header0">
                    {appInfo.theme?.web_auth?.project_name ?? t('common.particle_auth')}
                </div>

                {loginDes(showInputCaptcha)}

                {loginContent()}

                {!showInputCaptcha &&
                    !loginWithAccount &&
                    (loginType === AuthType.email ||
                        loginType === AuthType.phone ||
                        loginType === AuthType.passkeys) && (
                        <SocialLogin
                            supportAuthTypes={supportAuthTypes}
                            preferredAuthType={loginType}
                            onPreferredAuthTypeChange={setLoginType}
                            loginWithSocial={(authType: AuthType) => {
                                if (authType === AuthType.passkeys) {
                                    passkeysLogin();
                                } else {
                                    loginWithSocial(authType);
                                }
                            }}
                        />
                    )}
            </div>
            <PowerFooter />
            <MasterPasswordModal visible={promptSetMasterPassword} setVisible={setPromptSetMasterPassword} />
        </div>
    ) : (
        <SplashLoading visible={true} />
    );
}

export default Login;
