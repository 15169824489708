import { DownOutlined } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Button, message } from 'antd';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { defaultCountriesData, getCurrentCountry } from '../../../api/model/all-countries';
import pnApi from '../../../api/pn-api';
import SVGIcon from '../../../components/icon/svg-icon';
import PowerFooter from '../../../components/power-footer';
import TurnstileModal from '../../../components/turnstileModal';
import { EmailRegExp, isPhoneValid } from '../../../utils';
import CaptchaInput from './captcha-input';
import './index.less';

const AccountBind = (props: any) => {
    const navigate = useNavigate();

    const { t } = useTranslation();
    const { state } = useLocation();
    const redirectUrl = (state as any)?.redirectUrl;
    const verifyToken = (state as any)?.verifyToken;

    const clickRef = useRef<any>();
    const [bindAccount, setBindAccount] = useState<string>('');
    const [countryData, setCountryData] = useState(['United States', 'us', '1']);
    const [isSendCode, setIsSendCode] = useState(false);
    const accountInputRef = useRef(null);
    const [errorMsg, setError] = useState('');

    const [accountType, setAccountType] = useState<string>('email');
    const [turnstileVisible, setTurnstileVisible] = useState(false);

    useEffect(() => {
        if (accountType === 'phone' || (state as any)?.showSwitch) {
            const currentCountry = getCurrentCountry();
            if (currentCountry) {
                setCountryData(currentCountry);
            }
        }
    }, [accountType, (state as any)?.showSwitch]);

    const [visbSelectCountry, setVisbSelectCountry] = useState(false);
    useEffect(() => {
        if (visbSelectCountry) {
            document.addEventListener('click', clickCallback, false);
            return () => {
                document.removeEventListener('click', clickCallback, false);
            };
        }
    }, [visbSelectCountry]);

    const clickCallback = (event: any) => {
        if (clickRef.current?.contains(event.target)) {
            return;
        }
        setVisbSelectCountry(false);
    };
    useEffect(() => {
        const type = (state as any)?.accountType;
        if (type) {
            setAccountType(type);
        }
    }, [state]);

    const { loading: codeLoading, run: runGetCode } = useRequest(pnApi.securityAccountsCode, {
        manual: true,
        onSuccess: (result, params) => {
            setIsSendCode(result);
        },
        onError: (error: any) => {
            const code = error?.error_code || 0;
            if (code === 50003 || code === 50004) {
                setBindAccount('');
                (accountInputRef.current as any).value = '';
            }
        },
    });

    const checkAndRequestCode = () => {
        let account = (accountInputRef.current as any).value;

        if (accountType === 'email') {
            if (!account) {
                return setError(t('account.input_vaild_email'));
            } else if (!EmailRegExp.test(account)) {
                return setError(t('login.email_format_error'));
            }
        } else {
            const regionCode = countryData[1].toUpperCase();
            if (!account && account !== '0') {
                return setError(t('account.input_vaild_mobile'));
            } else if (!isPhoneValid(account, regionCode)) {
                return setError(t('login.phone_format_error'));
            } else {
                account = `+${countryData[2]}${account}`;
            }
        }
        setBindAccount(account);
        if (!account.includes('@')) {
            setTurnstileVisible(true);
        } else {
            runGetCode({
                email: account,
            });
        }
    };

    const changeAccountType = () => {
        setAccountType(accountType === 'email' ? 'phone' : 'email');
        setBindAccount('');
    };

    useEffect(() => {
        setTimeout(() => {
            (accountInputRef.current as any)?.focus();
        });
    }, []);

    // 输入验证码
    if (isSendCode) {
        return (
            <CaptchaInput
                bindAccount={bindAccount}
                redirectUrl={redirectUrl || ''}
                verifyToken={verifyToken}
                backToInputAccount={() => {
                    setIsSendCode(false);
                }}
            />
        );
    }
    return (
        <div className="set-email-container">
            <SVGIcon className="icon-navigation-back" name="circle_back" onClick={() => navigate(-1)} />
            <h2 className="set-email-title">
                {accountType === 'email' ? t('account.set_your_email') : t('account.set_your_mobile')}
            </h2>
            <p className="set-email-desc-1">
                {accountType === 'email' ? t('account.set_your_email_tip') : t('account.set_your_mobile_tip')}
            </p>

            {accountType === 'email' ? (
                <>
                    <div className="account-input-box">
                        <input
                            onInput={() => setError('')}
                            className="input_email"
                            type="email"
                            placeholder={t('account.place_email_address')}
                            ref={accountInputRef}
                            defaultValue={bindAccount}
                            // 回车事件
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    checkAndRequestCode();
                                }
                            }}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div className="account-input-box phone" ref={clickRef}>
                        {visbSelectCountry && (
                            <div className="account-select-country">
                                {defaultCountriesData.map((item, index) => (
                                    <p
                                        key={index}
                                        className="account-select-country-item"
                                        onClick={() => {
                                            setCountryData(item);
                                            setVisbSelectCountry(false);
                                        }}
                                    >
                                        <div className="country-box">
                                            <div className="country-flag">{getUnicodeFlagIcon(`${item[1]}`)}</div>
                                            <span className="country-name">{item[0]}</span>
                                        </div>
                                        <div className="country-code">{'+' + item[2]}</div>
                                    </p>
                                ))}
                            </div>
                        )}
                        <div className="account-select-opt" onClick={() => setVisbSelectCountry(!visbSelectCountry)}>
                            <div className="account-select-icon">{getUnicodeFlagIcon(`${countryData[1]}`)}</div>
                            <DownOutlined className="down-more" />
                        </div>
                        <span>+{countryData[2]}</span>
                        <input
                            onInput={() => {
                                setError('');

                                (accountInputRef.current as any).value =
                                    (accountInputRef.current as any).value.match(/^\d+/)?.[0] || '';
                            }}
                            type="text"
                            className="no-number dd"
                            placeholder={t('account.mobile')}
                            ref={accountInputRef}
                            onWheel={(e: any) => e.target.blur()}
                            defaultValue={bindAccount.replace(`+${countryData[2]}`, '')}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    checkAndRequestCode();
                                }
                            }}
                            onFocus={() => {
                                document.querySelector('.account-input-box.phone')?.setAttribute('data-focus', 'true');
                            }}
                            onBlur={() => {
                                document.querySelector('.account-input-box.phone')?.setAttribute('data-focus', 'false');
                            }}
                        />
                    </div>
                </>
            )}
            <p className={`error-tip ${errorMsg ? 'show' : ''}`}>{errorMsg}</p>

            <Button
                className="send-code-btn account-submit-btn bottom-margin"
                loading={codeLoading}
                onClick={checkAndRequestCode}
            >
                {t('login.get_captcha')}
            </Button>

            {(state as any)?.showSwitch && (
                <div className="account-switch-item" onClick={changeAccountType}>
                    {accountType === 'email' ? t('account.switch_text_mobile') : t('account.switch_text_email')}
                </div>
            )}
            <PowerFooter />

            <TurnstileModal
                onError={() => message.error(t('error.server_20112'))}
                onSuccess={(token) => {
                    runGetCode({
                        phone: bindAccount,
                        cf_turnstile_response: token,
                    });
                }}
                visible={turnstileVisible}
                setVisible={setTurnstileVisible}
            ></TurnstileModal>
        </div>
    );
};

export default AccountBind;
