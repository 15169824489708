import { useRequest } from 'ahooks';
import { Skeleton } from 'antd';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import pnApi from '../../../api/pn-api';
import Header from '../../../components/header';
import SVGIcon from '../../../components/icon/svg-icon';
import PowerFooter from '../../../components/power-footer';
import tokenProvider from '../../../provider';
import './index.less';

const DeviceList = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        loading,
        data: deviceList = [],
        run: getAuthorizationsRequest,
    } = useRequest(
        () => {
            return pnApi.getAuthorizations().then((list) => {
                return list.map((item) => {
                    if (item.updated_at) {
                        item.updated_at = dayjs(new Date(item.updated_at)).format('YYYY/MM/DD HH:mm');
                    }
                    if (tokenProvider.params.project_app_uuid === item.project_app_uuid) {
                        item.isCurrent = true;
                    }
                    if (item.login_channel) {
                        item.login_channel = item.login_channel.replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
                    }
                    if (item?.location?.country_code) {
                        try {
                            item.location.country_name = new Intl.DisplayNames([tokenProvider.launage || 'en'], {
                                type: 'region',
                            }).of(item?.location?.country_code);
                        } catch (error) {
                            console.log('Intl.DisplayNames', error);
                        }
                    }
                    return item;
                });
            });
        },
        {
            cacheKey: 'getAuthorizations',
            manual: true,
        }
    );

    useEffect(() => {
        getAuthorizationsRequest();
    }, []);

    return (
        <div className="device-list-container">
            <Header displayBackBtn={true}>{t('new.authorization')}</Header>
            <div className="devices-description">{t('new.authorization_tips')}</div>
            {loading && (
                <div className="device-list">
                    <Skeleton />
                </div>
            )}
            {(!loading || !!deviceList?.length) && (
                <div className="device-list">
                    {deviceList?.map((item, index) => {
                        return (
                            <div
                                className="item"
                                key={index}
                                onClick={() => {
                                    navigate('/manageDevices/deviceDetails', {
                                        state: {
                                            loginDeviceInfo: item,
                                        },
                                    });
                                }}
                            >
                                <div className="left">
                                    <div className="wrap">
                                        <div className="s-row device">{item.project_app_name}</div>
                                        <div className="s-row created_at">{item.updated_at}</div>
                                    </div>
                                </div>
                                <div className="right">
                                    {item.isCurrent && <div className="current-icon">{t('new.current')}</div>}
                                    <SVGIcon className="arrow-right-icon" name="arrow_right_icon" />
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            <PowerFooter />
        </div>
    );
};

export default DeviceList;
