import { addHexPrefix, publicToAddress, toBuffer, toChecksumAddress } from '@ethereumjs/util';
import { SignTypedDataVersion, concatSig } from '@metamask/eth-sig-util';
import type { Ecdsa, EdDsa } from '@particle/thresh-sig';
import bs58 from 'bs58';
import { fromHex } from 'tron-format-address';
import { v4 as uuidv4 } from 'uuid';
import { generateDataKey } from '../../aws/kms';
import tokenProvider from '../../provider';
import { ERC4337Options, EvmSignMethod, SolanaSignMethod } from '../../provider/bundle';
import { isNullish, shortString } from '../../utils/common-utils';
import retry from '../../utils/retry';
import { personalSignHash, signTypedDataHash } from '../../utils/sig-utils';
import { isTron } from '../../utils/transaction-utils';
import { createEncryptedWallet, decryptWallet, restoreType1Wallet } from '../master-password';
import { SmartAccount, Wallet, WalletEncryptedType, WalletInfo } from '../model/user-info';
import pnApi from '../pn-api';

const wallet = {
    walletInfo(): WalletInfo {
        return tokenProvider.userInfo?.wallets?.filter((info) => info?.chain_name === this.name())[0];
    },

    wallets(): Wallet[] {
        return tokenProvider.userInfo?.wallets
            ?.filter((info) => info.public_address && info.public_address.length > 0)
            .map<Wallet>((info) => {
                return {
                    uuid: info.uuid,
                    chain_name: info.chain_name,
                    public_address: info.public_address,
                };
            });
    },

    exist(): boolean {
        const wallet = this.walletInfo();
        return !isNullish(wallet.public_address);
    },

    hasMasterPassword(): boolean {
        return (
            tokenProvider.userInfo?.security_account?.has_set_master_password ||
            tokenProvider.userInfo.wallets?.some(
                (info) => info.encrypted_type === WalletEncryptedType.KMS_AES_WITH_DATA_KEY_AND_MASTER_PASSWORD
            )
        );
    },

    hasType1Wallet(): boolean {
        return tokenProvider.userInfo.wallets?.some(
            (info) => info.encrypted_type === WalletEncryptedType.KMS_AES_WITH_DATA_KEY_AND_DEFAULT_MASTER_PASSWORD
        );
    },

    name(): string {
        if (tokenProvider.chain.name.toLowerCase() === 'solana') {
            return 'solana';
        }
        return 'evm_chain';
    },

    isSolanaChain(): boolean {
        return this.name() === 'solana';
    },

    isEVMChain(): boolean {
        return this.name() === 'evm_chain';
    },

    async edDSA(): Promise<EdDsa> {
        const decryptAction = () => decryptWallet(this.walletInfo());
        const data = await retry(decryptAction, 3);
        const { EdDsa } = await import('@particle/thresh-sig');
        return EdDsa.from(data);
    },

    async ecDSA(): Promise<Ecdsa> {
        const decryptAction = () => decryptWallet(this.walletInfo());
        const data = await retry(decryptAction, 3);
        const { Ecdsa } = await import('@particle/thresh-sig');
        return Ecdsa.from(data);
    },

    async refreshEcdsa(p2Key: string): Promise<string> {
        const { Ecdsa } = await import('@particle/thresh-sig');
        const newP2Key = (
            await Ecdsa.from(p2Key).refresh(process.env.REACT_APP_BASE_URL as string, this.params())
        ).to();
        return newP2Key;
    },

    async decryptData(ciphertext: string, key: string): Promise<string> {
        try {
            const { decryptData } = await import('@particle/thresh-sig');
            const plaintext = await decryptData(ciphertext, key);
            return plaintext;
        } catch (error) {
            if (this.hasMasterPassword()) {
                throw new Error('Master password decryption error');
            } else {
                throw new Error('Decryption failed, please try again later.');
            }
        }
    },

    async encryptData(plaintext: string, key: string): Promise<string> {
        const { encryptData } = await import('@particle/thresh-sig');
        const encrypted = await encryptData(plaintext, key);
        return encrypted;
    },

    async generateKey(password: string, uuid: string): Promise<string> {
        const { generateKey } = await import('@particle/thresh-sig');
        const key = await generateKey(password, uuid);
        return key;
    },

    shortAddress(): string {
        const address = this.publicAddress();
        return shortString(address);
    },

    publicAddress(): string {
        const walletInfo = this.walletInfo();
        if (walletInfo?.public_address) {
            if (this.isSolanaChain()) {
                return walletInfo.public_address;
            }
            return toChecksumAddress(walletInfo.public_address);
        }
        return '';
    },

    async publicAddressDisplayed(): Promise<string> {
        let address = this.publicAddress();

        if (tokenProvider.erc4337 && this.isEVMChain()) {
            let accountConfig: ERC4337Options;
            if (typeof tokenProvider.erc4337 === 'boolean') {
                accountConfig = {
                    name: 'BICONOMY',
                    version: '1.0.0',
                };
            } else {
                accountConfig = tokenProvider.erc4337;
            }
            const localKey = `auth_aa_${tokenProvider.chain.id}_${accountConfig.name}_${accountConfig.version}_${address}`;
            const localAAAddress = localStorage.getItem(localKey);
            if (localAAAddress) {
                address = localAAAddress;
            } else {
                const accounts = await pnApi
                    .evmRpc<SmartAccount[]>({
                        id: uuidv4(),
                        jsonrpc: '2.0',
                        method: 'particle_aa_getSmartAccount',
                        params: [{ ...accountConfig, ownerAddress: address }],
                    })
                    .then((output) => output.result);
                localStorage.setItem(localKey, accounts[0].smartAccountAddress);
                address = accounts[0].smartAccountAddress;
            }
        }

        if (isTron(tokenProvider.chain)) {
            address = fromHex(address);
        }
        return address;
    },

    async create(chainName: string) {
        const endpoint = process.env.REACT_APP_BASE_URL as string;
        const params = this.params();
        let p2Key;
        let address;
        if (chainName === 'solana') {
            const { EdDsa } = await import('@particle/thresh-sig');
            const edDSA = await EdDsa.gen(endpoint, params);
            p2Key = edDSA.to();
            address = bs58.encode(edDSA.pub());
        } else {
            const { Ecdsa } = await import('@particle/thresh-sig');
            const ecDSA = await Ecdsa.gen(endpoint, params);
            p2Key = ecDSA.to();
            address = toChecksumAddress('0x' + publicToAddress(ecDSA.pub(), true).toString('hex'));
        }
        return {
            p2Key,
            address,
        };
    },

    async gen(): Promise<string> {
        const walletInfo = this.walletInfo();
        let publicAddress: string;
        if (walletInfo?.public_address) {
            publicAddress = walletInfo.public_address;
            console.log('wallet restore pubKey:', publicAddress);
            await restoreType1Wallet();
        } else {
            const otherWallet = tokenProvider.userInfo.wallets.filter((info) => info?.chain_name !== this.name())[0];
            let encryptedKMSDataKey = otherWallet.encrypted_kms_data_key;
            let localKMSDataKey;
            let p2KeyValue;

            if (encryptedKMSDataKey) {
                const { address, p2Key } = await this.create(walletInfo?.chain_name);
                publicAddress = address;
                p2KeyValue = p2Key;
            } else {
                const [{ p2Key, address }, kmsDataKey] = await Promise.all([
                    this.create(walletInfo?.chain_name),
                    generateDataKey().then((outputDK) => {
                        return Promise.all([
                            this.generateKey(
                                Buffer.from(outputDK.Plaintext as any).toString('base64'),
                                tokenProvider.userInfo.uuid
                            ),
                            Promise.resolve(Buffer.from(outputDK.CiphertextBlob as any).toString('base64')),
                        ]);
                    }),
                ]);

                p2KeyValue = p2Key;
                publicAddress = address;
                localKMSDataKey = kmsDataKey[0];
                encryptedKMSDataKey = kmsDataKey[1];
            }

            await createEncryptedWallet(walletInfo, p2KeyValue, publicAddress, encryptedKMSDataKey, localKMSDataKey);
            console.log(`wallet gen address: ${publicAddress}`);
        }
        return publicAddress;
    },

    async evmSign(message: string, method: string): Promise<string> {
        if (this.isSolanaChain()) {
            throw new Error('chain error');
        }
        const ecDSA = await this.ecDSA();
        if (method === EvmSignMethod.eth_sendTransaction) {
            const txData = JSON.parse(message);
            if (isTron(tokenProvider.chain)) {
                const signed = await ecDSA.signTronTx(process.env.REACT_APP_BASE_URL as string, this.params(), txData);
                return signed;
            } else {
                if (isNullish(txData.nonce)) {
                    txData.nonce = '0x0';
                }
                const signed = await ecDSA.signTx(process.env.REACT_APP_BASE_URL as string, this.params(), txData);
                const { TransactionFactory } = await import('@ethereumjs/tx');
                const tx = TransactionFactory.fromTxData(signed);
                return addHexPrefix(tx.serialize().toString('hex'));
            }
        } else {
            //sign typed data or personal sign
            let hash;
            if (method.includes(EvmSignMethod.eth_signTypedData)) {
                hash = signTypedDataHash({
                    data: JSON.parse(message),
                    version: getSignTypedVersion(method),
                });
            } else {
                hash = personalSignHash({
                    data: message,
                });
            }

            const sig = await ecDSA.sign(
                process.env.REACT_APP_BASE_URL as string,
                this.params(),
                hash,
                method.endsWith('_uniq')
            );
            return concatSig(toBuffer(sig.v + 27), sig.r, sig.s);
        }
    },

    async solanaSign(message: string, method: string): Promise<string> {
        if (this.isEVMChain()) {
            throw new Error('chain error');
        }
        const edDSA = await this.edDSA();
        if (method === SolanaSignMethod.signMessage) {
            const raw = bs58.decode(message);
            const signed = await edDSA.sign(process.env.REACT_APP_BASE_URL as string, this.params(), raw);
            return signed.toString('base64');
        } else {
            const { VersionedTransaction, PublicKey } = await import('@solana/web3.js');
            if (method === SolanaSignMethod.signAllTransactions) {
                const messages: string[] = JSON.parse(message);
                const txs = messages.map((msg) => VersionedTransaction.deserialize(bs58.decode(msg)));

                //replace recentBlockhash
                let blockhash;
                for (let i = 0; i < txs.length; i++) {
                    const tx = txs[i];
                    if (tx.signatures.length === 0) {
                        if (!blockhash) {
                            blockhash = await pnApi.getLatestBlockhash();
                        }
                        tx.message.recentBlockhash = blockhash;
                    }
                }

                const signatures = await edDSA.batchSign(
                    process.env.REACT_APP_BASE_URL as string,
                    this.params(),
                    txs.map((tx) => Buffer.from(tx.message.serialize()))
                );
                txs.forEach((tx, index) => {
                    tx.addSignature(new PublicKey(this.publicAddress()), signatures[index]);
                });
                return JSON.stringify(txs.map((tx) => Buffer.from(tx.serialize()).toString('base64')));
            } else {
                const tx = VersionedTransaction.deserialize(bs58.decode(message));

                //replace recentBlockhash
                if (tx.signatures.length === 0) {
                    const blockhash = await pnApi.getLatestBlockhash();
                    tx.message.recentBlockhash = blockhash;
                }

                const signed = await edDSA.sign(
                    process.env.REACT_APP_BASE_URL as string,
                    this.params(),
                    tx.message.serialize()
                );

                tx.addSignature(new PublicKey(this.publicAddress()), signed);
                return Buffer.from(tx.serialize()).toString('base64');
            }
        }
    },

    params(): string {
        return JSON.stringify({
            token: tokenProvider.token,
            project_uuid: tokenProvider.params.project_uuid,
            project_client_key: tokenProvider.params.project_client_key,
            project_app_uuid: tokenProvider.params.project_app_uuid,
            sdk_version: tokenProvider.params.sdk_version,
            device_id: tokenProvider.params.device_id,
            mac_key: tokenProvider.userInfo.mac_key,
            payment_token: tokenProvider.paymentToken,
        });
    },
};

function getSignTypedVersion(method: string): SignTypedDataVersion {
    if (method === EvmSignMethod.eth_signTypedData || method === EvmSignMethod.eth_signTypedData1) {
        return SignTypedDataVersion.V1;
    } else if (method === EvmSignMethod.eth_signTypedData3) {
        return SignTypedDataVersion.V3;
    } else {
        return SignTypedDataVersion.V4;
    }
}

export default wallet;
