import { RecordType } from '@particle-network/analytics';
import * as Sentry from '@sentry/react';
import { useInterval } from 'ahooks';
import { Button, message } from 'antd';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ApiError, isApiError } from '../../../api/api-error';
import { isPromptSetMasterPassword } from '../../../api/master-password';
import { LoginRequest } from '../../../api/model/login-type';
import pnApi from '../../../api/pn-api';
import wallet from '../../../api/wallet';
import SVGIcon from '../../../components/icon/svg-icon';
import AuthCode from '../../../components/react-auth-code-input';
import TurnstileModal from '../../../components/turnstileModal';
import { useParticleAuth } from '../../../context';
import useSignData from '../../../context/hooks/useSignData';
import tokenProvider from '../../../provider';
import authorizeUtils from '../../../utils/authorizeUtils';
import { biRecords } from '../../../utils/bi';
import './captcha-input.less';

function CaptchaInput(props: any) {
    const { sendCaptcha, setShowInputCaptcha, account, loginWithAccount, setPromptSetMasterPassword } = props;
    const [loading, setLoading] = useState(false);

    const [codeVal, setCodeVal] = useState<string>('');

    const [noInput, setNoInput] = useState(false);
    const [wrongInput, setWrongInput] = useState(false);
    const [invalidInput, setInvalidInput] = useState(false);
    const [resendTip, setResendTip] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(false);
    const { appInfo, loginSuccessRedirectToApp, securityAccountConfig, setUserInfo } = useParticleAuth();

    const { t } = useTranslation();

    const codeInput = useRef<any>(null);
    const codePattern = /^\d{6}$/;
    const [interval, setInterval] = useState<number | undefined>(1000);

    const [countdown, setCountdown] = useState(60);

    const navigate = useNavigate();

    const [turnstileVisible, setTurnstileVisible] = useState(false);

    const { signWithConfirm } = useSignData();

    useInterval(() => {
        if (countdown > 0) {
            const result = countdown - 1;
            setCountdown(result);
            if (result === 0) {
                setInterval(undefined);
            }
        }
    }, interval);

    const startCountdown = () => {
        setCountdown(60);
        setInterval(1000);
    };

    const getCaptcha = () => {
        if (account.includes('@')) {
            sendCaptchaImpl({ email: account });
        } else {
            setTurnstileVisible(true);
        }
    };

    const sendCaptchaImpl = (param: any) => {
        sendCaptcha(param)
            .then((res: boolean) => {
                startCountdown();
                setConfirmBtn(false);
                setResendTip(false);
            })
            .catch(() => {
                setCountdown(0);
                setInterval(undefined);
            });
    };

    const codeChange = (value: any) => {
        setWrongInput(false);
        // const code = value.join("");
        const code = value;
        const patt = /^[0-9]+$/g;
        if (code.length === 0) {
            setWrongInput(false);
            setNoInput(true);
        } else {
            if (!patt.test(code)) {
                setNoInput(false);
                setWrongInput(true);
            } else {
                setNoInput(false);
                setWrongInput(false);
            }
        }
    };

    const login = () => {
        setNoInput(false);
        setWrongInput(false);
        setInvalidInput(false);
        const code = codeVal;
        console.log(`code --> ${code}`);
        if (noInput || wrongInput) {
            return;
        }
        if (codePattern.test(codeVal)) {
            loginImpl(codeVal);
        } else {
            if ((codeVal.length > 0 && codeVal.length < 6) || !codePattern.test(codeVal)) {
                setWrongInput(true);
                setNoInput(false);
            }
            if (codeVal.length === 0) {
                setWrongInput(false);
                setNoInput(true);
            }
        }
    };

    const loginImpl = async (code: string) => {
        if (resendTip) {
            return;
        }
        setLoading(true);
        let data: LoginRequest;
        if (account.includes('@')) {
            data = {
                code: code,
                email: account,
            };
        } else {
            data = {
                code: code,
                phone: account,
            };
        }

        let userInfo;
        try {
            userInfo = await pnApi.login(data);
        } catch (error) {
            biRecords({
                record_type: RecordType.PAGE_LOGIN_BUTTON_CLICK_FAILURE, // 登录失败
            });
            handleLoginError(error);
            return;
        }

        try {
            setUserInfo({ ...userInfo });

            biRecords({
                record_type: RecordType.PAGE_LOGIN_BUTTON_CLICK_SUCCESS, // 登录成功
            });
            console.log('login successful, check master password');

            if (wallet.hasMasterPassword()) {
                console.log('login successful, start verify master password');
                navigate('/account/master-password/verify', {
                    replace: true,
                    state: {
                        loginVerifyMasterPassword: true,
                    },
                });
            } else {
                if (isPromptSetMasterPassword(securityAccountConfig.promptMasterPasswordSettingWhenLogin)) {
                    console.log('login successful, popup set master password');
                    setPromptSetMasterPassword(true);
                } else {
                    // 登录免确认签名：老用户登录跳过设置主密码，跳转签名页面 start
                    if (authorizeUtils.isNeedAuthorize()) {
                        if (!(await signWithConfirm())) {
                            return;
                        }
                    }
                    // 登录免确认签名：老用户登录跳过设置主密码，跳转签名页面 end

                    loginSuccessRedirectToApp();
                }
            }
        } catch (error: any) {
            if (error.message) {
                message.error(error.message);
            } else {
                message.error('Internal error');
            }
            Sentry.captureException(error, {
                extra: {
                    title: 'login successful, but before redirect catch error',
                    queryParams: tokenProvider.queryParams,
                    email: data.email,
                    phone: data.phone,
                },
            });
        }
    };

    const handleLoginError = (error: any) => {
        console.log('login error', error);
        setLoading(false);
        if (isApiError(error)) {
            const errorCode = error.error_code;
            if (errorCode === ApiError.InvalidCode) {
                setInvalidInput(true);
            } else if (errorCode === ApiError.ResendCode) {
                setResendTip(true);
                setConfirmBtn(true);
            }
        }
    };

    const onCodeInputChange = (codeArray: string) => {
        codeChange(codeArray);
        setCodeVal(codeArray);
        setNoInput(false);
        setWrongInput(false);
        setInvalidInput(false);
        setResendTip(false);
        if (codePattern.test(codeArray)) {
            throttleLoginImpl(codeArray);
        }
    };

    const throttleLoginImpl = useCallback(
        throttle(
            (code) => {
                loginImpl(code);
            },
            1000,
            {
                leading: true,
                trailing: false,
            }
        ),
        []
    );

    const handleCodeInputFocus = () => {
        const elements = document.getElementsByClassName('input-code-item');
        //@ts-ignore
        elements[Math.min(codeVal.length, 5)]?.focus();
    };

    const verifyCodeErrorMessage = useMemo(() => {
        let error = [
            noInput ? t('login.enter_captcha') : '',
            wrongInput ? t('login.captcha_input_error') : '',
            invalidInput ? t('login.invalid_code') : '',
            resendTip ? t('login.please_send_again') : '',
        ]
            .map((val) => val.trim())
            .filter((val) => !!val)
            .join(',');

        return error.includes(',') ? error + '.' : error;
    }, [noInput, wrongInput, invalidInput, resendTip]);

    useEffect(() => {
        const elements = document.getElementsByClassName('react-input-code');
        const contextmenuEvent = (e: any) => e.preventDefault();
        elements[0]?.addEventListener('contextmenu', contextmenuEvent);
        return () => {
            elements[0]?.removeEventListener('contextmenu', contextmenuEvent);
        };
    }, []);

    return (
        <div className="captcha-box">
            <div className="captcha-form">
                <div className="input-code-content">
                    <div onClick={handleCodeInputFocus}>
                        <AuthCode
                            containerClassName="react-input-code"
                            inputClassName="input-code-item"
                            allowedCharacters="numeric"
                            length={6}
                            ref={codeInput}
                            placeholder={' '}
                            onChange={onCodeInputChange}
                        />
                    </div>
                </div>
            </div>
            <div className="error-tip">{verifyCodeErrorMessage}</div>

            <div className="btn-box">
                <Button
                    type="primary"
                    htmlType="submit"
                    className="primary-antd-btn submit-btn"
                    onClick={() => login()}
                    disabled={confirmBtn}
                    loading={loading}
                >
                    {appInfo?.theme?.web_auth?.verify_button_text || t('common.confirm')}
                </Button>

                <Button className="get-btn" disabled={countdown > 0} type="link" onClick={getCaptcha}>
                    {t('login.send_again')} {countdown > 0 ? `(${countdown}s)` : ''}
                </Button>

                {!loginWithAccount && (
                    <div
                        className="back-box"
                        onClick={() => {
                            setShowInputCaptcha(false);
                        }}
                    >
                        <SVGIcon className="arrow1-icon" name="arrow1_icon" />
                        <span>{t('login.back')}</span>
                    </div>
                )}
            </div>
            <TurnstileModal
                onError={() => {
                    message.error(t('error.server_20112'));
                }}
                onSuccess={(token) => {
                    sendCaptchaImpl({ phone: account, cf_turnstile_response: token });
                }}
                visible={turnstileVisible}
                setVisible={setTurnstileVisible}
            ></TurnstileModal>
        </div>
    );
}

export default CaptchaInput;
