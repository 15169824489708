import { useRequest, useSetState } from 'ahooks';
import { EventEmitter } from 'events';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { clearLocalKeyAndLocalKMSDataKey } from '../api/master-password';
import { AppInfo } from '../api/model/common-data';
import { UserInfo } from '../api/model/user-info';
import pnApi from '../api/pn-api';
import wallet from '../api/wallet';
import AccountTipModal, { AccountTipModalProps } from '../pages/account/accountTipModal';
import PaymentVerifyModal, { PaymentVerify } from '../pages/account/paymentVerifyModal';
import SelectSecurityAccount from '../pages/account/selectSecurityAccount';
import WrongPasswordModal, { WrongPasswordInfo } from '../pages/account/wrongPasswordModal';
import tokenProvider from '../provider';
import { QueryParamKey, SecurityAccountConfig } from '../provider/bundle';
import { PreferenceKey } from '../repository';
import authorizeUtils from '../utils/authorizeUtils';
import { isInIframe, isNullish } from '../utils/common-utils';
import device from '../utils/detect-device';
import { updateUserInfo } from '../utils/nativeBridge';
import redirectToApp, { AuthError, RedirectData } from '../utils/redirect-utils';
import url from '../utils/url-utils';

type UseUserInfoType = () => {
    userInfo: UserInfo;
    setUserInfo: (info: any) => void;
};

interface GlobalState {
    userInfo: UserInfo;
    setUserInfo: (info: any) => void;
    appInfo: AppInfo;
    setAppInfo: (info: AppInfo) => void;
    showSelectSecurityAccount: (display: boolean, state?: any) => void;
    setWrongPassword: (info: WrongPasswordInfo) => void;
    setPaymentVerify: (props: PaymentVerify) => void;
    isPaymentPassword: boolean;
    setIsPaymentPassword: (bol: boolean) => void;
    isFaceId: boolean;
    setIsFaceId: (bol: boolean) => void;
    showAccountTipModal: (props: AccountTipModalProps) => void;
    setPaymentPassword: () => void;
    securityAccountConfig: SecurityAccountConfig;
    queryParamReady: boolean;
    setQueryParamReady: (bol: boolean) => void;
    loadSecurityAccountsAsync: () => Promise<any>;
    loginSuccessRedirectToApp: () => void;
    syncSecurityAccountsCompleted: boolean;
    events: EventEmitter;
    docHeight: number;
}

const defaultData: GlobalState = {
    userInfo: {} as any,
    setUserInfo: (info) => {},
    appInfo: { name: 'App' },
    setAppInfo: (info: AppInfo) => {},
    showSelectSecurityAccount: (display) => {},
    setWrongPassword: (info) => {},
    setPaymentVerify: (props) => {},
    isPaymentPassword: false,
    setIsPaymentPassword: (bol) => {},
    isFaceId: false,
    setIsFaceId: (bol) => {},
    showAccountTipModal: (props) => {},
    setPaymentPassword: () => {},
    securityAccountConfig: {
        promptSettingWhenSign: 1,
        promptMasterPasswordSettingWhenLogin: 0,
    },
    queryParamReady: false,
    setQueryParamReady: (props) => {},
    loadSecurityAccountsAsync: () => Promise.resolve(),
    loginSuccessRedirectToApp: () => {},
    syncSecurityAccountsCompleted: false,
    events: {} as any,
    docHeight: 0,
};

const GlobalContext = createContext<GlobalState>(defaultData);

export const useUserInfo: UseUserInfoType = () => useContext(GlobalContext);

export const useParticleAuth = () => useContext(GlobalContext);

export const GlobalContextProvider = (props: any) => {
    const { t } = useTranslation();

    const [userInfo, setUserInfo] = useSetState<UserInfo>({} as any);

    const [events] = useState(new EventEmitter());

    const [appInfo, setAppInfo] = useState<AppInfo>({
        name: 'App',
    });

    const [docHeight, setDocHeight] = useState(0);

    const [selectSecurityAccount, setSelectSecurityAccount] = useState(false);
    const [verifySecurityAccountState, setVerifySecurityAccountState] = useState<any>();

    const [queryParamReady, setQueryParamReady] = useState(false);

    const [wrongPassword, setWrongPassword] = useState<WrongPasswordInfo>({ visible: false });

    const [accountTipModal, setAccountTipModal] = useState<AccountTipModalProps>({
        visible: false,
    });

    const [paymentVerify, setPaymentVerify] = useState<PaymentVerify>({ visible: false });

    const [syncSecurityAccountsCompleted, setSyncSecurityAccountsCompleted] = useState(false);

    const [securityAccountConfig, setSecurityAccountConfig] = useState<SecurityAccountConfig>(
        defaultData.securityAccountConfig
    );

    const showSelectSecurityAccount = (display: boolean, state?: any) => {
        setVerifySecurityAccountState(state);
        setSelectSecurityAccount(display);
    };

    const showAccountTipModal = (props: AccountTipModalProps) => {
        setAccountTipModal(props);
    };
    const [isPaymentPassword, setIsPaymentPassword] = useState(false);
    const [isFaceId, setIsFaceId] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (queryParamReady && isInIframe()) {
            const state = tokenProvider.queryParams.state;
            window.parent.postMessage(
                {
                    name: 'particle-network-auth-load-completed',
                    state,
                },
                '*'
            );
        }
    }, [queryParamReady]);

    useEffect(() => {
        if (userInfo && userInfo.token) {
            // sync user into to native
            updateUserInfo();
        }
    }, [userInfo]);

    useEffect(() => {
        const saConfig =
            url.getQueryVariable(QueryParamKey.SECURITY_ACCOUNT) ||
            sessionStorage.getItem(PreferenceKey.PN_TEMP_SECURITY_ACCOUNT);
        sessionStorage.setItem(PreferenceKey.PN_TEMP_SECURITY_ACCOUNT, saConfig || '');
        console.log('setSecurityAccountConfig', saConfig);
        if (saConfig) {
            setSecurityAccountConfig(JSON.parse(saConfig));
        }
    }, [location.search]);

    const setPaymentPassword = () => {
        if (userInfo?.security_account?.has_set_payment_password) {
            return;
        }
        if (!userInfo?.security_account?.email && !userInfo?.security_account?.phone) {
            navigate('/account/bind', {
                state: {
                    accountType: userInfo?.email ? 'phone' : 'email',
                    showSwitch: true,
                    redirectUrl: '/account/set-password',
                },
            });
            return;
        }
        if (userInfo?.security_account?.email && userInfo?.security_account?.phone) {
            showSelectSecurityAccount(true);
        } else {
            navigate('/account/set-password', {
                state: {
                    account: userInfo?.security_account?.email || userInfo?.security_account?.phone,
                },
            });
        }
    };

    const documentHeight = () => {
        const fn = () => {
            const doc = document.documentElement;
            const minHeight = 180;
            const height = window.innerHeight < minHeight ? minHeight : window.innerHeight;
            doc.style.setProperty('--doc-height', `${height}px`);
            setDocHeight(height);
        };

        fn();

        for (let i = 0; i < 500; i++) {
            setTimeout(() => {
                fn();
            }, i * 2);
        }
    };

    useEffect(() => {
        const inputBlurHandler = (event: any) => {
            // @ts-ignore
            if (event.target.tagName === 'INPUT') {
                documentHeight();
            }
        };
        documentHeight();

        const observer = new MutationObserver((mutationsList) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'attributes' && mutation.attributeName === 'data-route') {
                    documentHeight();
                }
            }
        });

        observer.observe(document.querySelector('.App') as HTMLDivElement, {
            attributes: true,
            attributeFilter: ['data-route'],
        });

        window.addEventListener('resize', documentHeight);
        document.addEventListener('blur', inputBlurHandler, true);
        return () => {
            window.removeEventListener('resize', documentHeight);
            document.removeEventListener('blur', inputBlurHandler, true);
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        if (!appInfo.theme) {
            return;
        }

        const doc = document.querySelector('body')!;
        const themeMode =
            tokenProvider.themeType === 'dark'
                ? appInfo?.theme?.web_auth?.dark_mode
                : appInfo?.theme?.web_auth?.light_mode;

        if (themeMode) {
            Object.keys(themeMode).map((key) => {
                if (key) {
                    const value = themeMode[key];
                    if (!isNullish(value)) {
                        if (typeof value === 'number') {
                            doc.style.setProperty(`--${key.replace(/_/g, '-')}`, `${value}px`);
                        } else if (typeof value === 'string') {
                            doc.style.setProperty(`--${key.replace(/_/g, '-')}`, value);
                        }
                    }
                }
            });
            if (!isNullish(themeMode.background_color)) {
                doc.style.setProperty('--theme-background-color', themeMode.background_color);
            }
        }
        if (!isNullish(appInfo?.theme?.web_auth?.primary_btn_border_radius)) {
            doc.style.setProperty(
                '--primary-btn-border-radius',
                `${appInfo?.theme?.web_auth?.primary_btn_border_radius}px`
            );
        }

        if (!isNullish(appInfo?.theme?.web_auth?.modal_border_radius)) {
            doc.style.setProperty('--modal-border-radius', `${appInfo?.theme?.web_auth?.modal_border_radius}px`);
        }

        if (!isNullish(appInfo?.theme?.web_auth?.card_border_radius)) {
            doc.style.setProperty('--card-border-radius', `${appInfo?.theme?.web_auth?.card_border_radius}px`);
        }
    }, [appInfo]);

    const { run: loadSecurityAccounts, runAsync: loadSecurityAccountsAsync } = useRequest(pnApi.syncUserInfo, {
        manual: true,
        onSuccess: (result) => {
            const userInfo = tokenProvider.userInfo;
            if (result.wallets && !device.authEmbed()) {
                const masterPasswordChange = result.wallets.some((wallet) => {
                    const localWallet = userInfo.wallets.find((item) => item.uuid == wallet.uuid);
                    return !localWallet || localWallet.encrypted_data !== wallet.encrypted_data;
                });
                if (masterPasswordChange) {
                    console.log('master password changed');
                    clearLocalKeyAndLocalKMSDataKey();
                }
            }

            tokenProvider.userInfo = { ...userInfo, ...result };
            setUserInfo(tokenProvider.userInfo);
            setSyncSecurityAccountsCompleted(true);
            console.log('update security accounts success');
        },
        onError: (error) => {
            console.log('update security accounts', error);
        },
    });

    useEffect(() => {
        if (queryParamReady && tokenProvider.isLogin()) {
            loadSecurityAccounts();
        }
    }, [queryParamReady]);

    const loginSuccessRedirectToApp = () => {
        console.log('login success', 'redirectToApp');
        const params: RedirectData = tokenProvider.userPreview;
        params.wallets = wallet.wallets();

        // 登录免确认签名：返回app时，需要带上签名信息  start
        if (authorizeUtils.isNeedAuthorize() && sessionStorage.getItem(PreferenceKey.PN_TEMP_AUTHORIZE_RESULT)) {
            const signResult = JSON.parse(sessionStorage.getItem(PreferenceKey.PN_TEMP_AUTHORIZE_RESULT) as string);
            if (!signResult.signature) {
                params.error = signResult.error || AuthError.authorizeError();
            } else {
                params.signature = signResult.signature;
                params.message = signResult.message;
            }
        }
        // 登录免确认签名：返回app时，需要带上签名信息  end

        redirectToApp(params);
    };

    return (
        <GlobalContext.Provider
            value={{
                userInfo,
                setUserInfo,
                appInfo,
                setAppInfo,
                showSelectSecurityAccount,
                setWrongPassword,
                setPaymentVerify,
                isPaymentPassword,
                setIsPaymentPassword,
                isFaceId,
                setIsFaceId,
                showAccountTipModal,
                setPaymentPassword,
                securityAccountConfig,
                queryParamReady,
                setQueryParamReady,
                loadSecurityAccountsAsync,
                loginSuccessRedirectToApp,
                syncSecurityAccountsCompleted,
                events,
                docHeight,
            }}
        >
            <>
                {props.children}
                <SelectSecurityAccount visible={selectSecurityAccount} state={verifySecurityAccountState} />
                <WrongPasswordModal info={wrongPassword} />
                <PaymentVerifyModal props={paymentVerify} />
                <AccountTipModal modal={accountTipModal} />
            </>
        </GlobalContext.Provider>
    );
};
