import { useMemo } from 'react';
import tokenProvider from '../provider';
import './power-footer.less';

function PowerFooter(props: any) {
    const imgUrl = useMemo(() => {
        return tokenProvider.themeType === 'dark'
            ? require('@/common/images/footer_img_b.png')
            : require('@/common/images/footer_img.png');
    }, [tokenProvider.themeType]);

    return (
        <div className="footer-box" {...props}>
            <img className="footer" src={imgUrl} alt="" />
        </div>
    );
}

export default PowerFooter;
