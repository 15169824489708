import { Tree } from 'antd';
import type { DataNode } from 'antd/es/tree';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SecurityDetection } from '../../../api/model/rpc-data';
import './index.less';

interface Prop {
    securityDetection: SecurityDetection[];
    className?: string;
    title?: string;
}

const RiskTypography = (prop: Prop) => {
    const { securityDetection, title } = prop;
    const { t } = useTranslation();

    const treeData = useMemo<DataNode[]>(() => {
        const warningsTypes = securityDetection.filter((item) => item.warnings && item.warnings.length > 0);
        const risksTypes = securityDetection.filter((item) => item.risks && item.risks.length > 0);

        const data: DataNode[] = [];
        if (risksTypes.length > 0) {
            data.push({
                title: t('sign.security_risk'),
                key: '0-1',
                icon: <div className="risk-icon danger"></div>,
                children: risksTypes.map((item) => {
                    return {
                        title: t(`risk.${item.type}`),
                        key: item.type,
                        icon: <div className="risk-dot danger"></div>,
                        children: item.risks?.map((risk) => {
                            return {
                                title: t(`risk.${risk}`),
                                key: risk,
                                icon: <div className="risk-dot danger"></div>,
                            };
                        }),
                    };
                }),
            });
        }

        if (warningsTypes.length > 0) {
            data.push({
                title: t('sign.transaction_may_fail'),
                key: '0-0',
                icon: <div className="risk-icon warning"></div>,
                children: warningsTypes.map((item) => {
                    return {
                        title: t(`risk.${item.type}`),
                        key: item.type,
                        icon: <div className="risk-dot warning"></div>,
                        children: item.warnings?.map((warning) => {
                            return {
                                title: t(`risk.${warning}`),
                                key: warning,
                                icon: <div className="risk-dot warning"></div>,
                            };
                        }),
                    };
                }),
            });
        }

        return data;
    }, [securityDetection]);

    return (
        <div className={`risk-typography ${prop?.className}`}>
            {title && <div className="risk-typography-title">{title}</div>}
            <Tree rootClassName="risk-tree" showIcon defaultExpandAll treeData={treeData} selectable={false} />
        </div>
    );
};

export default RiskTypography;
