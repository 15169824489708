import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import App from './App';
import { GlobalContextProvider } from './context';
import './firebase';
import './i18n';

import '@/common/style/frame.less';

if (!/debug=true/.test(location.search) && !location.origin.includes('localhost')) {
    Sentry.init({
        dsn: 'https://c708a97532e54ca68ae7e010b4288721@o1241866.ingest.sentry.io/6396077',
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        tracePropagationTargets: [/^https:\/\/auth\.particle\.network/],
        release: `web-auth@${process.env.npm_package_version}`,
        tracesSampleRate: 0.1,
        sampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        beforeSend(event: any, hint: any) {
            const hostname = new URL(event?.request?.url).host;

            const ignoreErrors = [
                'TimeoutError',
                'Cannot redefine property: ethereum',
                'chrome-extension',
                'relay.walletconnect.org',
                'DatabaseClosedError',
            ];

            const message = hint?.originalException?.message || hint.originalException || event.message;

            if (
                hostname !== 'auth.particle.network' ||
                ignoreErrors.some((error) => message?.toLowerCase().includes(error?.toLowerCase()))
            ) {
                return null;
            }

            return event;
        },
    });
}

window.name = 'particle-auth-window';
ReactDOM.render(
    <React.StrictMode>
        <HashRouter>
            <GlobalContextProvider>
                <App />
            </GlobalContextProvider>
        </HashRouter>
    </React.StrictMode>,
    document.getElementById('root')
);
