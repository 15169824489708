/**
 * Particle Network api error code
 */
export enum ApiError {
    SystemError = 10001,
    ParamError = 10002,
    AuthorizationError = 10004,
    InvalidToken = 10005,
    MacError = 10009,

    InvalidCode = 20101,
    ResendCode = 20103,

    SecurityAccountFrozen = 50010,
    WrongPaymentPassword = 50102,
}

export function isApiError(err: any): boolean {
    return err.error_code && err.error_code > 0;
}
