import { useEffect, useState } from 'react';
import tokenProvider from '../../provider';
import pnApi from '../../api/pn-api';
import { ApiError, isApiError } from '../../api/api-error';
import './index.less';
import { useTranslation } from 'react-i18next';
import redirectToApp from '../../utils/redirect-utils';
import { Button } from 'antd';
import SplashLoading from '../../components/splash-loading';

function Logout(props: any) {
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>();

    const { t } = useTranslation();

    useEffect(() => {
        logoutImpl();
    }, []);

    const logout = async () => {
        await pnApi.logout();
        tokenProvider.logout();
        redirectToApp();
    };

    const logoutImpl = () => {
        setLoading(true);
        setErrorMessage(null);
        if (tokenProvider.isLogin()) {
            logout().catch((e: any) => {
                console.log('logout error', e);
                if (isApiError(e) && e.error_code === ApiError.InvalidToken) {
                    redirectToApp();
                } else {
                    displayError(e);
                }
            });
        } else {
            setLoading(false);
            redirectToApp();
        }
    };

    const displayError = (err: any) => {
        let message: string;
        if (isApiError(err)) {
            message = err.message;
        } else {
            message = err.toString();
        }
        setLoading(false);
        setErrorMessage(message);
    };

    return (
        <div className="logout">
            {errorMessage && <div className="logout-error">{errorMessage}</div>}

            {errorMessage && (
                <Button className="logout-retry" onClick={logoutImpl}>
                    {t('common.retry')}
                </Button>
            )}
            <SplashLoading visible={loading} />
        </div>
    );
}

export default Logout;
