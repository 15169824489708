import { RecordType } from '@particle-network/analytics';
import { useRequest } from 'ahooks';
import { Button, Form, message, Modal, Space, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { errorToRedirect } from '../../../api/error-handle';
import { setMasterPassword } from '../../../api/master-password';
import SVGIcon from '../../../components/icon/svg-icon';
import InputPassword from '../../../components/input-password';
import PowerFooter from '../../../components/power-footer';
import { useParticleAuth } from '../../../context';
import useSignData from '../../../context/hooks/useSignData';
import tokenProvider from '../../../provider';
import { PromptMasterPasswordSettingWhenLoginType } from '../../../provider/bundle';
import { isAutoFocusSupported } from '../../../utils';
import authorizeUtils from '../../../utils/authorizeUtils';
import { biRecords } from '../../../utils/bi';
import redirectToApp, { AuthError } from '../../../utils/redirect-utils';
import SetMasterPasswordSuccess from '../setMasterPasswordSuccess';
import './index.less';
import MasterPasswordLoading from './masterPasswordLoading';

const SetMasterPassword = () => {
    const navigate = useNavigate();

    const { t } = useTranslation();

    const { Text, Link } = Typography;

    const { state } = useLocation();

    const [digitsError, setDigitsError] = useState(false);

    const [matchError, setMatchError] = useState(false);

    const [passwordStep, setPasswordStep] = useState<'unset' | 'loading' | 'success' | 'failure'>('unset');

    const [passwordForm] = Form.useForm();

    const [passwordValue, setPasswordValue] = useState('');

    const [disableSubmit, setDisableSubmit] = useState(true);

    const { setUserInfo, loginSuccessRedirectToApp, securityAccountConfig } = useParticleAuth();

    const changePassword = useMemo(() => (state as any)?.setNewMasterPassword, [state]);

    const loginSetMasterPassword = useMemo(() => (state as any)?.loginSetMasterPassword, [state]);

    const { signWithConfirm } = useSignData();

    const { run: runSetMasterPassword } = useRequest(setMasterPassword, {
        manual: true,
        onBefore: () => {
            setPasswordStep('loading');
        },
        onSuccess: async (result) => {
            console.log('set master password success');
            setUserInfo(tokenProvider.userInfo);

            if (!changePassword) {
                biRecords({
                    record_type: RecordType.PAGE_SETTING_MASTER_PASSWORD_SET, // 主密码设置成功
                });
            } else {
                biRecords({
                    record_type: RecordType.PAGE_SETTING_MASTER_PASSWORD_CHANGE, // 主密码修改成功
                });
            }

            // 登录免确认签名：老用户登录设置主密码成功后跳转签名页面 start
            if (authorizeUtils.isNeedAuthorize()) {
                if (!(await signWithConfirm())) {
                    return;
                }
            }
            // 登录免确认签名：老用户登录设置主密码成功后跳转签名页面 end

            setPasswordStep('success');
        },
        onError: (error) => {
            console.log('set master password error', error);
            setPasswordStep('failure');
            message.error(t('account.master_password_set_failed'));
        },
    });

    const toDescription = () => {
        navigate('/account/master-password/description');
    };

    const onInputFinish = ({
        password,
        confirmPassword,
    }: {
        password: string | undefined;
        confirmPassword: string | undefined;
    }) => {
        if (password && password.length >= 6 && password.length <= 20 && password === confirmPassword) {
            setPasswordValue(password);
            runSetMasterPassword(password, changePassword);
        } else {
            setDigitsError(!password || password.length < 6 || password.length > 20);
            setMatchError(Boolean(password && confirmPassword && password !== confirmPassword));
        }
    };

    const onPasswordInputBlur = (event: any) => {
        const password: string | undefined = passwordForm.getFieldValue('password');
        const confirmPassword: string | undefined = passwordForm.getFieldValue('confirmPassword');
        setDigitsError(!password || password.length < 6 || password.length > 20);
        if (password && confirmPassword) {
            setMatchError(password !== confirmPassword);
        }
    };
    const onConfirmPasswordInputBlur = (event: any) => {
        const password: string | undefined = passwordForm.getFieldValue('password');
        const confirmPassword: string | undefined = passwordForm.getFieldValue('confirmPassword');
        setMatchError(Boolean(password && confirmPassword && password !== confirmPassword));
    };

    const onPasswordInputChange = () => {
        setDigitsError(false);
    };

    const onConfirmPasswordInputChange = () => {
        setMatchError(false);
    };

    const onBack = () => {
        if (
            securityAccountConfig.promptMasterPasswordSettingWhenLogin ===
            PromptMasterPasswordSettingWhenLoginType.everyAndNotSkip
        ) {
            // @ts-ignore
            window.warningModal = Modal.warning({
                className: 'm-warning-modal',
                content: (
                    <div className="content-wrap">
                        <div className="content">{t('account.unset_the_master_password')}</div>
                        <div className="footer-btns">
                            <Button
                                className="cancel-btn"
                                onClick={() => {
                                    redirectToApp({
                                        error: AuthError.notLogin(),
                                    });
                                }}
                            >
                                {t('account.exit')}
                            </Button>
                            <Button
                                className="confirm-btn"
                                onClick={() => {
                                    // @ts-ignore
                                    window.warningModal.destroy();
                                }}
                            >
                                {t('new.continue')}
                            </Button>
                        </div>
                    </div>
                ),
                closable: true,
                maskClosable: false,
                getContainer: () => {
                    return document.querySelector('.set-mp-container') as HTMLElement;
                },
            });
        } else if (loginSetMasterPassword) {
            // 登录免确认签名：老用户登录设置主密码成功后跳转签名页面 start
            if (authorizeUtils.isNeedAuthorize()) {
                signWithConfirm()
                    .then((result) => {
                        if (result) {
                            loginSuccessRedirectToApp();
                        }
                    })
                    .catch((error) => {
                        biRecords({
                            record_type: RecordType.PAGE_LOGIN_BUTTON_CLICK_FAILURE, // 登录失败
                        });
                        errorToRedirect(error);
                    });
            } else {
                loginSuccessRedirectToApp();
            }
            // 登录免确认签名：老用户登录设置主密码成功后跳转签名页面 end
        } else {
            navigate(-1);
        }
    };

    const onValuesChange = () => {
        const password = passwordForm.getFieldValue('password');
        const confirmPassword = passwordForm.getFieldValue('confirmPassword');
        setDisableSubmit(!password || !confirmPassword);
    };

    useEffect(() => {
        biRecords({
            record_type: RecordType.PAGE_SETTING_MASTER_PASSWORD_ENTER, // 进入主密码设置页面
        });
    }, []);

    return (
        <div className="set-mp-container">
            <div className="set-mp-title">
                {changePassword ? t('account.new_master_password') : t('account.master_password')}
            </div>

            {/* set master password */}
            {(passwordStep === 'unset' || passwordStep === 'failure') && (
                <Form onFinish={onInputFinish} form={passwordForm} layout="vertical" onValuesChange={onValuesChange}>
                    <div className="account-header">
                        <SVGIcon className="icon-navigation-back" name="circle_back" onClick={onBack} />
                    </div>

                    <div className="mp-input-name">
                        {changePassword ? t('account.new_master_password') : t('account.master_password')}
                    </div>
                    <Form.Item name="password" initialValue={passwordValue}>
                        <InputPassword
                            className="mp-input"
                            onBlur={onPasswordInputBlur}
                            onChange={onPasswordInputChange}
                            autoFocus={isAutoFocusSupported()}
                            defaultValue={passwordValue}
                        />
                    </Form.Item>

                    {digitsError && <div className="mp-input-error">{t('account.password_digits_hint')}</div>}

                    <div className="mp-confirm-input-name">
                        {changePassword
                            ? t('account.confirm_new_master_password')
                            : t('account.confirm_master_password')}
                    </div>

                    <Form.Item name="confirmPassword" initialValue={passwordValue}>
                        <InputPassword
                            className="mp-input"
                            onBlur={onConfirmPasswordInputBlur}
                            onChange={onConfirmPasswordInputChange}
                            autoFocus={false}
                            defaultValue={passwordValue}
                        />
                    </Form.Item>

                    {matchError && <div className="mp-input-error">{t('account.master_password_not_match')}</div>}

                    <Space className="description-space" direction="vertical">
                        <Text>
                            * {t('account.master_password_use1')}{' '}
                            <Link onClick={toDescription} className="more-text-btn">
                                {t('account.learn_more_period')}
                            </Link>
                        </Text>
                        <Text strong>* {t('account.master_password_store_hint')}</Text>
                        <Text>{t('account.master_password_note')}</Text>
                    </Space>

                    <Form.Item>
                        <div className="set-mp-footer">
                            <Button className="primary-antd-btn set-mp-btn" htmlType="submit" disabled={disableSubmit}>
                                {changePassword
                                    ? t('account.set_new_master_password')
                                    : t('account.set_master_password')}
                            </Button>
                            <PowerFooter />
                        </div>
                    </Form.Item>
                </Form>
            )}

            {/* set master password loading */}
            {passwordStep === 'loading' && <MasterPasswordLoading />}

            {/* set master password success */}
            {passwordStep === 'success' && <SetMasterPasswordSuccess loginSetMasterPassword={loginSetMasterPassword} />}
        </div>
    );
};

export default SetMasterPassword;
