import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js/max';
import url from './url-utils';
export const ipfsToSrc = (ipfs: string) => {
    if (!ipfs || !ipfs.startsWith('ipfs://')) {
        return ipfs || '';
    }

    return `https://ipfs.particle.network/${encodeURI(ipfs.slice(7))}`;
};

export const EmailRegExp = /^\w+([-+.]\w+)*@[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*\.[a-zA-Z0-9]+([.-][a-zA-Z0-9]+)*$/;

export const PhoneRegExp = /^[0-9]{4,16}$/;

export const PhoneE164Exp = /^\+[1-9]\d{4,14}$/;

export function isEmailValid(email: string): boolean {
    return EmailRegExp.test(email);
}

export function isPhoneValid(phone: string, regionCode: string): boolean {
    if (!phone || phone.length < 5) {
        return false;
    }
    const countryCode = regionCode?.toUpperCase() as CountryCode;
    const result = isValidPhoneNumber(phone, countryCode);
    return result;
}

export const isCookieEnabled = () => {
    try {
        return !(typeof localStorage === 'undefined' || typeof sessionStorage === 'undefined');
    } catch (error) {
        return false;
    }
};

export const isAutoFocusSupported = () => {
    return true;
};

export const getLanguage = () => {
    return url.getQueryVariable('language')?.replace?.('_', '-') || 'en-US';
};

export const isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};
