import * as Sentry from '@sentry/react';
import { v4 as uuidv4 } from 'uuid';
import tokenProvider from '../provider';
import { PreferenceKey, save } from '../repository';
import client from './index';
import { AppInfo, BooleanResult } from './model/common-data';
import {
    AuthType,
    CaptchaRequest,
    LoginBindRequest,
    LoginBindingsCheckResponse,
    LoginBindingsThirdpartyRequest,
    LoginRequest,
} from './model/login-type';
import { DeviceInfo, RecentBlockhash, RpcInput, RpcOutput } from './model/rpc-data';
import {
    CognitoResult,
    LoginAccount,
    SecurityAccount,
    UserInfo,
    UserInfoSync,
    UserWalletRequestParam,
    WalletInfo,
} from './model/user-info';
import wallet from './wallet';

const pnApi = {
    /**
     * send auth code
     * @param data phone or email
     */
    sendCaptcha(data: CaptchaRequest): Promise<BooleanResult> {
        return client
            .post('/code', data, {
                headers: {
                    'Auth-Type': 'Basic',
                },
            })
            .then((response) => response.data);
    },

    /**
     * login
     * @param data
     */
    login(data: LoginRequest): Promise<UserInfo> {
        return client
            .post('/login', data, {
                headers: {
                    'Auth-Type': 'Basic',
                },
            })
            .then(async (response) => {
                const userInfo: UserInfo = response.data;
                tokenProvider.userInfo = userInfo;
                const address = await wallet.gen();
                Sentry.setUser({ id: userInfo.uuid, username: address });
                // Save the authType of the last login
                const key = `login_auth_type_${userInfo.uuid}`;
                save(key, data.email ? 'email' : 'phone');
                return userInfo;
            });
    },

    loginWithThirdparty(provider: AuthType, code: string): Promise<UserInfo> {
        return client
            .post(
                '/login_thirdparty',
                {
                    provider: provider === 'twitter' ? 'twitterv1' : provider,
                    thirdparty_code: code,
                },
                {
                    headers: {
                        'Auth-Type': 'Basic',
                    },
                }
            )
            .then(async (response) => {
                console.log('/login_thirdparty', provider, response);
                const userInfo: UserInfo = response.data;
                tokenProvider.userInfo = userInfo;
                const address = await wallet.gen();
                Sentry.setUser({ id: userInfo.uuid, username: address });

                // Save the authType of the last login
                const key = `login_auth_type_${userInfo.uuid}`;
                save(key, provider);
                return userInfo;
            });
    },
    /**
     * loginBindings
     */
    loginBindings(data: LoginBindRequest): Promise<UserInfo> {
        return client
            .post('/login_bindings', data, {
                headers: {
                    disableSystemMessage: true,
                },
            })
            .then((response) => response.data);
    },

    getLoginBindings(): Promise<LoginAccount[]> {
        return client.get('/login_bindings').then((response) => response.data);
    },

    deleteLoginBinding(params: { id_combined: string; security_account_verify_token: string }): Promise<boolean> {
        return client
            .delete('/login_bindings', {
                params,
            })
            .then((response) => response.data.success);
    },

    /**
     * /login_bindings/thirdparty
     */
    loginBindingsThirdparty(data: LoginBindingsThirdpartyRequest): Promise<UserInfo> {
        return client
            .post(
                '/login_bindings/thirdparty',
                {
                    ...data,
                    provider: data.provider === 'twitter' ? 'twitterv1' : data.provider,
                },
                {
                    headers: {
                        disableSystemMessage: true,
                    },
                }
            )
            .then((response) => response.data);
    },
    loginBindingsCheck(data: CaptchaRequest): Promise<LoginBindingsCheckResponse> {
        return client({
            method: 'get',
            url: '/login_bindings/check',
            params: data,
            headers: {
                disableSystemMessage: true,
            },
        }).then((response) => response.data);
    },

    requestTwitterToken(): Promise<string> {
        return client
            .post('/twitter_request_token', undefined, {
                headers: {
                    'Auth-Type': 'Basic',
                },
            })
            .then((response) => response.data.oauth_token);
    },

    syncUserInfo(): Promise<UserInfoSync> {
        return client.get(`/users/${tokenProvider.userInfo.uuid}`).then((response) => response.data);
    },

    logout(): Promise<BooleanResult> {
        return client.post('/logout').then((response) => {
            Sentry.setUser(null);
            return response.data;
        });
    },

    /**
     * load app config
     */
    loadAppConfig(projectAppUuid: string): Promise<AppInfo> {
        return client
            .get(`/apps/${projectAppUuid}/simple-info`, {
                headers: {
                    'Auth-Type': 'Basic',
                },
            })
            .then((response) => response.data);
    },

    /**
     * upload kms crypto private key to server
     */
    uploadCryptoPartKey(walletUuid: string, publicAddress: string, encryptedData: string): Promise<WalletInfo> {
        return client
            .patch(`/wallets/${walletUuid}`, {
                public_address: publicAddress,
                encrypted_data: encryptedData,
            })
            .then((response) => {
                const userInfo = tokenProvider.userInfo;
                const wallets = userInfo.wallets.filter((info) => info.uuid !== response.data.uuid());
                wallets.push(response.data);
                userInfo.wallets = wallets;
                tokenProvider.userInfo = userInfo;
                return response.data;
            });
    },

    updateWallet(uuid: string, params: UserWalletRequestParam): Promise<boolean> {
        return client.patch(`/wallets/users/${uuid}`, params).then((response) => response.data.success);
    },

    async loginCognito(): Promise<CognitoResult> {
        return client.post('/login_cognito').then((response) => {
            const result = response.data;
            const userInfo = tokenProvider.userInfo;
            userInfo.cognito_result = result;
            tokenProvider.userInfo = userInfo;
            return result;
        });
    },

    async createAuthSession(data: string): Promise<string> {
        return client
            .post(
                '/auth-sessions',
                {
                    data: data,
                },
                {
                    headers: {
                        'Auth-Type': 'Basic',
                    },
                }
            )
            .then((response) => {
                const result = response.data;
                return result.key;
            });
    },

    async getAuthSession(key: string): Promise<string> {
        return client
            .get(`/auth-sessions/${key}`, {
                headers: {
                    'Auth-Type': 'None',
                },
            })
            .then((response) => {
                const result = response.data;
                return result.data;
            });
    },

    //Send a verification code to the specified email address or mobile phone number
    async securityAccountsCode(account: {
        email?: string;
        phone?: string;
        cf_turnstile_response?: string;
    }): Promise<boolean> {
        return client.post('/security/accounts/code', account).then((response) => response.data.success);
    },

    //Bind payment account
    async securityAccountsBind(params: { email?: string; phone?: string; code: string }): Promise<SecurityAccount> {
        return client.post('/security/accounts/bind', params).then((response) => {
            const userInfo = tokenProvider.userInfo;
            userInfo.security_account = response.data;
            tokenProvider.userInfo = userInfo;
            return response.data;
        });
    },

    //Select an email address or mobile phone number to send the verification code
    async securityAccountsVerifyCode(params: { verify_code_method: 'email' | 'phone' }): Promise<boolean> {
        return client.post('/security/accounts/verify_code', params).then((response) => response.data.success);
    },

    async securityAccountsVerify(params: { verify_code_method: 'email' | 'phone'; code: string }): Promise<string> {
        return client.post('/security/accounts/verify', params).then((response) => response.data.token);
    },

    //Set payment password for the first time
    async securityPayments(params: { password: string }): Promise<SecurityAccount> {
        return client.post('/security/payments', params).then((response) => {
            const userInfo = tokenProvider.userInfo;
            sessionStorage.setItem(PreferenceKey.PN_TEMP_SECURITY_ACCOUNT_CHANGED, '1');
            userInfo.security_account = response.data;
            tokenProvider.userInfo = userInfo;
            return response.data;
        });
    },

    //Reset payment password
    async securityPaymentsReset(params: {
        password: string;
        verify_code_method: 'email' | 'phone';
        code: string;
    }): Promise<SecurityAccount> {
        return client.post('/security/payments/reset', params).then((response) => {
            const userInfo = tokenProvider.userInfo;
            if (!userInfo.security_account?.has_set_payment_password) {
                sessionStorage.setItem(PreferenceKey.PN_TEMP_SECURITY_ACCOUNT_CHANGED, '1');
            }
            userInfo.security_account = response.data;
            tokenProvider.userInfo = userInfo;
            return response.data;
        });
    },

    //Update payment password
    async securityPaymentsUpdate(params: { password: string; old_password: string }): Promise<SecurityAccount> {
        return client.post('/security/payments/update', params).then((response) => {
            const userInfo = tokenProvider.userInfo;
            userInfo.security_account = response.data;
            tokenProvider.userInfo = userInfo;
            return response.data;
        });
    },

    //Verify payment password
    async securityPaymentsVerify(params: { password: string }): Promise<string> {
        return client.post('/security/payments/verify', params).then((response) => response.data.payment_token);
    },

    async evmRpc<T>(data: RpcInput): Promise<RpcOutput<T>> {
        return client
            .post('/evm-chain', data, {
                headers: {
                    'Auth-Type': 'Basic',
                },
                baseURL: process.env.REACT_APP_RPC_URL as string,
            })
            .then((response) => response.data);
    },

    async solanaRpc<T>(data: RpcInput): Promise<RpcOutput<T>> {
        return client
            .post('/solana', data, {
                headers: {
                    'Auth-Type': 'Basic',
                },
                baseURL: process.env.REACT_APP_RPC_URL as string,
            })
            .then((response) => response.data);
    },

    async getLatestBlockhash(): Promise<string> {
        return this.solanaRpc<RecentBlockhash>({
            id: uuidv4(),
            jsonrpc: '2.0',
            method: 'getLatestBlockhash',
            params: [
                {
                    commitment: 'finalized',
                },
            ],
        }).then((data) => data.result.value.blockhash);
    },
    /**
     * 关闭支付密码
     */
    async closePaymentPassword(params: { password: string }): Promise<SecurityAccount> {
        return client.post('/security/payments/disable', params).then((response) => {
            sessionStorage.setItem(PreferenceKey.PN_TEMP_SECURITY_ACCOUNT_CHANGED, '1');
            const userInfo = tokenProvider.userInfo;
            userInfo.security_account = response.data;
            tokenProvider.userInfo = userInfo;
            return response.data;
        });
    },
    /**
     * 获取授权列表
     */
    async getAuthorizations(): Promise<DeviceInfo[]> {
        return client.get('/authorizations').then((response) => {
            return response.data;
        });
    },
    /**
     * 删除授权
     */
    async deleteAuthorization(projectAppUuid: string): Promise<boolean> {
        return client.delete(`/authorizations/${projectAppUuid}`).then((response) => {
            return response.data.success;
        });
    },
};

export default pnApi;
