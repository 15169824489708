import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import SVGIcon from '../icon/svg-icon';
import './index.less';

interface IProps {
    children?: ReactNode;
    leftContent?: ReactNode | string;
    rightContent?: ReactNode | string;
    displayBackBtn?: boolean;
    onBack?: any;
}

const Header = (props: IProps) => {
    let { children, leftContent, rightContent, displayBackBtn = false, onBack } = props;
    const navigate = useNavigate();

    if (onBack) {
        displayBackBtn = true;
    }

    if (leftContent) {
        displayBackBtn = false;
    }

    return (
        <div className="p-auth-header">
            <div className="left">
                <>
                    {displayBackBtn && (
                        <SVGIcon
                            className="header-back-btn"
                            name="circle_back"
                            onClick={() => {
                                if (onBack) {
                                    onBack();
                                } else {
                                    navigate(-1);
                                }
                            }}
                        />
                    )}
                    {leftContent}
                </>
            </div>
            <div className="middle">{children}</div>
            <div className="right">{rightContent}</div>
        </div>
    );
};

export default Header;
