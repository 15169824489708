import { stripHexPrefix } from '@ethereumjs/util';
import { uuidv4 } from '@particle-network/crypto';
import wallet from '../api/wallet';
import tokenProvider from '../provider';
import { AuthorizationOptions, LoginQuery } from '../provider/bundle';
import { load } from '../repository';
import { shortString } from './common-utils';

//https://eips.ethereum.org/EIPS/eip-4361
const signInEthereumMessage = `{0} wants you to sign in with your Ethereum account:
{1}

{2}

URI: https://{3}/
Version: 1
Chain ID: {4}
Nonce: {5}
Issued At: {6}
Expiration Time: {7}`;

const authorizeUtils = {
    get(): AuthorizationOptions | undefined {
        return (tokenProvider.params as LoginQuery).authorization;
    },

    isNeedAuthorize() {
        return (
            this.get() &&
            (tokenProvider.chain.name.toLowerCase() !== 'solana' || this.get()?.message) &&
            tokenProvider.queryParams.redirectType === 'login'
        );
    },

    generateMessage(domain?: string): string {
        if (!domain) {
            domain = 'auth.particle.network';
        }

        const address = wallet.walletInfo().public_address || '';
        const key = `login_auth_type_${tokenProvider.userInfo.uuid}`;
        const authType = load(key) || tokenProvider.userInfo.thirdparty_user_info?.provider || 'email';
        const account =
            //@ts-ignore
            tokenProvider.userInfo[authType] ||
            //@ts-ignore
            tokenProvider.userInfo[`${authType}_email`] ||
            //@ts-ignore
            shortString(tokenProvider.userInfo[`${authType}_id`]);

        const statement = `${authType.titleCase()}: ${account}`;
        const chainId = tokenProvider.chain.id.toString();
        const nonce = uuidv4().replace(/-/g, '');
        const issuedAt = new Date().toISOString();
        // 15分钟有效期
        const expirationTime = new Date(Date.now() + 10 * 60 * 1000).toISOString();
        return signInEthereumMessage.format(
            domain,
            address,
            statement,
            domain,
            chainId,
            nonce,
            issuedAt,
            expirationTime
        );
    },

    /**
     * Only ApeX Pro can sign without confirm when no payment password
     * https://gray.pro.apex.exchange/trade
     * @returns
     */
    isCanSignWithoutConfirm() {
        const authorization = this.get();
        return (
            authorization?.message &&
            tokenProvider.params.project_uuid === '5c88e7a3-e9d5-42e7-85eb-58d9271f869b' &&
            !tokenProvider.hasPaymentPassword() &&
            stripHexPrefix(authorization?.message) ===
                '417065582050726f0a616374696f6e3a20417065582050726f204f6e626f617264696e670a6f6e6c795369676e4f6e3a2068747470733a2f2f70726f2e617065782e65786368616e6765'
        );
    },
};

export default authorizeUtils;
