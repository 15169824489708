import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecurityDetection } from '../../../api/model/rpc-data';
import RiskTypography from '../riskTypography';
import './index.less';

interface Prop {
    securityDetection: SecurityDetection[];
}

const RiskReminder = (prop: Prop) => {
    const { securityDetection } = prop;

    const { t } = useTranslation();

    const [fold, setFold] = useState(false);

    const toggleFold = () => {
        setFold(!fold);
    };

    useEffect(() => {
        const doc = document.documentElement;
        doc.style.setProperty('--risk-bar-height', '36px');
        return () => {
            doc.style.setProperty('--risk-bar-height', '0px');
        };
    }, []);

    return (
        <div className="risk-reminder">
            <div className="risk-header" onClick={toggleFold}>
                <div className="risk-red-dot"></div>
                <div className="risk-found">{t('sign.risk_found')}</div>
                {fold ? <UpOutlined className="btn-risk-fold" /> : <DownOutlined className="btn-risk-fold" />}
            </div>
            <RiskTypography
                className={fold ? 'hide' : ''}
                securityDetection={securityDetection}
                title={t('sign.risk_hint_title')}
            ></RiskTypography>
        </div>
    );
};

export default RiskReminder;
