import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

const firebaseConfig = {
    apiKey: 'AIzaSyCG6cqrhjy5zM0KDtynLT6ND61lMHtGdeI',
    authDomain: 'particle-network.firebaseapp.com',
    projectId: 'particle-network',
    storageBucket: 'particle-network.appspot.com',
    messagingSenderId: '977003236402',
    appId: '1:977003236402:web:7f3374cc03535f37f2002b',
    measurementId: 'G-F316YGR7T2',
};

const app = initializeApp(firebaseConfig);
getAnalytics(app);

console.log('firebase initializeApp');
