import { Modal, message } from 'antd';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './pages/login';

import { useEffect, useMemo, useState } from 'react';
import { hasLocalKey } from './api/master-password';
import pnApi from './api/pn-api';
import wallet from './api/wallet';
import CircleClose from './components/icon/circle-close';
import SplashLoading from './components/splash-loading';
import { useParticleAuth } from './context';
import AccountAndSecurity from './pages/account/accountAndSecurity/index';
import AccountBind from './pages/account/accountBind';
import AccountVerify from './pages/account/accountVerify';
import ChangePaymentPassword from './pages/account/changePaymentPassword/index';
import LoginAccountBindLoading from './pages/account/loginAccountBindLoading';
import MasterPasswordChange from './pages/account/masterPasswordChange';
import MasterPasswordDescription from './pages/account/masterPasswordDescription';
import MasterPasswordVerify from './pages/account/masterPasswordVerify';
import SetMasterPassword from './pages/account/setMasterPassword';
import SetPaymentPassword from './pages/account/setPaymentPassword/index';
import PageCreatePassKeys from './pages/createPassKeys';
import DeviceInfo from './pages/device';
import Error from './pages/error';
import Welcome from './pages/login/components/welcome';
import LoginAccount, { LoginAccountElement } from './pages/loginAccount';
import LoginAccountBind from './pages/loginAccountBind';
import LoginAccountDetails from './pages/loginAccountDetails';
import Logout from './pages/logout';
import DeviceDetails from './pages/manageDevices/deviceDetails';
import DeviceList from './pages/manageDevices/deviceList';
import OAuthCallback from './pages/oauth';
import Sign from './pages/sign';
import Wallet from './pages/wallet';
import tokenProvider from './provider';
import { QueryParamKey } from './provider/bundle';
import queryParse, { parseMessage, parseParams } from './provider/query-parse';
import { isCookieEnabled } from './utils';
import {
    getCacheData,
    getCacheDataUrl,
    setCacheData,
    switchLanguages,
    switchThemes,
    switchToDev,
} from './utils/cahceData';
import { checkParam } from './utils/common-utils';
import device from './utils/detect-device';
import redirectToApp, { AuthError } from './utils/redirect-utils';
import url from './utils/url-utils';

function App() {
    {
        // set global method
        window.__setCacheData = setCacheData;
        window.__getCacheData = getCacheData;
        window.__parseParams = parseParams;
        window.__queryParse = queryParse;
        window.__parseMessage = parseMessage;
        // @ts-ignore
        window.__switchThemes = switchThemes;
        // @ts-ignore
        window.__switchLanguages = switchLanguages;
        // @ts-ignore
        window.__getCacheDataUrl = getCacheDataUrl;
        // @ts-ignore
        window.__switchToDev = switchToDev;
    }

    const navigate = useNavigate();

    const [displayCloseButton, setDisplayCloseButton] = useState(false);

    const [paramParseCompleted, setParamParseCompleted] = useState(false);

    const { setUserInfo, userInfo, setAppInfo, setQueryParamReady } = useParticleAuth();

    useEffect(() => {
        const cacheKey = url.getQueryVariable('cacheKey');
        if (cacheKey) {
            pnApi
                .getAuthSession(cacheKey)
                .then((res) => {
                    const content = Buffer.from(res, 'base64').toString('utf-8');
                    setCacheData(content, true);
                })
                .catch((err) => {
                    console.log('getAuthSession error', err);
                });
        }

        if (!isCookieEnabled()) {
            Modal.warning({
                content: 'Please allow Cookie in your browser settings.',
                onOk() {
                    window.location.reload();
                },
            });
        } else if (url.getQueryVariable(QueryParamKey.PRELOAD) === 'true') {
            console.log('preload');
        } else {
            parseQueryParams();
        }
    }, []);

    const parseQueryParams = async () => {
        try {
            await queryParse();
            setUserInfo(tokenProvider.userInfo);
            setQueryParamReady(true);
            loadAppConfig();
        } catch (e) {
            console.error('parse query params error', e);
            message.error('Parameter parsing error, please try again');
        }
    };

    const loadAppConfig = async () => {
        let config;
        if (tokenProvider.params?.project_app_uuid) {
            try {
                config = await pnApi.loadAppConfig(tokenProvider.params?.project_app_uuid).then((config) => {
                    setAppInfo(config);
                    return config;
                });
            } catch (error) {
                console.error(`load app config ${tokenProvider.params?.project_app_uuid}`, error);
            }
        } else {
            console.error('Fatal error: project_app_uuid not found in query param');
        }

        if (config?.theme?.web_auth) {
            setTimeout(() => {
                setParamParseCompleted(true);
                initializeApp();
            });
        } else {
            setParamParseCompleted(true);
            initializeApp();
        }
    };

    const initializeApp = () => {
        const path = window.location.hash;

        if (
            path !== '' &&
            path !== '#/device' &&
            path !== '#/welcome' &&
            path !== '#/error' &&
            path !== '#/create-passkeys'
        ) {
            checkConfig();
        }

        // display close button
        if (path !== '' && path !== '#/device' && path !== '#/wallet') {
            const inIframe = window.location !== window.parent.location;
            const displayCloseButton = url.getQueryVariable(QueryParamKey.DISPLAY_CLOSE_BUTTON) === 'true';
            setDisplayCloseButton(inIframe && displayCloseButton);
        }
    };

    useEffect(() => {
        const path = window.location.hash;
        if (
            paramParseCompleted &&
            path !== '' &&
            path !== '#/login' &&
            path !== '#/logout' &&
            path !== '#/wallet' &&
            path !== '#/account/master-password/verify'
        ) {
            if (!device.authEmbed() && tokenProvider.isLogin() && wallet.hasMasterPassword() && !hasLocalKey()) {
                console.log('start verify master password');
                navigate('/account/master-password/verify');
            }
        }
    }, [userInfo.wallets?.[0]?.encrypted_kms_data_key, paramParseCompleted]);

    const checkConfig = () => {
        if (!checkParam(tokenProvider.params)) {
            navigate('/error/params');
        }
    };

    const closeIframe = () => {
        redirectToApp({
            error: AuthError.userCancelOperation(),
        });
    };

    const closeClass = useMemo(() => {
        if (paramParseCompleted && displayCloseButton) {
            if (
                !userInfo?.security_account?.has_set_payment_password &&
                (window.location.hash === '#/solana/sign' || window.location.hash === '#/evm-chain/sign')
            ) {
                return ' top-hint';
            }
        }
        return '';
    }, [
        paramParseCompleted,
        displayCloseButton,
        window.location.hash,
        userInfo?.security_account?.has_set_payment_password,
    ]);

    return (
        <div className="App" data-route={window.location.hash}>
            {paramParseCompleted && displayCloseButton && (
                <CircleClose className={'close-iframe' + closeClass} onClick={closeIframe} />
            )}

            {paramParseCompleted && (
                <Routes>
                    <Route path="/" element={<OAuthCallback />} />
                    <Route path="/oauth2" element={<OAuthCallback />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/wallet" element={<Wallet />} />
                    <Route path="/solana/sign" element={<Sign />} />
                    <Route path="/evm-chain/sign" element={<Sign />} />
                    <Route path="/welcome" element={<Welcome />} />
                    <Route path="/device" element={<DeviceInfo />} />
                    <Route path="/account/security" element={<AccountAndSecurity />} />
                    <Route path="/account/bind" element={<AccountBind />} />
                    <Route path="/account/change-password" element={<ChangePaymentPassword />} />
                    <Route path="/account/set-password" element={<SetPaymentPassword />} />
                    <Route path="/account/verify" element={<AccountVerify />} />
                    <Route path="/account/master-password" element={<SetMasterPassword />} />
                    <Route path="/account/master-password/description" element={<MasterPasswordDescription />} />
                    <Route path="/account/master-password/verify" element={<MasterPasswordVerify />} />
                    <Route path="/account/master-password/change" element={<MasterPasswordChange />} />
                    <Route path="/login-account/bind-loading" element={<LoginAccountBindLoading />} />
                    <Route path="/manageDevices/deviceList" element={<DeviceList />} />
                    <Route path="/manageDevices/deviceDetails" element={<DeviceDetails />} />
                    <Route path="/error/*" element={<Error />} />
                    <Route path="/login-account" element={<LoginAccountElement />}>
                        <Route index element={<LoginAccount />}></Route>
                        <Route path="details" element={<LoginAccountDetails />} />
                        <Route path="bind" element={<LoginAccountBind />} />
                    </Route>
                    <Route path="/create-passkeys" element={<PageCreatePassKeys />} />
                </Routes>
            )}

            {!paramParseCompleted && <SplashLoading visible={true} />}
        </div>
    );
}

export default App;
