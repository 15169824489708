import { useRequest, useThrottleFn } from 'ahooks';
import { Button, Form, Input, Modal, message } from 'antd';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthType } from '../../api/model/login-type';
import pnApi from '../../api/pn-api';
import Header from '../../components/header';
import PhoneInputItem from '../../components/phoneInputItem';
import PowerFooter from '../../components/power-footer';
import { useParticleAuth, useUserInfo } from '../../context';
import { EmailRegExp } from '../../utils';
import { PageType } from '../account/accountVerify/config';
import { encryptValue, getAccountList } from '../loginAccount';
import './index.less';

export interface IAccountInfo {
    type: AuthType;
    icon: string;
    name: string;
    value: string;
    id?: string;
    isOriginal?: boolean;
}

interface IState {
    authType: AuthType;
    verifyToken: string;
}

const LoginAccountBind = () => {
    const { state } = useLocation();
    const authType = (state as IState)?.authType || '';
    const verifyToken = (state as IState)?.verifyToken || '';
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { userInfo } = useUserInfo();
    const { showSelectSecurityAccount, events } = useParticleAuth();
    const [submitLoading, setSubmitLoading] = useState(false);
    const { runAsync: loginBindingsCheckRequest } = useRequest(pnApi.loginBindingsCheck, {
        manual: true,
    });

    const accountList = useMemo(() => {
        return getAccountList({ userInfo, t });
    }, [userInfo]);

    const accountInfo: IAccountInfo = useMemo(() => {
        const data = (accountList?.find?.((item) => item.type === authType) || {}) as IAccountInfo;
        return data;
    }, [accountList, authType]);

    /**
     *  可以已经绑定
     */
    const canLink = useMemo(() => {
        return !accountInfo.value && !accountInfo.id;
    }, [accountInfo]);

    /**
     * 是否可以解绑
     * 如果isOriginal=true 说明是原始账号，不能解绑
     */
    const canUnlink = useMemo(() => {
        return !accountInfo.isOriginal && !canLink;
    }, [accountInfo]);

    const bindLoginAccount = () => {
        form.validateFields().then((values) => {
            const targetInputValue: string = form.getFieldsValue()?.inputValue?.replace(/\s/g, '')?.trim?.() || '';
            setSubmitLoading(true);
            // 判断此手机号是否是安全手机号码，如果是安全手机号码，就提示”安全手机号码不能作为登录账号“
            if (authType === 'phone' && userInfo?.security_account?.phone === targetInputValue) {
                Modal.error({
                    title: t('new.duplicate_phonel_bindings'),
                });
                setSubmitLoading(false);
                return;
            } else if (
                authType === 'email' &&
                userInfo?.security_account?.email?.toLowerCase() === targetInputValue?.toLowerCase()
            ) {
                Modal.error({
                    title: t('new.duplicate_email_bindings'),
                });
                setSubmitLoading(false);
                return;
            }

            let params: any = {};
            if (authType === AuthType.phone) {
                params.phone = targetInputValue;
            } else {
                params.email = targetInputValue;
            }
            loginBindingsCheckRequest(params)
                .then((res) => {
                    navigate('/account/verify', {
                        state: {
                            account: targetInputValue,
                            authType,
                            verifyToken,
                            pageType: PageType.BindLoginAccount,
                        },
                    });
                    setSubmitLoading(false);
                })
                .catch((error) => {
                    setSubmitLoading(false);
                    if (error.error_code === 20109) {
                        Modal.error({
                            title:
                                authType === AuthType.phone
                                    ? t('error.server_phone_20109')
                                    : t('error.server_email_20109'),
                        });
                    } else {
                        message.error(error.message);
                    }
                });
        });
    };

    const { run: toAccountVerify } = useThrottleFn(
        (params) => {
            navigate('/account/verify', {
                state: {
                    account: params.account, // 验证安全账号
                    authType,
                    unbindAccount: form.getFieldValue('inputValue'),
                    pageType: PageType.UnbindLoginAccount,
                },
            });
        },
        { wait: 3000 }
    );

    const unbindLoginAccount = () => {
        // 解绑验证需要验证安全账号
        if (userInfo?.security_account?.email && userInfo?.security_account?.phone) {
            showSelectSecurityAccount(true, {
                authType,
                unbindAccount: form.getFieldValue('inputValue'),
                pageType: PageType.UnbindLoginAccount,
            });
        } else {
            toAccountVerify({
                account: userInfo?.security_account?.email || userInfo?.security_account?.phone,
            });
        }
    };

    useEffect(() => {
        if (accountInfo?.value || accountInfo?.id) {
            if (authType === AuthType.phone) {
                const phoneNumber = parsePhoneNumber(accountInfo?.value.replace(/\s/g, ''));
                const countryCode = phoneNumber.countryCallingCode;
                const nationalNumber = phoneNumber.nationalNumber;
                form.setFieldsValue({
                    inputValue: encryptValue(`+${countryCode} ${nationalNumber}`),
                });
            } else {
                form.setFieldsValue({
                    inputValue: encryptValue(accountInfo?.value || accountInfo?.id || ''),
                });
            }
        }
    }, [accountInfo?.value, accountInfo?.id, authType]);

    return (
        <div className="login-account-bind ">
            <Header displayBackBtn={true}>{accountInfo.name}</Header>
            <Form className="account-bind-form" layout="vertical" form={form} onFinish={bindLoginAccount}>
                {authType === AuthType.phone ? (
                    <PhoneInputItem
                        name="inputValue"
                        form={form}
                        disabled={!canLink}
                        previewMode="input"
                        extra={
                            <div className="link-account-hint">
                                {t('new.link_account_hint').format(accountInfo.name)}
                            </div>
                        }
                    />
                ) : (
                    <Form.Item
                        name="inputValue"
                        validateTrigger="onBlur"
                        extra={
                            <div className="link-account-hint">
                                {t('new.link_account_hint').format(accountInfo.name)}
                            </div>
                        }
                        rules={[
                            {
                                required: true,
                                validator: async (rule, value) => {
                                    if (!value) {
                                        return Promise.reject(t('account.input_vaild_email'));
                                    } else if (!EmailRegExp.test(value)) {
                                        return Promise.reject(t('login.email_format_error'));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input
                            className="account-input"
                            placeholder={accountInfo.name}
                            disabled={!canLink}
                            onChange={(e) =>
                                form.setFields([
                                    {
                                        name: 'email',
                                        value: e.target.value,
                                        errors: [],
                                    },
                                ])
                            }
                        />
                    </Form.Item>
                )}

                <Form.Item>
                    {canLink ? (
                        <Button
                            className="primary-antd-btn link-btn"
                            // onClick={bindLoginAccount}
                            loading={submitLoading}
                            htmlType="submit"
                        >
                            {t('account.link')}
                        </Button>
                    ) : (
                        <></>
                        // <Button
                        //     className="primary-antd-btn unlink-btn"
                        //     disabled={!canUnlink}
                        //     onClick={() => {
                        //         unbindLoginAccount();
                        //     }}
                        // >
                        //     Unlink
                        // </Button>
                    )}
                </Form.Item>
            </Form>
            <PowerFooter />
        </div>
    );
};

export default LoginAccountBind;
