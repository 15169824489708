import { RecordType } from '@particle-network/analytics';
import { Button, Modal, Tabs, message } from 'antd';
import bs58 from 'bs58';
import { useEffect, useMemo, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import errorHandle from '../../../api/error-handle';
import {
    SolanaDeserializeTransactionResult,
    SolanaEnhancedMethod,
    SplTokenChangeInfo,
    TokenChangeInfo,
} from '../../../api/model/rpc-data';
import pnApi from '../../../api/pn-api';
import wallet from '../../../api/wallet';
import IconCopy from '../../../components/icon/icon-copy';
import PowerFooter from '../../../components/power-footer';
import { useParticleAuth } from '../../../context';
import tokenProvider from '../../../provider';
import { PromptSettingWhenSignType, SignQuery, SolanaSignMethod } from '../../../provider/bundle';
import { PreferenceKey, load, save } from '../../../repository';
import authorizeUtils from '../../../utils/authorizeUtils';
import { biRecords } from '../../../utils/bi';
import { getChainDisplayName, getChainIcon } from '../../../utils/chain-utils';
import { isNullish, popupwindowNoLimit, shortString } from '../../../utils/common-utils';
import { formatTokenAmount } from '../../../utils/number-utils';
import redirectToApp, { AuthError } from '../../../utils/redirect-utils';
import solana from '../../../utils/solana-utils';
import url from '../../../utils/url-utils';
import './info-sign.less';
import Menu from './menu';

function InfoSign(props: any) {
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);
    const [signQuery, setSignQuery] = useState<SignQuery>();
    const [transactionInfo, setTransactionInfo] = useState<SolanaDeserializeTransactionResult>();

    const [nftChanges, setNftChanges] = useState<TokenChangeInfo[]>();

    const [tokenChanges, setTokenChanges] = useState<SplTokenChangeInfo[]>();

    const [headerTitle, setHeaderTitle] = useState<string>('');
    const [headerDes, setHeaderDes] = useState<string>('');

    const router = useNavigate();

    const {
        setPaymentVerify,
        showAccountTipModal,
        userInfo,
        setPaymentPassword,
        securityAccountConfig,
        loadSecurityAccountsAsync,
        loginSuccessRedirectToApp,
    } = useParticleAuth();

    const loadsecurityAccounts = () => {
        loadSecurityAccountsAsync()
            .then(() => {
                approveSign();
            })
            .catch((error) => {
                setLoading(false);
                message.error(error.message ?? 'Sign Error');
            });
    };

    const hasSetPaymentPassword = useMemo(
        () => userInfo?.security_account?.has_set_payment_password,
        [userInfo?.security_account?.has_set_payment_password]
    );

    const { TabPane } = Tabs;

    const showSetPaymentPasswordOrConfirm = (confirm: () => void) => {
        const has = userInfo?.security_account?.has_set_payment_password;
        if (has) {
            confirm();
        } else {
            if (
                securityAccountConfig.promptSettingWhenSign === PromptSettingWhenSignType.every ||
                securityAccountConfig.promptSettingWhenSign === PromptSettingWhenSignType.everyAndNotSkip
            ) {
                showAccountTipModal({
                    visible: true,
                    confirm,
                });
            } else if (
                (securityAccountConfig.promptSettingWhenSign ||
                    isNullish(securityAccountConfig.promptSettingWhenSign)) &&
                !load(PreferenceKey.PN_OPEN_SET_PAYMENT_PASSWORD + userInfo.uuid)
            ) {
                save(PreferenceKey.PN_OPEN_SET_PAYMENT_PASSWORD + userInfo.uuid, '1');
                showAccountTipModal({
                    visible: true,
                    confirm,
                });
            } else {
                confirm();
            }
        }
    };

    useEffect(() => {
        if (!wallet.exist()) {
            redirectToApp({
                error: AuthError.walletNotCreated(),
            });
        }
    }, []);

    const webWalletUrl = useMemo(() => {
        return signQuery ? url.buildWalletUrl(signQuery) : '';
    }, [signQuery]);

    const cancelSign = async () => {
        if (loading) {
            return;
        }
        //返回app
        redirectToApp({
            error: AuthError.userRejectedRequest(),
        });
    };

    async function asyncFilter<T>(predicate: (indo: T) => Promise<boolean>, arr?: T[]): Promise<T[]> {
        if (!arr) {
            return [];
        }
        const results = await Promise.all(arr.map(predicate));
        return arr.filter((_v, index) => results[index]);
    }

    const approveSign = async () => {
        if (!signQuery) return;

        if (!tokenProvider.isLogin()) {
            redirectToApp({
                error: AuthError.notLogin(),
            });
            return;
        }

        biRecords({
            record_type: RecordType.PAGE_SIGN_CONFIRM_BUTTON_CLICK, // confirm按钮点击
        });

        if (userInfo?.security_account?.has_set_payment_password) {
            setPaymentVerify({
                visible: true,
                onVerifyCompleted: approveSignImpl,
            });
        } else if (
            signQuery.method === SolanaSignMethod.signAndSendTransaction ||
            signQuery.method === SolanaSignMethod.signTransaction ||
            signQuery.method === SolanaSignMethod.signAllTransactions ||
            securityAccountConfig.promptSettingWhenSign === PromptSettingWhenSignType.everyAndNotSkip
        ) {
            // signAndSendTransaction signTransaction signAllTransactions 或者 强制设置支付密码
            showSetPaymentPasswordOrConfirm(approveSignImpl);
        } else {
            approveSignImpl();
        }
    };

    const approveSignImpl = async () => {
        if (!signQuery) return;
        setLoading(true);
        let signed;
        try {
            signed = await wallet.solanaSign(signQuery.message, signQuery.method);
            biRecords({
                record_type: RecordType.PAGE_SIGN_CONFIRM_BUTTON_CLICK_SUCCESS, // confirm成功
            });
        } catch (e: any) {
            console.error(signQuery.method, e);
            biRecords({
                record_type: RecordType.PAGE_SIGN_CONFIRM_BUTTON_CLICK_FAILURE, // confirm失败
            });
            if (e?.error_code === 50103 && !userInfo?.security_account?.has_set_payment_password) {
                loadsecurityAccounts();
            } else if (e?.message === 'Local Key not found' || e?.message === 'Master password decryption error') {
                router('/account/master-password/verify');
            } else {
                errorHandle(e);
                setLoading(false);
            }
            return;
        }

        if (signQuery.method === SolanaSignMethod.signAndSendTransaction) {
            let output;
            try {
                output = await pnApi.solanaRpc<string>({
                    id: uuidv4(),
                    jsonrpc: '2.0',
                    method: 'sendTransaction',
                    params: [signed, { encoding: 'base64', preflightCommitment: 'confirmed' }],
                });
            } catch (error: any) {
                setLoading(false);
                Modal.error({
                    title: error.message ?? 'Send Transaction Error',
                    okText: t('common.confirm'),
                    onOk: () => {
                        redirectToApp({
                            error: error,
                        });
                    },
                });
                return;
            }

            redirectToApp({
                signature: output.result,
            });
        } else {
            if (authorizeUtils.isNeedAuthorize()) {
                sessionStorage.setItem(
                    PreferenceKey.PN_TEMP_AUTHORIZE_RESULT,
                    JSON.stringify({
                        signature: signed,
                        message: signQuery.message,
                    })
                );
                loginSuccessRedirectToApp();
            } else {
                redirectToApp({
                    signature: signed,
                });
            }
        }
    };

    useEffect(() => {
        const signQuery = tokenProvider.params as SignQuery;
        setSignQuery(signQuery);
        if (signQuery.method === SolanaSignMethod.signMessage) {
            setHeaderTitle(t('sign.signature_message'));
            setHeaderDes(t('sign.signature_title'));
        } else if (signQuery.method === SolanaSignMethod.signAndSendTransaction) {
            setHeaderTitle(t('sign.send_transaction'));
            setHeaderDes(t('sign.approve_and').format(getChainDisplayName(tokenProvider.chain)));
            deserializeTransaction([signQuery.message]);
        } else if (signQuery.method === SolanaSignMethod.signTransaction) {
            setHeaderTitle(t('sign.sign_transaction'));
            setHeaderDes(t('sign.sign_but'));
            deserializeTransaction([signQuery.message]);
        } else if (signQuery.method === SolanaSignMethod.signAllTransactions) {
            setHeaderTitle(t('sign.sign_transaction'));
            setHeaderDes(t('sign.sign_but'));
            deserializeTransaction(JSON.parse(signQuery.message));
        }
    }, []);

    const deserializeTransaction = (messages: string[]) => {
        pnApi
            .solanaRpc<SolanaDeserializeTransactionResult>({
                id: uuidv4(),
                jsonrpc: '2.0',
                method: SolanaEnhancedMethod.enhancedDeserializeTransaction,
                params: messages,
            })
            .then((output) => {
                setTransactionInfo(output.result);
                console.log('SOLChanges', output.result?.estimatedChanges?.sols?.length);
                asyncFilter(async (info) => {
                    const associatedTokenAddress = await solana.findAssociatedTokenAddress(
                        wallet.publicAddress(),
                        info.mint
                    );
                    return info.associatedTokenAddress === associatedTokenAddress.toBase58();
                }, output.result?.estimatedChanges?.nfts).then((results) => {
                    console.log('NFTChanges', results.length);
                    setNftChanges(results);
                });

                asyncFilter(async (info) => {
                    const associatedTokenAddress = await solana.findAssociatedTokenAddress(
                        wallet.publicAddress(),
                        info.mint
                    );
                    return info.associatedTokenAddress === associatedTokenAddress.toBase58();
                }, output.result?.estimatedChanges?.tokens).then((results) => {
                    console.log('TokenChanges', results.length);
                    setTokenChanges(results);
                });
            })
            .catch((error) => {
                console.log('deserializeTransaction error', error);
                Modal.error({
                    title: error.message ?? 'Deserialize Transaction Error',
                    okCancel: true,
                    cancelText: t('common.cancel'),
                    okText: t('common.retry'),
                    onOk: () => {
                        deserializeTransaction(messages);
                    },
                });
            });
    };

    const signMessage = () => {
        return (
            <div className="sign-message">
                <div className={'message' + (hasSetPaymentPassword ? '' : ' no-password-tip')}>
                    <div className="pre-wrap personal-message">{bs58.decode(signQuery!.message).toString('utf-8')}</div>
                </div>
            </div>
        );
    };

    const signTransaction = () => {
        return (
            <div>
                <Tabs defaultActiveKey="1">
                    <TabPane tab={t('sign.details')} key="1">
                        <div className="balance-change">
                            <div className="title">{t('sign.estimated_balance_change')}</div>
                            <div className="change-body">
                                {transactionInfo?.estimatedChanges?.sols
                                    .filter((info) => info.address === wallet.publicAddress())
                                    .map((info, index) => {
                                        return (
                                            <div className="change-title" key={`sol-change-${index}`}>
                                                SOL
                                                <div
                                                    className="change-val"
                                                    style={info.lamportsChange < 0 ? { color: '#ea4335' } : {}}
                                                >
                                                    {info.lamportsChange < 0 ? '' : '+'}
                                                    {formatTokenAmount(info.lamportsChange, 9)}
                                                </div>
                                            </div>
                                        );
                                    })}

                                {nftChanges?.map((info, index) => {
                                    return (
                                        <div className="change-title" key={`nft-change-${index}`}>
                                            {info.name ? info.name : 'Unknown NFT'}
                                            <div
                                                className="change-val"
                                                style={info.amountChange < 0 ? { color: '#ea4335' } : {}}
                                            >
                                                {info.amountChange < 0 ? '' : '+'}
                                                {info.amountChange}
                                            </div>
                                        </div>
                                    );
                                })}

                                {tokenChanges?.map((info, index) => {
                                    return (
                                        <div className="change-title" key={`token-change-${index}`}>
                                            {info.name ? info.name : 'Unknown Token'}
                                            <div
                                                className="change-val"
                                                style={info.amountChange < 0 ? { color: '#ea4335' } : {}}
                                            >
                                                {info.amountChange < 0 ? '' : '+'}
                                                {formatTokenAmount(info.amountChange, info.decimals)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="net-fee solana">
                            <div className="title">
                                {t('sign.network_fee')}
                                {transactionInfo && (
                                    <div className="fee-val">
                                        {formatTokenAmount(transactionInfo.estimatedLamportsFee, 9)} SOL
                                    </div>
                                )}
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tab={t('sign.data')} key="2">
                        <div>
                            {transactionInfo?.instructions.map((instruction, index) => {
                                return (
                                    <div className="inner-instruction" key={`instruction-${index}`}>
                                        <div className="inner-content">
                                            <div className="content-item">
                                                <div className="item">
                                                    <div className="item-0">
                                                        #{index + 1} - {t(`program.${instruction.type}`)}
                                                    </div>
                                                    <div className="item-1 mt10">
                                                        {t('sign.program_id')}
                                                        <span>{shortString(instruction.programId)}</span>
                                                    </div>
                                                    <div className="item-1 mt15">
                                                        {t('sign.data')}
                                                        <span>{shortString(instruction.data)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </TabPane>
                </Tabs>
            </div>
        );
    };

    return (
        <div className="info-sign">
            {!hasSetPaymentPassword && (
                <div className="has-payment-password">
                    <div className="has-payment-password-icon"></div>
                    <div className="has-payment-password-tip">{t('account.waring_tip1')}</div>
                    <div className="has-payment-password-set" onClick={setPaymentPassword}>
                        {t('account.set')}
                    </div>
                </div>
            )}
            <div className={'scroll-part' + (hasSetPaymentPassword ? '' : ' no-password-tip')}>
                <div className="menu-entry">
                    {!!webWalletUrl && (
                        <div
                            className="wallet-entry"
                            onClick={() => {
                                popupwindowNoLimit(webWalletUrl, '_blank', 1000, 800);
                            }}
                        />
                    )}
                    <div
                        className="wallet-entry security-entry"
                        onClick={() => {
                            router('/account/security');
                        }}
                    />
                </div>
                <Menu wallet={wallet} userInfo={userInfo} transactionInfo={transactionInfo} signQuery={signQuery} />
                <div className="info-request">{headerTitle}</div>
                <div className="info-title">
                    <img src={getChainIcon(tokenProvider.chain)} alt="" />
                    {getChainDisplayName(tokenProvider.chain)}
                </div>
                <CopyToClipboard text={wallet.publicAddress()} onCopy={() => message.success(t('new.copied_to'))}>
                    <div className="info-address">
                        {wallet.shortAddress()}
                        <div className="copy-icon">
                            <IconCopy />
                        </div>
                    </div>
                </CopyToClipboard>
                <div className="info-des">{headerDes}</div>
                <div className="apart-line"></div>

                {signQuery?.method && signQuery?.method === SolanaSignMethod.signMessage && signMessage()}

                {signQuery?.method && signQuery?.method !== SolanaSignMethod.signMessage && signTransaction()}
            </div>

            <div className="btn-box">
                <div>
                    <Button className="btn-cancel" onClick={cancelSign}>
                        {t('common.cancel')}
                    </Button>
                    <Button className="btn-approve" onClick={approveSign} loading={loading}>
                        {t('common.confirm')}
                    </Button>
                </div>
                <PowerFooter></PowerFooter>
            </div>
        </div>
    );
}

export default InfoSign;
