import qs from 'qs';
import pnApi from '../api/pn-api';

export const getCacheData = () => {
    const cacheData = {
        sessionStorage: Object.keys(sessionStorage).map((key) => [key, sessionStorage[key]]),
        localStorage: Object.keys(localStorage).map((key) => [key, localStorage[key]]),
        url: location.href,
    };
    const encodeCacheData = encodeURIComponent(JSON.stringify(cacheData));
    return encodeCacheData;
};

/**
 * setCacheData
 * @param {string} refresh 是否刷新页面
 */
export const setCacheData = (encodeCacheData: string, refresh = false) => {
    const {
        sessionStorage: sessionStorageList,
        localStorage: localStorageList,
        url,
    } = JSON.parse(decodeURIComponent(encodeCacheData));

    sessionStorageList.forEach((item: any) => {
        sessionStorage.setItem(item[0], item[1]);
    });
    localStorageList.forEach((item: any) => {
        localStorage.setItem(item[0], item[1]);
    });
    if (refresh && url) {
        const targetUrl = location.origin + location.pathname + '?' + url.split('?').pop();
        console.log('targetUrl', targetUrl);
        location.href = targetUrl;
    }
};

export const switchToDev = async (devHost = 'http://localhost:3066') => {
    const cahceData = Buffer.from(getCacheData()).toString('base64');
    const key = await pnApi.createAuthSession(cahceData);
    if (!location.href.includes('cacheKey=')) {
        location.href = `${devHost}?cacheKey=${key}&${location.href.split('?').pop()}`;
    }
};

// 切换主题
export const switchThemes = () => {
    const { origin, hash, search } = location;
    // @ts-ignore
    const query = qs.parse(search.split('?').pop());
    const theme = query.theme_type || 'light';
    const newTheme = theme === 'light' ? 'dark' : 'light';
    const newQuery = {
        ...query,
        theme_type: newTheme,
    };
    const url = `${origin}?${qs.stringify(newQuery)}${hash}`.replace(/\n/g, '');
    console.log('url', url);
    location.href = url;
};

// 切换语言
export const switchLanguages = () => {
    const { origin, hash, search } = location;
    // @ts-ignore
    const query = qs.parse(search.split('?').pop());
    const language = query.language || 'en-US';
    const newLanguage = language === 'en-US' ? 'zh-CN' : 'en-US';
    const newQuery = {
        ...query,
        language: newLanguage,
    };
    const url = `${origin}?${qs.stringify(newQuery)}${hash}`.replace(/\n/g, '');
    console.log('url', url);
    location.href = url;
};

// 获取静态链接
export const getCacheDataUrl = () => {
    // @ts-ignore
    const query = qs.parse(location.search.split('?').pop());
    const hash = location.hash;
    let cacheValue: string = getCacheData();
    const newQuery = {
        ...query,
        cacheValue,
    };
    const newUrl = `${location.origin}?${qs.stringify(newQuery)}${hash}`.replace(/\n/g, '');
    return newUrl;
};
