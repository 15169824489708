import { Button, Form, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthType } from '../../../api/model/login-type';
import { useParticleAuth } from '../../../context';
import { EmailRegExp } from '../../../utils';
import { isInIframe } from '../../../utils/common-utils';
import device from '../../../utils/detect-device';
import popupLogin from '../../../utils/popup-login';
import './login.less';

function EmailLogin(props: any) {
    const { t } = useTranslation();

    const { sendCaptcha, setShowInputCaptcha, account, supportAuthTypes, loginWithAccount } = props;

    const { docHeight } = useParticleAuth();

    const [errorTip, setErrorTip] = useState(false);
    const [noInputTip, setNoInputTip] = useState(false);

    const [loading, setLoading] = useState(false);

    const emailInput = useRef<any>(null);

    const { appInfo } = useParticleAuth();

    useEffect(() => {
        console.log('loginWithAccount', loginWithAccount, account);
        if (loginWithAccount && account && sendCaptcha) {
            getCaptcha(account);
        }
    }, []);

    const getCaptcha = (email: string, formSubmit = false) => {
        emailInput.current.focus();
        console.log('login email: ' + email);
        if (EmailRegExp.test(email)) {
            if (formSubmit && isInIframe() && device.isBlockingThirdpartyCookiesBrowser(true)) {
                //safari popup login
                popupLogin(AuthType.email, email);
            } else {
                sendCaptchaImpl(email);
            }
        } else {
            if (!email) {
                setNoInputTip(true);
                setErrorTip(false);
            } else {
                setErrorTip(true);
            }
        }
    };

    const sendCaptchaImpl = (email: string) => {
        setLoading(true);
        sendCaptcha({
            email: email,
        })
            .then((result: boolean) => {
                setShowInputCaptcha(result);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    return (
        <div className="login-style">
            <Form
                name="login-form"
                onFinish={(values) => getCaptcha(values.email.trim(), true)}
                className="form-box"
                requiredMark={false}
                labelCol={{
                    style: { textAlign: 'left' },
                }}
            >
                <div className="email-box">
                    <Form.Item
                        name="email"
                        label=""
                        className="form-item"
                        initialValue={account?.includes('@') ? account : ''}
                    >
                        <Input
                            className="email-input"
                            placeholder={t('login.email_address')}
                            ref={emailInput}
                            onChange={() => {
                                setErrorTip(false);
                                setNoInputTip(false);
                            }}
                            readOnly={loginWithAccount}
                            onFocus={() => {
                                document.querySelector('.email-box')?.setAttribute('data-focus', 'true');
                            }}
                            onBlur={() => {
                                document.querySelector('.email-box')?.removeAttribute('data-focus');
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="error-tip">{errorTip && t('login.email_format_error')}</div>
                <div className="error-tip">{noInputTip && t('login.input_email_holder')}</div>

                <Form.Item className="form-item">
                    <Button type="primary" htmlType="submit" className="primary-antd-btn submit-btn" loading={loading}>
                        {appInfo?.theme?.web_auth?.send_button_text || t('login.get_captcha')}
                    </Button>
                </Form.Item>

                {!loginWithAccount && supportAuthTypes.length > 0 && (
                    <div className="login-or" data-size={docHeight < 640 ? 'mini' : ''}>
                        <div className="or-line"></div>
                        {t('login.or')}
                        <div className="or-line"></div>
                    </div>
                )}
            </Form>
        </div>
    );
}

export default EmailLogin;
