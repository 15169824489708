import { useThrottleFn } from 'ahooks';
import { message } from 'antd';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthType } from '../../api/model/login-type';
import Header from '../../components/header';
import SVGIcon from '../../components/icon/svg-icon';
import PowerFooter from '../../components/power-footer';
import { useParticleAuth } from '../../context';
import device from '../../utils/detect-device';
import { isSDKSupportPassKeys } from '../../utils/passkeys';
import { PageType } from '../account/accountVerify/config';
import './index.less';

export const getAccountList = (params: any) => {
    const { userInfo, t } = params;
    const list = [
        {
            type: AuthType.phone,
            icon: require('../../common/images/phone_icon.png'),
            name: t('account.mobile'),
            value: userInfo.phone,
            id: undefined,
            isOriginal: false,
        },
        {
            type: AuthType.email,
            icon: require('../../common/images/email_icon.png'),
            name: t('account.email'),
            value: userInfo.email,
            id: undefined,
            isOriginal: false,
        },
    ];
    if (!device.authEmbed()) {
        list.push(
            {
                type: AuthType.google,
                icon: require('../../common/images/google_icon.png'),
                name: t('login.google'),
                value: userInfo.google_email,
                id: userInfo.google_id,
                isOriginal: false,
            },
            {
                type: AuthType.facebook,
                icon: require('../../common/images/facebook_icon.png'),
                name: t('login.facebook'),
                value: userInfo.facebook_email,
                id: userInfo.facebook_id,
                isOriginal: false,
            },
            {
                type: AuthType.twitter,
                icon: require('../../common/images/twitter_icon.png'),
                name: t('login.twitter'),
                value: userInfo.twitter_email,
                id: userInfo.twitter_id,
                isOriginal: false,
            }
        );
        if (userInfo.email && isSDKSupportPassKeys()) {
            list.push({
                type: AuthType.passkeys,
                icon: require('../../common/images/passkeys_icon.png'),
                name: t('login.passkeys'),
                value: undefined,
                id: userInfo.passkeys_id ? userInfo.email : undefined,
                isOriginal: false,
            });
        }
    }
    return list;
};

export const LoginAccountElement = () => {
    const navigation = useNavigate();
    return (
        <>
            <SVGIcon className="icon-navigation-back" name="circle_back" onClick={() => navigation(-1)} />
            <Outlet />
        </>
    );
};

export const encryptValue = (value: string) => {
    let result = value?.replace(' ', '');
    if (!result) {
        return result;
    } else if (result?.includes?.('@')) {
        // email
        result = `${result.split('@')[0].substr(0, 3)}****@${result.split('@')[1]}`;
    } else if (result?.includes?.('+')) {
        // phone
        const phoneNumber = parsePhoneNumber(result);
        const nationalNumber = phoneNumber.nationalNumber.toString();
        const countryCode = phoneNumber.countryCallingCode;
        result = `+${countryCode} ${nationalNumber?.substr?.(0, 3)}****${nationalNumber?.substr?.(-4)}`;
    } else if (result) {
        result = `${result.substr(0, 3)}****${result.substr(-4)}`;
    }
    return result;
};

let selectedAuthType: AuthType;

const LoginAccount = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { userInfo, showSelectSecurityAccount } = useParticleAuth();

    const { run: toAccountVerify } = useThrottleFn(
        (params) => {
            navigate('/account/verify', {
                state: {
                    account: params.account, // 验证安全账号
                    authType: selectedAuthType, // 列表选择的 authType
                    pageType: PageType.VerifySecurityAccount_BindLoginAccount,
                },
            });
        },
        { wait: 3000 }
    );

    const accountList = useMemo(() => {
        return getAccountList({ userInfo, t });
    }, [userInfo]);

    const toLoginAccountBind = (item: any) => {
        if (item.value || item.id) {
            // 直接跳转到LoginAccount页面
            if (item.type === AuthType.passkeys) {
                navigate('/login-account/details', {
                    state: {
                        accountInfo: item,
                    },
                });
            } else {
                navigate('/login-account/bind', {
                    state: {
                        authType: item.type,
                    },
                });
            }
        } else if (!userInfo?.security_account?.email && !userInfo?.security_account?.phone) {
            // 绑定安全账号
            message.error('Please bind security account first.');
        } else if (userInfo?.security_account?.email && userInfo?.security_account?.phone) {
            // 选择安全账号
            showSelectSecurityAccount(true, {
                authType: selectedAuthType, // 列表选择的 authType
                pageType: PageType.VerifySecurityAccount_BindLoginAccount,
            });
        } else {
            // 验证安全账号
            toAccountVerify({
                account: userInfo?.security_account?.email || userInfo?.security_account?.phone,
            });
        }
    };

    return (
        <div className="login-account-box">
            <Header displayBackBtn={true}>{t('account.login_account')}</Header>
            <div className="login-account-description">{t('account.login_account_hint')}</div>

            <div className="account-list">
                {accountList?.map((item, index) => {
                    return (
                        <div
                            className="login-account-item"
                            onClick={() => {
                                selectedAuthType = item.type;
                                toLoginAccountBind(item);
                            }}
                            key={index}
                        >
                            <img src={item.icon} />
                            <div className="login-account-name">{item.name}</div>
                            <div className="login-account-value" data-no-linked={item.value || item.id ? false : true}>
                                {encryptValue(item.value || item.id) || t('account.not_linked')}
                            </div>
                            <SVGIcon className="arrow-right-icon" name="arrow_right_icon" />
                        </div>
                    );
                })}
            </div>

            <PowerFooter />
        </div>
    );
};

export default LoginAccount;
