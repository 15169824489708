import { urlCrypto } from '@particle-network/crypto';
import * as Sentry from '@sentry/react';
import { Modal } from 'antd';
import { detect } from 'detect-browser';
import { UserPreview, Wallet } from '../api/model/user-info';
import wallet from '../api/wallet';
import tokenProvider from '../provider';
import { PreferenceKey, load } from '../repository';
import device from './detect-device';

export enum RedirectType {
    login = 'login',
    logout = 'logout',
}

export class AuthError {
    constructor(public code: number, public message: string) {
        this.code = code;
        this.message = message;
    }

    public static feeError() {
        return new AuthError(8101, 'maxFeePerGas cannot be less than maxPriorityFeePerGas');
    }

    public static userRejectedRequest() {
        return new AuthError(4001, 'The user rejected the request');
    }

    public static userCancelOperation() {
        return new AuthError(4011, 'The user cancel the operation');
    }

    public static unauthorized() {
        return new AuthError(4100, 'The requested method and/or account has not been authorized by the user');
    }

    public static webviewUnsupported() {
        return new AuthError(8004, 'Webview opening is unsupported');
    }

    public static systemError() {
        return new AuthError(8001, 'System Error');
    }

    public static paramsError() {
        return new AuthError(8002, 'Param error, see doc for more info');
    }

    public static notLogin() {
        return new AuthError(8005, 'User not login');
    }

    public static walletNotCreated() {
        return new AuthError(8006, 'Wallet not created');
    }

    public static authorizeError() {
        return new AuthError(8007, 'Authorize error');
    }

    public static network() {
        return new AuthError(8011, 'Network error');
    }

    public static unknown(message: string) {
        return new AuthError(9000, message);
    }
}

export interface RedirectData extends Partial<UserPreview> {
    redirect_type?: string;
    wallets?: Wallet[];
    signature?: string;
    message?: string;
    error?: AuthError;
}

export default function redirectToApp(params: RedirectData = {}, fallback = true) {
    console.log('redirectToApp:params', JSON.stringify(params));
    if (!tokenProvider.params) {
        Sentry.captureException(new Error('routerToApp error'), {
            extra: {
                queryParam: JSON.stringify(tokenProvider.params),
                redirectType: tokenProvider.queryParams.redirectType,
                localStorage: typeof localStorage,
                sessionStorage: typeof sessionStorage,
                device: JSON.stringify(detect()),
            },
        });

        Modal.error({
            content: 'System Error, Param Not Found',
            onOk() {
                window.close();
            },
        });
        return;
    }

    if (params.error) {
        const { message, code } = params.error;
        params = {
            error: {
                code,
                message,
            },
        };
    }

    if (!params.redirect_type) {
        params.redirect_type = tokenProvider.queryParams.redirectType;
    }

    console.log('redirectToApp:redirect_type', params['redirect_type']);

    if (sessionStorage.getItem(PreferenceKey.PN_TEMP_SECURITY_ACCOUNT_CHANGED)) {
        //update wallets and security_account
        if (!params.wallets) {
            params.wallets = wallet.wallets();
        }
        if (!params.security_account && tokenProvider.userInfo.security_account) {
            params.security_account = tokenProvider.userInfo.security_account;
        }
    }

    // Encrypt
    const data = encryptReturnData(params);
    routerToApp(data, fallback, params.error, params.redirect_type);
}

function routerToApp(data: string, fallback: boolean, error?: AuthError, redirectType?: string) {
    let authType;
    if (redirectType === 'login' && !error) {
        const key = `login_auth_type_${tokenProvider.userInfo?.uuid}`;
        authType = load(key);
    }

    if (tokenProvider.params.sdk_version.includes('web')) {
        const state = tokenProvider.queryParams.state;
        // pc or mobile web browser
        const inIframe = window.location !== window.parent.location;
        console.log('routerToApp inIframe', inIframe);
        if (inIframe) {
            console.log('routerToApp postMessage with window.parent');
            window.parent.postMessage(
                {
                    name: 'particle-network-provider',
                    data: data,
                    state,
                    authType,
                },
                '*'
            );
        } else if (window.opener && window.opener === window.opener.parent) {
            //one-click 一步登录：直接点击Social Login 或在Safari中account传值登录
            //从开发者网站直接弹窗打开的auth（登录或签名）
            console.log('one-clickrouterToApp postMessage with window.opener', window.opener);
            window.opener.postMessage(
                {
                    name: 'particle-network-provider',
                    data: data,
                    state,
                    authType,
                },
                '*'
            );
            setTimeout(() => {
                console.log('delay close window');
                window.close();
            }, 10);
        } else {
            // 在iframe中弹窗登录：social login 或 Safari中 email/phone。（用户在Auth(iframe)中弹窗登录，popup弹窗后授权登录再redirect）
            if (window.opener) {
                let crossDomainError = false;
                try {
                    window.opener.location.href;
                } catch (e) {
                    crossDomainError = true;
                }
                if (!crossDomainError && window.opener.location.href.startsWith(process.env.REACT_APP_AUTH_DOMAIN)) {
                    console.log('routerToApp postMessage with window.opener.parent', window.opener.parent);
                    window.opener.parent.postMessage(
                        {
                            name: 'particle-network-provider',
                            data: data,
                            state,
                            authType,
                        },
                        '*'
                    );
                } else {
                    //safari iframe wallet 弹窗签名
                    console.log('routerToApp postMessage with window.opener', window.opener);
                    window.opener.postMessage(
                        {
                            name: 'particle-network-provider',
                            data: data,
                            state,
                            authType,
                        },
                        '*'
                    );
                }
            } else {
                /**
                 * @deprecated twitter v2
                 */
                console.log('routerToApp postMessage with storage event');
                // twitter header : Cross-Origin-Opener-Policy: same-origin-allow-popups
                // can not get opener，send data with storge event
                window.localStorage.setItem('particle-network-social-login', data);
            }
            setTimeout(() => {
                console.log('delay close window');
                window.close();
            }, 10);
        }
    } else if (device.authEmbed()) {
        // mobile device use webview
        if (device.isIOS()) {
            iosOnResult(data);
        } else if (window.particleAuth) {
            window.particleAuth.onResult(data);
        }
    } else {
        // mobile device use browser
        const appId = tokenProvider.params.project_app_uuid;
        window.location.href = `pn${appId}://callback#${data}`;
        if (fallback) {
            setTimeout(() => {
                if (error) {
                    window.location.href = `?data=${encodeURIComponent(JSON.stringify(error))}#/error/redirect`;
                } else {
                    window.location.href = `?data=${data}&project_app_uuid=${appId}#/welcome`;
                }
            }, 3000);
        }
    }
    sessionStorage.removeItem(PreferenceKey.PN_QUERY_PARAMS);
}

function encryptReturnData(params: any): string {
    // Encrypt
    const cipherText = urlCrypto.encryptData(params, tokenProvider.secretKey, 'hex');

    return cipherText;
}

function iosOnResult(data: string) {
    try {
        window.webkit.messageHandlers.onResult.postMessage(data);
    } catch (err) {
        console.log('iosOnResult', err);
    }
}
