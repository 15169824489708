import { Form, Image, Input, Modal } from 'antd';
import { BigNumber } from 'bignumber.js';
import dayjs from 'dayjs';
import numbro from 'numbro';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import evmApi from '../../../api/evm-api';
import {
    EVMDeserializeTransactionResult,
    EVMFunction,
    EVMNFTChange,
    TransactionSmartType,
} from '../../../api/model/rpc-data';
import IconEditPen from '../../../components/icon-edit-pen';
import IconTo from '../../../components/icon-to';
import CircleClose from '../../../components/icon/circle-close';
import SVGIcon from '../../../components/icon/svg-icon';
import tokenProvider from '../../../provider';
import { SignQuery } from '../../../provider/bundle';
import { useAppSelector } from '../../../redux/hooks';
import {
    defaultTokenIcon,
    formatAddress,
    getChainInfo,
    getNativeSymbol,
    getNativeTokenIcon,
} from '../../../utils/chain-utils';
import { isNullish, shortString } from '../../../utils/common-utils';
import { ipfsToSrc } from '../../../utils/index';
import { formatDisplayPrice, formatPrice } from '../../../utils/number-utils';
import { AuthError } from '../../../utils/redirect-utils';
import { isTron } from '../../../utils/transaction-utils';
import { selectTransaction, selectTransactionData } from '../transaction-slice';
import GasDisplay from './gas-display';
import NoGas from './no-gas';

const trimDecimals = (value: string, decimals = 10) => {
    let [integerPart, decimalPart] = value.split('.');
    if (decimalPart && decimalPart.length > decimals) {
        decimalPart = decimalPart.slice(0, decimals + 1).slice(0, -1);
    }
    return `${integerPart}.${decimalPart ?? ''}`.replace(/0+$/, '').replace(/\.$/, '');
};

interface Props {
    displayDetail: boolean;
    gasError: AuthError | undefined;
    signQuery: SignQuery | undefined;
    setGasVis: React.Dispatch<React.SetStateAction<boolean>>;
    formatFunction: (evmFunction: EVMFunction) => string;
    setDisplayDetail: React.Dispatch<React.SetStateAction<boolean>>;
    transactionInfo: EVMDeserializeTransactionResult | undefined;
    changeApproveAmount: string;
    setChangeApproveAmount: (amount: string) => Promise<void>;
    signLoading: boolean;
    signMessage: () => JSX.Element;
}

const NewErcTransfers = (props: Props) => {
    const { t } = useTranslation();
    const {
        transactionInfo,
        setDisplayDetail,
        displayDetail,
        gasError,
        signQuery,
        setGasVis,
        formatFunction,
        changeApproveAmount,
        setChangeApproveAmount,
        signLoading,
    } = props;
    const transaction = useAppSelector(selectTransaction);
    const transactionData = useAppSelector(selectTransactionData);
    const [visibleModal, setVisibleModal] = useState<boolean>(false);
    const [tokenPrice, setTokenPrice] = useState<number | undefined>(undefined);
    const [editApproveAmountForm] = Form.useForm();
    const approveAmount = Form.useWatch('amount', editApproveAmountForm);

    const [changeApproveAmountLoading, setChangeApproveAmountLoading] = useState(false);

    // to address
    const to = useMemo(() => {
        const params = transactionInfo?.data?.function?.params;
        if (transactionInfo?.type == TransactionSmartType.ERC20_TRANSFER) {
            return params ? (params[params.length === 3 ? 1 : 0].value as string) : '';
        } else if (transactionInfo?.type == TransactionSmartType.ERC20_APPROVE) {
            return params ? (params[0].value as string) : '';
        } else if (transactionInfo?.type == TransactionSmartType.NativeTransfer) {
            return transactionInfo.data.to;
        } else if (
            transactionInfo?.type == TransactionSmartType.ERC721_TRANFER ||
            transactionInfo?.type == TransactionSmartType.ERC1155_TRANFER
        ) {
            return params ? (params[1].value as string) : '';
        } else if (
            transactionInfo?.type === TransactionSmartType.SEAPORT_CANCEL_ORDER ||
            transactionInfo?.type === TransactionSmartType.SEAPORT_FULFILL_ORDER
        ) {
            return transactionInfo.data.to;
        } else {
            return '';
        }
    }, [transactionInfo?.data?.function?.params, transactionInfo?.data?.to]);

    const contractAddress = useMemo(() => {
        if (transactionInfo?.estimatedChanges?.tokens?.[0]?.address) {
            return transactionInfo.estimatedChanges.tokens[0].address;
        } else {
            return '';
        }
    }, [transactionInfo?.estimatedChanges]);

    const chainInfo = useMemo(() => {
        const info = getChainInfo(tokenProvider.chain);
        return info!;
    }, [tokenProvider.chain]);

    const {
        tokenIcon = '',
        tokenSymbol = '',
        tokenDecimals = 18,
    } = useMemo(() => {
        let tokenIcon = '';
        let tokenSymbol = '';
        let tokenDecimals = 18;
        if (
            transactionInfo?.type == TransactionSmartType.ERC20_TRANSFER ||
            transactionInfo?.type == TransactionSmartType.ERC20_APPROVE
        ) {
            tokenIcon = transactionInfo?.estimatedChanges?.tokens[0].image;
            tokenSymbol = transactionInfo?.estimatedChanges?.tokens[0].symbol;
            tokenDecimals = transactionInfo?.estimatedChanges?.tokens[0].decimals;
            if (!tokenIcon) {
                tokenIcon = defaultTokenIcon;
            }
        } else if (transactionInfo?.type == TransactionSmartType.NativeTransfer) {
            tokenIcon = getNativeTokenIcon(chainInfo);
            tokenSymbol = chainInfo?.nativeCurrency?.symbol || '';
        }

        return {
            tokenIcon,
            tokenSymbol,
            tokenDecimals,
        };
    }, [transactionInfo?.estimatedChanges?.tokens, chainInfo]);

    // 根据合约地址 获取合约token的price  setTokenPrice(tokenPrice);
    useEffect(() => {
        let addresses: string[] | undefined = undefined;
        if (
            contractAddress &&
            (transactionInfo?.type == TransactionSmartType.ERC20_APPROVE ||
                transactionInfo?.type == TransactionSmartType.ERC20_TRANSFER)
        ) {
            addresses = [contractAddress];
        } else if (transactionInfo?.type == TransactionSmartType.NativeTransfer) {
            addresses = ['native'];
        }

        if (addresses) {
            evmApi
                .getPrice(addresses, [tokenProvider.fiatCoin.toLowerCase()])
                .then((res) => {
                    const tokenPrice =
                        res.find((item) => item.address.toLowerCase() === addresses?.[0].toLowerCase())?.currencies?.[0]
                            ?.price || 0;
                    setTokenPrice(tokenPrice);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [contractAddress]);

    const amountChange = useMemo(() => {
        let value = '';
        const { decimals = 18, amountChange = 0 } = transactionInfo?.estimatedChanges?.tokens?.[0] || {};
        if (transactionInfo?.type == TransactionSmartType.ERC20_APPROVE) {
            const amount = transactionInfo.data?.function?.params?.[1]?.value as string;
            value = new BigNumber(amount).dividedBy(new BigNumber(10).pow(decimals)).toString();
        } else if (transactionInfo?.type == TransactionSmartType.ERC20_TRANSFER) {
            value = new BigNumber(amountChange).dividedBy(new BigNumber(10).pow(decimals)).toString();
        } else if (transactionInfo?.type == TransactionSmartType.NativeTransfer) {
            const { nativeChange } = transactionInfo.estimatedChanges.natives[0];
            value = new BigNumber(nativeChange)
                .dividedBy(new BigNumber(10).pow(chainInfo.nativeCurrency.decimals))
                .toString();
        } else if (
            transactionInfo?.type == TransactionSmartType.ERC721_TRANFER ||
            transactionInfo?.type === TransactionSmartType.SEAPORT_FULFILL_ORDER ||
            transactionInfo?.type === TransactionSmartType.SEAPORT_CANCEL_ORDER ||
            transactionInfo?.type == TransactionSmartType.ERC1155_TRANFER
        ) {
            value = transactionInfo.estimatedChanges?.nfts?.[0]?.amountChange?.toString();
        }
        return value || '';
    }, [transactionInfo?.estimatedChanges?.tokens]);

    const amountChangeUSD = useMemo(() => {
        if (amountChange && tokenPrice) {
            return formatPrice(amountChange, tokenPrice);
        } else {
            return '';
        }
    }, [amountChange, tokenPrice]);

    const formartApproveAmount = (value: BigNumber): string => {
        const amount = value.toString();
        if (amount.length < 10) {
            return amount;
        } else {
            return value.toPrecision(10);
        }
    };

    const formatAmountChange = useMemo(() => {
        let value = changeApproveAmount || amountChange || '0';
        if (transactionInfo?.type === TransactionSmartType.ERC20_APPROVE) {
            value = formartApproveAmount(new BigNumber(value));
        } else {
            value = numbro(value).format({
                thousandSeparated: true,
                trimMantissa: true,
                mantissa: 9,
            });
        }
        return value || '0';
    }, [transactionInfo?.type, amountChange, changeApproveAmount]);

    const amountFontSize = useMemo(() => {
        let fontSize = 22;
        const len = (formatAmountChange?.length || 0) + (tokenSymbol?.length || 0);
        if (len > 26) {
            fontSize = 14;
        } else if (len > 22) {
            fontSize = 16;
        } else if (len > 18) {
            fontSize = 18;
        }
        return `${fontSize}px`;
    }, [formatAmountChange, tokenSymbol]);

    const nftData: EVMNFTChange | undefined = useMemo(() => {
        return transactionInfo?.estimatedChanges?.nfts?.[0] || transactionInfo?.data?.offer?.[0];
    }, [transactionInfo?.estimatedChanges?.nfts, transactionInfo?.data?.offer]);

    const contentTitle: string = useMemo(() => {
        if (transactionInfo?.type === TransactionSmartType.ERC20_APPROVE) {
            return t('new.token_per');
        } else if (transactionInfo?.type === TransactionSmartType.SEAPORT_FULFILL_ORDER) {
            return t('new.buy_nft');
        } else if (transactionInfo?.type === TransactionSmartType.SEAPORT_CANCEL_ORDER) {
            return t('new.cancel_nft_listing');
        } else if (transactionInfo?.type === TransactionSmartType.SEAPORT_NFT_LISTING) {
            return t('new.nft_listing');
        } else {
            return t('new.confirmation');
        }
    }, [transactionInfo?.type]);

    const hexData = useMemo(() => {
        return transactionInfo?.data?.data || '';
    }, [transactionInfo?.data?.data]);

    /**
     * toTag
     */
    const contractTag = useMemo(() => {
        if (
            (transactionInfo?.type === TransactionSmartType.SEAPORT_FULFILL_ORDER ||
                transactionInfo?.type === TransactionSmartType.SEAPORT_CANCEL_ORDER) &&
            transactionInfo?.toTag
        ) {
            return ` (${transactionInfo?.toTag})`;
        } else {
            return '';
        }
    }, [transactionInfo?.type]);

    const nftName = useMemo(() => {
        if (nftData) {
            if (nftData.name && nftData.name.length > 0) {
                return nftData.name;
            } else {
                return `NFT#${nftData.tokenId}`;
            }
        }
        return 'NFT';
    }, [nftData]);

    /**
     * 交易nonce
     */
    const nonce = useMemo(() => {
        if (transactionInfo?.data?.nonce && !isTron(tokenProvider.chain)) {
            return `#${parseInt(transactionInfo?.data?.nonce as string)}`;
        } else {
            return '';
        }
    }, [tokenProvider.chain, transactionInfo?.data?.nonce]);

    /**
     * NFT 类型
     */
    const nftType = useMemo((): 'ERC1155' | 'ERC721' | '' => {
        return transactionInfo?.estimatedChanges?.nfts?.[0]?.isSemiFungible === true
            ? 'ERC1155'
            : transactionInfo?.estimatedChanges?.nfts?.[0]?.isSemiFungible === false
            ? 'ERC721'
            : '';
    }, [transactionInfo?.type, nftData?.isSemiFungible]);

    /**
     * nft 上架 数量
     */
    const NFTListingQuantity = useMemo(() => {
        return nftData?.amount || '';
    }, [nftData]);

    /**
     * nft 价格
     */
    const nftPrice = useMemo(() => {
        const { symbol, decimals, amount } = transactionInfo?.price || {};
        if (transactionInfo?.type === TransactionSmartType.SEAPORT_FULFILL_ORDER && symbol && decimals && amount) {
            const price = new BigNumber(amount).dividedBy(new BigNumber(10).pow(decimals));
            return `${trimDecimals(price.toString(10))} ${symbol}`;
        } else {
            return '';
        }
    }, [transactionInfo?.type, transactionInfo?.price]);

    const NFTListingData = useMemo(() => {
        if (transactionInfo?.type === TransactionSmartType.SEAPORT_NFT_LISTING) {
            const seller = `${shortString(formatAddress(transactionInfo?.data?.offerer || ''))}(${t('new.you')})`;
            const symbol = transactionInfo?.data?.consideration?.[0]?.symbol || '';
            let price = '';
            let sellerEarnings = '';
            let others = '';
            const selfAddress = transactionInfo?.data?.offerer || '';
            const startMS = Number(transactionInfo?.data?.startTime || 0) * 1000;
            const startTime = dayjs(new Date(startMS)).format('MM/DD/YYYY hh:mm A');
            let endTime = '';
            if (transactionInfo?.data?.endTime && transactionInfo?.data?.endTime?.length > 10) {
                // 无限
                endTime = t('new.infinite');
            } else {
                const endMS = Number(transactionInfo?.data?.endTime || 0) * 1000;
                endTime = dayjs(new Date(endMS)).format('MM/DD/YYYY hh:mm A');
            }
            if (transactionInfo?.data?.consideration) {
                const list = transactionInfo?.data?.consideration;
                let result = new BigNumber(0);
                for (let i = 0; i < list.length; i++) {
                    const item = list[i];
                    result = result.plus(new BigNumber(item.amount));
                    const amount = `${trimDecimals(
                        new BigNumber(item.amount)
                            .dividedBy(new BigNumber(10).pow(list?.[0]?.decimals ?? 18))
                            .toString(10)
                    )} ${symbol}`;
                    if (item.recipient.toLowerCase() === selfAddress.toLowerCase()) {
                        sellerEarnings = amount;
                    } else {
                        others = amount;
                    }
                }
                result = result.dividedBy(new BigNumber(10).pow(list?.[0]?.decimals ?? 18));
                price = `${trimDecimals(result.toString(10))} ${symbol}`;
            }
            return {
                seller,
                price,
                sellerEarnings,
                others,
                startTime,
                endTime,
            };
        } else {
            return {};
        }
    }, [transactionInfo]);

    return (
        <div className="transfer-content">
            <pre
                data-key="transactionInfo"
                style={{
                    display: 'none',
                }}
            >
                {JSON.stringify(transactionInfo, null, 2)}
            </pre>
            <pre
                data-key="transaction"
                style={{
                    display: 'none',
                }}
            >
                {JSON.stringify(transaction, null, 2)}
            </pre>
            <pre
                data-key="nftData"
                style={{
                    display: 'none',
                }}
            >
                {JSON.stringify(nftData, null, 2)}
            </pre>
            {transactionInfo && (
                <div className="less-box">
                    <div className="groups">
                        <div className="item item1">
                            <div className="flex-sp-row row1">
                                <div className="left">{contentTitle}</div>
                                {nonce && <div className="right">{nonce}</div>}
                            </div>
                            {/* ERC20_TRANSFER or NativeTransfer*/}
                            {(transactionInfo?.type === TransactionSmartType.ERC20_TRANSFER ||
                                transactionInfo?.type === TransactionSmartType.NativeTransfer) && (
                                <div
                                    className="flex-sp-row row2"
                                    data-feed-line={`${formatAmountChange}`.length > 13 ? true : false}
                                >
                                    <div className="left">
                                        <div className="icon">
                                            {!!tokenIcon && (
                                                <Image
                                                    className="icon"
                                                    src={tokenIcon}
                                                    fallback={defaultTokenIcon}
                                                    preview={false}
                                                />
                                            )}
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="amount">
                                            <div
                                                className="change-val"
                                                style={{
                                                    color: formatAmountChange?.includes?.('-')
                                                        ? '#ea4335'
                                                        : 'var(--text-color)',
                                                    fontSize: amountFontSize,
                                                }}
                                            >
                                                {formatAmountChange}
                                            </div>
                                            <div
                                                className="symbol"
                                                style={{
                                                    fontSize: amountFontSize,
                                                }}
                                            >
                                                {tokenSymbol}
                                            </div>
                                        </div>
                                        <div className="amount-usd">{amountChangeUSD}</div>
                                    </div>
                                </div>
                            )}
                            {/* ERC20_APPROVE */}
                            {transactionInfo?.type === TransactionSmartType.ERC20_APPROVE && (
                                <div className="row-erc20-approve-content">
                                    <div className="flex-sp-row row-ac1">
                                        <img className="token-icon" src={tokenIcon} alt="" />
                                        <div className="amount">
                                            {formatAmountChange}&nbsp;{tokenSymbol}
                                        </div>
                                    </div>
                                    <div
                                        className="flex-sp-row row-ac2"
                                        onClick={() => {
                                            const amount = new BigNumber(changeApproveAmount || amountChange);
                                            editApproveAmountForm.setFieldsValue({
                                                amount: formartApproveAmount(amount),
                                            });
                                            setVisibleModal(true);
                                        }}
                                    >
                                        <div>{t('new.edit_approve')}</div>
                                        <div className="icon">
                                            <IconEditPen />
                                        </div>
                                    </div>
                                    <div className="flex-sp-row row-ac3">{t('new.by_granting')}</div>
                                </div>
                            )}
                            {/* ERC1155_TRANFER || ERC721_TRANFER || SEAPORT_FULFILL_ORDER || SEAPORT_CANCEL_ORDER*/}
                            {(transactionInfo?.type === TransactionSmartType.ERC1155_TRANFER ||
                                transactionInfo?.type === TransactionSmartType.SEAPORT_FULFILL_ORDER ||
                                transactionInfo?.type === TransactionSmartType.SEAPORT_CANCEL_ORDER ||
                                transactionInfo?.type === TransactionSmartType.SEAPORT_NFT_LISTING ||
                                transactionInfo?.type === TransactionSmartType.ERC721_TRANFER) && (
                                <>
                                    <div className="flex-sp-row row3">
                                        <div className="left">
                                            <Image
                                                width={32}
                                                height={32}
                                                src={ipfsToSrc(nftData?.image || '')}
                                                fallback={require('@/common/images/default_nft.png')}
                                            />
                                        </div>
                                        <div className="right">
                                            <div className="name">{nftName}</div>
                                        </div>
                                    </div>
                                    <div className="flex-sp-row row4">{nftType}</div>
                                    {transactionInfo?.type === TransactionSmartType.SEAPORT_NFT_LISTING &&
                                        NFTListingQuantity && (
                                            <div className="flex-sp-row row5">
                                                <div className="left">{t('new.amount')}</div>
                                                <div className="right">{NFTListingQuantity}</div>
                                            </div>
                                        )}
                                    {nftPrice && (
                                        <div className="flex-sp-row row6">
                                            <div className="left">{t('new.nft_price')}</div>
                                            <div className="right">{nftPrice}</div>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        {/* ERC1155_TRANFER */}
                        {transactionInfo?.type === TransactionSmartType.ERC1155_TRANFER && (
                            <div className="item item2-0">
                                <div className="flex-sp-row">
                                    <div className="left">{t('new.amount')}</div>
                                    <div className="right">
                                        {isNullish(nftData?.amountChange)
                                            ? t('program.unknown')
                                            : Math.abs(nftData?.amountChange || 1)}
                                    </div>
                                </div>
                            </div>
                        )}

                        {transactionInfo?.type !== TransactionSmartType.SEAPORT_NFT_LISTING && (
                            <div className="item item2">
                                <div className="flex-sp-row">
                                    <div className="left">
                                        <div className="pn-row pn-row1">
                                            {t('new.from')} ({t('new.you')})
                                        </div>
                                        <div className="pn-row pn-row2">
                                            {shortString(formatAddress(transactionInfo.data.from))}
                                        </div>
                                    </div>
                                    <div className="middle">
                                        <div className="icon">
                                            <IconTo />
                                        </div>
                                    </div>
                                    <div className="right">
                                        <div className="pn-row pn-row1">
                                            {t('new.to')}
                                            {contractTag}
                                        </div>
                                        {/* <div className="pn-row pn-row2">{shortString(transactionInfo.data.to)}</div> */}
                                        <div className="pn-row pn-row2">{shortString(formatAddress(to))}</div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!isTron(chainInfo) && transactionInfo?.type !== TransactionSmartType.SEAPORT_NFT_LISTING && (
                            <div className="item item4">
                                <div className="flex-sp-row">
                                    <div className="left">{t('new.network_fee')}</div>
                                    {gasError ? (
                                        <div className="gas-warning">
                                            <img alt="" src={require('../../../common/images/no_warning.png')} />
                                            <span>{t('sign.gas_fee_cannot')}</span>
                                        </div>
                                    ) : (
                                        <div className="right">
                                            <div className="pn-row pn-row1">
                                                {transaction?.gasFeeDisplay?.maxFee}{' '}
                                                {getNativeSymbol(tokenProvider.chain)}
                                            </div>
                                            <div className="pn-row pn-row2">
                                                {formatDisplayPrice(
                                                    transaction?.gasFeeDisplay?.maxFee || '',
                                                    transaction?.prices?.[0]?.currencies?.[0]?.price || 0,
                                                    4
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {transactionInfo?.type === TransactionSmartType.SEAPORT_NFT_LISTING && (
                            <div className="item item5">
                                <div className="flex-sp-row">
                                    <div className="left">{t('new.seller')}</div>
                                    <div className="right">{NFTListingData.seller}</div>
                                </div>
                                <div className="flex-sp-row">
                                    <div className="left">{t('new.price')}</div>
                                    <div className="right">{NFTListingData.price}</div>
                                </div>
                                <div className="flex-sp-row price-sub">
                                    <div className="left">{t('new.seller_earnings')}</div>
                                    <div className="right">{NFTListingData.sellerEarnings}</div>
                                </div>
                                <div className="flex-sp-row price-sub">
                                    <div className="left">{t('new.others')}</div>
                                    <div className="right">{NFTListingData.others}</div>
                                </div>
                                <div className="flex-sp-row">
                                    <div className="left">{t('new.start_time')}</div>
                                    <div className="right">{NFTListingData.startTime}</div>
                                </div>
                                <div className="flex-sp-row">
                                    <div className="left">{t('new.end_time')}</div>
                                    <div className="right">{NFTListingData.endTime}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div className="fold-content">
                <div
                    className="wrap"
                    onClick={() => {
                        setDisplayDetail(!displayDetail);
                    }}
                >
                    {displayDetail ? (
                        <div className="fold" key="less">
                            <span>{t('new.less')}</span>
                            <SVGIcon className="arrow-icon" name="arrow_icon" />
                        </div>
                    ) : (
                        <div className="unfold" key="more">
                            <span>{t('new.details')}</span>
                            <SVGIcon className="arrow-icon" name="arrow_icon" />
                        </div>
                    )}
                </div>
            </div>
            <div
                className="details-box"
                style={{
                    display: displayDetail ? '' : 'none',
                }}
            >
                {!gasError && !isTron(chainInfo) && transactionData && signQuery && (
                    <GasDisplay
                        transactionData={transactionData}
                        openGasDrawer={() => setGasVis(true)}
                        signLoading={signLoading}
                    />
                )}
                {gasError && !isTron(chainInfo) && <NoGas />}
                <div className="mg-bottom-15"></div>
                <div>
                    {/* function */}
                    {transactionInfo && transactionInfo.data.function && (
                        <div className="inner-instruction" key={'instruction-function'}>
                            <div className="inner-content">
                                <div className="content-item">
                                    <div className="item">
                                        <div className="item-0">
                                            {t('sign.function_type')} {formatFunction(transactionInfo.data.function)}
                                        </div>

                                        {transactionInfo.data.function.params.map((item) => (
                                            <div className="item-1 mt10" key={`instruction-function${item.name}`}>
                                                {shortString(item.name)}
                                                <span>{shortString(item.value)}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* hex data */}
                    {hexData && (
                        <div className="inner-instruction" key={'instruction-hex-data'}>
                            <div className="inner-content">
                                <div className="content-item">
                                    <div className="item">
                                        <div className="item-0">{t('sign.hex_data')}</div>
                                        <div className="item-1 mt10">
                                            <div className="data">{hexData}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {/* signMessage */}
                    {transactionInfo?.type === TransactionSmartType.SEAPORT_NFT_LISTING &&
                        !!props.signMessage &&
                        props.signMessage()}
                </div>
            </div>
            <Modal
                title={t('new.edit_approve')}
                visible={visibleModal}
                centered={true}
                wrapClassName="edit-approve-amount-modal"
                okText={t('common.confirm')}
                confirmLoading={changeApproveAmountLoading}
                closeIcon={<CircleClose />}
                onOk={() => {
                    setChangeApproveAmountLoading(true);
                    editApproveAmountForm
                        .validateFields()
                        .then((values) => {
                            const amount = values?.amount?.replace?.(/\.+$/g, '') || '';
                            if (amount) {
                                return setChangeApproveAmount(amount);
                            }
                        })
                        .then(() => {
                            setChangeApproveAmountLoading(false);
                            setVisibleModal(false);
                        })
                        .catch((error) => {
                            setChangeApproveAmountLoading(false);
                            console.log(error);
                        });
                }}
                onCancel={() => {
                    setChangeApproveAmountLoading(false);
                    setVisibleModal(false);
                }}
            >
                <Form form={editApproveAmountForm} autoComplete="off">
                    <Form.Item
                        label=""
                        name="amount"
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (
                                        isNaN((value || '').replace(/\.$/, '')) ||
                                        !isFinite((value || '').replace(/\.$/, ''))
                                    ) {
                                        return Promise.reject(t('new.please_enter'));
                                    }
                                    if (!value || (value && new BigNumber(value).lte(new BigNumber(0)))) {
                                        return Promise.reject(t('new.the_amount'));
                                    }
                                    const amount = value?.replace?.(/\.+$/g, '') || '';
                                    const bn = new BigNumber(amount);
                                    if (bn.decimalPlaces() > tokenDecimals) {
                                        return Promise.reject(t('new.please_enter'));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <Input placeholder={t('new.please_enter')} />
                    </Form.Item>
                    <div className="icon">
                        <img src={tokenIcon} alt="" />
                    </div>
                    <div className="symbol">{tokenSymbol}</div>
                </Form>
            </Modal>
        </div>
    );
};

export default NewErcTransfers;
