// import './index.less';
import Icon from '@ant-design/icons';
import svg from '../../common/icon/copy.svg';

const IconCopy = ({ className = '', ...others }) => {
    return (
        <Icon
            className={className}
            //@ts-ignore
            component={svg}
            {...others}
        />
    );
};

export default IconCopy;
