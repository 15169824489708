import base64 from 'crypto-js/enc-base64';
import utf8 from 'crypto-js/enc-utf8';
import md5 from 'crypto-js/md5';
import tokenProvider from '../provider';
import device from '../utils/detect-device';

const storage: Storage = device.authEmbed() ? sessionStorage : localStorage;

export function save(key: string, value: string | null) {
    if (value) {
        storage.setItem(keyJoint(key), base64.stringify(utf8.parse(value)));
    } else {
        removeItem(key);
    }
}

export function load(key: string): string | null {
    const base64String = storage.getItem(keyJoint(key));
    if (base64String == null) {
        return null;
    }
    const wordArray = base64.parse(base64String);
    return utf8.stringify(wordArray);
}

export function once(key: string): boolean {
    const value = storage.getItem(key);

    if (!value) {
        storage.setItem(key, '1');
    }
    return Boolean(value);
}

export function removeItem(key: string) {
    storage.removeItem(keyJoint(key));
}

function keyJoint(key: string): string {
    const projectAppUuid = tokenProvider.params?.project_app_uuid;
    return md5(`${projectAppUuid}_${key}`).toString().toLowerCase();
}

export class PreferenceKey {
    static PN_USER_INFO = 'PN_USER_INFO';
    static PN_OPEN_SET_PAYMENT_PASSWORD = 'PN_OPEN_SET_PAYMENT_PASSWORD';
    static PN_LOCAL_KEY = 'PN_LOCAL_KEY';
    static PN_OPEN_SET_MASTER_PASSWORD = 'PN_OPEN_SET_MASTER_PASSWORD';
    static PN_QUERY_PARAMS = 'PN_QUERY_PARAMS';

    static PN_TEMP_SOCIAL_STATE_KEY = 'PN_TEMP_SOCIAL_STATE_KEY';
    static PN_TEMP_SECURITY_ACCOUNT = 'PN_TEMP_SECURITY_ACCOUNT';
    static PN_TEMP_SECURITY_ACCOUNT_CHANGED = 'PN_TEMP_SECURITY_ACCOUNT_CHANGED';
    static PN_TEMP_AUTHORIZE_RESULT = 'PN_TEMP_AUTHORIZE_RESULT';
}
