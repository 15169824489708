import { RightOutlined } from '@ant-design/icons';
import { useInterval } from 'ahooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TokenPrice } from '../../../api/model/rpc-data';
import SVGIcon from '../../../components/icon/svg-icon';
import tokenProvider from '../../../provider';
import { EvmSignMethod, SignQuery } from '../../../provider/bundle';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { EVMTransaction } from '../../../types/tx';
import { getNativeSymbol } from '../../../utils/chain-utils';
import { formatPrice } from '../../../utils/number-utils';
import { GasFeeMode, fetchGasAsync, selectTransaction } from '../transaction-slice';
import './info-sign.less';

type IProps = {
    transactionData: EVMTransaction;
    openGasDrawer: () => void;
    signLoading: boolean;
};

function GasDisplay(props: IProps) {
    const { transactionData, openGasDrawer, signLoading } = props;

    const { t } = useTranslation();

    const transaction = useAppSelector(selectTransaction);

    const dispatch = useAppDispatch();

    const [interval, setInterval] = useState<number | undefined>(undefined);

    const [countdown, setCountdown] = useState(10);

    const signQuery = tokenProvider.params as SignQuery;

    useInterval(() => {
        const result = countdown - 1;
        if (result <= 0) {
            setCountdown(10);
            loadGasConfig();
        } else {
            setCountdown(result);
        }
    }, interval);

    useEffect(() => {
        if (signLoading) {
            setInterval(undefined);
            setCountdown(10);
        } else {
            setInterval(1000);
            loadGasConfig();
        }
        return () => {
            setInterval(undefined);
            setCountdown(10);
        };
    }, [signLoading]);

    const loadGasConfig = () => {
        dispatch(
            fetchGasAsync({
                addresses: ['native'],
                from: transactionData.from,
                to: transactionData.to,
                value: transactionData.value,
                data: transactionData.data,
            })
        );
    };

    const editGas = () => {
        if (!transaction.gasFee) {
            return;
        }
        openGasDrawer();
    };

    const estimatedTime = (data?: EVMTransaction): string => {
        if (!data || !transaction.gasFee || !transaction.gasFeeMode) {
            return '';
        }

        let time: number;
        if (transaction.gasFeeMode == GasFeeMode.low) {
            time = transaction.gasFee.low.maxWaitTime;
        } else if (transaction.gasFeeMode == GasFeeMode.medium) {
            time = transaction.gasFee.medium.maxWaitTime;
        } else if (transaction.gasFeeMode == GasFeeMode.high) {
            time = transaction.gasFee.high.maxWaitTime;
        } else {
            time = transaction.gasFee.low.maxWaitTime;
        }
        let displayTime: string;
        if (time / 1000 >= 60) {
            displayTime = `>${time / 1000 / 60}min`;
        } else {
            displayTime = `<${time / 1000}s`;
        }

        return displayTime;
    };

    const formatDisplayPrice = (fee: string, prices: TokenPrice[]): string => {
        return prices.length > 0 && prices[0].currencies && prices[0].currencies.length > 0
            ? formatPrice(fee, prices[0].currencies[0].price)
            : '';
    };

    return (
        <div>
            <div className="net-fee" onClick={editGas}>
                <div className="evm-fee">
                    <div className="fee-title">
                        {t('sign.evm_fee')}
                        {transaction.gasFeeDisplay && interval && (
                            <span>
                                {' '}
                                {t('sign.refresh_in')} {countdown}s
                            </span>
                        )}
                    </div>

                    {transaction.gasFeeDisplay && transaction.prices && (
                        <div className="fee-row">
                            <div>
                                {transaction.gasFeeDisplay.fee && (
                                    <div>
                                        {transaction.gasFeeDisplay.fee} {getNativeSymbol(tokenProvider.chain)}
                                        <span className="approximately">
                                            {' '}
                                            {formatDisplayPrice(transaction.gasFeeDisplay.fee, transaction.prices)}
                                        </span>
                                    </div>
                                )}
                                {t('sign.max_fee')}
                                {transaction.gasFeeDisplay.maxFee} {getNativeSymbol(tokenProvider.chain)}
                                <span className="approximately">
                                    {' '}
                                    {formatDisplayPrice(transaction.gasFeeDisplay.maxFee, transaction.prices)}
                                </span>
                            </div>
                            <div className="fee-right">
                                <div>
                                    {transaction.gasFeeDisplay && (
                                        <div className="fee-standard">{t(`sign.gas_${transaction.gasFeeMode}`)}</div>
                                    )}
                                    <div
                                        className="fee-time"
                                        style={
                                            estimatedTime(transaction.data).search('min') !== -1
                                                ? { color: '#F55F0D' }
                                                : {}
                                        }
                                    >
                                        {estimatedTime(transaction.data)}
                                    </div>
                                </div>
                                <div>
                                    <SVGIcon className="arrow-right-icon" name="arrow_right_icon" />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {signQuery?.method && signQuery?.method !== EvmSignMethod.eth_sendTransaction && (
                <div className="total">
                    <div className="total-title">{t('sign.total')}</div>
                    {transaction.totalAmountDisplay && transaction.prices && (
                        <div className="total-content mt8">
                            {transaction.totalAmountDisplay.amount && (
                                <div>
                                    {transaction.totalAmountDisplay.amount} {getNativeSymbol(tokenProvider.chain)}
                                    <span>
                                        {' '}
                                        {formatDisplayPrice(transaction.totalAmountDisplay.amount, transaction.prices)}
                                    </span>
                                </div>
                            )}
                            <div>
                                {t('sign.max_amount')}: {transaction.totalAmountDisplay.maxAmount}{' '}
                                {getNativeSymbol(tokenProvider.chain)}
                                <span>
                                    {' '}
                                    {formatDisplayPrice(transaction.totalAmountDisplay.maxAmount, transaction.prices)}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

export default GasDisplay;
