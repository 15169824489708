import './index.less';
import { detect } from 'detect-browser';
import { getVersion } from '../../utils/common-utils';

function DeviceInfo() {
    const language = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
    const browser = detect() ?? {
        name: '',
        version: '',
        os: '',
        type: '',
    };
    return (
        <div>
            <div>Version: {getVersion()}</div>
            <div>Platform: {navigator.platform}</div>
            <div>UserAgent: {navigator.userAgent}</div>
            <div>Detect Name: {browser.name}</div>
            <div>Detect Version: {browser.version}</div>
            <div>Detect OS: {browser.os}</div>
            <div>Detect Type: {browser.type}</div>
            <div>navigator language: {language}</div>
            <div className="content-item" />
        </div>
    );
}

export default DeviceInfo;
