import { useInterval } from 'ahooks';
import { Button, Space, Typography } from 'antd';
import { Suspense, lazy, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PowerFooter from '../../../components/power-footer';
import { useParticleAuth } from '../../../context';
import './index.less';

const Lottie = lazy(() => import('lottie-react'));
const rightData = require('../../../common/lottie/right.json');

const SetMasterPasswordSuccess = (props: any) => {
    const { loginSetMasterPassword } = props;
    const { loginSuccessRedirectToApp } = useParticleAuth();
    const { Text } = Typography;
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [interval, setInterval] = useState<number | undefined>(1000);

    const [countdown, setCountdown] = useState(3);

    useInterval(() => {
        if (countdown > 0) {
            const result = countdown - 1;
            setCountdown(result);
            if (result === 0) {
                setInterval(undefined);
                backOnClick();
            }
        }
    }, interval);

    const backOnClick = () => {
        if (loginSetMasterPassword) {
            loginSuccessRedirectToApp();
        } else {
            navigate('/account/security', { replace: true });
        }
    };

    return (
        <div className="set-mp-success-container">
            <Suspense fallback={<></>}>
                <Lottie className="set-mp-success-icon" animationData={rightData} loop={false} />
            </Suspense>
            <div className="set-mp-success">{t('account.setting_successed')}</div>
            <Space className="set-mp-success-tip" direction="vertical">
                <Text>{t('account.master_password_successed')}</Text>
                <Text strong>{t('account.master_password_store_hint')}</Text>
            </Space>

            <Button className="primary-antd-btn back-btn" onClick={backOnClick}>
                {t('login.back') + ` (${countdown})`}
            </Button>
            <PowerFooter />
        </div>
    );
};

export default SetMasterPasswordSuccess;
