const device = {
    isAndroidBrowser(): boolean {
        const ua = navigator.userAgent;

        //detect android webview
        const pattern = new RegExp(
            '^Mozilla/[.0-9]* \\(Linux; Android [.0-9]*;[\\w\\W]*wv\\)[\\w\\W]*Version/[.0-9]* Chrome/[.0-9]*'
        );
        return !pattern.test(ua) || ua.toLowerCase().includes('vivobrowser');
    },

    isIOSBrowser(): boolean {
        const ua = navigator.userAgent;

        //detect ios safari
        const pattern = new RegExp('^Mozilla/[.0-9]* \\((iPhone|Macintosh|iPad)[\\w\\W]* Safari/[.\\w]*$');
        return pattern.test(ua);
    },

    authEmbed(): boolean {
        const ua = navigator.userAgent;
        return ua.includes('Particle/auth_embed');
    },

    isIOS(): boolean {
        const ua = navigator.userAgent;
        const platform = navigator.platform;
        const pattern = new RegExp(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

        return (platform.slice(0, 2) === 'iP' || platform.slice(0, 3) === 'Mac') && pattern.test(ua);
    },

    isSafariOrIOS(): boolean {
        const ua = navigator.userAgent;
        return (
            /Version\/([0-9\\._]+).*Mobile.*Safari.*/.test(ua) ||
            /Version\/([0-9\\._]+).*Safari/.test(ua) ||
            /iP(hone|od|ad)/.test(ua)
        );
    },

    isBraveBrowser(): boolean {
        return typeof window !== 'undefined' && (navigator as any).brave;
    },

    isFireFox(): boolean {
        return typeof window !== 'undefined' && /Firefox\/([0-9.]+)(?:\s|$)/.test(navigator.userAgent);
    },

    isChrome(): boolean {
        return (
            typeof window !== 'undefined' &&
            /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\\.]+)(:?\s|$)/.test(navigator.userAgent)
        );
    },

    isBlockingThirdpartyCookiesBrowser(chromeExclude?: boolean): boolean {
        return (
            this.isSafariOrIOS() ||
            this.isBraveBrowser() ||
            this.isFireFox() ||
            (this.isChrome() && !this.isBraveBrowser() && !chromeExclude)
        );
    },
};

export default device;
