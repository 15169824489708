import { startAuthentication, startRegistration } from '@simplewebauthn/browser';
import type {
    PublicKeyCredentialCreationOptionsJSON,
    PublicKeyCredentialRequestOptionsJSON,
} from '@simplewebauthn/typescript-types';
import Base64url from 'crypto-js/enc-base64url';
import Utf8 from 'crypto-js/enc-utf8';
import SHA256 from 'crypto-js/sha256';
import tokenProvider from '../provider';

export const passkeysRegistration = async ({
    uuid,
    account,
    projectUuid,
}: {
    uuid: string;
    account: string;
    projectUuid: string;
}) => {
    const expiration = Math.floor(new Date().getTime() / 1000 + 300); // 有效期  300s
    const challenge = Base64url.stringify(
        Utf8.parse(`${SHA256(`${projectUuid}:${expiration}`).toString()}:${expiration}`)
    );
    const options: PublicKeyCredentialCreationOptionsJSON = {
        challenge,
        rp: { name: 'Particle Network', id: 'particle.network' },
        user: {
            id: uuid,
            name: account,
            displayName: account,
        },
        pubKeyCredParams: [
            { alg: -8, type: 'public-key' },
            { alg: -7, type: 'public-key' },
            { alg: -257, type: 'public-key' },
        ],
        timeout: 60000,
        attestation: 'none',
        excludeCredentials: [],
        authenticatorSelection: {
            residentKey: 'preferred',
            userVerification: 'required',
            requireResidentKey: false,
        },
        extensions: { credProps: true },
    };

    const response = await startRegistration(options);
    return {
        challenge,
        response,
    };
};

export const passkeysAuthentication = async (startConditionalUI = false) => {
    const projectUuid = tokenProvider.params.project_uuid;
    const expiration = Math.floor(new Date().getTime() / 1000 + 300); // 有效期  300s
    const challenge = Base64url.stringify(
        Utf8.parse(`${SHA256(`${projectUuid}:${expiration}`).toString()}:${expiration}`)
    );
    const options: PublicKeyCredentialRequestOptionsJSON = {
        challenge,
        allowCredentials: undefined,
        timeout: 60000,
        userVerification: 'required',
        extensions: undefined,
        rpId: 'particle.network',
    };
    const response = await startAuthentication(options, startConditionalUI);
    return {
        challenge,
        response,
    };
};

export const isSDKSupportPassKeys = (): boolean => {
    // const version = tokenProvider.params.sdk_version;
    // if (version.indexOf('web_') === 0) {
    //     const parts = version.split('_')[1].split('.');
    //     const versionCode =
    //         Number(parts[0]) * 10_000 +
    //         Number(parts[1]) * 100 +
    //         Number(parts[2].includes('-') ? parts[2].split('-')[0] : parts[2]) * 1;
    //     // 1.0.0以上支持：https://git.minijoy.work/frontend/particle-monorepo/commit/0188482e3481d0110081945f46e52b4dc32852ca
    //     // iframe 添加了publickey-credentials-get权限
    //     return versionCode > 10000;
    // }
    // return true;
    return false;
};
