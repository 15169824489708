import { urlCrypto } from '@particle-network/crypto';
import { AuthType } from '../api/model/login-type';
import wallet from '../api/wallet';
import tokenProvider from '../provider';
import { QueryParamKey } from '../provider/bundle';
import { EvmSignMethod, SignQuery, SolanaSignMethod } from './../provider/bundle';

const url = {
    getQueryVariable(variable: string): string | null {
        const searchParams = new URLSearchParams(window.location.search);
        return searchParams.get(variable);
    },

    buildWalletUrl(signQuery: SignQuery, displayWallet = false): string {
        displayWallet = this.getQueryVariable(QueryParamKey.DISPLAY_WALLET) === 'true' || displayWallet;

        if (
            displayWallet &&
            (signQuery.method === EvmSignMethod.eth_sendTransaction ||
                signQuery.method === SolanaSignMethod.signAndSendTransaction)
        ) {
            const { project_app_uuid, project_client_key, project_uuid } = signQuery;
            const params = tokenProvider.userPreview;
            const userInfo = params as any;
            userInfo.wallets = wallet.wallets();

            const openWalletData = {
                projectId: project_uuid,
                clientKey: project_client_key,
                appId: project_app_uuid,
                chainName: tokenProvider.chain.name,
                chainId: tokenProvider.chain.id,
                authUserInfo: userInfo,
            };
            const ciphertext = urlCrypto.encryptUrlParam(openWalletData);
            let url = `${process.env.REACT_APP_WEB_WALLET_URL}/?params=${ciphertext}&theme=${tokenProvider.themeType}`;
            const languageCode = tokenProvider.launage;
            if (languageCode) {
                url += `&language=${languageCode}`;
            }

            (openWalletData as any).walletUrl = url;
            // @ts-ignore
            window.openWalletData = openWalletData;
            return url;
        } else {
            return '';
        }
    },

    buildLoginUrl({ authType, account }: { authType: AuthType; account?: string }): string {
        const schema = process.env.REACT_APP_AUTH_DOMAIN as string;

        const params = {
            ...tokenProvider.params,
            login_type: authType,
            account: account,
        };
        const secretKey = tokenProvider.secretKey;

        const cipherText = urlCrypto.encryptData(params, secretKey);

        const value = cipherText + secretKey;
        let query = `?params=${value}`;

        const searchParams = new URLSearchParams(
            location.href.replace(location.hash, '').replace(location.origin + '/?', '')
        );
        searchParams.forEach((v, k) => {
            if (k !== 'params') {
                query += `&${k}=${encodeURIComponent(v)}`;
            }
        });

        const url = `${schema}${query}#/login`;
        return url;
    },
};
export default url;
