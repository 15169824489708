import { useTranslation } from 'react-i18next';
import tokenProvider from '../../../provider';
import { useAppSelector } from '../../../redux/hooks';
import { getNativeSymbol } from '../../../utils/chain-utils';
import { selectTotalAmount } from '../transaction-slice';
import './info-sign.less';

function NoGas() {
    const { t } = useTranslation();

    const totalAmount = useAppSelector(selectTotalAmount);

    return (
        <div className="no-gas-fee">
            <div className="no-title">{t('sign.network_fee')}</div>
            <div className="no-warning">
                <img alt="" src={require('../../../common/images/no_warning.png')} />
                <span>{t('sign.gas_fee_cannot')}</span>
            </div>
            {totalAmount && (
                <>
                    <div className="data-title">{t('sign.total')}</div>
                    {totalAmount.amount && (
                        <div className="data-item">
                            {totalAmount.amount} {getNativeSymbol(tokenProvider.chain)}
                        </div>
                    )}
                    <div className="data-item">
                        {t('sign.max_amount')}:{' '}
                        <span>
                            {totalAmount.maxAmount} {getNativeSymbol(tokenProvider.chain)}
                        </span>
                    </div>
                </>
            )}
        </div>
    );
}

export default NoGas;
