import { Drawer } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';
import CircleClose from '../../../../components/icon/circle-close';
import { isMobile } from '../../../../utils';
import './index.less';
interface IProps {
    children?: React.ReactNode;
    onClose: () => void;
    visible: boolean;
    title?: string;
    [key: string]: any;
}
const ParticleDrawer = (props: IProps) => {
    const { title, children, onClose, visible, ...rest } = props;
    const drawerContentRef = useRef<HTMLDivElement>(null);
    const [mobile, setMobile] = useState<boolean>(isMobile());

    const dropDownRef = useRef({
        isDown: false,
        moveY: 0,
    });

    const className = useMemo(() => {
        let classNames = [props.className, `particle-${mobile ? 'mobile' : 'pc'}-drawer`, 'particle-drawer-container'];
        return classNames.join(' ');
    }, [props.className, mobile]);

    const isScroll = (target: HTMLElement) => {
        let parentElement: HTMLElement | null = target;
        let flag = false;
        while (parentElement) {
            parentElement = parentElement.parentElement;
            if (parentElement?.classList.contains('ant-drawer-body')) {
                break;
            }
            if (parentElement?.scrollTop) {
                flag = true;
                break;
            }
        }
        return flag;
    };

    useEffect(() => {
        if (!mobile) {
            return;
        }
        const touchstartHandler = (e: MouseEvent | TouchEvent) => {
            if (e.type === 'touchstart' && isScroll(e.target as HTMLElement)) {
                return;
            }
            const clientY = (e as MouseEvent).clientY || (e as TouchEvent).touches[0].clientY;
            e.stopPropagation();
            dropDownRef.current.isDown = true;
            dropDownRef.current.moveY = clientY;
        };

        setTimeout(() => {
            if (visible) {
                drawerContentRef.current?.addEventListener('touchstart', touchstartHandler);
            }
        }, 400);

        if (!visible) {
            drawerContentRef.current?.removeEventListener('touchstart', touchstartHandler);
            dropDownRef.current.isDown = false;
            dropDownRef.current.moveY = 0;
        }

        return () => {
            drawerContentRef.current?.removeEventListener('touchstart', touchstartHandler);
            dropDownRef.current.isDown = false;
            dropDownRef.current.moveY = 0;
        };
    }, [visible, mobile]);

    useEffect(() => {
        if (!mobile) {
            return;
        }
        const touchendHandler = (e: MouseEvent | TouchEvent) => {
            if (dropDownRef.current.isDown) {
                e.stopPropagation();
                dropDownRef.current.isDown = false;
            }
        };

        const touchmoveHandler = (e: MouseEvent | TouchEvent) => {
            const clientY = (e as MouseEvent).clientY || (e as TouchEvent).touches[0].clientY;
            if (dropDownRef.current.isDown && clientY - dropDownRef.current.moveY > 60) {
                onClose();
            }
        };

        document.addEventListener('touchend', touchendHandler);

        document.addEventListener('touchmove', touchmoveHandler);

        return () => {
            document.removeEventListener('touchend', touchendHandler);
            document.removeEventListener('touchmove', touchmoveHandler);
        };
    }, [mobile]);

    useEffect(() => {
        const resizeHandler = () => {
            setMobile(isMobile());
        };
        window.addEventListener('resize', resizeHandler);
        return () => {
            window.removeEventListener('resize', resizeHandler);
        };
    }, []);

    return (
        <Drawer onClose={onClose} visible={visible} {...rest} className={className} destroyOnClose={false}>
            <div ref={drawerContentRef} className="particle-drawer-content">
                <CircleClose
                    data-mobile={mobile}
                    className="close-icon"
                    onClick={() => {
                        onClose();
                    }}
                />
                <div className="particle-close-btn" data-mobile={mobile}></div>
                {title && <div className="particle-title">{title}</div>}
                {children}
            </div>
        </Drawer>
    );
};

export default ParticleDrawer;
