import { isHexString } from '@ethereumjs/util';
import type { Chain, ChainInfo } from '@particle-network/chains';
import { Ethereum, chains } from '@particle-network/chains';
import { fromHex } from 'tron-format-address';
import tokenProvider from '../provider';
import { isTron } from './transaction-utils';

export const getChainDisplayName = (chain: Chain) => {
    const chainInfo = getChainInfo(chain);
    if (!chainInfo) {
        return '';
    }
    if (chainInfo.network === 'Mainnet') {
        return chainInfo.fullname.replace(' Mainnet', '');
    } else {
        return chainInfo.fullname;
    }
};

export function getNativeSymbol(chain: Chain): string {
    return getChainInfo(chain)?.nativeCurrency?.symbol || '';
}

export function getChainIcon(chain?: Chain): string {
    if (chain) {
        const chainInfo = getChainInfo(chain);
        if (chainInfo) {
            return chainInfo.icon;
        }
    }
    return defaultTokenIcon;
}

export function getNativeTokenIcon(chain?: Chain): string {
    if (chain) {
        const chainInfo = getChainInfo(chain);
        if (chainInfo) {
            if (chainInfo.nativeCurrency.symbol === 'ETH') {
                return Ethereum.icon;
            } else {
                return chainInfo?.nativeIcon || chainInfo?.icon || defaultTokenIcon;
            }
        } else {
            return defaultTokenIcon;
        }
    }
    return defaultTokenIcon;
}

export function getChainInfo(chain: Chain): ChainInfo | undefined {
    return chains.getChainInfo(chain);
}

export function formatAddress(address: string): string {
    if (isTron(tokenProvider.chain) && isHexString(address)) {
        return fromHex(address);
    }
    return address;
}

export const defaultTokenIcon = 'https://static.particle.network/token-list/defaultToken/default.png';
