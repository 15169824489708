import { useInterval, useRequest } from 'ahooks';
import { Button, message } from 'antd';
import throttle from 'lodash/throttle';
import qs from 'qs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ApiError } from '../../../api/api-error';
import pnApi from '../../../api/pn-api';
import SVGIcon from '../../../components/icon/svg-icon';
import PowerFooter from '../../../components/power-footer';
import AuthCode from '../../../components/react-auth-code-input';
import TurnstileModal from '../../../components/turnstileModal';
import { useUserInfo } from '../../../context';
import './index.less';

const CaptchaInput = (props: any) => {
    const { bindAccount, backToInputAccount, redirectUrl = '', verifyToken = '' } = props;

    const { t } = useTranslation();

    const route = useNavigate();

    const [captchaCode, setCaptchaCode] = useState<string>('');

    const { setUserInfo } = useUserInfo();

    const [interval, setInterval] = useState<number | undefined>(1000);

    const [countdown, setCountdown] = useState(60);

    const [errorTip, setErrorTip] = useState<string>();

    const inputRef = useRef(null);

    const [turnstileVisible, setTurnstileVisible] = useState(false);

    useInterval(() => {
        if (countdown > 0) {
            const result = countdown - 1;
            setCountdown(result);
            if (result === 0) {
                setInterval(undefined);
            }
        }
    }, interval);

    const { loading: bindLoading, run: runBinding } = useRequest(pnApi.securityAccountsBind, {
        manual: true,
        onSuccess: (result: any, params) => {
            setUserInfo({
                security_account: result,
            });
            if (redirectUrl) {
                route(redirectUrl.split('?')[0], {
                    replace: true,
                    state: {
                        ...qs.parse(redirectUrl.split('?')[1]),
                        verifyToken: result?.token,
                    },
                });
            } else if (!result.has_set_payment_password) {
                // router to set payment password
                route('/account/set-password');
            } else {
                route('/account/security', { replace: true });
            }
        },
        onError: (error: any) => {
            console.log('bind accounts error', error);
            if (error?.error_code === ApiError.InvalidCode) {
                setErrorTip(t('login.invalid_code'));
            } else if (error?.error_code === ApiError.ResendCode) {
                setErrorTip(t('login.please_send_again'));
            }
        },
    });

    const { loading: codeLoading, run: runGetCode } = useRequest(pnApi.securityAccountsCode, {
        manual: true,
        onSuccess: (result, params) => {
            if (!interval) {
                setCountdown(60);
                setInterval(1000);
            }
        },
        onError: (error: any) => {
            setCountdown(0);
            setInterval(undefined);
        },
    });

    const submitBind = (code?: string) => {
        let param: { email?: string; phone?: string; code: string; token?: string };
        if (bindAccount.includes('@')) {
            param = { email: bindAccount, code: code || captchaCode };
        } else {
            param = { phone: bindAccount, code: code || captchaCode };
        }

        if (verifyToken) {
            param.token = verifyToken;
        }

        runBinding(param);
    };

    const requestCaptchaCode = () => {
        setErrorTip('');
        if (bindAccount.includes('@')) {
            runGetCode({
                email: bindAccount,
            });
        } else {
            setTurnstileVisible(true);
        }
    };

    const onCodeInputChange = (code: string) => {
        setCaptchaCode(code);
        setErrorTip('');
        if (code.length === 6) {
            throttleSubmitBind(code);
        }
    };

    const throttleSubmitBind = useCallback(
        throttle(
            (code) => {
                submitBind(code);
            },
            1000,
            {
                leading: true,
                trailing: false,
            }
        ),
        []
    );

    useEffect(() => {
        const elements = document.getElementsByClassName('react-input-code');
        const contextmenuEvent = (e: any) => e.preventDefault();
        elements[0]?.addEventListener('contextmenu', contextmenuEvent);
        return () => {
            elements[0]?.removeEventListener('contextmenu', contextmenuEvent);
        };
    }, []);

    const handleCodeInputFocus = () => {
        const elements = document.getElementsByClassName('input-code-item');
        //@ts-ignore
        elements[Math.min(captchaCode.length, 5)]?.focus();
    };

    return (
        <div className="set-email-container">
            <SVGIcon className="icon-navigation-back" name="circle_back" onClick={backToInputAccount} />
            <h2 className="set-email-title">{t('account.enter_code')}</h2>
            <p className="set-email-desc-2" style={{ margin: 20 }}>
                {bindAccount}
            </p>
            <div onClick={handleCodeInputFocus}>
                <AuthCode
                    containerClassName="react-input-code"
                    inputClassName="input-code-item"
                    allowedCharacters="numeric"
                    length={6}
                    ref={inputRef}
                    placeholder={' '}
                    onChange={onCodeInputChange}
                />
            </div>

            {errorTip && <div className="code-error">{errorTip}</div>}

            <Button
                disabled={captchaCode.length !== 6}
                className="account-submit-btn"
                loading={bindLoading}
                onClick={() => submitBind()}
            >
                {t('common.confirm')}
            </Button>

            <Button className="send-again" onClick={requestCaptchaCode} disabled={countdown > 0 || codeLoading}>
                {countdown > 0 ? `${t('login.send_again')} (${countdown}s)` : t('login.send_again')}
            </Button>
            <div className="back" onClick={backToInputAccount}>
                <SVGIcon className="arrow1-icon" name="arrow1_icon" />
                <span>{t('login.back')}</span>
            </div>
            <PowerFooter />
            <TurnstileModal
                onError={() => message.error(t('error.server_20112'))}
                onSuccess={(token) => {
                    runGetCode({
                        phone: bindAccount,
                        cf_turnstile_response: token,
                    });
                }}
                visible={turnstileVisible}
                setVisible={setTurnstileVisible}
            ></TurnstileModal>
        </div>
    );
};

export default CaptchaInput;
