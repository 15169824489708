import type { ChainId, ChainName } from '@particle-network/chains';
import type { AuthType } from '../api/model/login-type';

export type Theme = 'dark' | 'light';

export enum SolanaSignMethod {
    signTransaction = 'signTransaction',
    signAndSendTransaction = 'signAndSendTransaction',
    signMessage = 'signMessage',
    signAllTransactions = 'signAllTransactions',
}

export enum EvmSignMethod {
    eth_sendTransaction = 'eth_sendTransaction',
    eth_signTypedData = 'eth_signTypedData',
    eth_signTypedData1 = 'eth_signTypedData_v1',
    eth_signTypedData3 = 'eth_signTypedData_v3',
    eth_signTypedData4 = 'eth_signTypedData_v4',
    eth_signTypedData_v4_uniq = 'eth_signTypedData_v4_uniq',
    personal_sign = 'personal_sign',
    personal_sign_uniq = 'personal_sign_uniq',
}

export const currencyUnitValues = [
    'USD',
    'CNY',
    'JPY',
    'HKD',
    'INR',
    'KRW',
    'usd',
    'cny',
    'jpy',
    'hkd',
    'inr',
    'krw',
] as const;

export type CurrencyUnit = typeof currencyUnitValues[number];

export interface BasicQuery {
    token?: string;
    project_uuid: string;
    project_client_key: string;
    project_app_uuid: string;
    device_id: string;
    sdk_version: string;
    chain_name: ChainName;
    chain_id: ChainId;

    message?: string;
    method?: SolanaSignMethod | EvmSignMethod;
}

export enum QueryParamKey {
    PARAMS = 'params',
    THEME_TYPE = 'theme_type',
    DISPLAY_CLOSE_BUTTON = 'display_close_button', //used for web sdk.
    DISPLAY_WALLET = 'display_wallet', //display wallet when sendTransaction
    SECURITY_ACCOUNT = 'security_account', //security account config
    STATE = 'state', //any string，redirect to app
    FIAT_COIN = 'fiat_coin',
    ERC4337 = 'erc4337',
    PRELOAD = 'preload',
    LANGUAGE = 'language',
}

export interface QueryParams {
    params: LoginQuery | SignQuery | BasicQuery;
    secretKey: string;
    themeType: string | null;
    redirectType: string;
    state: string | null;
    fiatCoin: string;
    erc4337: boolean | ERC4337Options;
    language?: string;
}

export type ERC4337Options = {
    name: string;
    version: string;
};

type PromptType = 'none' | 'consent' | 'select_account';

export interface AuthorizationOptions {
    message?: string;
    uniq?: boolean;
}

export interface LoginQuery extends BasicQuery {
    login_type?: AuthType;
    account?: string; // used for email/phone/jwt login
    support_auth_types?: string;
    prompt?: PromptType; //used for social login (google, discord, microsoft)
    authorization?: AuthorizationOptions;
}

export interface SignQuery extends BasicQuery {
    method: SolanaSignMethod | EvmSignMethod;
    message: string;
}

export enum PromptSettingWhenSignType {
    /**
     * 不提醒
     */
    none = 0,
    /**
     * 首次提醒
     */
    first = 1,
    /**
     * 每次都提醒
     */
    every = 2,
    /**
     * 每次都提醒，且不可跳过
     */
    everyAndNotSkip = 3,
}

export enum PromptMasterPasswordSettingWhenLoginType {
    /**
     * 不提醒
     */
    none = 0,
    /**
     * 首次提醒
     */
    first = 1,
    /**
     * 每次都提醒
     */
    every = 2,
    /**
     * 每次都提醒，且不可跳过
     */
    everyAndNotSkip = 3,
}

export interface SecurityAccountConfig {
    promptSettingWhenSign?: PromptSettingWhenSignType | boolean; // 0: 不提醒， 1: 首次提醒， 2: 每次都提醒, 3: 每次都提醒，且不可跳过
    promptMasterPasswordSettingWhenLogin?: PromptMasterPasswordSettingWhenLoginType | boolean; // 0: 不提醒， 1: 首次提醒， 2: 每次都提醒， 3: 每次都提醒，且不可跳过
}
