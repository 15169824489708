import { useRequest } from 'ahooks';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthType } from '../../api/model/login-type';
import { LoginAccount } from '../../api/model/user-info';
import pnApi from '../../api/pn-api';
import SVGIcon from '../../components/icon/svg-icon';
import PowerFooter from '../../components/power-footer';
import { useParticleAuth } from '../../context';
import { displayEmail } from '../../utils/common-utils';
import { PageType } from '../account/accountVerify/config';
import { IAccountInfo } from '../loginAccountBind';
import './index.less';

const LoginAccountDetails = () => {
    const { t } = useTranslation();
    const { state } = useLocation();
    const [fold, setFold] = useState(true);
    const [passkeysAccount, setPasskeysAccount] = useState<LoginAccount>();
    const { userInfo, showSelectSecurityAccount } = useParticleAuth();
    const navigate = useNavigate();

    useRequest(pnApi.getLoginBindings, {
        onSuccess: (loginaccounts) => {
            const passKeysAccount = loginaccounts.find((item) => item.provider === AuthType.passkeys);
            setPasskeysAccount(passKeysAccount);
        },
        retryCount: 3,
        retryInterval: 1000,
    });

    const accountInfo = useMemo(() => (state as any)?.accountInfo as IAccountInfo, [state]);

    const toggleShowDetails = () => {
        if (fold) {
            setFold(false);
        } else {
            setFold(true);
        }
    };

    return (
        <div className="login-account-details">
            <div className="account-title">{accountInfo.name}</div>
            <div className="link-account-hint">{t('new.link_account_hint').format(accountInfo.name)}</div>
            <div className="account-name-title">{t('account.username')}</div>
            <div className="account-name">{displayEmail(userInfo.email || '')}</div>
            <div className="account-type-title">{t('login.passkeys')}</div>
            <div className={`card ${fold ? 'fold' : ''}`}>
                <div className="account-value">
                    {userInfo.passkeys_id
                        ? `${userInfo.passkeys_id.substr(0, 3)}****${userInfo.passkeys_id.substr(-4)}`
                        : '----'}
                    {fold ? (
                        <SVGIcon className="icon-arrow icon-down" name="circle_back" onClick={toggleShowDetails} />
                    ) : (
                        <SVGIcon className="icon-arrow icon-up" name="circle_back" onClick={toggleShowDetails} />
                    )}
                </div>
                <div className="create-at">
                    <div>{t('account.create_at')}</div>
                    <div>
                        {passkeysAccount
                            ? dayjs(new Date(passkeysAccount.created_at)).format('YYYY/MM/DD HH:mm:ss')
                            : '----'}
                    </div>
                </div>
                <div
                    className="btn-delete"
                    onClick={() => {
                        if (passkeysAccount) {
                            if (userInfo?.security_account?.email && userInfo?.security_account?.phone) {
                                // 选择安全账号
                                showSelectSecurityAccount(true, {
                                    authType: AuthType.passkeys,
                                    pageType: PageType.UnbindLoginAccount,
                                    unbindAccount: passkeysAccount.id_combined,
                                });
                            } else {
                                navigate('/account/verify', {
                                    state: {
                                        account: userInfo.security_account?.email || userInfo.security_account?.phone, // 验证安全账号
                                        authType: AuthType.passkeys,
                                        pageType: PageType.UnbindLoginAccount,
                                        unbindAccount: passkeysAccount.id_combined,
                                    },
                                });
                            }
                        }
                    }}
                >
                    {t('new.delete')}
                </div>
            </div>

            <PowerFooter />
        </div>
    );
};

export default LoginAccountDetails;
