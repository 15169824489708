import { message } from 'antd';
import axios, { AxiosBasicCredentials } from 'axios';
import sha256 from 'crypto-js/sha256';
import stringify from 'fast-json-stable-stringify';
import qs from 'qs';
import { v4 as uuidv4 } from 'uuid';
import i18n from '../i18n';
import tokenProvider from '../provider';
import errorHandle from './error-handle';

// Set config defaults when creating the instance
const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL as string,
    timeout: 30000,
    validateStatus: function (status) {
        return status >= 200 && status <= 500;
    },
    withCredentials: false,
    paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'brackets' });
    },
});
client.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

// Add a request interceptor
client.interceptors.request.use(
    function (config) {
        const noneAuth = config.headers && config.headers['Auth-Type'] === 'None';
        if (noneAuth) {
            return config;
        }
        let macKey = '';
        const basicAuth = config.headers && config.headers['Auth-Type'] === 'Basic';
        if (basicAuth) {
            let auth: AxiosBasicCredentials;
            if (config.baseURL?.includes('rpc')) {
                auth = {
                    username: tokenProvider.params.project_uuid,
                    password: tokenProvider.params.project_client_key,
                };
            } else {
                auth = {
                    username: process.env.REACT_APP_BASIC_USERNAME as string,
                    password: process.env.REACT_APP_BASIC_PASSWORD as string,
                };
                macKey = sha256(auth.username + auth.password)
                    .toString()
                    .toLowerCase();
            }

            config.auth = auth;
        } else {
            //token auth
            const token = tokenProvider.token;
            macKey = tokenProvider.userInfo.mac_key;
            if (config.headers) {
                config.headers['Authorization'] = `Bearer ${token}`;
            } else {
                config.headers = { Authorization: `Bearer ${token}` };
            }
        }

        if (!config.params) {
            config.params = {};
        }

        if (config.baseURL?.includes('rpc')) {
            config.params.chainId = tokenProvider.chain.id;
            config.params.projectUuid = tokenProvider.params.project_uuid;
            config.params.projectKey = tokenProvider.params.project_client_key;
        } else {
            const timestamp: number = Math.round(new Date().getTime() / 1000);
            const randomStr: string = uuidv4();

            config.params.timestamp = timestamp;
            config.params.random_str = randomStr;
            config.params.device_id = tokenProvider.params.device_id;
            config.params.sdk_version = tokenProvider.params.sdk_version;

            config.params.project_uuid = tokenProvider.params.project_uuid;
            config.params.project_client_key = tokenProvider.params.project_client_key;
            config.params.project_app_uuid = tokenProvider.params.project_app_uuid;

            const merged = { ...config.params };
            if (config.data) {
                Object.assign(merged, { ...config.data });
            }
            merged['mac_key'] = macKey;
            const mac = stringify(merged);
            config.params.mac = sha256(mac).toString().toLowerCase();
        }

        return config;
    },
    function (error) {
        errorHandle(error);
        return Promise.reject(error);
    }
);

// Add a response interceptor
client.interceptors.response.use(
    function (response) {
        if (response.status === 200 || response.status < 300 || response.status === 304) {
            const data = response.data;
            if (data.error || data.error_code) {
                if (response.config?.headers?.disableSystemMessage !== true) {
                    if (data.error_code === 20104 && data.extra?.includes('passkeys')) {
                        const msg = i18n.t('login.passkeys_auth_error');
                        message.error(msg);
                    } else {
                        errorHandle(data.error ?? data, response.config.url);
                    }
                }
                return Promise.reject(data.error ?? data);
            }
            return Promise.resolve(response);
        }
        errorHandle(response);
        return Promise.reject(response);
    },
    function (error) {
        errorHandle(error);
        return Promise.reject(error);
    }
);

export default client;
