import { useRequest } from 'ahooks';
import { Button, Form, Space, Typography, message } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { checkMasterPassword } from '../../../api/master-password';
import Header from '../../../components/header';
import InputPassword from '../../../components/input-password';
import PowerFooter from '../../../components/power-footer';
import './index.less';

const MasterPasswordChange = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { Text, Link } = Typography;

    const [showError, setShowError] = useState(false);

    const [nextDisabled, setNextDisabled] = useState(true);

    const { run: runCheckMasterPassword, loading } = useRequest(checkMasterPassword, {
        manual: true,
        onSuccess: (result) => {
            setNewMasterPassword();
        },
        onError: (error) => {
            console.log('checkMasterPassword error', error);
            if (error?.message === 'Master password decryption error') {
                setShowError(true);
            } else {
                message.error(error?.message || 'check master password error');
            }
        },
    });

    const setNewMasterPassword = () => {
        navigate('/account/master-password', {
            state: {
                setNewMasterPassword: true,
            },
        });
    };

    const onInputFinish = (values: any) => {
        const { password } = values;
        if (password && password.length >= 6 && password.length <= 20) {
            runCheckMasterPassword(password);
        } else {
            setShowError(true);
        }
    };

    const onValuesChange = (values: any) => {
        setShowError(false);
        const { password } = values;
        setNextDisabled(!password);
    };

    const toDecription = () => {
        navigate('/account/master-password/description');
    };

    return (
        <div className="mp-change-container">
            <Header displayBackBtn={true}>{t('account.current_master_password')}</Header>
            <div className="wapper">
                <Form onFinish={onInputFinish} layout="vertical" onValuesChange={onValuesChange}>
                    <div className="mp-input-name">{t('account.input_master_password')}</div>
                    <Form.Item name="password">
                        <InputPassword className="mp-input" />
                    </Form.Item>
                    {showError && <div className="mp-input-error">{t('account.password_error')}</div>}
                    <Space direction="vertical" className="mp-tip-space">
                        <Text>{t('account.mpc_tss_intro')}</Text>
                        <Text>
                            {t('account.input_decrypt_hint')}{' '}
                            <Link onClick={toDecription} className="more-text-btn">
                                {t('account.learn_more_period')}
                            </Link>
                        </Text>
                    </Space>
                    <Form.Item>
                        <div className="bottom-container">
                            <Button
                                className="primary-antd-btn mp-next"
                                htmlType="submit"
                                loading={loading}
                                disabled={nextDisabled}
                            >
                                {t('common.next')}
                            </Button>
                            <PowerFooter />
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default MasterPasswordChange;
