import { AuthType } from '../api/model/login-type';
import tokenProvider from '../provider';
import { popupwindow } from './common-utils';
import url from './url-utils';

export const handleStorageEvent = (e: StorageEvent) => {
    if (e.key === 'particle-network-social-login') {
        console.log('handleStorageEvent', e);
        const state = tokenProvider.queryParams.params;
        window.parent.postMessage(
            {
                name: 'particle-network-provider',
                data: e.newValue,
                state,
            },
            '*'
        );
        window.localStorage.removeItem('particle-network-social-login');
    }
};

//login with phone or email
export default function popupLogin(authType: AuthType, account: string) {
    const loginUrl = url.buildLoginUrl({ authType, account });
    window.removeEventListener('storage', handleStorageEvent);
    window.addEventListener('storage', handleStorageEvent);

    popupwindow(loginUrl, 'particle-auth-popup-login', 475, 770);
}
