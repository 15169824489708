import { useEffect } from 'react';
import { errorToRedirect } from '../../api/error-handle';
import wallet from '../../api/wallet';
import Loading from '../../components/loading';
import { useParticleAuth } from '../../context';
import tokenProvider from '../../provider';
import redirectToApp, { AuthError } from '../../utils/redirect-utils';

function Wallet(props: any) {
    const { syncSecurityAccountsCompleted } = useParticleAuth();

    useEffect(() => {
        if (!tokenProvider.isLogin()) {
            redirectToApp({
                error: AuthError.notLogin(),
            });
        }
    }, []);

    useEffect(() => {
        if (syncSecurityAccountsCompleted) {
            checkOrCreateWallet();
        }
    }, [syncSecurityAccountsCompleted]);

    const checkOrCreateWallet = () => {
        if (wallet.exist()) {
            backToApp();
        } else {
            wallet
                .gen()
                .then((address) => backToApp())
                .catch((e) => errorToRedirect(e));
        }
    };

    const backToApp = () => {
        redirectToApp({
            wallets: wallet.wallets(),
        });
    };

    return <Loading visible={true} />;
}

export default Wallet;
