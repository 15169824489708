import { BI, SendActiveParams, SendRecordsParams } from '@particle-network/analytics';
import tokenProvider from '../provider';
import { SignQuery } from '../provider/bundle';

const getBI = () => {
    try {
        const signQuery = tokenProvider.params as SignQuery;
        const { project_app_uuid, project_client_key, project_uuid, sdk_version, device_id } = signQuery;
        // @ts-ignore
        if (!window.bi) {
            // @ts-ignore
            window.bi = new BI({
                sdk_api_domain: process.env.REACT_APP_BASE_URL as string,
                device_id,
                sdk_version,
                project_config: {
                    project_uuid: project_uuid,
                    project_key: project_client_key,
                    project_app_uuid,
                },
            });
        }
    } catch (error) {
        console.log(error);
    }
    // @ts-ignore
    return window.bi;
};

export const biRecords = (params: SendRecordsParams) => {
    getBI()?.records?.(params);
};

export const biActive = (params: SendActiveParams) => {
    getBI()?.active?.(params);
};
