import { PrefixedHexString, isHexString, stripHexPrefix } from '@ethereumjs/util';
import { BigNumber } from 'bignumber.js';
import BN from 'bn.js';
import numbro from 'numbro';
import tokenProvider from '../provider';
import { bnToHex, isNullish, toHexPrefixString } from './common-utils';

export function formatTokenAmount(amount: number, decimals: number): string {
    if (!amount || !decimals) {
        return '0';
    }

    return numbro(amount / Math.pow(10, decimals)).format({
        thousandSeparated: true,
        mantissa: decimals,
        trimMantissa: true,
    });
}

export function toWeiBigNumber(amount: string | number | undefined, unit = 'ether'): BigNumber {
    return new BigNumber(bnToHex(toWei(amount, unit)));
}

export function toBigNumber(amount: string | number | undefined): BigNumber {
    if (typeof amount === 'string' || typeof amount === 'number') {
        return new BigNumber(amount);
    }
    return new BigNumber(toHexPrefixString(amount));
}

export function toWei(amount: BN | string | number | undefined, unit = 'ether'): BN {
    if (!amount) {
        return new BN(0);
    }
    const ethjs = require('ethjs-unit');
    return ethjs.toWei(amount, unit);
}

export function fromWei(amount: BN | PrefixedHexString | undefined, unit = 'ether'): string {
    if (!amount) {
        return '0';
    }
    const ethjs = require('ethjs-unit');
    let result;
    if (typeof amount === 'string' && amount.startsWith('0x')) {
        result = ethjs.fromWei(new BN(stripHexPrefix(amount), 16), unit);
    } else {
        result = ethjs.fromWei(new BN(amount), unit);
    }
    return result;
}

export function fromWeiFormat(amount: BN | PrefixedHexString | undefined, unit = 'ether', mantissa = 6): string {
    if (!amount) {
        return '0';
    }
    const ethjs = require('ethjs-unit');
    let result;
    if (typeof amount === 'string' && amount.startsWith('0x')) {
        result = ethjs.fromWei(new BN(stripHexPrefix(amount), 16), unit);
    } else {
        result = ethjs.fromWei(new BN(amount), unit);
    }
    return numbro(result).format({
        thousandSeparated: true,
        trimMantissa: true,
        mantissa: mantissa,
    });
}

export function formatPrice(amount: string | number, price: number, mantissa?: number): string {
    const value = new BigNumber(amount).multipliedBy(new BigNumber(price));
    if (isNullish(mantissa)) {
        if (value.lt(new BigNumber(1))) {
            mantissa = 4;
        } else {
            mantissa = 2;
        }
    }

    return `≈${numbro(value)
        .format({
            thousandSeparated: true,
            trimMantissa: true,
            mantissa: mantissa,
        })
        .replace(/-0$/, '0')} ${tokenProvider.fiatCoin.toUpperCase()}`;
}

export function trimDecimals(value: number | string, mantissa: number): string {
    return numbro(value).format({
        thousandSeparated: false,
        trimMantissa: true,
        mantissa: mantissa,
    });
}

export const formatDisplayPrice = (fee: string, price: number, mantissa = 2): string => {
    return price ? formatPrice(fee, price, mantissa) : '';
};

export function fromSunFormat(amount: PrefixedHexString | string, mantissa = 6): string {
    const bn = new BigNumber(amount, isHexString(amount) ? 16 : 10);
    const value = bn.div(new BigNumber(1000_000)).toString(10);
    return numbro(value).format({
        thousandSeparated: true,
        trimMantissa: true,
        mantissa: mantissa,
    });
}

/**
 * 格式化代币数量
 * @param value 原始值，可以是 BigNumber、number 或 string 类型
 * @param decimals 小数位数
 * @returns 格式化后的代币数量
 */
export const formatTokenAmount2 = (value: BigNumber | number | string, decimals: number): string => {
    let bn: BigNumber;
    if (!(value instanceof BigNumber)) {
        bn = new BigNumber(value).dividedBy(new BigNumber(10).pow(decimals));
    } else {
        bn = value;
    }

    const str = bn.toFixed();
    const [int, dec] = str.split('.');
    let decimalLength = 9;

    if (int.length > 15) {
        return bn.toExponential(2); // 使用科学计数法，小数点后两位
    }

    if (int.length > 5) {
        decimalLength = Math.max(9 - (int.length - 5), 0);
    }

    if (dec && decimalLength > 0) {
        const decPart = dec.slice(0, decimalLength);
        return new BigNumber(`${int}.${decPart}`).toFixed();
    }

    return int;
};

/**
 * 格式化法币数量
 * @param value 原始值，可以是 BigNumber、number 或 string 类型
 * @returns 格式化后的法币数量
 */
export const formatFiatAmount = (value: BigNumber | number | string): string => {
    let bn: BigNumber;
    if (!(value instanceof BigNumber)) {
        bn = new BigNumber(value);
    } else {
        bn = value;
    }

    const str = bn.toFixed();
    const [int, dec] = str.split('.');

    if (dec) {
        const decPart = dec.slice(0, 4);
        return new BigNumber(`${int}.${decPart}`).toFixed();
    }

    return int;
};
