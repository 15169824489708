import { Modal, message } from 'antd';
import i18n from '../i18n';
import tokenProvider from '../provider';
import redirectToApp, { AuthError } from '../utils/redirect-utils';
import { ApiError, isApiError } from './api-error';

/**
 * error handler
 * @param err
 */
function errorHandle(err: any, url?: string) {
    console.log('errorHandle: ' + JSON.stringify(err));
    const errorCode = err.error_code ?? err.code;
    // 判断服务器响应
    if (errorCode && typeof errorCode === 'number') {
        if (errorCode === ApiError.InvalidToken) {
            if (!window.location.hash.includes('logout')) {
                showTokenError(err);
            } else {
                tokenProvider.logout();
            }
        } else if (
            errorCode === ApiError.InvalidCode ||
            errorCode === ApiError.ResendCode ||
            errorCode === ApiError.SecurityAccountFrozen ||
            errorCode === ApiError.WrongPaymentPassword
        ) {
            //ignore error
        } else {
            if (!url || (!url.includes('/evm-chain') && !url.includes('/solana'))) {
                // not chain rpc request error
                const message = formatServerError(err);
                if (message) {
                    Modal.error({
                        title: message,
                    });
                }
            }
        }
    } else if (err.code === 'ECONNABORTED') {
        if (err.message) {
            message.error(err.message);
        } else {
            message.error('Network Error');
        }
    } else {
        switch (err.status) {
            case 401:
            case 403:
            case 404:
            case 500:
                message.error('Http Error: ' + err.status);
                break;
            default:
                if (err.message && typeof err.message === 'string') {
                    message.error(err.message);
                } else {
                    console.error('http error', err);
                }
                break;
        }
    }
}

let tokenErrorModal: any;
function showTokenError(error: any) {
    const message = formatServerError(error);
    if (message && !tokenErrorModal) {
        tokenErrorModal = Modal.error({
            title: message,
            onOk: () => {
                redirectToApp({
                    error: new AuthError(ApiError.InvalidToken, message),
                });
                tokenProvider.logout();
            },
        });
    }
}

export function errorToRedirect(error: any) {
    console.log('Error To Redirect', error);
    if (isApiError(error)) {
        redirectToApp({
            error: new AuthError(error.error_code, error.message),
        });
    } else {
        if (error.code === 'ECONNABORTED') {
            redirectToApp({
                error: AuthError.network(),
            });
        } else if (error.code && error.message) {
            redirectToApp({
                error,
            });
        } else if (typeof error === 'string') {
            redirectToApp({
                error: AuthError.unknown(error),
            });
        } else {
            redirectToApp({
                error: AuthError.systemError(),
            });
        }
    }
}

export function formatServerError(error: any): string | undefined {
    if (error && error.error_code) {
        let message = i18n.t(`error.server_${error.error_code}`);
        if (message && message.startsWith('error.server_')) {
            if (error?.extra && Array.isArray(error?.extra) && error?.extra.length) {
                message = error?.extra[0];
            } else {
                message = error?.extra ?? error.message;
            }
            message = message.charAt(0).toUpperCase() + message.slice(1);
            return message;
        }
        return message;
    }
    return undefined;
}

export default errorHandle;
