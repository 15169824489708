import { Input } from 'antd';
import { isAutoFocusSupported } from '../../utils';
import { isNullish } from '../../utils/common-utils';
import { EyeClose, EyeOpen } from '../eyeIcon';
import './index.less';

type Prop = {
    className?: string;
    maxLength?: number;
    defaultValue?: string;
    onChange?: (value: string) => void;
    onPressEnter?: (event: any) => void;
    onBlur?: (event: any) => void;
    autoFocus?: boolean;
};
const InputPassword = (prop: Prop) => {
    return (
        <Input.Password
            className={'password-input' + (prop?.className ? ` ${prop?.className}` : '')}
            iconRender={(visible) =>
                visible ? (
                    <div>
                        <EyeOpen />
                    </div>
                ) : (
                    <div>
                        <EyeClose />
                    </div>
                )
            }
            onChange={(e) => prop?.onChange?.(e.target.value)}
            defaultValue={prop?.defaultValue}
            maxLength={prop?.maxLength || 20}
            onPressEnter={(e) => prop?.onPressEnter?.(e)}
            onBlur={(e) => prop?.onBlur?.(e)}
            autoFocus={isNullish(prop?.autoFocus) && isAutoFocusSupported() ? true : prop?.autoFocus}
        />
    );
};

export default InputPassword;
