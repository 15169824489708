import { PrefixedHexString, stripHexPrefix } from '@ethereumjs/util';
import BN from 'bn.js';
import { v4 as uuidv4 } from 'uuid';
import { fromWeiFormat } from '../utils/number-utils';
import { EvmEnhancedMethod, GasFeeResult, TokenPrice } from './model/rpc-data';
import pnApi from './pn-api';

export interface GasFee {
    fee?: string;
    maxFee: string;
}

export interface TotalAmount {
    amount?: string;
    maxAmount: string;
}

const evmApi = {
    suggestedGasFees(): Promise<GasFeeResult> {
        return pnApi
            .evmRpc<GasFeeResult>({
                id: uuidv4(),
                jsonrpc: '2.0',
                method: EvmEnhancedMethod.particle_suggestedGasFees,
            })
            .then((output) => output.result);
    },

    getPrice(addresses: string[], currencies: string[]): Promise<TokenPrice[]> {
        return pnApi
            .evmRpc<TokenPrice[]>({
                id: uuidv4(),
                jsonrpc: '2.0',
                method: EvmEnhancedMethod.particle_getPrice,
                params: [addresses, currencies],
            })
            .then((output) => output.result);
    },
    getPendingTransactionsByAddress(account: string): Promise<any> {
        return pnApi
            .evmRpc<any>({
                id: uuidv4(),
                jsonrpc: '2.0',
                method: EvmEnhancedMethod.particle_getPendingTransactionsByAddress,
                params: [account],
            })
            .then((output) => output.result);
    },

    estimateGas({
        from,
        to,
        value,
        data,
    }: {
        from: string;
        to: string;
        value?: string;
        data?: string;
    }): Promise<string> {
        const param = !to || to.length === 0 ? { from, value, data } : { from, to, value, data };
        return pnApi
            .evmRpc<string>({
                id: uuidv4(),
                jsonrpc: '2.0',
                method: EvmEnhancedMethod.eth_estimateGas,
                params: [param],
            })
            .then((output) => output.result);
    },

    gasFee({
        gasLimit,
        baseFee,
        maxFeePerGas,
        maxPriorityFeePerGas,
        gasPrice,
    }: {
        gasLimit: PrefixedHexString;
        baseFee: PrefixedHexString;
        maxFeePerGas?: PrefixedHexString;
        maxPriorityFeePerGas?: PrefixedHexString;
        gasPrice?: PrefixedHexString;
    }): GasFee {
        if (maxFeePerGas && maxPriorityFeePerGas && baseFee && gasLimit) {
            //eip 1559
            const fee = new BN(stripHexPrefix(maxPriorityFeePerGas), 16)
                .add(new BN(stripHexPrefix(baseFee), 16))
                .mul(new BN(stripHexPrefix(gasLimit), 16));

            const maxFee = new BN(stripHexPrefix(maxFeePerGas), 16)
                .add(new BN(stripHexPrefix(baseFee), 16))
                .mul(new BN(stripHexPrefix(gasLimit), 16));
            return {
                fee: fromWeiFormat(fee),
                maxFee: fromWeiFormat(maxFee),
            };
        } else if (gasPrice) {
            const fee = new BN(stripHexPrefix(gasPrice), 16).mul(new BN(stripHexPrefix(gasLimit), 16));
            return {
                maxFee: fromWeiFormat(fee),
            };
        }
        return {
            maxFee: '',
        };
    },

    totalAmount({
        value,
        gasLimit,
        baseFee,
        maxFeePerGas,
        maxPriorityFeePerGas,
        gasPrice,
    }: {
        value?: PrefixedHexString;
        gasLimit: PrefixedHexString;
        baseFee: PrefixedHexString;
        maxFeePerGas?: PrefixedHexString;
        maxPriorityFeePerGas?: PrefixedHexString;
        gasPrice?: PrefixedHexString;
    }): TotalAmount {
        if (!value) {
            value = '0x0';
        }
        if (maxFeePerGas && maxPriorityFeePerGas) {
            //eip 1559
            const fee = new BN(stripHexPrefix(maxPriorityFeePerGas), 16)
                .add(new BN(stripHexPrefix(baseFee), 16))
                .mul(new BN(stripHexPrefix(gasLimit), 16));

            const maxFee = new BN(stripHexPrefix(maxFeePerGas), 16)
                .add(new BN(stripHexPrefix(baseFee), 16))
                .mul(new BN(stripHexPrefix(gasLimit), 16));

            return {
                amount: fromWeiFormat(new BN(stripHexPrefix(value), 16).add(fee)),
                maxAmount: fromWeiFormat(new BN(stripHexPrefix(value), 16).add(maxFee)),
            };
        } else if (gasPrice) {
            const fee = new BN(stripHexPrefix(gasPrice), 16).mul(new BN(stripHexPrefix(gasLimit), 16));
            return {
                maxAmount: fromWeiFormat(new BN(stripHexPrefix(value), 16).add(fee)),
            };
        }
        return {
            maxAmount: '',
        };
    },
};

export default evmApi;
