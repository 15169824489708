import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { useTranslation } from 'react-i18next';
import { SecurityDetection } from '../../../api/model/rpc-data';
import CircleClose from '../../../components/icon/circle-close';
import RiskTypography from '../riskTypography';
import './index.less';

interface Prop {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    onConfirm: () => void;
    securityDetection: SecurityDetection[];
}

const RiskModal = (prop: Prop) => {
    const { visible, setVisible, onConfirm, securityDetection } = prop;

    const { t } = useTranslation();

    const onClose = () => {
        setVisible(false);
    };

    return (
        <Modal
            className="risk-modal"
            visible={visible}
            closeIcon={<CircleClose />}
            centered
            maskClosable={false}
            onCancel={onClose}
        >
            <div className="risk-modal-title">{t('sign.risk_hint_title')}</div>
            <RiskTypography
                className="risk-modal-content"
                securityDetection={securityDetection}
                title={t('sign.risk_identified')}
            ></RiskTypography>

            <div className="risk-bottom-btn-box">
                <Button className="primary-antd-btn secondary" onClick={onClose}>
                    {t('common.cancel')}
                </Button>
                <Button
                    className="primary-antd-btn danger"
                    onClick={() => {
                        onConfirm();
                        setVisible(false);
                    }}
                >
                    {t('common.confirm')}
                </Button>
            </div>
        </Modal>
    );
};

export default RiskModal;
