import { uuidv4 } from '@particle-network/crypto';
import { UserInfo } from '../api/model/user-info';
import tokenProvider from '../provider';
import device from './detect-device';

interface PostResponsePayload<T> {
    id: string;
    result?: T;
    error?: {
        code: number;
        message: string;
    };
}

export interface PostRequestPayload<T> {
    id: string;
    method: string;
    params?: T;
}

export const loadNativeUserInfo = async () => {
    if (!device.authEmbed()) {
        return;
    }

    return new Promise((resolve, reject) => {
        const id = uuidv4();
        window.postResponse = (data) => {
            const json: PostResponsePayload<UserInfo> = JSON.parse(data);
            if (json.id === id) {
                if (json.result) {
                    tokenProvider.userInfo = json.result;
                    resolve(true);
                } else {
                    reject(json.error ?? 'user not logged in');
                }
            }
        };

        const payload = JSON.stringify({ id, method: 'user_info' });
        if (device.isIOS()) {
            try {
                window.webkit.messageHandlers.postRequest.postMessage(payload);
            } catch (err) {
                console.log('iosPostRequest', err);
            }
        } else if (window.particleAuth) {
            window.particleAuth.postRequest(payload);
        }
    });
};

export const updateUserInfo = () => {
    if (!device.authEmbed()) {
        return;
    }
    const id = uuidv4();
    const { mac_key, token, wallets, cognito_result, thirdparty_user_info, ...info } = tokenProvider.userInfo;
    const payload = JSON.stringify({ id, method: 'update_user_info', params: [info] });
    if (device.isIOS()) {
        try {
            window.webkit.messageHandlers.postRequest.postMessage(payload);
        } catch (err) {
            console.log('iosPostRequest', err);
        }
    } else if (window.particleAuth) {
        window.particleAuth.postRequest(payload);
    }
};
