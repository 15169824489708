import { MinusCircleFilled, PlusCircleFilled } from '@ant-design/icons';
import { PrefixedHexString, addHexPrefix } from '@ethereumjs/util';
import { Button, Form, Input, Radio } from 'antd';
import { BigNumber } from 'bignumber.js';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import evmApi, { GasFee } from '../../../api/evm-api';
import { TokenPrice } from '../../../api/model/rpc-data';
import SVGIcon from '../../../components/icon/svg-icon';
import PowerFooter from '../../../components/power-footer';
import tokenProvider from '../../../provider';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { getNativeSymbol } from '../../../utils/chain-utils';
import { bnToHex, toHexPrefixString } from '../../../utils/common-utils';
import {
    formatPrice,
    fromWei,
    fromWeiFormat,
    toBigNumber,
    toWei,
    toWeiBigNumber,
    trimDecimals,
} from '../../../utils/number-utils';
import { isEIP1559Type } from '../../../utils/transaction-utils';
import { GasFeeMode, selectTransaction, setGasFeeMode, updateTransaction } from '../transaction-slice';
import './evm-gas.less';
import ParticleDrawer from './particle-drawer';

function EvmGas(props: any) {
    const { openGasDrawer, visible } = props;
    const [moreDetails, setMoreDetails] = useState(false);
    const [errorTip, setErrorTip] = useState(false);
    const [errorTip1, setErrorTip1] = useState<string>();
    const [errorTip2, setErrorTip2] = useState<string>();
    const optionsForm = useRef<any>(null);

    const { t } = useTranslation();

    const transaction = useAppSelector(selectTransaction);
    const dispatch = useAppDispatch();

    const [gasMode, setGasMode] = useState(transaction.gasFeeMode);
    const [gasFeeDisplay, setGasFeeDisplay] = useState(transaction.gasFeeDisplay);
    const [gasLimit, setGasLimit] = useState<string>('');
    const [maxPriorityFeePerGas, setMaxPriorityFeePerGas] = useState(transaction.data?.maxPriorityFeePerGas);

    const [maxFee, setMaxFee] = useState<string>('');

    const [gasForm] = Form.useForm();

    useEffect(() => {
        if (transaction.gasFeeMode) {
            setGasMode(transaction.gasFeeMode);
        }
    }, [transaction.gasFeeMode]);

    useEffect(() => {
        if (transaction.gasFeeDisplay) {
            setGasFeeDisplay(transaction.gasFeeDisplay);
        }
    }, [transaction.gasFeeDisplay]);

    useEffect(() => {
        setMaxPriorityFeePerGas(transaction.data?.maxPriorityFeePerGas);
    }, [transaction.data?.maxPriorityFeePerGas]);

    useEffect(() => {
        setMaxFee(
            (isEIP1559Type(transaction?.data?.type as string)
                ? transaction.data?.maxFeePerGas
                : transaction?.data?.gasPrice) as string
        );
    }, [transaction?.data?.type]);

    useEffect(() => {
        if (transaction?.data?.gasLimit) {
            setGasLimit(transaction.data.gasLimit as any);
        }
    }, [transaction?.data?.gasLimit]);

    useEffect(() => {
        if (transaction && transaction.data && gasMode !== 'custom' && gasLimit) {
            if (isEIP1559Type(transaction.data.type)) {
                setGasFeeDisplay(
                    evmApi.gasFee({
                        gasLimit: gasLimit,
                        baseFee: bnToHex(toWei(transaction.gasFee?.baseFee, 'gwei')),
                        maxFeePerGas: maxFee,
                        maxPriorityFeePerGas,
                    })
                );
            } else {
                setGasFeeDisplay(
                    evmApi.gasFee({
                        gasLimit: gasLimit,
                        baseFee: bnToHex(toWei(transaction.gasFee?.baseFee, 'gwei')),
                        gasPrice: maxFee,
                    })
                );
            }
        } else if (transaction.gasFeeDisplay) {
            setGasFeeDisplay(transaction.gasFeeDisplay);
        }
    }, [gasMode, transaction?.data, transaction.gasFeeDisplay, visible]);

    const estimatedTime = (mode?: GasFeeMode): string => {
        if (!transaction.gasFee) {
            return '';
        }

        let time: number;
        if (mode == GasFeeMode.low) {
            time = transaction.gasFee.low.maxWaitTime;
        } else if (mode == GasFeeMode.medium) {
            time = transaction.gasFee.medium.maxWaitTime;
        } else if (mode == GasFeeMode.high) {
            time = transaction.gasFee.high.maxWaitTime;
        } else {
            time = transaction.gasFee.low.maxWaitTime;
        }
        let displayTime: string;
        if (time / 1000 >= 60) {
            displayTime = `>${time / 1000 / 60}min`;
        } else {
            displayTime = `<${time / 1000}s`;
        }

        return displayTime;
    };

    const usdDisplay = (gasFee?: GasFee): string => {
        if (!transaction.data || !transaction.prices || !gasFee) {
            return '';
        }
        if (isEIP1559Type(transaction.data.type)) {
            //Max Fee: 0.00032 ETH(≈0.01 $)

            const maxFeeFormat = `${t('sign.max_fee')} ${gasFee.maxFee} ${getNativeSymbol(tokenProvider.chain)}`;
            const price = formatDisplayPrice(gasFee.maxFee, transaction.prices);
            if (price === '') {
                return maxFeeFormat;
            }
            return maxFeeFormat + `(${price})`;
        } else {
            return formatDisplayPrice(gasFee.maxFee, transaction.prices);
        }
    };

    const formatDisplayPrice = (fee: string, prices: TokenPrice[]): string => {
        return prices.length > 0 && prices[0].currencies && prices[0].currencies.length > 0
            ? formatPrice(fee, prices[0].currencies[0].price)
            : '';
    };

    const selectGasMode = (mode: GasFeeMode) => {
        setErrorTip(false);
        setErrorTip1(undefined);
        setErrorTip2(undefined);
        if (mode !== gasMode) {
            setGasMode(mode);
            applyGasMode(mode);
        }
    };

    const applyGasMode = (mode: GasFeeMode) => {
        if (transaction.gasFee && mode != GasFeeMode.custom) {
            const maxFeeValue = bnToHex(toWei(transaction.gasFee[mode].maxFeePerGas, 'gwei'));
            setMaxFee(maxFeeValue);
            gasForm.setFieldsValue({ 'max-fee': feeDisplay(maxFeeValue) });
            const maxPriorityFeePerGasValue = bnToHex(toWei(transaction.gasFee[mode].maxPriorityFeePerGas, 'gwei'));
            setMaxPriorityFeePerGas(maxPriorityFeePerGasValue);
            gasForm.setFieldsValue({ 'max-priority-fee': feeDisplay(maxPriorityFeePerGasValue) });
            if (transaction.gasLimit) {
                setGasLimit(transaction.gasLimit);
            }
        }
    };

    const setCustomGasLimit = (value: string) => {
        setErrorTip(false);
        if (gasMode !== GasFeeMode.custom) {
            setGasMode(GasFeeMode.custom);
        }
        const bn = Number(value);
        value = isNaN(bn) ? '0x0' : toHexPrefixString(bn);
        setGasLimit(value);
        if (Number(value) < Number(transaction.gasLimit)) {
            setErrorTip(true);
        } else {
            setErrorTip(false);
        }
    };

    const setCustomMaxPriorityFee = (value: string) => {
        setErrorTip1(undefined);
        const bn = toBigNumber(value);
        value = bn.isNaN() || value === '' ? '0' : bn.toString();
        value = bnToHex(toWei(value, 'gwei'));
        if (gasMode !== GasFeeMode.custom) {
            setGasMode(GasFeeMode.custom);
        }
        setMaxPriorityFeePerGas(value);
        maxPriorityFeeErrorTip(value);
    };

    const setCustomMaxFee = (value: string) => {
        console.log('setCustomMaxFee', value);
        setErrorTip2(undefined);
        const bn = toBigNumber(value);
        value = bn.isNaN() || value === '' ? '0' : bn.toString();
        value = bnToHex(toWei(value, 'gwei'));
        if (gasMode !== GasFeeMode.custom) {
            setGasMode(GasFeeMode.custom);
        }
        setMaxFee(value);
        maxFeeErrorTip(value);
    };

    const addGasLimit = () => {
        if (transaction.gasLimit && Number(gasLimit) < Number(transaction.gasLimit)) {
            setCustomGasLimit(Number(transaction.gasLimit).toString());
        } else {
            setCustomGasLimit((Number(gasLimit) + 1).toString());
        }
    };

    const minusGasLimit = () => {
        if (Number(gasLimit) >= Number(transaction.gasLimit) + 1) {
            setCustomGasLimit((Number(gasLimit) - 1).toString());
        }
    };

    const addMaxPriorityFee = () => {
        const value = new BigNumber(maxPriorityFeePerGas ?? '0x0').plus(1000000000);
        const hex = addHexPrefix(value.toString(16));
        setCustomMaxPriorityFee(fromWei(hex, 'gwei'));
        gasForm.setFieldsValue({ 'max-priority-fee': feeDisplay(hex) });
    };

    const minusMaxPriorityFee = () => {
        let value = new BigNumber(maxPriorityFeePerGas ?? '0x0').minus(1000000000);
        if (value.isNegative()) {
            value = new BigNumber(0);
        }
        const hex = addHexPrefix(value.toString(16));
        setCustomMaxPriorityFee(fromWei(hex, 'gwei'));
        gasForm.setFieldsValue({ 'max-priority-fee': feeDisplay(hex) });
    };

    const addMaxFee = () => {
        const value = new BigNumber(maxFee).plus(1000000000);
        const hex = addHexPrefix(value.toString(16));
        setCustomMaxFee(fromWei(hex, 'gwei'));
        gasForm.setFieldsValue({ 'max-fee': feeDisplay(hex) });
    };

    const minusMaxFee = () => {
        let value = new BigNumber(maxFee).minus(1000000000);
        if (value.isNegative()) {
            value = new BigNumber(0);
        }
        const hex = addHexPrefix(value.toString(16));
        setCustomMaxFee(fromWei(hex, 'gwei'));
        gasForm.setFieldsValue({ 'max-fee': feeDisplay(hex) });
    };

    useEffect(() => {
        if (isEIP1559Type(transaction?.data?.type) && maxPriorityFeePerGas) {
            maxPriorityFeeErrorTip(maxPriorityFeePerGas);
        }

        maxFeeErrorTip(maxFee);
    }, []);

    const maxPriorityFeeErrorTip = (value: PrefixedHexString) => {
        if (!transaction.gasFee) return;

        if (
            new BigNumber(value).isGreaterThan(
                toWeiBigNumber(transaction.gasFee.medium.maxPriorityFeePerGas, 'gwei').multipliedBy(10)
            )
        ) {
            setErrorTip1(t('sign.pr_fee_is_high'));
        } else if (
            new BigNumber(value).isLessThan(toWeiBigNumber(transaction.gasFee.low.maxPriorityFeePerGas, 'gwei'))
        ) {
            setErrorTip1(t('sign.pr_fee_is_low'));
        } else {
            setErrorTip1(undefined);
        }

        if (toBigNumber(value).gte(toBigNumber(maxFee))) {
            setErrorTip2(t('sign.fee_low_priority'));
        } else {
            if (
                toBigNumber(maxFee).gt(toWeiBigNumber(transaction.gasFee.medium.maxFeePerGas, 'gwei').multipliedBy(10))
            ) {
                setErrorTip2(t('sign.fee_is_high'));
            } else if (toBigNumber(maxFee).lt(toWeiBigNumber(transaction.gasFee.low.maxFeePerGas, 'gwei'))) {
                setErrorTip2(t('sign.fee_is_low'));
            } else {
                setErrorTip2(undefined);
            }
        }
    };

    const maxFeeErrorTip = (value: PrefixedHexString) => {
        if (!transaction.gasFee) return;

        if (
            isEIP1559Type(transaction?.data?.type) &&
            maxPriorityFeePerGas &&
            toBigNumber(value).lte(maxPriorityFeePerGas)
        ) {
            setErrorTip2(t('sign.fee_low_priority'));
        } else if (
            toBigNumber(value).gt(toWeiBigNumber(transaction.gasFee.medium.maxFeePerGas, 'gwei').multipliedBy(10))
        ) {
            setErrorTip2(t('sign.fee_is_high'));
        } else if (toBigNumber(value).lt(toWeiBigNumber(transaction.gasFee.low.maxFeePerGas, 'gwei'))) {
            setErrorTip2(t('sign.fee_is_low'));
        } else {
            setErrorTip2(undefined);
        }
    };

    const checkSaveBtEnable = (
        minPriorityFee: BigNumber,
        minFee: BigNumber,
        maxPriorityFee: BigNumber,
        maxFee: BigNumber
    ): boolean => {
        if (errorTip) {
            return false;
        } else if (errorTip1 === t('sign.pr_fee_is_low')) {
            return false;
        } else if (errorTip2 === t('sign.fee_is_low') || errorTip2 === t('sign.fee_low_priority')) {
            return false;
        } else if (isEIP1559Type(transaction?.data?.type)) {
            return maxFee.gte(minFee) && maxPriorityFee.gte(minPriorityFee) && maxFee.gt(maxPriorityFee);
        } else {
            return maxFee.gte(minFee);
        }
    };

    const saveGas = (values: unknown) => {
        console.log('saveGas', gasMode, gasLimit, maxPriorityFeePerGas, maxFee);
        if (gasMode && gasMode !== transaction.gasFeeMode) {
            dispatch(setGasFeeMode(gasMode));
        }
        if (gasMode === GasFeeMode.custom && transaction.data) {
            if (isEIP1559Type(transaction.data.type)) {
                dispatch(
                    updateTransaction({
                        maxPriorityFeePerGas,
                        maxFeePerGas: maxFee,
                        gasLimit: gasLimit,
                    })
                );
            } else {
                dispatch(
                    updateTransaction({
                        gasPrice: maxFee,
                        gasLimit: gasLimit,
                    })
                );
            }
        }

        openGasDrawer();
    };

    const estimateFee = (limit: PrefixedHexString, fee: PrefixedHexString): string => {
        return fromWeiFormat(addHexPrefix(toBigNumber(limit).multipliedBy(fee).toString(16)), 'ether', 6);
    };

    const feeDisplay = (weiFee: PrefixedHexString): string => {
        return fromWeiFormat(weiFee, 'gwei', 9);
    };

    useEffect(() => {
        if (gasMode && gasMode !== GasFeeMode.custom) {
            applyGasMode(gasMode);
        }
    }, [transaction.gasFee, transaction.gasLimit]);

    return (
        <ParticleDrawer
            title={t('sign.edit_priority')}
            visible={
                visible &&
                !!transaction &&
                !!transaction.gasFee &&
                !!transaction.data &&
                !!transaction.gasFeeMode &&
                !!gasFeeDisplay
            }
            placement="bottom"
            onClose={openGasDrawer}
            className="evm-gas"
            contentWrapperStyle={{ height: 'auto' }}
        >
            {!!transaction &&
                !!transaction.gasFee &&
                !!transaction.data &&
                !!transaction.gasFeeMode &&
                !!gasFeeDisplay && (
                    <>
                        <div className="gas-content-box">
                            <div className="eth-amount">
                                {gasFeeDisplay?.fee ?? gasFeeDisplay?.maxFee} {getNativeSymbol(tokenProvider.chain)}
                            </div>

                            <div className="eth-amount-about">{usdDisplay(gasFeeDisplay)}</div>

                            <div
                                className="speed-time"
                                style={estimatedTime(gasMode).search('min') != -1 ? { color: '#F55F0D' } : {}}
                            >
                                {estimatedTime(gasMode)}
                            </div>
                            <div className="speed-situation">
                                <div className="situation-img">
                                    <Radio
                                        className="radio-item"
                                        checked={gasMode === GasFeeMode.low}
                                        onClick={() => selectGasMode(GasFeeMode.low)}
                                    />
                                    <Radio
                                        className="radio-item"
                                        checked={gasMode === GasFeeMode.medium}
                                        onClick={() => selectGasMode(GasFeeMode.medium)}
                                    />
                                    <Radio
                                        className="radio-item"
                                        checked={gasMode === GasFeeMode.high}
                                        onClick={() => selectGasMode(GasFeeMode.high)}
                                    />
                                </div>
                                <div className="situation-line" />
                                <div className="situation-text">
                                    <span>{t('sign.gas_low')}</span>
                                    <span>{t('sign.gas_medium')}</span>
                                    <span>{t('sign.gas_high')}</span>
                                </div>
                            </div>
                            <div className="more-details" onClick={() => setMoreDetails(!moreDetails)}>
                                {t('sign.advanced_options')}
                                {moreDetails ? (
                                    <SVGIcon className="arrow-icon" name="arrow_icon" data-fold="true" />
                                ) : (
                                    <SVGIcon className="arrow-icon" name="arrow_icon" data-fold="false" />
                                )}
                            </div>

                            <div className="advanced-options">
                                <Form
                                    className="options-form"
                                    onFinish={saveGas}
                                    form={gasForm}
                                    layout="vertical"
                                    ref={optionsForm}
                                >
                                    {moreDetails && (
                                        <div className="form-son">
                                            <div>
                                                <div className="img-box-minus" onClick={minusGasLimit}>
                                                    <MinusCircleFilled className="minus-icon" />
                                                </div>
                                                <div className="img-box-add" onClick={addGasLimit}>
                                                    <PlusCircleFilled className="add-icon" />
                                                </div>
                                                {errorTip && transaction?.gasLimit && (
                                                    <div className="error-tip mt-e0">
                                                        {t('sign.gas_limit_must').format(
                                                            toBigNumber(transaction?.gasLimit).toString()
                                                        )}
                                                    </div>
                                                )}
                                                <Form.Item
                                                    label={t('sign.gas_limit')}
                                                    getValueFromEvent={(event) => {
                                                        return event.target.value
                                                            .replace(/[^\d.]/g, '')
                                                            .replace(/(\.)(\d*)(\1*)/g, '$1$2');
                                                    }}
                                                >
                                                    <Input
                                                        value={toBigNumber(gasLimit).toString()}
                                                        maxLength={9}
                                                        onChange={(e) => setCustomGasLimit(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </div>

                                            {isEIP1559Type(transaction?.data?.type) && (
                                                <div>
                                                    <div className="estimate mt1">
                                                        {t('sign.estimate')}
                                                        <span className="estimate-val">
                                                            {' '}
                                                            {trimDecimals(
                                                                transaction?.gasFee?.medium?.maxPriorityFeePerGas || 0,
                                                                9
                                                            )}{' '}
                                                            GWEI
                                                        </span>
                                                    </div>
                                                    <div className="about-val mt-a1">
                                                        {!!gasLimit && estimateFee(gasLimit, maxPriorityFeePerGas!)}{' '}
                                                        {getNativeSymbol(tokenProvider.chain)}
                                                    </div>
                                                    <div className="img-box-minus" onClick={minusMaxPriorityFee}>
                                                        <MinusCircleFilled className="minus-icon" />
                                                    </div>
                                                    <div className="img-box-add" onClick={addMaxPriorityFee}>
                                                        <PlusCircleFilled className="add-icon" />
                                                    </div>
                                                    {errorTip1 && <div className="error-tip mt-e0">{errorTip1}</div>}
                                                    <Form.Item
                                                        label={t('sign.max_priority_fee')}
                                                        name="max-priority-fee"
                                                        getValueFromEvent={(event) => {
                                                            return event.target.value
                                                                .replace(/[^\d.]/g, '')
                                                                .replace(/(\.)(\d*)(\1*)/g, '$1$2');
                                                        }}
                                                    >
                                                        <Input
                                                            maxLength={17}
                                                            className="special-input"
                                                            defaultValue={feeDisplay(maxPriorityFeePerGas!)}
                                                            onInput={(e: any) => {
                                                                const value = e.target.value;
                                                                e.target.value = value
                                                                    .replace(/[^\d.]/g, '')
                                                                    .replace(/\D*(\d*)(\.?)(\d{0,9})\d*/, '$1$2$3');
                                                            }}
                                                            onChange={(e) => setCustomMaxPriorityFee(e.target.value)}
                                                        />
                                                    </Form.Item>
                                                </div>
                                            )}

                                            <div>
                                                <div className="estimate mt1">
                                                    {t('sign.estimate')}
                                                    <span className="estimate-val">
                                                        {' '}
                                                        {trimDecimals(
                                                            transaction?.gasFee?.medium?.maxFeePerGas || 0,
                                                            9
                                                        )}{' '}
                                                        GWEI
                                                    </span>
                                                </div>
                                                <div className="about-val mt-a1">
                                                    {!!gasLimit && estimateFee(gasLimit, maxFee)}{' '}
                                                    {getNativeSymbol(tokenProvider.chain)}
                                                </div>
                                                <div className="img-box-minus" onClick={minusMaxFee}>
                                                    <MinusCircleFilled className="minus-icon" />
                                                </div>
                                                <div className="img-box-add" onClick={addMaxFee}>
                                                    <PlusCircleFilled className="add-icon" />
                                                </div>
                                                {errorTip2 && <div className="error-tip mt-e0">{errorTip2}</div>}
                                                <Form.Item
                                                    label={t('sign.max_fee')}
                                                    name="max-fee"
                                                    getValueFromEvent={(event) => {
                                                        return event.target.value
                                                            .replace(/[^\d.]/g, '')
                                                            .replace(/(\.)(\d*)(\1*)/g, '$1$2');
                                                    }}
                                                >
                                                    <Input
                                                        maxLength={17}
                                                        className="special-input"
                                                        defaultValue={feeDisplay(maxFee)}
                                                        onInput={(e: any) => {
                                                            const value = e.target.value;
                                                            e.target.value = value
                                                                .replace(/[^\d.]/g, '')
                                                                .replace(/\D*(\d*)(\.?)(\d{0,9})\d*/, '$1$2$3');
                                                        }}
                                                        onChange={(e) => setCustomMaxFee(e.target.value)}
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    )}
                                </Form>
                            </div>
                        </div>
                        <div className="item-save-btn">
                            <Button
                                className="save-btn"
                                type="primary"
                                disabled={
                                    !checkSaveBtEnable(
                                        toWeiBigNumber(transaction?.gasFee?.low.maxPriorityFeePerGas, 'gwei'),
                                        toWeiBigNumber(transaction?.gasFee?.low.maxFeePerGas, 'gwei'),
                                        toBigNumber(maxPriorityFeePerGas),
                                        toBigNumber(maxFee)
                                    ) || toBigNumber(gasLimit).lt(toBigNumber(transaction?.gasLimit))
                                }
                                onClick={() => optionsForm.current.submit()}
                            >
                                {t('common.save')}
                            </Button>
                        </div>
                        <PowerFooter />
                    </>
                )}
        </ParticleDrawer>
    );
}

export default EvmGas;
