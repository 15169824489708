import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js/max';
import { bufferToHex, isHexString, ToBufferInputTypes, PrefixedHexString, toBuffer } from '@ethereumjs/util';
import { AuthType } from './../api/model/login-type';
import { BasicQuery, LoginQuery, SignQuery } from '../provider/bundle';
import device from './detect-device';
import BN from 'bn.js';
import * as Sentry from '@sentry/react';

export function shortString(str: any): string {
    if (Array.isArray(str)) {
        str = '[' + str.toString() + ']';
    }
    if (str) {
        if (typeof str.toString === 'function') {
            str = str.toString();
        }
        if (str.length <= 10) {
            return str;
        }
        return `${str.slice(0, 5)}...${str.slice(str.length - 5, str.length)}`;
    }
    return '';
}

export const displayEmail = (email: string): string => {
    if (!email) {
        return '';
    }
    const parts = email.split('@');
    if (parts[0].length <= 3) {
        return `${parts[0]}****@${parts[1]}`;
    } else {
        return `${parts[0].slice(0, 3)}****@${parts[1]}`;
    }
};

export const displayPhone = (phone: string) => {
    if (!phone) {
        return '';
    }
    if (isValidPhoneNumber(phone)) {
        const phoneNumber = parsePhoneNumber(phone);
        const countryCode = phoneNumber.countryCallingCode;
        const code = phoneNumber.nationalNumber.toString();

        if (code && countryCode) {
            const count = code.length >= 6 ? 3 : Math.floor(code.length / 2);
            return `+${countryCode} ${code.slice(0, count)}****${code.slice(-count)}`;
        }
    }
    return phone;
};

declare global {
    interface String {
        format(...params: string[]): string;
        titleCase(): string;
    }
}

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.format = function (...params: string[]): string {
    const args = params;
    return this.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != 'undefined' ? args[number] : match;
    });
};

String.prototype.titleCase = function (): string {
    if (this.length === 0) {
        return '';
    } else if (this.length === 1) {
        return this.toUpperCase();
    }

    return this.slice(0, 1).toUpperCase() + this.slice(1);
};

export function assert(condition: any, message?: any) {
    if (!condition) {
        throw new Error(message || 'Assertion failed');
    }
}

export const isSignQuery = (props: any): props is SignQuery =>
    typeof (props as SignQuery)['message'] !== 'undefined' &&
    typeof (props as SignQuery)['method'] !== 'undefined' &&
    typeof (props as SignQuery)['chain_id'] !== 'undefined';

export function checkParam(params: LoginQuery | SignQuery | BasicQuery): boolean {
    if (
        !params.project_uuid ||
        typeof params.project_uuid !== 'string' ||
        !params.project_client_key ||
        typeof params.project_client_key !== 'string' ||
        !params.project_app_uuid ||
        typeof params.project_app_uuid !== 'string' ||
        !params.device_id ||
        typeof params.device_id !== 'string' ||
        !params.sdk_version ||
        typeof params.sdk_version !== 'string' ||
        params.sdk_version.length === 0 ||
        !params.chain_name ||
        typeof params.chain_name !== 'string'
    ) {
        console.log('basic params error');
        Sentry.captureException(new Error('check query param error'), {
            extra: {
                queryParam: JSON.stringify(params),
            },
        });
        return false;
    }
    if (isSignQuery(params)) {
        const result =
            typeof params['message'] === 'string' &&
            typeof params['method'] === 'string' &&
            typeof params['chain_id'] === 'number';
        console.assert(result, 'sign params error');
        return result;
    }

    if ('login_type' in params && device.authEmbed()) {
        return !params.login_type || !isSocialLogin(params.login_type);
    }

    return true;
}

export function isSocialLogin(type: string): boolean {
    return type !== AuthType.phone && type !== AuthType.email && type !== AuthType.jwt;
}

/**
 * Returns `true` if the given value is nullish.
 *
 * @param value - The value being checked.
 * @returns Whether the value is nullish.
 */
export function isNullish(value: any) {
    return value === null || value === undefined;
}

/**
 * Convert a value to a Buffer.
 *
 * @param value - The value to convert to a Buffer.
 * @returns The given value as a Buffer.
 */
export function legacyToBuffer(value: ToBufferInputTypes) {
    return typeof value === 'string' && !isHexString(value) ? Buffer.from(value) : toBuffer(value);
}

export function toHexPrefixString(value: ToBufferInputTypes): PrefixedHexString {
    if (typeof value === 'string' && isHexString(value)) {
        return value;
    } else {
        return bufferToHex(legacyToBuffer(value));
    }
}

/**
 * Convert BN to 0x-prefixed hex string.
 */
export function bnToHex(value: BN): PrefixedHexString {
    return `0x${value.toString(16)}`;
}

export function popupwindow(url: string, title: string, w: number, h: number): Window | null {
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    return window.open(
        url,
        title,
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
            w +
            ', height=' +
            h +
            ', top=' +
            top +
            ', left=' +
            left
    );
}

export function popupwindowNoLimit(url: string, title: string, w: number, h: number): Window | null {
    const left = screen.width / 2 - w / 2;
    const top = screen.height / 2 - h / 2;
    return window.open(url, title, 'width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
}

export function getVersion(): string {
    const packages = require('./../../package.json');
    return `webauth_${packages.version}`;
}

export function isInIframe(): boolean {
    return window.location !== window.parent.location;
}

/**
 * 窗口信息传递
 */
export function authPostMessage(key: string, data: any) {
    data = {
        ...data,
        name: key,
        _: Date.now(),
    };
    try {
        window.parent.postMessage(data, '*');
    } catch (error) {
        // pass
    }
    try {
        window.opener.postMessage(data, '*');
    } catch (error) {
        // pass
    }
    try {
        window.opener.parent.postMessage(data, '*');
    } catch (error) {
        // pass
    }
    sessionStorage.setItem(key, JSON.stringify(data));
    localStorage.setItem(key, JSON.stringify(data));
}

/**
 * 口信息接收
 * @param key
 * @param cb
 * @returns
 */
export function authReceiveMessage(key: string, cb: any) {
    const messageHandler = (event: any) => {
        if (event instanceof MessageEvent || event instanceof StorageEvent) {
            let data;
            if (event instanceof MessageEvent) {
                data = event.data;
            } else {
                // @ts-ignore
                data = JSON.parse(event.newValue);
            }

            if (data?.name === key) {
                const newData = {
                    ...data,
                    messageType: event instanceof MessageEvent ? 'message' : 'storage',
                };
                console.log('messageData', data);
                cb(newData);
            }
        }
    };

    window.addEventListener('message', messageHandler);
    window.addEventListener('storage', messageHandler);

    return {
        destroyMessage() {
            window.removeEventListener('message', messageHandler);
            window.removeEventListener('storage', messageHandler);
        },
    };
}
