import { v4 as uuidv4 } from 'uuid';
import { AuthType } from '../api/model/login-type';
import pnApi from '../api/pn-api';
import tokenProvider from '../provider';
import { LoginQuery } from '../provider/bundle';
import { isInIframe, popupwindow } from '../utils/common-utils';
import device from '../utils/detect-device';
import { handleStorageEvent } from '../utils/popup-login';
import url from '../utils/url-utils';
import { PreferenceKey } from './../repository/index';

export async function authLogin(authType: AuthType, bindAccountVerifyToken = '') {
    const inIframe = window.location !== window.parent.location;
    if (isInIframe() && device.isBlockingThirdpartyCookiesBrowser() && !bindAccountVerifyToken) {
        console.log('social login in iframe, popup to authorized');
        // popup auth login window -> redirect to social login -> redirect ro auth
        window.removeEventListener('storage', handleStorageEvent);
        window.addEventListener('storage', handleStorageEvent);
        popupwindow(url.buildLoginUrl({ authType }), 'oauth-login-window', 475, 770);
        return;
    }

    const prompt = bindAccountVerifyToken ? 'select_account' : (tokenProvider.params as LoginQuery).prompt;
    const redirectUri = encodeURIComponent((process.env.REACT_APP_AUTH_DOMAIN as string) + '/');

    const state = `${authType}@${uuidv4()}@${bindAccountVerifyToken || ''}`; // authType@uuidv4@bindAccountVerifyToken|''

    let loginUrl: string | undefined;
    if (authType === AuthType.apple) {
        const clientId = process.env.REACT_APP_APPLE_CLIENT_ID as string;
        const nonce = uuidv4();
        loginUrl = `https://appleid.apple.com/auth/authorize?response_type=code&response_mode=query&client_id=${clientId}&state=${state}&redirect_uri=${redirectUri}&nonce=${nonce}`;
    } else if (authType === AuthType.google) {
        const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;
        loginUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=openid%20email%20profile&include_granted_scopes=true&state=${state}&redirect_uri=${redirectUri}&response_type=code&client_id=${clientId}`;
        if (prompt) {
            loginUrl += `&prompt=${prompt}`;
        }
    } else if (authType === AuthType.facebook) {
        const clientId = process.env.REACT_APP_FACEBOOK_ID as string;
        loginUrl = `https://www.facebook.com/v14.0/dialog/oauth?client_id=${clientId}&response_type=code&scope=public_profile,email&redirect_uri=${redirectUri}&state=${state}`;
    } else if (authType === AuthType.discord) {
        const clientId = process.env.REACT_APP_DISCORD_ID as string;
        loginUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=identify%20email&state=${state}`;
        if (prompt) {
            loginUrl += `&prompt=${prompt}`;
        }
    } else if (authType === AuthType.github) {
        const clientId = process.env.REACT_APP_GITHUB_ID as string;
        loginUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
    } else if (authType === AuthType.twitch) {
        const clientId = process.env.REACT_APP_TWITCH_ID as string;
        loginUrl = `https://id.twitch.tv/oauth2/authorize?client_id=${clientId}&response_type=code&scope=user%3Aread%3Aemail&redirect_uri=${redirectUri}&state=${state}`;
    } else if (authType === AuthType.microsoft) {
        const clientId = process.env.REACT_APP_MICROSOFT_ID as string;
        loginUrl = `https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&response_mode=query&scope=User.Read&state=${state}`;
        if (prompt) {
            loginUrl += `&prompt=${prompt}`;
        }
    } else if (authType === AuthType.linkedin) {
        const clientId = process.env.REACT_APP_LINKEDIN_ID as string;
        loginUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=r_liteprofile%20r_emailaddress`;
    } else if (authType === AuthType.twitter) {
        loginUrl = await loginWithTwitter(bindAccountVerifyToken);
    }

    if (!loginUrl) return;

    if (authType !== AuthType.twitter) {
        sessionStorage.setItem(PreferenceKey.PN_TEMP_SOCIAL_STATE_KEY, state);
    }

    console.log('loginUrl', loginUrl);

    if (inIframe || bindAccountVerifyToken) {
        window.removeEventListener('storage', handleStorageEvent);
        window.addEventListener('storage', handleStorageEvent);

        const iframeWidth = authType === AuthType.facebook ? 800 : 475;
        const iframeHeight = 770;

        const win = popupwindow(loginUrl, 'oauth-login-window', iframeWidth, iframeHeight);

        return {
            winParams: {
                loginUrl,
                title: 'oauth-login-window',
                iframeWidth,
                iframeHeight,
            },
            win,
        };
    } else {
        // window.open(loginUrl, '_parent');
        window.location.replace(loginUrl); //adapt firefox
    }
}

const loginWithTwitter = async (bindAccountVerifyToken = ''): Promise<string> => {
    const token = await pnApi.requestTwitterToken();
    const loginUrl = `https://api.twitter.com/oauth/authorize?oauth_token=${token}`;
    sessionStorage.setItem(PreferenceKey.PN_TEMP_SOCIAL_STATE_KEY, `twitter@${token}@${bindAccountVerifyToken}`);
    return loginUrl;
};
