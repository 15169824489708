import { useInterval, useRequest } from 'ahooks';
import { Button, Modal, message } from 'antd';
import throttle from 'lodash/throttle';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiError } from '../../../api/api-error';
import { AuthType } from '../../../api/model/login-type';
import pnApi from '../../../api/pn-api';
import SVGIcon from '../../../components/icon/svg-icon';
import PowerFooter from '../../../components/power-footer';
import AuthCode from '../../../components/react-auth-code-input';
import TurnstileModal from '../../../components/turnstileModal';
import { useParticleAuth, useUserInfo } from '../../../context';
import tokenProvider from '../../../provider';
import { displayEmail, displayPhone, isSignQuery } from '../../../utils/common-utils';
import hashPassword from '../../../utils/hashPassword';
import { isEMVMethod } from '../../../utils/transaction-utils';
import { getAccountList } from '../../loginAccount';
import { PageType } from './config';
import './index.less';

interface IParams {
    account: string; // 进入页面时会给这个账户发送验证码，这个账户可能是安全账号，也可能是要绑定的账号
    pageType: PageType;
    unbindAccount: string; //解绑手机、邮箱时使用
    authType: AuthType;
    verifyToken: string;
    redirectUrl: string;
    password: string; // 重置密码时需要
}

const AccountVerify = () => {
    const route = useNavigate();
    const { t } = useTranslation();

    const { state } = useLocation();

    const { account, unbindAccount, pageType, authType, verifyToken, redirectUrl, password } = state as IParams;

    const [code, setCode] = useState<string>('');

    const [interval, setInterval] = useState<number | undefined>(1000);

    const [countdown, setCountdown] = useState(60);

    const { setUserInfo } = useUserInfo();

    const [errorTip, setErrorTip] = useState<string>();

    const { userInfo } = useParticleAuth();

    const inputRef = useRef(null);

    const [turnstileVisible, setTurnstileVisible] = useState(false);

    useInterval(() => {
        if (countdown > 0) {
            const result = countdown - 1;
            setCountdown(result);
            if (result === 0) {
                setInterval(undefined);
            }
        }
    }, interval);

    // 获取验证码
    const { run: runGetCode } = useRequest(
        async (params) => {
            console.log('get code', params);
            if (pageType === PageType.BindLoginAccount) {
                params = {
                    [authType as string]: account,
                    cf_turnstile_response: params.cf_turnstile_response,
                };
                return pnApi.securityAccountsCode(params).then((res) => {
                    return res;
                });
            } else {
                return pnApi.securityAccountsVerifyCode(params);
            }
        },
        {
            manual: true,
            onSuccess: (result, params) => {
                if (!interval) {
                    setCountdown(60);
                    setInterval(1000);
                }
            },
            onError: (error) => {
                setCountdown(0);
                setInterval(undefined);
            },
        }
    );

    // 验证安全账号，获取token
    const { loading: verifyLoading, runAsync: securityAccountsVerifyRequest } = useRequest(
        pnApi.securityAccountsVerify,
        {
            manual: true,
            onError: (error: any) => {
                if (error?.error_code === ApiError.InvalidCode) {
                    setErrorTip(t('login.invalid_code'));
                } else if (error?.error_code === ApiError.ResendCode) {
                    setErrorTip(t('login.please_send_again'));
                } else {
                    error.message && message.error(error.message);
                }
            },
        }
    );

    // 绑定登录邮箱或手机号
    const { loading: loadBindingLoading, runAsync: loginBindingsAsyncRequest } = useRequest(
        async (params) => {
            console.log('loginBindingsAsyncRequest', params);
            return pnApi.loginBindings(params).then((res) => {
                const _userInfo = tokenProvider.userInfo;
                if (authType === AuthType.email) {
                    _userInfo.email = res.email;
                    tokenProvider.userInfo = _userInfo;
                    setUserInfo(tokenProvider.userInfo);
                } else if (authType === AuthType.phone) {
                    _userInfo.phone = res.phone;
                    tokenProvider.userInfo = _userInfo;
                    setUserInfo(tokenProvider.userInfo);
                }
            });
        },
        {
            manual: true,
            onSuccess: (data) => {
                message.success(t('new.bind_login_account_success'));
            },
            onError: (error: any) => {
                console.log('loginBindings', error);
                let messageValue = error.message;
                if (error?.error_code === ApiError.InvalidCode) {
                    setErrorTip(t('login.invalid_code'));
                    return;
                } else if (error?.error_code === ApiError.ResendCode) {
                    setErrorTip(t('login.please_send_again'));
                    return;
                } else if (error.error_code === 20109) {
                    messageValue =
                        authType == AuthType.email ? t('error.server_email_20109') : t('error.server_phone_20109');
                } else {
                    messageValue = error?.extra?.[0] || error.message;
                }

                Modal.error({
                    title: messageValue,
                    onOk: () => {
                        (inputRef.current as any)?.clear();
                        if (error.error_code === 20109) {
                            // back
                            route(-1);
                        }
                    },
                });
            },
        }
    );

    // 重置支付密码
    const { loading: resetLoading, run: runResetPaymentPassword } = useRequest(pnApi.securityPaymentsReset, {
        manual: true,
        onError: (error: any) => {
            if (error?.error_code === ApiError.InvalidCode) {
                setErrorTip(t('login.invalid_code'));
            } else if (error?.error_code === ApiError.ResendCode) {
                setErrorTip(t('login.please_send_again'));
            }
        },
        onSuccess: (result, params) => {
            setUserInfo({
                security_account: result,
            });
            if (isSignQuery(tokenProvider.params)) {
                if (isEMVMethod(tokenProvider.params.method)) {
                    route('/evm-chain/sign', { replace: true });
                } else {
                    route('/solana/sign', { replace: true });
                }
            } else {
                // route('/account/security', { replace: true });
                route(-2);
            }
        },
    });

    // 解绑登录账号
    const { loading: deleteLoginAccountLoading, run: deleteLoginAccount } = useRequest(pnApi.deleteLoginBinding, {
        manual: true,
        onSuccess: (result) => {
            const _userInfo = tokenProvider.userInfo;
            _userInfo.passkeys_id = undefined;
            tokenProvider.userInfo = _userInfo;
            setUserInfo({
                passkeys_id: undefined,
            });
            route(-2);
        },
    });

    const verifyCodeMethod = useMemo(() => {
        return account?.includes('@') ? 'email' : 'phone';
    }, [account]);

    useEffect(() => {
        requestVerifyCode();
    }, [account]);

    const requestVerifyCode = () => {
        setErrorTip('');
        if (pageType === PageType.BindLoginAccount && authType === AuthType.phone) {
            setTurnstileVisible(true);
        } else {
            runGetCode({
                verify_code_method: verifyCodeMethod,
            });
        }
    };

    const onCodeInputChange = (code: string) => {
        setErrorTip('');
        setCode(code);

        if (code.length === 6) {
            throttleConfirmCode(code);
        }
    };

    const throttleConfirmCode = useCallback(
        throttle(
            (code) => {
                confirmCode(code);
            },
            1000,
            {
                leading: true,
                trailing: false,
            }
        ),
        []
    );

    const resetPassword = (codeValue?: string) => {
        runResetPaymentPassword({
            password: hashPassword(password!),
            verify_code_method: verifyCodeMethod,
            code: codeValue || code,
        });
    };

    const confirmCode = (code = '') => {
        console.log('pageType', pageType);
        if (errorTip) {
            console.log('errorTip', errorTip);
            return;
        }
        let data: any = { code };
        if (pageType === PageType.ResetPaymentPassword) {
            resetPassword(code);
        } else if (pageType == PageType.VerifySecurityAccount_SetSecurityAccount) {
            // 绑定安全账号时前置验证安全账号
            securityAccountsVerifyRequest({
                verify_code_method: verifyCodeMethod,
                code,
            }).then((verifyToken) => {
                route('/account/bind', {
                    replace: true,
                    state: {
                        accountType: authType,
                        verifyToken,
                        redirectUrl,
                    },
                });
            });
        } else if (pageType === PageType.VerifySecurityAccount_BindLoginAccount) {
            // 绑定登录账号时前置验证安全账号
            securityAccountsVerifyRequest({
                verify_code_method: verifyCodeMethod,
                code,
            }).then((verifyToken) => {
                const accountList = getAccountList({
                    userInfo,
                    t,
                });
                const { id, value } = accountList.find((item) => item.type === authType) || {};
                // 验证成功后，根据 authType 执行不同逻辑
                // AuthType.phone，AuthType.email 跳转页面，其他的执行auth签名跳转登录授权
                if (authType === AuthType.phone || authType === AuthType.email || value || id) {
                    route('/login-account/bind', {
                        state: {
                            authType,
                            verifyToken,
                        },
                        replace: true,
                    });
                } else {
                    console.log('绑定第三方账号跳转到中转loading页面');
                    route('/login-account/bind-loading', {
                        state: {
                            authType,
                            verifyToken,
                        },
                        replace: true,
                    });
                }
            });
        } else if (pageType === PageType.BindLoginAccount) {
            data = { ...data, security_account_verify_token: verifyToken, [authType as string]: account };
            console.log('>>>BindLoginAccount', data);
            loginBindingsAsyncRequest(data).then((res) => {
                route(-2);
            });
        } else if (pageType === PageType.UnbindLoginAccount) {
            if (authType !== AuthType.passkeys) {
                return;
            }
            // 暂时只支持passkeys解绑
            console.log('>>>UnbindLoginAccount');
            securityAccountsVerifyRequest({
                verify_code_method: verifyCodeMethod,
                code,
            }).then((verifyToken) => {
                deleteLoginAccount({
                    id_combined: unbindAccount,
                    security_account_verify_token: verifyToken,
                });
            });
        }
    };

    const formatDisplayAccount = (): string => {
        if (account.includes('@')) {
            return displayEmail(account);
        } else {
            return displayPhone(account);
        }
    };

    useEffect(() => {
        const elements = document.getElementsByClassName('react-input-code');
        const contextmenuEvent = (e: any) => e.preventDefault();
        elements[0]?.addEventListener('contextmenu', contextmenuEvent);
        return () => {
            elements[0]?.removeEventListener('contextmenu', contextmenuEvent);
        };
    }, []);

    const handleCodeInputFocus = () => {
        const elements = document.getElementsByClassName('input-code-item');
        //@ts-ignore
        elements[Math.min(code.length, 5)]?.focus();
    };

    return (
        <div className="set-viery-container">
            <div className="account-header">
                <SVGIcon
                    className="icon-navigation-back"
                    name="circle_back"
                    onClick={() => {
                        route(-1);
                    }}
                />
            </div>
            <h2 className="set-email-title">{t('account.enter_code')}</h2>
            <p className="set-email-desc-2">{formatDisplayAccount()}</p>

            <div onClick={handleCodeInputFocus}>
                <AuthCode
                    containerClassName="react-input-code"
                    inputClassName="input-code-item"
                    allowedCharacters="numeric"
                    length={6}
                    ref={inputRef}
                    placeholder={' '}
                    onChange={onCodeInputChange}
                />
            </div>

            {errorTip && <div className="code-error">{errorTip}</div>}

            <Button
                className="account-submit-btn"
                loading={resetLoading || verifyLoading || loadBindingLoading || deleteLoginAccountLoading}
                onClick={() => {
                    setTimeout(() => {
                        confirmCode(code);
                    });
                }}
                disabled={code.length !== 6}
            >
                {t('common.confirm')}
            </Button>

            <Button className="send-again" onClick={requestVerifyCode} disabled={countdown > 0}>
                {countdown > 0 ? `${t('login.send_again')} (${countdown}s)` : t('login.send_again')}
            </Button>
            <div
                className="back"
                onClick={() => {
                    route(-1);
                }}
            >
                <SVGIcon className="arrow1-icon" name="arrow1_icon" />
                <span>{t('login.back')}</span>
            </div>

            <PowerFooter />
            <TurnstileModal
                onError={() => {
                    message.error(t('error.server_20112'));
                }}
                onSuccess={(token) => {
                    runGetCode({
                        verify_code_method: verifyCodeMethod,
                        cf_turnstile_response: token,
                    });
                }}
                visible={turnstileVisible}
                setVisible={setTurnstileVisible}
            ></TurnstileModal>
        </div>
    );
};

export default AccountVerify;
