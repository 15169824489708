import { ToBufferInputTypes, hashPersonalMessage, arrToBufArr } from '@ethereumjs/util';
import { keccak256 } from 'ethereum-cryptography/keccak';
import { SignTypedDataVersion, MessageTypes, TypedDataV1, TypedMessage, TypedDataUtils } from '@metamask/eth-sig-util';
import { isNullish, legacyToBuffer } from './common-utils';
import { solidityPack } from 'ethereumjs-abi';

export function personalSignHash({ data }: { data: ToBufferInputTypes }) {
    if (isNullish(data)) {
        throw new Error('Missing data parameter');
    }

    const message = legacyToBuffer(data);
    const msgHash = hashPersonalMessage(message);
    return msgHash;
}

export function signTypedDataHash<V extends SignTypedDataVersion, T extends MessageTypes>({
    data,
    version,
}: {
    data: V extends 'V1' ? TypedDataV1 : TypedMessage<T>;
    version: V;
}) {
    if (isNullish(data)) {
        throw new Error('Missing data parameter');
    }

    const messageHash =
        version === SignTypedDataVersion.V1
            ? _typedSignatureHash(data as TypedDataV1)
            : TypedDataUtils.eip712Hash(
                  data as TypedMessage<T>,
                  version as SignTypedDataVersion.V3 | SignTypedDataVersion.V4
              );
    return messageHash;
}

/**
 * Generate the "V1" hash for the provided typed message.
 *
 * The hash will be generated in accordance with an earlier version of the EIP-712
 * specification. This hash is used in `signTypedData_v1`.
 *
 * @param typedData - The typed message.
 * @returns The hash representing the type of the provided message.
 */
function _typedSignatureHash(typedData: TypedDataV1): Buffer {
    const error = new Error('Expect argument to be non-empty array');
    if (typeof typedData !== 'object' || !('length' in typedData) || !typedData.length) {
        throw error;
    }

    const data = typedData.map(function (e) {
        if (e.type !== 'bytes') {
            return e.value;
        }

        return legacyToBuffer(e.value);
    });
    const types = typedData.map(function (e) {
        return e.type;
    });
    const schema = typedData.map(function (e) {
        if (!e.name) {
            throw error;
        }
        return `${e.type} ${e.name}`;
    });

    return arrToBufArr(
        keccak256(
            solidityPack(
                ['bytes32', 'bytes32'],
                [
                    keccak256(solidityPack(new Array(typedData.length).fill('string'), schema)),
                    keccak256(solidityPack(types, data)),
                ]
            )
        )
    );
}
