import { Buffer } from 'buffer';
import pnApi from '../api/pn-api';
import tokenProvider from '../provider';
// import type { EncryptCommandInput, GenerateDataKeyResponse } from '@aws-sdk/client-kms';
/**
 * @link https://github.com/aws/aws-sdk-js-v3/tree/5375c91f8a4d2cac6918885843718f47ce82e5d5/packages/credential-providers
 */
async function createKMSClient() {
    const [AWS, fromCognitoIdentity, NotAuthorizedException, LZUTF8] = await Promise.all([
        import('@aws-sdk/client-kms'),
        import('@aws-sdk/credential-providers').then((m) => m.fromCognitoIdentity),
        import('@aws-sdk/client-cognito-identity').then((m) => m.NotAuthorizedException),
        import('lzutf8').then((m) => m.default),
    ]);

    const credentials = fromCognitoIdentity({
        // Required. The unique identifier for the identity against which credentials
        // will be issued.
        identityId: tokenProvider.userInfo.cognito_result.identity_id,
        // Optional. A set of name-value pairs that map provider names to provider tokens.
        // Required when using identities associated with external identity providers such as Facebook.
        logins: {
            'cognito-identity.amazonaws.com': tokenProvider.userInfo.cognito_result.id_token,
        },
        // Optional. Custom client config if you need overwrite default Cognito Identity client
        // configuration.
        clientConfig: { region: tokenProvider.userInfo.cognito_result.region },
    });

    return {
        kmsClient: new AWS.KMS({
            region: tokenProvider.userInfo.cognito_result.region,
            credentials: credentials,
        }),
        NotAuthorizedException,
        LZUTF8,
    };
}

export async function generateDataKey(): Promise<any> {
    const { kmsClient, NotAuthorizedException } = await createKMSClient();

    let output;
    try {
        output = await kmsClient.generateDataKey({
            KeyId: tokenProvider.userInfo.cognito_result.kms_key_id,
            KeySpec: 'AES_256',
        });
    } catch (e) {
        console.log('generateDataKey', e);
        if (e instanceof NotAuthorizedException) {
            await pnApi.loginCognito();
            return await generateDataKey();
        } else {
            throw e;
        }
    }

    return output;
}

export async function decryptDataKey(encryptDataKey: string): Promise<string> {
    const { kmsClient, NotAuthorizedException } = await createKMSClient();

    let dataKey;
    try {
        const output = await kmsClient.decrypt({
            KeyId: tokenProvider.userInfo.cognito_result.kms_key_id,
            CiphertextBlob: Buffer.from(encryptDataKey, 'base64'),
        });
        dataKey = output.Plaintext;
    } catch (e) {
        console.log('decrypt data key error', e);
        if (e instanceof NotAuthorizedException) {
            await pnApi.loginCognito();
            const result = await decryptDataKey(encryptDataKey);
            return result;
        } else {
            throw e;
        }
    }

    if (!dataKey) {
        throw new Error('KMS decryption failed, please try again later.');
    }
    return Buffer.from(dataKey).toString('base64');
}

/**
 * encrypt data
 * @param data
 * @param compress
 * @link https://docs.aws.amazon.com/zh_cn/kms/latest/developerguide/programming-encryption.html
 * @link https://github.com/aws/aws-sdk-js-v3/tree/main/clients/client-kms
 */
export async function encrypt(data: string, compress = false): Promise<string> {
    const { kmsClient, NotAuthorizedException, LZUTF8 } = await createKMSClient();

    let output;
    if (compress) {
        output = LZUTF8.compress(data);
    } else {
        output = Buffer.from(data);
    }

    const args: any = {
        KeyId: tokenProvider.userInfo.cognito_result.kms_key_id,
        Plaintext: output,
    };
    let CiphertextBlob;
    try {
        const output = await kmsClient.encrypt(args);
        CiphertextBlob = output.CiphertextBlob;
    } catch (e) {
        console.log('encrypt catch ', e);
        if (e instanceof NotAuthorizedException) {
            await pnApi.loginCognito();
            const txt = await encrypt(data, compress);
            console.log('encrypt data: ', txt);
            return txt;
        }
    }

    if (!CiphertextBlob) {
        throw new Error('encrypt error');
    }
    return Buffer.from(CiphertextBlob).toString('base64');
}

/**
 * decrypt data
 * @param data
 * @param decompress
 * @link https://docs.aws.amazon.com/zh_cn/kms/latest/developerguide/programming-encryption.html
 */
export async function decrypt(data: string, decompress = false): Promise<string> {
    const { kmsClient, NotAuthorizedException, LZUTF8 } = await createKMSClient();

    let Plaintext;
    try {
        const output = await kmsClient.decrypt({
            KeyId: tokenProvider.userInfo.cognito_result.kms_key_id,
            CiphertextBlob: Buffer.from(data, 'base64'),
        });
        Plaintext = output.Plaintext;
    } catch (e) {
        console.log('KMS decrypt error', e);
        if (e instanceof NotAuthorizedException) {
            await pnApi.loginCognito();
            const txt = await decrypt(data, decompress);
            return txt;
        }
    }

    if (!Plaintext) {
        throw new Error('KMS decryption failed, please try again later.');
    }

    if (decompress) {
        const output = LZUTF8.decompress(Plaintext);
        return Buffer.from(output).toString('utf8');
    } else {
        return Buffer.from(Plaintext).toString('utf8');
    }
}
