import React from 'react';

const IconEditPen = () => {
    return (
        <svg width={10.577} height={10.492} viewBox="0 0 10.577 10.492">
            <defs>
                <style>
                    {
                        '.cls-1{fill:currentColor;stroke-width:0.2px}.cls-1,.cls-2{stroke:currentColor}.cls-2{fill:none;stroke-width:0.8px}'
                    }
                </style>
            </defs>
            <g id="组_120" transform="translate(-869.458 -38.834)">
                <path
                    id="Fill_583"
                    className="cls-1"
                    d="M2.337 10.292H.356A.356.356 0 0 1 0 9.936V7.955a1.29 1.29 0 0 1 .385-.924L7.033.382a1.3 1.3 0 0 1 1.843 0l1.035 1.031a1.311 1.311 0 0 1 0 1.847L3.262 9.909a1.306 1.306 0 0 1-.925.383Zm3.718-7.926L.888 7.534a.583.583 0 0 0-.176.421V9.58h1.625a.612.612 0 0 0 .423-.174l5.166-5.169-1.871-1.871Zm1.9-1.654a.583.583 0 0 0-.418.173l-.978.976 1.87 1.872.978-.976a.6.6 0 0 0 0-.84L8.373.886a.582.582 0 0 0-.418-.174Z"
                    transform="translate(869.558 38.934)"
                />

                <path id="路径_2122" className="cls-2" d="m0 0 2.389 2.389" transform="translate(870.102 46.23)" />
            </g>
        </svg>
    );
};
export default React.memo(IconEditPen);
