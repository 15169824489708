import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import './error.less';
import { Button } from 'antd';
import redirectToApp, { AuthError } from '../../utils/redirect-utils';
import url from '../../utils/url-utils';
import { lazy, Suspense } from 'react';

const Lottie = lazy(() => import('lottie-react'));
const wrongData = require('../../common/lottie/wrong.json');

function Error(props: any) {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [error, setError] = useState<AuthError>();

    useEffect(() => {
        const hashPath = window.location.hash;
        console.log(hashPath);
        if (hashPath.includes('params')) {
            setMessage(t('error.params_error'));
            setError(AuthError.paramsError());
        } else if (hashPath.includes('login')) {
            setMessage(t('error.not_login_error'));
            const err = AuthError.notLogin();
            setError(err);
            errorBack(err);
        } else if (hashPath.includes('redirect') && url.getQueryVariable('data')) {
            let error;
            try {
                error = JSON.parse(decodeURIComponent(url.getQueryVariable('data')!));
            } catch (e) {
                console.error('error page parse data', e);
                error = AuthError.systemError();
            }
            setError(error);
            if (error.message) {
                setMessage(error.message);
            }
        } else {
            setMessage(t('error.unknown'));
            setError(AuthError.systemError());
        }
    }, []);

    const errorBack = (err?: AuthError) => {
        redirectToApp(
            {
                error: err ?? error,
            },
            false
        );
    };

    return (
        <div className="error-box">
            <Suspense fallback={<></>}>
                <Lottie className="error-gif" animationData={wrongData} loop={false} />
            </Suspense>
            <div className="error-msg">{message}</div>
            <Button type="primary" className="return-button" onClick={() => errorBack()}>
                {t('login.return_to_the_app')}
            </Button>
        </div>
    );
}

export default Error;
