import { Button, Modal, message } from 'antd';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthType } from '../../../api/model/login-type';
import { UserInfo } from '../../../api/model/user-info';
import pnApi from '../../../api/pn-api';
import AlertModal from '../../../components/alertModal';
import Header from '../../../components/header';
import SVGIcon from '../../../components/icon/svg-icon';
import { useUserInfo } from '../../../context';
import { authLogin } from '../../../oauth';
import tokenProvider from '../../../provider';
import { authReceiveMessage, isInIframe, popupwindow } from '../../../utils/common-utils';
import { passkeysRegistration } from '../../../utils/passkeys';
import { getAccountList } from '../../loginAccount';
import './index.less';

const LoginAccountBindLoading = () => {
    const route = useNavigate();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const { userInfo, setUserInfo } = useUserInfo();
    const [displayOpenWindowButton, setDisplayOpenWindowButton] = useState(false);
    const [openWindowSuccess, setOpenWindowSuccess] = useState(false);
    const [openWindowParams, setWindowParams] = useState<any>({});
    const [createPassKeysError, setCreatePassKeysError] = useState<string>();
    const accountList = useMemo(() => {
        return getAccountList({ userInfo, t });
    }, [userInfo]);

    const state = (location.state as any) || {};

    const authItem = useMemo(() => {
        const { authType = 'google', verifyToken } = state || {};
        const item = (accountList.find((item) => item.type == authType) || {}) as any;
        return {
            ...item,
            // icon: require('@/common/images/' + item?.type + '_fff_icon.svg'),
        };
    }, [accountList, state]);

    const openWindow = async () => {
        const { authType, verifyToken } = state;
        let res = await authLogin(authType as AuthType, verifyToken);
        const { win, winParams } = res || {};
        setWindowParams(winParams);
        if (win) {
            setOpenWindowSuccess(true);
        }
        return win;
    };

    useEffect(() => {
        if (state.authType === AuthType.passkeys) {
            createPassKeys();
            if (isInIframe()) {
                const messageHandler = (event: MessageEvent) => {
                    const data = event.data;
                    if (data?.name === 'particle-network-create-passkeys') {
                        if (data.error) {
                            message.error(data.error);
                            route(-1);
                        } else {
                            bindPassKeys(data.data);
                        }
                    }
                };
                window.addEventListener('message', messageHandler);
                return () => {
                    window.removeEventListener('message', messageHandler);
                };
            }
        } else {
            openWindow().then((win) => {
                if (!win) {
                    setDisplayOpenWindowButton(true);
                }
            });

            const messageKey = 'particle-network-bind-account';

            const debouncedFunction = debounce((data) => {
                if (data.state == 'success') {
                    const newUserInfo = data.data;
                    const _userInfo = tokenProvider.userInfo;
                    if (state.authType === AuthType.google) {
                        _userInfo.google_email = newUserInfo.google_email;
                        _userInfo.google_id = newUserInfo.google_id;
                        tokenProvider.userInfo = _userInfo;
                        setUserInfo(tokenProvider.userInfo);
                    } else if (state.authType === AuthType.facebook) {
                        _userInfo.facebook_email = newUserInfo.facebook_email;
                        _userInfo.facebook_id = newUserInfo.facebook_id;
                        tokenProvider.userInfo = _userInfo;
                        setUserInfo(tokenProvider.userInfo);
                    } else if (state.authType === AuthType.twitter) {
                        _userInfo.twitter_email = newUserInfo.twitter_email;
                        _userInfo.twitter_id = newUserInfo.twitter_id;
                        tokenProvider.userInfo = _userInfo;
                        setUserInfo(tokenProvider.userInfo);
                    } else {
                        console.log('error');
                    }

                    // 提示弹出消息
                    message.success(t('new.bind_login_account_success'));
                    // back
                    route(-1);
                } else {
                    // 提示弹出消息
                    let messageValue = '';
                    let { message, errorCode } = data.data || {};
                    if (errorCode === 20109) {
                        // Account already exists
                        messageValue =
                            state.authType == AuthType.twitter
                                ? t('error.server_twitter_20109')
                                : state.authType == AuthType.facebook
                                ? t('error.server_facebook_20109')
                                : state.authType == AuthType.google
                                ? t('error.server_google_20109')
                                : '';
                    }

                    Modal.error({
                        title: messageValue || message || 'Bind account failed',
                        onOk: () => {
                            console.log('delay close window');
                        },
                    });

                    route(-1);
                }
            }, 100);

            const { destroyMessage } = authReceiveMessage(messageKey, debouncedFunction);

            return () => {
                destroyMessage();
            };
        }
    }, [state]);

    const createPassKeys = async () => {
        if (isInIframe()) {
            const url = `${window.location.origin}?uuid=${userInfo.uuid}&account=${encodeURIComponent(
                userInfo.email!
            )}&projectUuid=${tokenProvider.params.project_uuid}&theme_type=${tokenProvider.themeType}#/create-passkeys`;
            const iframeWidth = 475;
            const iframeHeight = 770;
            const win = popupwindow(url, 'oauth-login-window', iframeWidth, iframeHeight);
            setWindowParams({
                loginUrl: url,
                title: 'oauth-login-window',
                iframeWidth,
                iframeHeight,
            });
            if (win) {
                setOpenWindowSuccess(true);
            } else {
                setDisplayOpenWindowButton(true);
            }
        } else {
            setOpenWindowSuccess(true);
            try {
                const data = await passkeysRegistration({
                    uuid: userInfo.uuid,
                    account: userInfo.email!,
                    projectUuid: tokenProvider.params.project_uuid,
                });
                bindPassKeys(data);
            } catch (error: any) {
                setCreatePassKeysError(error.message?.split(' See: https')?.[0] || error);
            }
        }
    };

    const bindPassKeys = async (data: any) => {
        const { verifyToken } = state;
        const { challenge, response } = data;
        let newUserInfo: UserInfo | undefined;
        try {
            newUserInfo = await pnApi.loginBindingsThirdparty({
                provider: AuthType.passkeys,
                thirdparty_code: JSON.stringify({ challenge, response }),
                security_account_verify_token: verifyToken,
            });
        } catch (error: any) {
            console.error('bind passkeys error', error);
            message.error(error.message || 'Bind account failed');
            route(-1);
        }
        if (newUserInfo) {
            const _userInfo = tokenProvider.userInfo;
            _userInfo.passkeys_id = newUserInfo.passkeys_id;
            tokenProvider.userInfo = _userInfo;
            setUserInfo(tokenProvider.userInfo);
            // 提示弹出消息
            message.success(t('new.bind_login_account_success'));
            // back
            route(-1);
        }
    };

    // @ts-ignore
    window.__authItem = authItem;
    // @ts-ignore
    window.__setLoading = setLoading;
    // @ts-ignore
    window.__loading = loading;
    // @ts-ignore
    window.__Modal = Modal;
    // @ts-ignore
    window.__message = message;
    // @ts-ignore
    window.__state = state;
    // @ts-ignore
    window.__displayOpenWindowButton = displayOpenWindowButton;

    return (
        <div className="account-bind-container">
            <Header displayBackBtn={true}></Header>
            <>
                <div className="particle-connect-form-contaier center-center flex-column">
                    {loading ? (
                        <>
                            <div className="particle-loading">
                                <SVGIcon name={authItem.type + '_icon'} className="logo-img logo-img-2" />
                                <h3>
                                    {authItem?.type == AuthType.google
                                        ? t('new.opening_google')
                                        : authItem?.type == AuthType.facebook
                                        ? t('new.opening_facebook')
                                        : authItem?.type == AuthType.twitter
                                        ? t('new.opening_twitter')
                                        : authItem?.type == AuthType.passkeys
                                        ? t('new.opening_passkeys')
                                        : ''}
                                    ...
                                </h3>
                                <p>{t('new.confirm_connection')}</p>
                                {displayOpenWindowButton && (
                                    <Button
                                        type="primary"
                                        className="link_btn primary-antd-btn"
                                        onClick={() => {
                                            const { loginUrl, title, iframeWidth, iframeHeight } = openWindowParams;
                                            popupwindow(loginUrl, title, iframeWidth, iframeHeight);
                                        }}
                                    >
                                        {state.authType == AuthType.google
                                            ? t('new.click_to_link_with_google')
                                            : state.authType == AuthType.facebook
                                            ? t('new.click_to_link_with_facebook')
                                            : state.authType == AuthType.twitter
                                            ? t('new.click_to_link_with_twitter')
                                            : state.authType == AuthType.passkeys
                                            ? t('new.click_to_link_with_passkeys')
                                            : ''}
                                    </Button>
                                )}
                                {openWindowSuccess && (
                                    <p>
                                        <img
                                            className="particle-loading-img"
                                            width={30}
                                            height={30}
                                            src={require('@/common/images/loading.png')}
                                            alt=""
                                        />
                                    </p>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="result-content resultsuccess">
                                <img src={authItem?.icon} alt="" />
                                <div className="info">Binding succeeded！</div>
                                <div className="back">
                                    <Button>Back</Button>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </>

            {state.authType === AuthType.passkeys && (
                <AlertModal
                    okText={t('common.retry')}
                    cancelText={t('common.cancel')}
                    closable={false}
                    onOk={() => {
                        setCreatePassKeysError(undefined);
                        createPassKeys();
                    }}
                    onCancel={() => {
                        setCreatePassKeysError(undefined);
                        route(-1);
                    }}
                    visible={createPassKeysError != undefined}
                    contentMessage={createPassKeysError || ''}
                ></AlertModal>
            )}
        </div>
    );
};

export default LoginAccountBindLoading;
