import type { PublicKey } from '@solana/web3.js';

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = 'ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL';

const solana = {
    async findAssociatedTokenAddress(walletAddress: string, tokenMintAddress: string): Promise<PublicKey> {
        const { TOKEN_PROGRAM_ID } = await import('@solana/spl-token');
        const { PublicKey } = await import('@solana/web3.js');
        return PublicKey.findProgramAddressSync(
            [
                new PublicKey(walletAddress).toBuffer(),
                TOKEN_PROGRAM_ID.toBuffer(),
                new PublicKey(tokenMintAddress).toBuffer(),
            ],
            new PublicKey(SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID)
        )[0];
    },
};

export default solana;
