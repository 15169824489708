import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApiError } from '../../../api/api-error';
import pnApi from '../../../api/pn-api';
import { useParticleAuth } from '../../../context';
import tokenProvider from '../../../provider';
import hashPassword from '../../../utils/hashPassword';
import ParticleDrawer from '../../sign/components/particle-drawer';
import ParticleKeywords from '../ParticleKeywords';
import './index.less';
export interface PaymentVerify {
    visible: boolean;
    onVerifyCompleted?: (password: string) => void;
    onVerifyFailed?: (message: string) => void;
    type?: 'close' | 'verify';
}

//支付时验证
const PaymentVerifyModal = ({ props }: { props: PaymentVerify }) => {
    const { setPaymentVerify, setWrongPassword } = useParticleAuth();
    const { t } = useTranslation();

    const [pwd, setPwd] = useState('');

    useEffect(() => {
        if (props.visible) {
            setPwd('');
        }
    }, [props.visible]);

    const { loading: verifyLoading, run: runVerify } = useRequest(pnApi.securityPaymentsVerify, {
        manual: true,
        onSuccess: (result, params) => {
            setPaymentVerify({ visible: false });
            tokenProvider.paymentToken = result;
            props.onVerifyCompleted?.(hashPassword(pwd));
        },
        onError: (error: any) => {
            console.log('verify password error', error);
            setPwd('');
            if (error?.error_code === ApiError.WrongPaymentPassword) {
                setWrongPassword({ visible: true });
            } else if (error?.error_code === ApiError.SecurityAccountFrozen) {
                const seconds = error.extra.seconds || 0;
                setWrongPassword({
                    visible: true,
                    accountFrozen: {
                        seconds: seconds,
                    },
                });
            }
        },
    });

    const closeVerify = () => {
        if (verifyLoading) return false;
        setPaymentVerify({ visible: false });
        props.onVerifyFailed?.(t('common.cancel'));
    };

    const checkInputPwd = (value: string) => {
        setPwd(value);
        if (value.length === 6) {
            runVerify({
                password: hashPassword(value),
            });
        }
    };

    return (
        <ParticleDrawer
            visible={props.visible}
            placement="bottom"
            height={421}
            closable={false}
            maskClosable={false}
            onClose={closeVerify}
            title={props.type === 'close' ? t('account.close_payment_password') : t('account.payment_password')}
        >
            <div className="content payment-verify-content">
                <ParticleKeywords onChange={checkInputPwd} value={pwd} keyboardInvisible={verifyLoading} />
            </div>
        </ParticleDrawer>
    );
};

export default PaymentVerifyModal;
