import { useTranslation } from 'react-i18next';
import './welcome.less';
import { Button } from 'antd';
import PowerFooter from '../../../components/power-footer';
import url from '../../../utils/url-utils';
import { lazy, Suspense } from 'react';

const Lottie = lazy(() => import('lottie-react'));
const rightData = require('../../../common/lottie/right.json');

function Welcome(props: any) {
    const { t } = useTranslation();
    const data = url.getQueryVariable('data');
    const schemeUrl = `pn${url.getQueryVariable('project_app_uuid')}`;

    return (
        <div className="welcome-container">
            <div className="welcome">
                <Suspense fallback={<></>}>
                    <Lottie className="wel-out" animationData={rightData} loop={false} />
                </Suspense>

                <div className="welcome-text">{t('login.welcome')}</div>

                <Button type="primary" className="return-button" href={`${schemeUrl}://callback#${data}`}>
                    {t('login.return_to_the_app')}
                </Button>
            </div>
            <PowerFooter />
        </div>
    );
}

export default Welcome;
