import { Space, Typography } from 'antd';
import { Suspense, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import PowerFooter from '../../../components/power-footer';
import tokenProvider from '../../../provider';
const Lottie = lazy(() => import('lottie-react'));

const walletEncryptLight = require('../../../common/lottie/wallet_encrypt_fff.json');
const walletEncryptDark = require('../../../common/lottie/wallet_encrypt_000.json');

const MasterPasswordLoading = () => {
    const { t } = useTranslation();

    return (
        <div className="mp-loading-container" data-theme={tokenProvider.themeType}>
            <Suspense fallback={<></>}>
                <Lottie
                    className="mp-icon-loading"
                    animationData={tokenProvider.themeType === 'dark' ? walletEncryptDark : walletEncryptLight}
                    loop={true}
                />
            </Suspense>
            <Space className="loading-tip" direction="vertical">
                <Typography.Text>{t('account.master_password_loading_tip1')}</Typography.Text>
                <Typography.Text>{t('account.master_password_loading_tip2')}</Typography.Text>
                <Typography.Text>{t('account.master_password_loading_tip3')}</Typography.Text>
            </Space>
            <PowerFooter />
        </div>
    );
};

export default MasterPasswordLoading;
