import SVGIcon from '../icon/svg-icon';
import './index.less';

const EyeOpen = () => {
    return <SVGIcon className="icon-eye" name="eye_open" />;
};
const EyeClose = () => {
    return <SVGIcon className="icon-eye" name="eye_close" />;
};

export { EyeOpen, EyeClose };
